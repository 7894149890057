import axios from 'axios';
import { GET_HR_USER_SUCCESS, GET_HR_USER_LOADING } from '../actions/actions';

export const getHumanResourceData = () => {
  return (dispatch) => {
    dispatch({
      type: GET_HR_USER_LOADING
    });
    axios
      .get(process.env.REACT_APP_USER_URL + '/employee-directory/users', {
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*'
        }
      })
      .then((response) => {
        dispatch({
          type: GET_HR_USER_SUCCESS,
          payload: {
            hrData: response.data
          }
        });
      });
  };
};
