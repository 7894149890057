import React, { useContext, useEffect, useState } from 'react';

//mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//assests
import filterIcon from '../../assets/icons/filterIconMarket.svg';
import filterSearchIcon from '../../assets/icons/filterSearchIcon.svg';
import SearchIcon from '@material-ui/icons/Search';

//scss
import s from './filter.module.scss';
import { MyContext } from '../../context/MyContext';
import { property } from 'lodash';

const Filter = ({ isMobile, landmarkData, setCurrentIndex, value }) => {
  const { marketplaceFilterBody, setMarketplaceFilterBody } = useContext(MyContext);

  const [priceRange, setPriceRange] = React.useState([0, 100000]);
  const [selectedChipsProperty, setSelectedChipsProperty] = React.useState([]);
  const [selectedChipsRooms, setSelectedChipsRooms] = React.useState([]);
  const [minPrice, setMinPrice] = React.useState(priceRange[0]);
  const [maxPrice, setMaxPrice] = React.useState(priceRange[1]);
  const [status, setStatus] = useState(marketplaceFilterBody.postAvailability);
  const [searchMode, setSearchMode] = useState(false);
  const [expanded, setExpanded] = useState({
    budget: false,
    propertyType: false,
    rooms: false,
    localities: false
  });

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  useEffect(() => {
    setStatus(marketplaceFilterBody.postAvailability);
    setPriceRange([0, 100000]);
    setSelectedChipsProperty([]);
    setSelectedChipsRooms([]);
    setSelectedLocalities([]);

    setMinPrice(0);
    setMaxPrice(100000);
    setSearchMode(false);
    setExpanded({
      budget: false,
      propertyType: false,
      rooms: false,
      localities: false
    });
  }, [value]);

  const handleChangePostAvailability = (e) => {
    setStatus(e.target.value);

    setMarketplaceFilterBody({
      ...marketplaceFilterBody,
      propertyTypes: ['APARTMENT', 'PG', 'INDEPENDENT_FLOOR', 'FULL_RESIDENCE'],
      numberOfBedrooms: [],
      furnishingTypes: ['FURNISHED', 'SEMI_FURNISHED', 'UNFURNISHED'],
      minPrice: 0,
      maxPrice: 100000,
      postAvailability: e.target.value,
      suitableFor: ['FAMILY', 'BACHELORS', 'ANY'],
      locations: [],
      landmarks: []
    });
    setSelectedLocalities([]);
    setSelectedChipsProperty([]);
    setSelectedChipsRooms([]);
    setMaxPrice(priceRange[1]);
    setMinPrice(priceRange[0]);
    setExpanded({
      budget: false,
      propertyType: false,
      rooms: false,
      localities: false
    });
    // }
    setCurrentIndex(0);
  };

  const [dynamicLocalities, setDynamicLocalities] = useState([]);

  useEffect(() => {
    setDynamicLocalities(landmarkData);
  }, [landmarkData]);

  const defaultMaxValue = 100000;

  const handleMinPriceChange = (event) => {
    const value = event.target.value;
    let parsedValue = parseFloat(value);

    // Check if parsed value is NaN or negative
    if (isNaN(parsedValue) || parsedValue < 0) {
      parsedValue = 0;
    }

    // Ensure min value is less than or equal to max value
    if (parsedValue > maxPrice) {
      parsedValue = maxPrice;
    }

    setMinPrice(parsedValue);
    setMarketplaceFilterBody({ ...marketplaceFilterBody, minPrice: parsedValue });
  };

  const handleMaxPriceChange = (event) => {
    const value = event.target.value;
    let parsedValue = parseFloat(value);

    // Check if parsed value is NaN, negative, or exceeds the default max value
    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > defaultMaxValue) {
      parsedValue = defaultMaxValue;
    }

    // Ensure max value is greater than or equal to min value
    if (parsedValue < minPrice) {
      parsedValue = minPrice;
    }

    setMaxPrice(parsedValue);
    setMarketplaceFilterBody({
      ...marketplaceFilterBody,
      maxPrice: parsedValue
    });
  };

  const handlePriceChange = (event, newPriceRange) => {
    setPriceRange(newPriceRange);
    setMinPrice(newPriceRange[0]);
    setMaxPrice(newPriceRange[1]);
  };
  const handleNewPriceChange = (event, newPriceRange) => {
    setPriceRange(newPriceRange);
    setMinPrice(newPriceRange[0]);
    setMaxPrice(newPriceRange[1]);
    setMarketplaceFilterBody({
      ...marketplaceFilterBody,
      minPrice: newPriceRange[0],
      maxPrice: newPriceRange[1]
    });
  };

  const handleChipClick = (type, value) => {
    // Handle chip click to add or remove filters
    if (type === 'property') {
      const index = selectedChipsProperty.indexOf(value);
      const newChips = [...selectedChipsProperty];

      if (index === -1) {
        newChips.push(value);
      } else {
        newChips.splice(index, 1);
      }
      setSelectedChipsProperty(newChips);
      setMarketplaceFilterBody({
        ...marketplaceFilterBody,
        propertyTypes: newChips?.length
          ? newChips
          : ['APARTMENT', 'PG', 'INDEPENDENT_FLOOR', 'FULL_RESIDENCE']
      });
    } else if (type === 'rooms') {
      const index = selectedChipsRooms.indexOf(value);
      const newChips = [...selectedChipsRooms];

      if (index === -1) {
        newChips.push(value);
      } else {
        newChips.splice(index, 1);
      }
      setSelectedChipsRooms(newChips);
      // Check if 'morethan3' is present in newChips
      if (newChips.includes('more than 3')) {
        // If 'morethan3' is present, replace it with [4,5,6,7,8]
        const updatedChips = newChips
          .filter((chip) => chip !== 'more than 3')
          .concat(['4', '5', '6', '7', '8']);
        setMarketplaceFilterBody({
          ...marketplaceFilterBody,
          numberOfBedrooms: updatedChips
        });
      } else {
        // If 'morethan3' is not present, remove [4,5,6,7,8]
        const updatedChips = newChips.filter((chip) => !['4', '5', '6', '7', '8'].includes(chip));
        setMarketplaceFilterBody({
          ...marketplaceFilterBody,
          numberOfBedrooms: updatedChips
        });
      }
    }
  };

  const filterOptions = [
    { label: 'Apartment', value: 'APARTMENT' },
    { label: 'PG', value: 'PG' },
    { label: 'Full Residence', value: 'FULL_RESIDENCE' },
    { label: 'Indepedent Floor', value: 'INDEPENDENT_FLOOR' }
  ];

  const filterOptionsRoom = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '> 3', value: 'more than 3' }
  ];

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };
  const [localities, setLocalities] = useState(
    dynamicLocalities?.reduce((acc, locality) => {
      acc[locality] = false;
      return acc;
    }, {})
  );

  const initialLocalitiesToShow = 4;

  const [visibleLocalities, setVisibleLocalities] = useState(
    dynamicLocalities?.slice(0, initialLocalitiesToShow)
  );

  const [searchTerm, setSearchTerm] = useState('');

  const [selectedLocalities, setSelectedLocalities] = useState([]);

  const handleLocalityChange = (locality) => {
    setLocalities((prevLocalities) => ({
      ...prevLocalities,
      [locality]: !prevLocalities[locality]
    }));
    setSelectedLocalities((prevSelectedLocalities) => {
      if (!prevSelectedLocalities.includes(locality)) {
        return [...prevSelectedLocalities, locality];
      } else {
        return prevSelectedLocalities.filter((selectedLocality) => selectedLocality !== locality);
      }
    });

    // Set the locations array in marketplaceFilterBody
    const updatedLocations = selectedLocalities.includes(locality)
      ? selectedLocalities.filter((selectedLocality) => selectedLocality !== locality)
      : [...selectedLocalities, locality];
    setMarketplaceFilterBody({
      ...marketplaceFilterBody,
      locations: updatedLocations.length ? updatedLocations : []
    });
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    const filteredLocalities = dynamicLocalities?.filter((locality) =>
      locality.toLowerCase().includes(value.toLowerCase())
    );

    const finalLocalities = [...selectedLocalities, ...filteredLocalities];
    const uniqueLocalities = [...new Set(finalLocalities)];

    setVisibleLocalities(uniqueLocalities);
  };

  const handleSearchClick = () => {
    handleShowMoreClick();
    setSearchMode(true);
    setSearchTerm('');
    const allLocalities = dynamicLocalities?.filter(
      (locality) => !selectedLocalities.includes(locality)
    );
    setVisibleLocalities(allLocalities);
  };
  const [showMore, setShowMore] = useState(false);

  const updateVisibleLocalities = () => {
    const remainingLocalities = dynamicLocalities?.filter(
      (locality) => !selectedLocalities.includes(locality)
    );
    const newVisibleLocalities = showMore
      ? [...selectedLocalities, ...remainingLocalities]
      : dynamicLocalities?.slice(0, initialLocalitiesToShow);
    setVisibleLocalities(newVisibleLocalities);
  };

  useEffect(() => {
    updateVisibleLocalities();
  }, [selectedLocalities, showMore, dynamicLocalities]);

  const handleShowMoreClick = () => {
    setShowMore(true);
  };

  const renderSelectedFilters = () => {
    if (
      selectedChipsProperty.length === 0 &&
      selectedChipsRooms.length === 0 &&
      selectedLocalities.length === 0
    ) {
      return null;
    }

    return (
      <div style={{ width: isMobile ? '100%' : '252px' }}>
        <Typography
          variant="h6"
          style={{ marginTop: '10px', marginBottom: '6px', fontWeight: 'bold', fontSize: '14px' }}
        >
          Selected Filters:
        </Typography>
        {/* Render selected property type filters */}
        {selectedChipsProperty.length > 0 && (
          <div>
            <Typography variant="body2" className={s.chipHead}>
              Property Type
            </Typography>
            <div>
              {selectedChipsProperty.map((selectedType) => (
                <Chip
                  key={selectedType}
                  label={selectedType}
                  className={s.chipStyle}
                  onDelete={() => handleChipClick('property', selectedType)}
                />
              ))}
            </div>
          </div>
        )}

        {/* Render selected rooms filters */}
        {selectedChipsRooms.length > 0 && (
          <div className="mt-2">
            <Typography variant="body2" className={s.chipHead}>
              Number of Rooms
            </Typography>
            <div>
              {selectedChipsRooms.map((selectedRoom) => (
                <Chip
                  key={selectedRoom}
                  className={s.chipStyle}
                  label={`${selectedRoom} BHK`}
                  onDelete={() => handleChipClick('rooms', selectedRoom)}
                />
              ))}
            </div>
          </div>
        )}

        {/* Render selected localities filters */}
        {selectedLocalities.length > 0 && (
          <div className="mt-2">
            <Typography variant="body2" className={s.chipHead}>
              Selected Localities
            </Typography>
            <div>
              {selectedLocalities.map((selectedLocality) => (
                <Chip
                  key={selectedLocality}
                  className={s.chipStyle}
                  label={selectedLocality}
                  onDelete={() => handleLocalityChange(selectedLocality)}
                />
              ))}
            </div>
          </div>
        )}
        <Divider style={{ margin: '20px 0' }} />
      </div>
    );
  };

  return (
    <div
      className={` ${!isMobile && 'col-4'} mb-4 pb-4 ${s.filterContainer}`}
      style={{
        pointerEvents: value !== 1 && 'none',
        backgroundColor: value === 1 ? 'white' : '#D4D4D4',
      }}
    >
      <div
        className="d-flex pt-2"
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: value === 1 ? 'white' : '#D4D4D4',
          zIndex: 1
        }}
      >
        <div className="px-2 py-2">
          <img src={filterIcon} alt="filterIcon" style={{ width: '34px', height: '34px' }} />
        </div>
        <div>
          <p
            className="lead-font-weight my-3"
            style={{
              color: '#282D30',
              fontSize: '15px',
              fontWeight: '600'
            }}
          >
            Filter By
          </p>
        </div>
      </div>
      {renderSelectedFilters()}
      <div className="px-2" style={{ width: isMobile ? '100%' : '252px' }}>
        <div className="px-1 py-2">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={status}
              onChange={handleChangePostAvailability}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="AVAILABLE"
                control={<Radio />}
                label={
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: 'black',
                      fontWeight: 550
                    }}
                  >
                    Active
                  </Typography>
                }
              />
              <FormControlLabel
                value="UNAVAILABLE"
                control={<Radio />}
                label={
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: 'black',
                      fontWeight: 550
                    }}
                  >
                    Inactive
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </div>

        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded.budget}
          onChange={handleExpand('budget')}
          disabled={status === 'UNAVAILABLE'}
          style={{ backgroundColor: status === 'UNAVAILABLE' || value !== 1 ? '#D4D4D4' : '#FFF' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{
                fontSize: '14px',
                color: 'black',
                fontWeight: 550
              }}
            >
              Budget
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={300} display="flex" flexDirection="column" alignItems="center">
              <div
                className="d-flex justify-content-between mt-2 "
                style={{ width: '100%', marginBottom: '-8px' }}
              >
                <Typography
                  variant="body2"
                  className="text-left"
                  style={{
                    fontSize: '14px',
                    color: 'black',
                    fontWeight: 550
                  }}
                >
                  {formatCurrency(minPrice)}
                </Typography>
                <Typography
                  variant="body2"
                  className="text-right"
                  style={{
                    fontSize: '14px',
                    color: 'black',
                    fontWeight: 550
                  }}
                >
                  {formatCurrency(maxPrice)}
                </Typography>
              </div>
              <Slider
                value={priceRange}
                onChange={handlePriceChange}
                onChangeCommitted={handleNewPriceChange}
                aria-labelledby="range-slider"
                valueLabelDisplay="off"
                min={0}
                max={100000}
                step={5000}
                style={{ color: '#205072' }}
              />
              {/* <div className="d-flex justify-content-between mt-2 mb-1">
                <div className="d-flex justify-content-between">
                  <TextField
                    type="number"
                    label="Minimum"
                    value={minPrice}
                    onChange={handleMinPriceChange}
                    InputLabelProps={{
                      style: {
                        fontSize: '14px',
                        color: 'black',
                        fontWeight: '550'
                      }
                    }}
                    InputProps={{
                      style: {
                        fontSize: '14px',
                        color: 'black',
                        fontWeight: '550'
                      }
                    }}
                    style={{ width: '45%' }}
                  />
                  <TextField
                    type="number"
                    label="Maximum"
                    value={maxPrice}
                    onChange={handleMaxPriceChange}
                    InputLabelProps={{
                      style: {
                        fontSize: '14px',
                        color: 'black',
                        fontWeight: '550'
                      }
                    }}
                    InputProps={{
                      style: {
                        fontSize: '14px',
                        color: 'black',
                        fontWeight: '550'
                      }
                    }}
                    style={{ width: '45%', marginLeft: '10px' }}
                  />
                </div>
              </div> */}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded.propertyType}
          onChange={handleExpand('propertyType')}
          disabled={status === 'UNAVAILABLE'}
          style={{ backgroundColor: status === 'UNAVAILABLE' || value !== 1 ? '#D4D4D4' : '#FFF' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{
                fontSize: '14px',
                color: 'black',
                fontWeight: 550
              }}
            >
              Property Type
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={300} display="flex" flexDirection="column" alignItems="center">
              <div style={{ marginTop: '2px', width: '100%' }}>
                {filterOptions?.map((option) => (
                  <Chip
                    key={option.value}
                    label={option.label}
                    color={selectedChipsProperty.includes(option.value) ? 'primary' : 'default'}
                    onClick={() => handleChipClick('property', option.value)}
                    className="ml-2 mt-2"
                    style={{
                      backgroundColor: selectedChipsProperty.includes(option.value)
                        ? '#205072'
                        : 'white',
                      color: selectedChipsProperty.includes(option.value) ? 'white' : '#205072',
                      border: `1px solid #205072`,
                      height: '25px',
                      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                      transition: 'box-shadow 0.3s ease-in-out',
                      fontSize: '11px'
                    }}
                  />
                ))}
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded.rooms}
          onChange={handleExpand('rooms')}
          disabled={status === 'UNAVAILABLE'}
          style={{ backgroundColor: status === 'UNAVAILABLE' || value !== 1 ? '#D4D4D4' : '#FFF' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{
                fontSize: '14px',
                color: 'black',
                fontWeight: 550
              }}
            >
              No. of Bedrooms
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={300} display="flex" flexDirection="column" alignItems="center">
              <div style={{ marginTop: '2px', width: '100%' }}>
                {filterOptionsRoom.map((option) => (
                  <Chip
                    key={option.value}
                    label={option.label}
                    color={selectedChipsRooms.includes(option.value) ? 'primary' : 'default'}
                    onClick={() => handleChipClick('rooms', option.value)}
                    className="ml-2 mt-2"
                    style={{
                      backgroundColor: selectedChipsRooms.includes(option.value)
                        ? '#205072'
                        : 'white',
                      color: selectedChipsRooms.includes(option.value) ? 'white' : '#205072',
                      border: `1px solid #205072`,
                      height: '25px',
                      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                      transition: 'box-shadow 0.3s ease-in-out',
                      fontSize: '11px'
                    }}
                  />
                ))}
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded.localities}
          onChange={handleExpand('localities')}
          disabled={status === 'UNAVAILABLE'}
          style={{ backgroundColor: status === 'UNAVAILABLE' || value !== 1 ? '#D4D4D4' : '#FFF' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{
                fontSize: '14px',
                color: 'black',
                fontWeight: 550
              }}
            >
              City
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ maxHeight: '300px', overflowY: 'auto', marginBottom: '10px' }}>
            <Box display="flex" flexDirection="column">
              {visibleLocalities?.map((locality) => (
                <Box key={locality} display="flex" alignItems="center">
                  <Checkbox
                    checked={selectedLocalities.includes(locality)}
                    onChange={() => handleLocalityChange(locality)}
                  />
                  <Typography
                    style={{ wordWrap: 'break-word', maxWidth: '150px', overflowX: 'hidden' }}
                  >
                    {locality}
                  </Typography>
                </Box>
              ))}
              <Box display="flex" alignItems="center" className="ml-2">
                {searchMode ? (
                  <TextField
                    label="Search Localities"
                    variant="standard"
                    onChange={handleSearchChange}
                    value={searchTerm}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span onClick={() => setSearchMode(false)} style={{ cursor: 'pointer' }}>
                            <SearchIcon style={{ color: '#636874', height: '18px' }} />
                          </span>
                        </InputAdornment>
                      ),
                      inputProps: {
                        style: {
                          marginBottom: '8px'
                        }
                      }
                    }}
                  />
                ) : (
                  <span
                    onClick={handleSearchClick}
                    className=" my-1 d-flex"
                    style={{
                      cursor: 'pointer',
                      color: '#282D30',
                      fontSize: '16px',
                      fontWeight: '500'
                    }}
                  >
                    <img src={filterSearchIcon} alt="searchIcon" className="pr-2" />
                    <p style={{ fontSize: '14px', fontWeight: 550 }}>More Localities</p>
                  </span>
                )}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Filter;
