import React from 'react';

import LoaderStatic from '../../../../../assets/icons/loader-ellipse-animated.svg';

const ProfileLoader = () => {
  return (
    <div style={{ height: '80vh' }} className="d-flex align-items-center justify-content-center">
      <div>
        {' '}
        <img style={{ width: '100px' }} src={LoaderStatic} alt="Loading" />{' '}
        <p style={{ textAlign: 'center' }} className="text-secondary">
          Loading...
        </p>
      </div>
    </div>
  );
};

export default ProfileLoader;
