import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';

import cls from './ConfirmationModal.module.scss';

const SuggestionModal = ({ toggleDialog, setToggleDialog }) => {
  return (
    <div className={cls.modalWrapper}>
      <Dialog
        open={toggleDialog}
        aria-labelledby="simple-dialog-title"
        onClose={() => {
          setToggleDialog(false);
        }}
      >
        <DialogTitle id="simple-dialog-title">Unsaved Changes Detected</DialogTitle>
        <DialogContent className={cls.dialogBox}>
          {' '}
          You have unsaved changes in your current post. Please Save/Discard your changes and try
          again.
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: '#3da9a1', color: 'white' }}
            onClick={() => {
              setToggleDialog(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuggestionModal;
