import {
  GET_PROFILE_LOADING,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  // EDIT_SKILL_IN_PROFILE,
  PROFILE_UPDATE_SUCCESS
} from '../actions/actions';

const initialState = {
  loading: false,
  error: '',
  addLoading: false,
  addError: '',
  details: {}
};

const profileReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_PROFILE_LOADING:
      newState.loading = true;
      break;
    case GET_PROFILE_SUCCESS:
      newState.details = { ...state.details, ...action.payload.details };
      newState.loading = false;
      break;
    case GET_PROFILE_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.payload.details
      };
    default:
      return state;
  }
  return newState;
};

export default profileReducer;
