import {
  GET_EMPID_BY_EMAIL_FAILURE,
  GET_EMPID_BY_EMAIL_SUCCESS,
  GET_EMPID_BY_EMAIL_LOADING,
  GET_PROFILE_BY_EMPID_SUCCESS,
  GET_PROFILE_BY_EMPID_LOADING,
  GET_PROFILE_BY_EMPID_FAILURE
} from '../actions/actions';
const initialState = {
  loading: false,
  error: '',
  addLoading: false,
  addError: '',
  details: {}
};
const profileShareReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_PROFILE_BY_EMPID_LOADING:
      newState.loading = true;
      newState.details = {};
      break;
    case GET_PROFILE_BY_EMPID_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      newState.details = {};
      break;
    case GET_PROFILE_BY_EMPID_SUCCESS:
      newState.details = { ...state.details, ...action.payload.details };
      newState.loading = false;
      //localStorage.setItem('details',newState.details);

      break;

    case GET_EMPID_BY_EMAIL_FAILURE:
      newState.loading = false;
      break;
    case GET_EMPID_BY_EMAIL_SUCCESS:
      newState.error = '';
      newState.loading = false;
      break;
    case GET_EMPID_BY_EMAIL_LOADING:
      newState.loading = true;
      break;
    // case EDIT_SKILL_IN_PROFILE:
    //   // let details = newState.details;
    //   // details.skills = action.payload.skills;
    //   newState.details.skills = action.payload.skills;
    //   break;

    default:
      return state;
  }
  return newState;
};

export default profileShareReducer;
