import {
  EDIT_CERTIFICATION_LOADING,
  EDIT_CERTIFICATION_SUCCESS,
  EDIT_CERTIFICATION_FAILURE,
  GET_CERTIFICATIONS_SUCCESS,
  GET_CERTIFICATIONS_LOADING,
  GET_CERTIFICATIONS_FAILURE,
  GET_TECH_TYPES_SUCCESS,
  GET_TECH_TYPES_LOADING,
  GET_TECH_TYPES_FAILURE
} from '../../actions/actions';

const initialState = {
  certifications: [],
  editLoading: false,
  editError: '',
  getLoading: false,
  getError: '',
  addLoading: false,
  addError: '',
  techTypes: []
};

const certificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CERTIFICATIONS_SUCCESS:
      return {
        ...state,
        getLoading: false,
        certifications: action.payload.certifications,
        getError: ''
      };
    case GET_CERTIFICATIONS_LOADING:
      return {
        ...state,
        getLoading: true
      };
    case GET_CERTIFICATIONS_FAILURE:
      return {
        ...state,
        getLoading: false,
        getError: action.payload.error
      };
    case EDIT_CERTIFICATION_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case EDIT_CERTIFICATION_SUCCESS:
      let certificatesCopy = state.certifications.map((certificate) => {
        if (certificate.certificateId == action.payload.certifications.certificateId) {
          return action.payload.certifications;
        }
        return certificate;
      });
      return {
        ...state,
        editLoading: false,
        certifications: certificatesCopy
      };
    case EDIT_CERTIFICATION_FAILURE:
    case GET_TECH_TYPES_SUCCESS:
      return {
        ...state,
        getLoading: false,
        techTypes: action.payload.techTypes,
        getError: ''
      };
    case GET_TECH_TYPES_LOADING:
      return {
        ...state,
        getLoading: true
      };
    case GET_TECH_TYPES_FAILURE:
      return {
        ...state,
        getLoading: false,
        getError: action.payload.error
      };
    default:
      return state;
  }
};

export default certificationReducer;
