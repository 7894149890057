const locales = {
  prefix: '',
  sufix: 'ago',

  seconds: 'less than a minute',
  minute: 'about a minute',
  minutes: '%d minutes',
  hour: 'about an hour',
  hours: 'about %d hours',
  day: 'a day',
  days: '%d days',
  month: 'about a month',
  months: '%d months',
  year: 'about a year',
  years: '%d years'
};

const TimeAgo = (timeAgo) => {
  var seconds = Math.floor((new Date() - parseInt(timeAgo)) / 1000),
    separator = locales.separator || ' ',
    words = locales.prefix + separator,
    interval = 0,
    intervals = {
      year: seconds / 31536000,
      month: seconds / 2592000,
      day: seconds / 86400,
      hour: seconds / 3600,
      minute: seconds / 60
    };

  var distance = locales.seconds;

  for (var key in intervals) {
    interval = Math.floor(intervals[key]);

    if (interval > 1) {
      distance = locales[key + 's'];
      break;
    } else if (interval === 1) {
      distance = locales[key];
      break;
    }
  }

  distance = distance.replace(/%d/i, interval);
  words += distance + separator + locales.sufix;

  return words.trim();
};

export default TimeAgo;
