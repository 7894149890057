import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';

//scss
import s from './members.module.scss';

//Helper
import { Skeleton } from '@material-ui/lab';
import { connect } from 'react-redux';

const Members = React.memo(
  ({ setShowDrawer, setShowMembers, setShowRequests, users, joinRequestData, ...props }) => {
    const [membersInClub, setMembersInClub] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      users.length > 0 ? setLoading(false) : setLoading(true);

      let temp = users?.filter(function (f1) {
        return props.membersDetails?.some(function (f2) {
          return f1?.email === f2?.userId && f2?.roles.clubRoleId === 2;
        });
      });
      setMembersInClub(temp);
    }, [users]);

    const checkAdmin = () => {
      let isAdmin = props.membersDetails?.find((member) => {
        return member.userId === localStorage.getItem('email') && member.roles.clubRoleId === 1
          ? true
          : false;
      });
      return isAdmin;
    };

    const handleViewDrawer = () => {
      const isAdmin = checkAdmin();

      setShowDrawer(true);
      setShowMembers(!isAdmin || !joinRequestData?.length);
      setShowRequests(isAdmin && joinRequestData?.length > 0);
    };

    return (
      <>
        <div className={s.members}>
          <div className={s.membersTitle}>
            <h4 onClick={() => setShowDrawer(true)}>Members</h4>
            {membersInClub?.length ? (
              <p className={s.viewAll} onClick={() => handleViewDrawer()}>
                View{' '}
                {checkAdmin() && joinRequestData.length ? (
                  <>
                    Requests<span className={s.showRequestBtn}></span>
                  </>
                ) : (
                  'All'
                )}
              </p>
            ) : null}
          </div>
          <div className={s.member}>
            {loading ? (
              <Skeleton variant="text" className={s.memberLoading} />
            ) : (
              membersInClub?.length === 0 && (
                <div className={s.noMember}>No member is present in this Club.</div>
              )
            )}
            <div className={s.membersImgWrapper} onClick={() => handleViewDrawer()}>
              <div className={s.imagesContainer}> {/* wrapper for styling img*/}
                {membersInClub?.map((item) => {
                  item.unique_id = uniqueId();
                  return (
                    <div className={s.memberImages} key={item.unique_id}>
                      <img src={item?.photoUrl ?? item?.misPhotoUrl} alt="user" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

function mapStateToProps(state) {
  return {
    membersDetails: state.clubsReducer.membersDetails
  };
}

export default connect(mapStateToProps)(Members);
