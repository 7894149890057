import * as Yup from 'yup';

const getMaxDate = () => {
  const now = new Date();
  now.setMonth(now.getMonth() + 6);
  // Strip the time part for comparison
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

// Function to get today's date without the time part
const getTodayDate = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

export const currentValidationSchema = Yup.object().shape({
  carPoolType: Yup.string().required('Please select an option'),
  title: Yup.string()
    .trim()
    .required('Please specify Title')
    .max(60, 'Title must be at most 60 characters long'),

  departLocation: Yup.string().trim().required('Please specify Departure Location'),

  destLocation: Yup.string().trim().required('Please specify Destination Location'),

  otherDepartureLocation: Yup.string().when(['departLocation'], {
    is: (departLocation) => departLocation === 'others',
    then: () =>
      Yup.string()
        .trim()
        .required('Please specify other departure location')
        .max(50, 'Departure location must be at most 50 characters long')
  }),

  otherDestLocation: Yup.string().when(['destLocation'], {
    is: (destLocation) => destLocation === 'others',
    then: () =>
      Yup.string()
        .trim()
        .required('Please specify other destination location')
        .max(50, 'Destination location must be at most 50 characters long')
  }),

  departAndDest: Yup.string().test(
    'departAndDest',
    'Departure and destination cannot be the same',
    function () {
      const { otherDepartureLocation, otherDestLocation, departLocation, destLocation } =
        this.parent;

      // Normalize the locations by trimming and converting to lowercase
      const normalizedDepartLocation = departLocation ? departLocation.trim().toLowerCase() : '';
      const normalizedDestLocation = destLocation ? destLocation.trim().toLowerCase() : '';
      const normalizedOtherDepartureLocation = otherDepartureLocation
        ? otherDepartureLocation.trim().toLowerCase()
        : '';
      const normalizedOtherDestLocation = otherDestLocation
        ? otherDestLocation.trim().toLowerCase()
        : '';

      // Check if any of the locations are the same
      if (
        (normalizedOtherDepartureLocation &&
          normalizedOtherDestLocation &&
          normalizedOtherDepartureLocation === normalizedOtherDestLocation) ||
        (normalizedOtherDepartureLocation &&
          normalizedDestLocation &&
          normalizedOtherDepartureLocation === normalizedDestLocation) ||
        (normalizedOtherDestLocation &&
          normalizedDepartLocation &&
          normalizedOtherDestLocation === normalizedDepartLocation) ||
        (normalizedDepartLocation &&
          normalizedDestLocation &&
          normalizedDepartLocation === normalizedDestLocation &&
          normalizedDestLocation !== 'others' &&
          normalizedDepartLocation !== 'others')
      ) {
        return false;
      }
      return true;
    }
  ),

  departDateTime: Yup.date()
    .required('Please specify Departure Date/Time')
    .min(getTodayDate(), 'Departure Date/Time cannot be in the past')
    .max(getMaxDate(), 'Departure Date/Time cannot be more than 6 months from now'),

  totalSeats: Yup.number().when('carPoolType', {
    is: 'DRIVER_POST',
    then: () => Yup.number().required('Please specify Total seats')
  }),

  vehicleInfo: Yup.string().when('carPoolType', {
    is: 'DRIVER_POST',
    then: () =>
      Yup.string()
        .trim()
        .required('Please specify Vehicle details')
        .max(60, 'Vehicle details must be at most 60 characters long')
        .test(
          'is-not-empty-after-trim',
          'Please specify Vehicle details',
          (value) => value.trim().length > 0
        )
  }),

  costPerSeat: Yup.number().when('carPoolType', {
    is: 'DRIVER_POST',
    then: () =>
      Yup.number()
        .typeError('Price must be a number')
        .required('Please specify Price')
        .min(0, 'Cost per seat cannot be negative')
        .max(10000, 'Cost per seat cannot be greater than 10000')
        .integer('Cost per seat cannot be decimal')
  }),

  additionalInfo: Yup.string().trim().max(60, 'Vehicle details must be at most 60 characters long')
});

export const convertToNewFormat = (originalJson) => {
  return {
    poolId: originalJson?.carPool?.poolId,
    postedBy: originalJson?.carPool?.postedBy,
    carPoolType: originalJson?.carPool?.carPoolType,
    departLocation: originalJson?.carPool?.departLocation,
    destLocation: originalJson?.carPool?.destLocation,
    departDateTime: originalJson?.carPool?.departDateTime,
    totalSeats: originalJson?.carPool?.totalSeats,
    availableSeats: originalJson?.carPool?.availableSeats,
    passengerList: originalJson?.carPool?.passengerList,
    costPerSeat: originalJson?.carPool?.costPerSeat,
    vehicleInfo: originalJson?.carPool?.vehicleInfo,
    additionalInfo: originalJson?.carPool?.additionalInfo,
    status: originalJson?.carPool?.status,
    postedOn: originalJson?.carPool?.postedOn,
    updateDate: originalJson?.carPool?.updateDate,
    comment: originalJson?.comment,
    reactions: originalJson?.reactions,
    destDateTime: originalJson?.carPool?.destDateTime
  };
};
