import React, { useState, useEffect, useRef, useContext } from 'react';

//SCSS
import s from '../marketplace.module.scss';

//Assets
import deleteIcon from '../../../assets/icons/deletepost.svg';
import userImg from '../../../assets/img/user.png';
import copylink from '../../../assets/icons/copylink.svg';
import editpost from '../../../assets/icons/editpost.svg';
import CombinedShapeIcon from '../../../assets/img/combinedShape.png';
import inactive from '../../../assets/img/marketPlace/inactive.png';
import active from '../../../assets/img/marketPlace/active.png';

//other imports
import TimeAgo from '../../helpers/timer';
import cns from 'classnames';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import { getProfileByEmail, isUserActive, popUpMessages } from '../../../helpers';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteMarketPost,
  getInctiveActivePost,
  setPost
} from '../../../redux/actionCreators/marketplace.actionCreator';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { MyContext } from '../../../context/MyContext';

const BuySellBody = ({
  data1,
  setData1,
  user,
  users,
  time,
  post,
  isEdited,
  postId,
  setCurrentEditedPost,
  pageNum,
  setPage,
  getValue,
  finalData,
  setFinalData,
  currentEditedPost,
  setCurrentIndex,
  loading,
  postType = 'all',
  selectedSortOption,
  ...props
}) => {
  const getProfile = async (userId, e) => {
    e.stopPropagation();
    getProfileByEmail(userId);
  };
  const data = users && Object.values(users).filter((value) => value.email === post?.postedBy);
  const userData = data && data[0];
  const [togglePostOptions, setTogglePostOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalForInactive, setShowModalForInactive] = useState(false);
  const { marketplaceFilterBody, marketplaceCarPoolFilterBody } = useContext(MyContext);
  const activeStatus = getValue === 1 ? post?.postAvailability : post?.status;

  const history = useHistory();
  const inActivePostToggleModal = () => {
    setShowModalForInactive(!showModalForInactive);
    setTogglePostOptions(false);
  };
  const toggleModal = () => {
    setShowModal(!showModal);
    setTogglePostOptions(false);
  };
  const dispatch = useDispatch();
  const page0Posts = useSelector((state) => state.marketPlace.post);
  const deleteHandler = (e) => {
    e.preventDefault();
    if (postId) {
      dispatch(deleteMarketPost(postId));
      let dataList = finalData.filter((posts) => {
        return posts.postId ? posts.postId !== postId : posts.poolId !== postId;
      });
      let data1List = data1.filter((posts) => {
        return posts.postId ? posts.postId !== postId : posts.poolId !== postId;
      });
      let page0PostsUpdated = Object.values(page0Posts).filter((posts) => {
        return posts.postId ? posts.postId !== postId : posts.poolId !== postId;
      });
      dispatch(setPost(page0PostsUpdated));
      setFinalData(dataList);
      setData1(data1List);
    }
  };

  const inactiveActiveHandler = (e) => {
    e.preventDefault();
    if (postId) {
      setCurrentIndex(postId);
      const postStatus = activeStatus === 'AVAILABLE' ? 'UNAVAILABLE' : 'AVAILABLE';
      dispatch(
        getInctiveActivePost(
          postId,
          postStatus,
          marketplaceFilterBody,
          0,
          1,
          postType,
          selectedSortOption
        )
      );
      setPage(0);
      window.scrollTo(0, 0);
      let dataList = finalData.map((post) => {
        if (post?.postId === postId) {
          return { ...post, postAvailability: postStatus };
        }
        return post;
      });
      let data1List = data1.map((post) => {
        if (post?.postId === postId) {
          return { ...post, postAvailability: postStatus };
        }
        return post;
      });
      let page0PostsUpdated = Object.values(page0Posts).map((post) => {
        if (post?.postId === postId) {
          return { ...post, postAvailability: postStatus };
        }
        return post;
      });

      dispatch(setPost(page0PostsUpdated));
      setFinalData(dataList);
      setData1(data1List);
    }
  };

  const inactiveActiveHandlerCarPool = (e) => {
    e.preventDefault();
    if (postId) {
      setCurrentIndex(postId);
      const postStatus = post?.status === 'AVAILABLE' ? 'UNAVAILABLE' : 'AVAILABLE';
      dispatch(
        getInctiveActivePost(
          postId,
          postStatus,
          marketplaceCarPoolFilterBody,
          0,
          2,
          postType,
          selectedSortOption
        )
      );

      window.scrollTo(0, 0);
      let dataList = finalData.map((post) => {
        if (post?.postId === postId) {
          return { ...post, postAvailability: postStatus };
        }
        return post;
      });
      let data1List = data1.map((post) => {
        if (post?.postId === postId) {
          return { ...post, postAvailability: postStatus };
        }
        return post;
      });
      let page0PostsUpdated = Object.values(page0Posts).map((post) => {
        if (post?.postId === postId) {
          return { ...post, postAvailability: postStatus };
        }
        return post;
      });

      dispatch(setPost(page0PostsUpdated));
      setFinalData(dataList);
      setData1(data1List);
    }
  };

  function capitalizeFirstLetter(inputString) {
    // Check if the input string is not empty
    if (inputString && typeof inputString === 'string') {
      // Capitalize the first letter and convert the rest to lowercase
      return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
    }
    return '';
  }
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTogglePostOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);
  const copyHandler = (e) => {
    const url = window.location.origin;
    const val = url + `/#/marketplace/market-place-buy-sell-post/v-${getValue}/postId/` + postId;
    navigator.clipboard.writeText(val);
    setTogglePostOptions(false);
    toast.success('Copied!');
  };

  return (
    <div className={s.postHeader}>
      {loading ? (
        <div style={{ width: '100%' }} className={s.headerLoading}>
          <div>
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
          </div>
          <div style={{ width: '100%', marginLeft: '6px' }}>
            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="40%" />
          </div>
        </div>
      ) : (
        <>
          <div className={s.profilePicture}>
            <img
              referrerPolicy="no-referrer"
              onError={(e) => {
                e.target.src = userImg;
                e.target.onerror = null;
              }}
              src={userData?.photoUrl ?? ''}
              alt="user"
              height={30}
              width={30}
              style={{ cursor: isUserActive(users, userData?.email) ? 'pointer' : 'auto' }}
              onClick={(e) =>
                userData && isUserActive(users, userData?.email) && getProfile(userData?.email, e)
              }
            />
          </div>
          <div className={s.headerText}>
            <div
              className={s.profileName}
              style={{ cursor: isUserActive(users, userData?.email) ? 'pointer' : 'auto' }}
              onClick={(e) =>
                userData && isUserActive(users, userData?.email) && getProfile(userData?.email, e)
              }
            >
              {userData?.name}
            </div>

            <div className={s.details}>
              <span className="time">{TimeAgo(time)}</span> {<i>&#8226; {isEdited}</i>}
            </div>
          </div>
        </>
      )}

      {localStorage.getItem('email') === post?.postedBy ? (
        <div ref={dropdownRef} className={cns(s.dropDown, togglePostOptions ? s.active : '')}>
          <div style={{ marginBottom: '15px' }}>
            <button onClick={() => setTogglePostOptions(!togglePostOptions)}>
              {' '}
              <img  src={CombinedShapeIcon} alt="icon" style={{height:'12px'}} />
            </button>
          </div>
          <ul className={s.deleteBtn}>
            <Tooltip
              title={
                currentEditedPost !== undefined && currentEditedPost !== 0
                  ? 'Already in Editing Mode'
                  : 'Edit post'
              }
            >
              <li
                onClick={() => {
                  if (getValue === 1) {
                    history.push(`/marketplace/flat-renting-add-post/${postId}`);
                  } else if (getValue === 2) {
                    history.push(`/marketplace/car-pool-add-post/${postId}`);
                  }
                }}
              >
                <img width="28" height="28" src={editpost} alt="editIcon"/> <span>Edit post</span>
              </li>
            </Tooltip>
            <Tooltip title={'Delete Post'}>
              <li onClick={toggleModal}>
                <img width="28" height="28" src={deleteIcon} alt="deleteIcon" />
                <span> Delete Post</span>
              </li>
            </Tooltip>
            <Tooltip title={activeStatus === 'AVAILABLE' ? 'Mark as Inactive' : 'Mark as Active'}>
              <li onClick={inActivePostToggleModal}>
                <img
                  width="28"
                  height="18"
                  src={activeStatus === 'AVAILABLE' ? inactive : active}
                  alt="inactiveIcon"
                />
                <span> {activeStatus === 'AVAILABLE' ? 'Mark as Inactive' : 'Mark as Active'}</span>
              </li>
            </Tooltip>
            <Tooltip title={'Copy Link'}>
              <li onClick={copyHandler}>
                <img width="28" height="28" src={copylink} alt="copyIcon" />
                <span> Copy link to Post</span>
              </li>
            </Tooltip>
          </ul>
        </div>
      ) : (
        <div ref={dropdownRef} className={cns(s.dropDown, togglePostOptions ? s.active : '')}>
          <div>
            <button onClick={() => setTogglePostOptions(!togglePostOptions)}>
              {' '}
              <img  src={CombinedShapeIcon} alt="icon" style={{height:'12px'}}/>
            </button>
          </div>
          <ul className={s.deleteBtn}>
            <li onClick={copyHandler}>
              <img src={copylink} alt="copyIcon" /> Copy link to Post
            </li>
          </ul>
        </div>
      )}
      <ConfirmationModal
        showModal={showModal}
        toggle={toggleModal}
        heading={popUpMessages.DELETE_POST}
        message={popUpMessages.CONFIRM_DELETE_POST}
        onSuccess={(e) => {
          deleteHandler(e);

          toggleModal();
        }}
      />
      <ConfirmationModal
        showModal={showModalForInactive}
        toggle={inActivePostToggleModal}
        heading={
          activeStatus === 'AVAILABLE' ? popUpMessages.INACTIVE_POST : popUpMessages.ACTIVE_POST
        }
        message={
          activeStatus === 'AVAILABLE'
            ? popUpMessages.CONFIRM_INACTIVE_POST
            : popUpMessages.CONFIRM_ACTIVE_POST
        }
        onSuccess={(e) => {
          getValue === 1 ? inactiveActiveHandler(e) : inactiveActiveHandlerCarPool(e);
          inActivePostToggleModal();
        }}
      />
    </div>
  );
};

export default BuySellBody;
