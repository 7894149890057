import React, { useCallback, useState } from 'react';

//layouts
import Sidebar from '../../layouts/layout1/sidebar/index';
import Navbar from '../../layouts/layout1/navbar/index';
import Footer from '../../layouts/layout1/footer/index';

//scss
import p from '../dashboard/components/home/home.module.scss';
import s from '../dashboard/dashboard.module.scss';
import '../../index.css';

//components
import AddPostForm from './AddPostForm';
import BuySell from './BuySellPost';
import AddPostBuySell from './AddPostBuySell';
import SinglePost from './BuySellPost/SinglePost';
import useCheckJwtExpiration from '../../hooks/useCheckJwtExpiration';

//other imports
import cns from 'classnames';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Redirect, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getClubDetails } from '../../redux/actionCreators/clubs.actionCreator';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from 'react-responsive';
import SearchedPeople from '../dashboard/components/home/searched-people';
import Posts from '../dashboard/components/home/postContainer/posts';
import postClass from '../../pages/dashboard/components/home/postContainer/postContainer.module.scss';
import peopleClass from '../../pages/dashboard/components/home/home.module.scss';
import RightSidebar from '../dashboard/components/home/sidebar';
import AddCarPoolForm from './AddCarPoolPost';

const MarketPlace = (props) => {
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const postUrl =
    window.location.href.includes('/postId/') && window.location.href.split('/').pop();

  const [inputChangeData, setInputChangeData] = useState({
    searchValue: '',
    isPost: false
  });
  const [inputVal, setInputVal] = useState('');
  const [toggle, setToggle] = useState(isMobile ? false : true);

  const onChange = (value, isPost) => {
    setInputChangeData({ searchValue: value, isPost: isPost });
  };
  const debouncedOnChange = useCallback(_.debounce(onChange, 500), [onChange]);
  const [currentEditedPost, setCurrentEditedPost] = useState(0);
  const [editOnePost, setEditOnePost] = useState(false);

  const renderUnAuthenticatedRoute = () => {
    return (
      <UnauthenticatedTemplate>
        <Redirect to="/" />
      </UnauthenticatedTemplate>
    );
  };

  const renderAuthenticatedRoute = (Component, props) => {
    return (
      <AuthenticatedTemplate>
        <Component {...props} />
      </AuthenticatedTemplate>
    );
  };
  const useJwtExpiration = useCheckJwtExpiration();
  return (
    <>
      <div className={cns(s.dashboard)} style={{ minHeight: '100vh' }}>
        <section className={s.profileSection}>
          <Navbar
            setToggle={setToggle}
            toggle={toggle}
            profileDetails={props.profileDetails}
            onChange={debouncedOnChange}
            inputVal={inputVal}
            setInputVal={setInputVal}
          />
          <div className="container">
            <div className="row">
              <div className="col-sm-3 col-md-2 p-0">
                <div className={p.mobileHide}>
                  <Sidebar
                    toggle={toggle}
                    setToggle={setToggle}
                    onChange={debouncedOnChange}
                    inputVal={inputVal}
                    setInputVal={setInputVal}
                  />

                  {inputChangeData.searchValue.length ? (
                    <div className={peopleClass.home}>
                      {!inputChangeData.isPost ? (
                        <div className={peopleClass.containerWrapper}>
                          <SearchedPeople searchValue={inputChangeData.searchValue} />
                        </div>
                      ) : (
                        <div className={peopleClass.containerWrapper}>
                          <div className={postClass.postContainer}>
                            <Posts
                              searchValue={inputChangeData.searchValue}
                              editOnePost={editOnePost}
                              setEditOnePost={(val) => setEditOnePost(val)}
                              currentEditedPost={currentEditedPost}
                              setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
                            />
                          </div>
                        </div>
                      )}
                      <div className={peopleClass.sidebarWrapper}>
                        <RightSidebar />
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              {!inputChangeData.searchValue.length && (
                <div className="col-sm-9 col-md-10 ">
                  <Switch>
                    <Route
                      path="/marketplace/flat-renting-add-post/:id"
                      exact
                      render={(props) => (
                        <>
                          {localStorage.getItem('email') ? (
                            <AddPostForm {...props} />
                          ) : (
                            <>
                              {renderAuthenticatedRoute(AddPostForm, props)}
                              {renderUnAuthenticatedRoute()}
                            </>
                          )}
                        </>
                      )}
                    ></Route>
                    <Route
                      path="/marketplace/flat-renting-add-post"
                      exact
                      render={(props) => (
                        <>
                          {localStorage.getItem('email') ? (
                            <AddPostForm {...props} />
                          ) : (
                            <>
                              {renderAuthenticatedRoute(AddPostForm, props)}
                              {renderUnAuthenticatedRoute()}
                            </>
                          )}
                        </>
                      )}
                    ></Route>
                    <Route
                      path="/marketplace/car-pool-add-post"
                      exact
                      render={(props) => (
                        <>
                          {localStorage.getItem('email') ? (
                            <AddCarPoolForm {...props} />
                          ) : (
                            <>
                              {renderAuthenticatedRoute(AddCarPoolForm, props)}
                              {renderUnAuthenticatedRoute()}
                            </>
                          )}
                        </>
                      )}
                    ></Route>
                    <Route
                      path="/marketplace/car-pool-add-post/:id"
                      exact
                      render={(props) => (
                        <>
                          {localStorage.getItem('email') ? (
                            <AddCarPoolForm {...props} />
                          ) : (
                            <>
                              {renderAuthenticatedRoute(AddCarPoolForm, props)}
                              {renderUnAuthenticatedRoute()}
                            </>
                          )}
                        </>
                      )}
                    ></Route>
                    <Route
                      path="/marketplace/buy-sell-add-post"
                      render={(props) => (
                        <>
                          {localStorage.getItem('email') ? (
                            <AddPostBuySell {...props} />
                          ) : (
                            <>
                              {renderAuthenticatedRoute(AddPostBuySell, props)}
                              {renderUnAuthenticatedRoute()}
                            </>
                          )}
                        </>
                      )}
                    ></Route>
                    <Route
                      exact
                      path="/marketplace/market-place-buy-sell-post"
                      render={(props) => (
                        <>
                          {localStorage.getItem('email') ? (
                            <BuySell {...props} />
                          ) : (
                            <>
                              {renderAuthenticatedRoute(BuySell, props)}
                              {renderUnAuthenticatedRoute()}
                            </>
                          )}
                        </>
                      )}
                    ></Route>
                    <Route
                      exact
                      path="/marketplace/market-place-buy-sell-post/v-:val/postId/:pid"
                      render={(props) => (
                        <>
                          {localStorage.getItem('email') ? (
                            postUrl ? (
                              <SinglePost />
                            ) : (
                              <BuySell {...props} />
                            )
                          ) : (
                            <>
                              {renderAuthenticatedRoute(BuySell, props)}
                              {renderUnAuthenticatedRoute()}
                            </>
                          )}
                        </>
                      )}
                    ></Route>
                  </Switch>
                </div>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profileDetails: state.profileReducer.details,
    loading: state.clubsReducer.loading,
    error: state.clubsReducer.error,
    clubDetails: state.clubsReducer.clubDetails
  };
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClubDetails: getClubDetails
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(MarketPlace);
