import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import cns from 'classnames';
import cls from './ConfirmationModal.module.scss';
import Button from './../Button';

const ConfirmationModal = ({
  showModal = false,
  heading,
  message,
  successBtnText = 'Yes',
  rejectBtnText = 'No',
  toggle = () => {},
  onSuccess = () => {},
  onReject = toggle
}) => {
  let modalBoxRef = useRef();
  const btnClass = 'px-5 py-2';

  const outsideClickHanlder = useCallback(
    (event) => {
      if (showModal && !modalBoxRef.current.contains(event.target)) {
        modalBoxRef.current.classList.add(cls.alert);
        setTimeout(() => {
          modalBoxRef.current.classList.remove(cls.alert);
        }, 30);
      }
    },
    [showModal]
  );

  useEffect(() => {
    document.addEventListener('mousedown', outsideClickHanlder);
    return () => {
      document.removeEventListener('mousedown', outsideClickHanlder);
    };
  }, [showModal]);

  return (
    <>
      {showModal ? (
        <div className={cns(cls.modalWrapper)}>
          <div
            ref={modalBoxRef}
            className={cns(cls.modalBox, 'd-flex flex-column justify-content-center p-3')}
          >
            <div
              className={cns(
                'd-flex flex-row justify-content-center mb-2 text-center px-4',
                cls.actionIcon
              )}
            >
              {heading}
            </div>
            <p className={cns(cls.message, 'my-1 text-center')}>{message}</p>
            <div className={cns(cls.btnRow, 'd-flex flex-row')}>
              <Button className={cns(btnClass, cls.btn, cls.rejectBtn)} onClick={onReject}>
                {rejectBtnText}
              </Button>
              <Button className={cns(btnClass, cls.btn, cls.successBtn)} onClick={onSuccess}>
                {successBtnText}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default React.memo(ConfirmationModal);
