import React from 'react';

/* Icons */
import awrdIcon from '../../../assets/icons/award.svg';
import bdayIcon from '../../../assets/icons/Birthday.png';
import eventIcon from '../../../assets/icons/EventIcon.png';
import anniversaryIcon from '../../../assets/icons/Anniversary.png';
import newMemberIcon from '../../../assets/icons/Member.png';
import hashtagIcon from '../../../assets/icons/hashtag.svg';

import s from './navbar.module.scss';

/* Redux */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

/*Action Creator */
import { getEvents } from '../../../redux/actionCreators/event.actionCreator';

const ActivityMobile = ({
  showRightWrapperMobile,
  setShowRightWrapperMobile,
  activeMobileActivity,
  setActiveMobileActivity,
  ...props
}) => {
  const allActivities = [
    { name: 'Events', icon: eventIcon, classname: 'event' },
    { name: 'Awards', icon: awrdIcon, classname: 'award' },
    { name: 'Trending', icon: hashtagIcon, classname: 'hashtag' },
    { name: 'Birthdays', icon: bdayIcon, classname: 'birthday' },
    { name: 'Anniversary', icon: anniversaryIcon, classname: 'anniversary' },
    { name: 'New Members', icon: newMemberIcon, classname: 'member' }
  ];

  return (
    <div className={s.activityWrapper}>
      {allActivities.map((activity) => {
        let activityClass = activity.classname;
        activity.key = crypto.randomUUID();
        return (
          <div
            className={s.activityContainer}
            key={activity.key}
            onClick={() => {
              setShowRightWrapperMobile(true);
              setActiveMobileActivity(activity.name);
            }}
          >
            {activity.name === 'Events' ? (
              <span className={s.currentEventsCount}>{props?.events.length}</span>
            ) : null}
            <img
              src={activity.icon}
              className={s[`${activityClass}Header`]}
              height="35"
              width="35"
              alt="acitivity icon"
            />
            <span className={s.activityName}>{activity.name}</span>
          </div>
        );
      })}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    events: state.eventReducer.events
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEvents: getEvents
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityMobile);
