import axios from 'axios';
import {
  GET_HASHTAG_FAILURE,
  GET_HASHTAG_LOADING,
  GET_HASHTAG_SUCCESS,
  GET_TRENDING_HASHTAG_FAILURE,
  GET_TRENDING_HASHTAG_LOADING,
  GET_TRENDING_HASHTAG_SUCCESS
} from '../actions/actions';

export const getAllHashtags = () => {
  return (dispatch) => {
    dispatch({
      type: GET_HASHTAG_LOADING
    });

    axios
      .get(`${process.env.REACT_APP_GEMBOOK_SVC_URL}/get-all-hashtags`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_HASHTAG_SUCCESS,
            payload: {
              hashtags: [...res.data]
            }
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_HASHTAG_FAILURE,
          payload: {
            error: err.message
          }
        });
      });
  };
};

export const getTrendingHashtags = () => {
  return (dispatch) => {
    dispatch({
      type: GET_TRENDING_HASHTAG_LOADING
    });

    axios
      .get(
        `${
          process.env.REACT_APP_GEMBOOK_SVC_URL
        }/get-trending-hashtags?userId=${localStorage.getItem('email')}`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_TRENDING_HASHTAG_SUCCESS,
            payload: {
              hashtags: [...res.data]
            }
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_TRENDING_HASHTAG_FAILURE,
          payload: {
            error: err.message
          }
        });
      });
  };
};
