import React, { useEffect, useState } from 'react';
import moment from 'moment';

//scss
import s from './aboutPage.module.scss';

//images and icons
import membersIcon from '../../../../../../assets/img/membersIcon.png';
import createdOnIcon from '../../../../../../assets/img/createdOnIcon.png';
import { Skeleton } from '@material-ui/lab';
import edit from '../../../../../../assets/icons/editpost.svg';
import close from '../../../../../../assets/icons/close.svg';
import AboutPageEditing from './aboutPageEditing';
import { connect } from 'react-redux';

const AboutPage = ({ data, membersData, dataLoading, ...props }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [membersThisWeek, setMembersThisWeek] = useState();
  const [descriptionInput, setDescriptionInput] = useState(
    props.descriptionData ?? props.clubDetails.description
  );

  useEffect(() => {
    let temp = membersData.some((item) => {
      if (item.userId === localStorage.getItem('email') && item.roles.clubRoleId === 1) {
        return true;
      }
      return false;
    });
    setIsAdmin(temp);

    let count = 0;
    let lastWeek = moment().subtract(7, 'days').format('YYYY-MM-DD');
    membersData.map(
      (item) => item?.createDate > lastWeek && item?.roles.clubRoleId === 2 && count++
    );
    setMembersThisWeek(count);
  });

  const membersCount = () => {
    let count = 0;
    membersData.forEach((member) => {
      if (member?.roles?.clubRoleId === 2) count++;
    });
    return count;
  };

  const handleClose = () => {
    setDescriptionInput(props.clubDetails.description);
    setIsEditing(false);
  };

  return (
    <>
      {/* About This Club */}
      <div>
        {!dataLoading ? (
          <div className={s.aboutThisClub}>
            <div className={s.headingDiv}>
              <h4 className={s.heading}>About This Club</h4>
              {isAdmin &&
                (!isEditing ? (
                  <div className={s.buttonContainer}>
                    <button onClick={() => setIsEditing(true)}>
                      <img src={edit} className={s.editImg} />
                    </button>
                  </div>
                ) : (
                  <div className={s.buttonContainer}>
                    <button onClick={handleClose}>
                      <img src={close} />
                    </button>
                  </div>
                ))}
            </div>
            <hr />
            {isEditing ? (
              <AboutPageEditing
                setIsEditing={setIsEditing}
                descriptionInput={descriptionInput}
                setDescriptionInput={setDescriptionInput}
              />
            ) : (
              <p className={s.desc}>{descriptionInput}</p>
            )}
          </div>
        ) : (
          <div className={s.aboutThisClub}>
            <Skeleton variant="text" width="30%" />
            <hr />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" width="80%" />
          </div>
        )}

        {/* Activity */}

        {!dataLoading ? (
          <div className={s.activity}>
            <h4>Activity</h4>
            <hr />
            <div className={s.activities}>
              <div className={s.iconDiv}>
                <div className={s.icon}>
                  <img src={membersIcon} style={{display:"inline"}} alt="members Icon" />
                </div>
                <h5>{membersCount()} total members</h5>
                <p>+{membersThisWeek} in last week</p>
              </div>
              <div className={s.iconDiv}>
                <div className={s.icon}>
                  <img src={createdOnIcon} alt="create Icon" style={{display:"inline"}} />
                </div>
                <h5>Created on</h5>
                <p>{data.createDate}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className={s.activity}>
            <Skeleton variant="text" width="30%" />
            <hr />
            <div className={s.activities}>
              <div className={s.iconDivLoading}>
                <Skeleton variant="rect" width={150} height={70} className={s.icon} />
              </div>
              <div className={s.iconDivLoading}>
                <Skeleton variant="rect" width={150} height={70} className={s.icon} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    descriptionData: state.clubDetailsReducer.description,
    clubDetails: state.clubsReducer.clubDetails
  };
}
export default connect(mapStateToProps)(AboutPage);
