import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { formats } from '../../../../../helpers/editorToolbar';
import 'react-quill/dist/quill.snow.css';
import { editDescription } from '../../../../../../redux/actionCreators/clubDetails.actionCreator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Scss
import s from './aboutPage.module.scss';

const AboutPageEditing = ({ setIsEditing, descriptionInput, setDescriptionInput, ...props }) => {
  const [isEmpty, setIsEmpty] = useState(false);

  const handleSave = () => {
    if (descriptionInput.match('<p>(.*?)</p>')) {
      const temp = descriptionInput.replace(/<p>(.*?)<\/p>/g, '$1');
      setDescriptionInput(temp);
    }
    let data = {
      description: descriptionInput.replace(/<p>(.*?)<\/p>/g, '$1'),
      clubId: props.clubDetails.clubId
    };
    props.editDescription(data);
    setIsEditing(false);
  };

  const handleChange = (content, source, delta, editor) => {
    setDescriptionInput(content);
    content.match('<br>') ? setIsEmpty(true) : setIsEmpty(false);
  };

  const handleClose = () => {
    setDescriptionInput(props.newDescription ?? props.clubDetails.description);
    setIsEditing(false);
  };

  return (
    <div className={s.editDiv}>
      <ReactQuill
        value={descriptionInput}
        onChange={handleChange}
        className={s.editorStyle}
        formats={formats}
        modules={{ toolbar: false }}
        selection={{ start: 0, end: 0 }}
      />

      <button className={s.discardBtn} onClick={handleClose}>
        Discard
      </button>
      <button
        className={isEmpty ? s.saveBtnDisabled : s.saveBtn}
        onClick={handleSave}
        disabled={isEmpty}
      >
        Save
      </button>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    clubDetails: state.clubsReducer.clubDetails,
    newDescription: state.clubDetailsReducer.description
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editDescription: editDescription
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutPageEditing);
