import React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllUsers } from '../../../../../../redux/actionCreators/user.actionCreator';
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { uniqueId } from 'lodash';

//scss
import s from './membersPage.module.scss';

//icons
import closeIcon from '../../../../../../assets/icons/close.svg';
import adminIcon from '../../../../../../assets/icons/admin.svg';

//Helper
import { getProfileByEmail } from '../../../../../../helpers';
import {
  getClubMembers,
  joinRequestAction,
  updateMemberRole
} from '../../../../../../redux/actionCreators/clubs.actionCreator';
import RequestListTable from './requestListTable';
import { toast } from 'react-toastify';
import MembersListTable from './membersListTable';

const MembersPage = ({
  showMembers,
  setShowMembers,
  setShowDrawer,
  showRequests,
  setShowRequests,
  clubName,
  membersData,
  users,
  getClubMembers,
  clubId,
  joinRequestData,
  ...props
}) => {
  const [allUsersData, setAllUsersData] = useState([]);
  const [membersInClub, setMembersInClub] = useState();
  const [confirmAllRequest, setConfirmAllRequest] = useState(false);
  const [joinClubList, setJoinClubList] = useState();

  const getProfile = (email) => {
    getProfileByEmail(email);
  };

  useEffect(() => {
    let temp = Object.values(users);
    setAllUsersData(temp);
  }, [users]);

  useEffect(() => {
    let tempJoin = allUsersData?.filter(function (f1) {
      return joinRequestData?.some(function (f2) {
        return f1.email === f2.userId;
      });
    });
    setJoinClubList(tempJoin);
  }, [allUsersData]);

  useEffect(() => {
    let temp = allUsersData?.filter(function (f1) {
      return props.membersDetails?.some(function (f2) {
        return f1?.email === f2?.userId && f2?.roles.clubRoleId === 2;
      });
    });

    setMembersInClub(temp);
  }, [allUsersData, membersInClub?.length]);

  const handleAddAdmin = (email) => {
    let id = membersData?.filter((user) => {
      if (user.userId === email) {
        return user;
      }
    })[0].clubMemberId;
    let myid = membersData?.filter((user) => {
      if (user.userId === localStorage.getItem('email')) {
        return user;
      }
    })[0].clubMemberId;
    props.updateMemberRole(myid, 1, id);
    setTimeout(() => {
      getClubMembers(clubId);
    }, 1000);
  };

  const checkAdmin = () => {
    let isAdmin = membersData?.find((member) => {
      return member.userId === localStorage.getItem('email') && member.roles.clubRoleId === 1
        ? true
        : false;
    });
    return isAdmin;
  };

  //Handling requests
  const getID = (data, email) => {
    return data?.filter((user) => {
      if (user.userId === email) {
        return user;
      }
    })[0].clubMemberId;
  };

  const handleRequestAccept = (email) => {
    let memberId = '';
    memberId = getID(joinRequestData, email);
    const admin = membersData.find((member) => member.userId === localStorage?.getItem('email'));
    props.joinRequestAction(memberId, admin.clubMemberId, 'REQUEST_APPROVED', clubId);
  };

  const handleAllRequestAccept = () => {
    joinRequestData.forEach((item) => {});
    toast.success('All requests are approved for joining this club!');
    setTimeout(() => {
      props.getRequests(clubId);
    }, 1000);
  };

  const handleRequestReject = (email) => {
    let memberId = '';
    memberId = getID(joinRequestData, email);
    const admin = membersData.find((member) => member.userId === localStorage?.getItem('email'));
    props.joinRequestAction(memberId, admin.clubMemberId, 'REQUEST_REJECTED', clubId);
  };

  return (
    <>
      <div className={s.outerBox}>
        <div className={s.icons}>
          <img
            src={closeIcon}
            alt="close Icon"
            onClick={() => {
              setShowDrawer(false);
            }}
            className={s.iconImg}
          />
        </div>
        {checkAdmin() && clubName === 'Tech Titans' ? (
          <div className={s.subHeading}>
            <div className={s.eventTimeline}>
              <div
                className={showMembers ? s.selected : s.notSelected}
                onClick={() => {
                  setShowRequests(false);
                  setShowMembers(true);
                  setConfirmAllRequest(false);
                }}
              >
                <p>All Members</p>{' '}
                <p className={showMembers ? s.selectedMembersCount : s.notSelectedMembersCount}>
                  {membersInClub?.length}
                </p>
              </div>
              <div
                className={showMembers ? s.notSelected : s.selected}
                onClick={() => {
                  setShowRequests(true);
                  setShowMembers(false);
                  setConfirmAllRequest(false);
                }}
              >
                <p>Requests</p>{' '}
                {joinRequestData?.length ? (
                  <p className={showMembers ? s.notSelectedMembersCount : s.selectedMembersCount}>
                    {joinRequestData?.length}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={s.heading}>
            <div className={s.title}>
              <h4 className={s.title}>All Members</h4>
            </div>
          </div>
        )}

        {showMembers && !showRequests && (
          <MembersListTable
            membersInClub={membersInClub}
            handleAddAdmin={handleAddAdmin}
            checkAdmin={checkAdmin}
          />
        )}

        {showRequests && !showMembers && (
          <RequestListTable
            data={joinClubList}
            joinRequestData={joinRequestData}
            handleRequestAccept={handleRequestAccept}
            handleRequestReject={handleRequestReject}
          />
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    users: state.userReducer.users,
    membersDetails: state.clubsReducer.membersDetails
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllUsers: getAllUsers,
      updateMemberRole: updateMemberRole,
      getClubMembers: getClubMembers,
      joinRequestAction: joinRequestAction
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersPage);
