import React, { useRef, useState } from 'react';
import cns from 'classnames';
import {
  Stepper,
  Step,
  Paper,
  Button,
  Typography,
  Grid,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import s from './marketplace.module.scss';
import { useDropzone } from 'react-dropzone';
import fileimage from '../../assets/icons/fileimage.png';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addPostMarketPlace } from '../../redux/actionCreators/marketplace.actionCreator';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const AddPostBuySell = () => {
  const [steps, setSteps] = useState([
    'Basic Details',
    'Location',
    'Photos'
    // 'Pricing'
  ]);

  const [activeStep, setActiveStep] = useState(0);
  const useStyles = makeStyles((theme) => ({
    stepper: {
      backgroundColor: '#42AE9F',
      padding: theme.spacing(3, 0, 5),
      borderRadius: '8px'
    },
    stepLabel: {
      textAlign: 'right'
    },
    stepIcon: {
      color: '#42AE9F',

      '&.active': {
        color: '#42AE9F'
      }
    }
  }));
  const classes = useStyles();
  const history = useHistory();
  const handleNext = async () => {
    const isStepValid = stepValid(activeStep);
    if (isStepValid) {
      if (activeStep == steps.length - 1) {
        buySellPost.location = buySellPost.apartmentSociety + ', ' + buySellPost.location;
        await dispatch(addPostMarketPlace('BUY_SELL', buySellPost, files));
        if (!loading) {
          setActiveStep((prevActiveStep) => 0);
          history.push('/marketplace/market-place-buy-sell-post');
          setFiles([]);
          setbuySellPost({
            postType: '',
            transactionType: 'SELL',
            propertyType: 'APARTMENT',
            numberOfMembers: 1,
            numberOfBedrooms: 1,
            numberOfBathrooms: 1,
            title: '',
            apartmentSociety: '',
            furnishingType: 'FURNISHED',
            furnishingDetails: '',
            propertyArea: '',
            pincode: '',
            otherRooms: '', // not in backend
            location: '',
            price: '',
            availableFromDate: '2023-12-19T15:19:23.678Z',
            parkingAvailable: false,
            postedOn: '2023-12-19T15:19:23.678Z',
            activeFlag: true,
            securityDeposit: '',
            transactionType: '',
            title: '',
            details: '',
            isAvailable: false
          });
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      toast.error('Fill Required Fields');
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  const [buySellPost, setbuySellPost] = useState({
    transactionType: 'SELL',
    price: 0,
    title: '',
    apartmentSociety: '',
    location: '',
    pincode: '',
    details: '',
    postedOn: localISOTime,
    isAvailable: ''
  });
  const validationObj = {
    transactionType: 'BUY',
    price: '',
    apartmentSociety: '',
    title: '',
    location: '',
    pincode: '',
    details: '',
    postedOn: '',
    isAvailable: ''
  };
  const keyValue = {
    transactionType: 'Objective',
    price: 'Price',
    title: 'Title',
    location: 'Location',
    apartmentSociety: 'Location',
    pincode: 'Location',
    details: 'Details',
    postedOn: '',
    isAvailable: ''
  };
  const [validationErrors, setValidationErrors] = useState(validationObj);
  const handleInputChange = (fieldName, event) => {
    if (event.target.value.length > 0) {
      setValidationErrors({ ...validationErrors, [fieldName]: '' });
    } else if (event.target.value.length <= 0) {
      setValidationErrors({
        ...validationErrors,
        [fieldName]: `${keyValue[fieldName]} is Required`
      });
    }

    if (fieldName == 'pincode' && event.target.value.length > 6) {
      return;
    } else {
      setbuySellPost({
        ...buySellPost,
        [fieldName]: event.target.value
      });
    }
  };

  const dispatch = useDispatch();

  // useSelector takes a selector function to extract values from the Redux store
  const loading = useSelector((state) => state.marketPlace.loading);
  const error = useSelector((state) => state.marketPlace.error);

  // Your component logic here

  const handleVehicleTypeToggle = (type) => () => {
    // const updatedVehicleTypes = vehicleInfo.vehicleType.includes(type)
    //   ? vehicleInfo.vehicleType.filter((t) => t !== type)
    //   : [...vehicleInfo.vehicleType, type];
    const sellSteps = ['Photos'];

    if (type === 'SELL' && !steps.includes('Photos')) {
      setSteps((prev) => [...prev, ...sellSteps]);
    } else if (type == 'BUY') {
      setSteps((prev) => prev.filter((step) => !sellSteps.includes(step)));
    }
    setbuySellPost((prevData) => ({
      ...prevData,
      transactionType: type
    }));
  };
  const handlePropertyTypeToggle = (propertyType) => () => {
    // const updatedPostType = buySellPost.title.includes(propertyType)
    //     ? buySellPost.propertyType.filter((type) => type !== propertyType)
    //     : [...buySellPost.propertyType, propertyType];

    setbuySellPost({
      ...buySellPost,
      propertyType: propertyType
    });
  };
  const handleOtherRoomsChange = (room) => () => {
    // const updatedOtherRooms = buySellPost.otherRooms.includes(room)
    //     ? buySellPost.otherRooms.filter((r) => r !== room)
    //     : [...buySellPost.otherRooms, room];

    setbuySellPost({
      ...buySellPost,
      otherRooms: room
    });
  };
  const [files, setFiles] = useState([]);
  const onDrop = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    );
  };
  const stepValid = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        if (buySellPost.title.length <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, title: 'Title is Required' };
          });
        }
        if (buySellPost.transactionType.length <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, transactionType: 'Objective is Required' };
          });
        }
        if (buySellPost.price <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, price: 'Price is Required' };
          });
        }

        if (buySellPost.details.length <= 0) {
          setValidationErrors((prev) => ({ ...prev, details: 'Details is Required' }));
        }
        return (
          buySellPost.transactionType.length > 0 &&
          buySellPost.title.length > 0 &&
          (buySellPost.transactionType == 'BUY' || buySellPost.price > 0) &&
          buySellPost.details.length > 0
        );

      case 1:
        if (buySellPost.location.length <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, location: 'Property Descrition is Required' };
          });
        }
        const pat1 = /^\d{6}$/;
        if (buySellPost.pincode.length <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, pincode: 'Pincode is Required' };
          });
        }
        if (buySellPost.pincode.length > 6) {
          setValidationErrors((prev) => {
            return { ...prev, pincode: 'Pincode Should be of 6 digits' };
          });
        }
        if (buySellPost.apartmentSociety.length <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, apartmentSociety: 'Apartment Society is Required' };
          });
        }
        return (
          buySellPost.location.length > 0 &&
          buySellPost.apartmentSociety.length > 0 &&
          buySellPost.pincode.length > 0 &&
          buySellPost.pincode.length <= 6
        );
      case 3:
        return files.length > 0;

      default:
        return true;
    }
  };

  const imageRef = useRef(null);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: 'image/*',
    maxSize: 5 * 1024 * 1024,
    multiple: true
  });
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="container mt-1 d-flex">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h6 className="fw-b " style={{ fontSize: 'medium' }}>
                  Please fill the basic details to continue
                </h6>
              </Grid>
              <Grid item xs={12} className="my-0 py-0">
                <Grid item xs={12}>
                  <h6 component="legend">1. Your Objective?</h6>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        variant={
                          buySellPost.transactionType.includes('BUY') ? 'contained' : 'outlined'
                        }
                        color="primary"
                        size="small"
                        onClick={handleVehicleTypeToggle('BUY')}
                        style={{
                          color: buySellPost.transactionType.includes('BUY') ? 'white' : '#38A3A5',
                          borderColor: '#38A3A5',
                          backgroundColor: buySellPost.transactionType.includes('BUY')
                            ? '#38A3A5'
                            : 'inherit',
                          borderRadius: '51px'
                        }}
                      >
                        Buy
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={
                          buySellPost.transactionType.includes('SELL') ? 'contained' : 'outlined'
                        }
                        color="primary"
                        size="small"
                        onClick={handleVehicleTypeToggle('SELL')}
                        style={{
                          color: buySellPost.transactionType.includes('SELL') ? 'white' : '#38A3A5',
                          borderColor: '#38A3A5',
                          borderRadius: '51px',
                          backgroundColor: buySellPost.transactionType.includes('SELL')
                            ? '#38A3A5'
                            : 'inherit'
                        }}
                      >
                        Sell
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className="my-0 py-0">
                <div className="form-group">
                  <label for="city-form">2. Title</label>

                  <div className="position-relative w-75">
                    <input
                      value={buySellPost.title}
                      onChange={(event) => handleInputChange('title', event)}
                      id="city-form"
                      type="text"
                      placeholder="Enter"
                      className={cns(
                        s.formC,
                        validationErrors.title.length > 0
                          ? ' is-invalid form-control'
                          : 'form-control'
                      )}
                    />
                  </div>
                  <span className="text-danger"> {validationErrors.title}</span>
                </div>
              </Grid>
              {buySellPost.transactionType == 'SELL' && (
                <Grid item xs={12} className="my-0 py-0">
                  {/* <p style={{ fontSize: "12px", color: "black" }}>Please add accurate location which helps to connect with buyers</p> */}
                  <div className="form-group">
                    <label for="city-form">3. Price</label>
                    <div
                      className={isMobile ? 'w-100 position-relative' : 'w-75 position-relative'}
                    >
                      <span
                        className="position-absolute"
                        style={{ left: '10px', top: '26%', transform: 'translate(-50 %, -50 %)' }}
                      >
                        &#x20B9;
                      </span>{' '}
                      <input
                        value={buySellPost.price}
                        id="city-form"
                        onChange={(event) => handleInputChange('price', event)}
                        type="number"
                        placeholder="Enter"
                        className={cns(
                          s.formC,
                          validationErrors.price.length > 0
                            ? ' is-invalid form-control px-5'
                            : 'form-control px-5'
                        )}
                      />
                    </div>
                    <span className="text-danger"> {validationErrors.price}</span>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={12} className="my-0 py-0">
                <div className="form-group">
                  <label for="city-form">
                    {buySellPost.transactionType == 'BUY' ? '3. ' : '4. '} Enter Asset Details
                  </label>
                  <div className="w-75">
                    <textarea
                      rows={3}
                      value={buySellPost.details}
                      id="city-form"
                      onChange={(event) => handleInputChange('details', event)}
                      type="number"
                      placeholder="Enter"
                      className={cns(
                        s.formC,
                        validationErrors.details.length > 0
                          ? ' is-invalid form-control'
                          : 'form-control'
                      )}
                    />
                  </div>
                  <span className="text-danger"> {validationErrors.details}</span>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <div className="container mt-1">
            <h6 className="fw-b" style={{ fontSize: 'medium' }}>
              Where is the Property Located?
            </h6>
            <p style={{ fontSize: '12px', color: 'black' }}>
              Please add accurate location which helps to connect with buyers
            </p>
            <div className="form-group mt-3">
              <label for="city-form1">City</label>
              <div className={`position-relative ${isMobile ? ' w-100' : ' w-50'} `}>
                <input
                  value={buySellPost.location}
                  onChange={(event) => handleInputChange('location', event)}
                  id="city-form1"
                  type="text"
                  placeholder="Enter City Name"
                  className={cns(
                    s.formC,
                    validationErrors.location.length > 0
                      ? ' is-invalid form-control'
                      : 'form-control'
                  )}
                />
                <svg
                  className="position-absolute"
                  style={{ bottom: '10px', right: '10px' }}
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11 13.5C12.3807 13.5 13.5 12.3807 13.5 11C13.5 9.61929 12.3807 8.5 11 8.5C9.61929 8.5 8.5 9.61929 8.5 11C8.5 12.3807 9.61929 13.5 11 13.5Z"
                    stroke="#38A3A5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11 17.25C14.4518 17.25 17.25 14.4518 17.25 11C17.25 7.54822 14.4518 4.75 11 4.75C7.54822 4.75 4.75 7.54822 4.75 11C4.75 14.4518 7.54822 17.25 11 17.25Z"
                    stroke="#38A3A5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 1.625V4.75"
                    stroke="#38A3A5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.625 11H4.75"
                    stroke="#38A3A5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 20.375V17.25"
                    stroke="#38A3A5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.375 11H17.25"
                    stroke="#38A3A5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="form-group mt-3">
              <label for="city-form2">Appartment/Society</label>
              <div className={`position-relative ${isMobile ? ' w-100' : ' w-50'} `}>
                <input
                  value={buySellPost.apartmentSociety}
                  onChange={(event) => handleInputChange('apartmentSociety', event)}
                  id="city-form2"
                  type="text"
                  placeholder="Enter"
                  className={cns(
                    s.formC,
                    validationErrors.apartmentSociety.length > 0
                      ? ' is-invalid form-control'
                      : 'form-control'
                  )}
                />
              </div>
            </div>
            <div className="form-group mt-3">
              <label for="city-form3">Pincode</label>
              <div className={`position-relative ${isMobile ? ' w-100' : ' w-50'} `}>
                <input
                  value={buySellPost.pincode}
                  onChange={(event) => handleInputChange('pincode', event)}
                  id="city-form3"
                  type="number"
                  placeholder="Enter"
                  className={cns(
                    s.formC,
                    validationErrors.pincode.length > 0
                      ? ' is-invalid form-control'
                      : 'form-control'
                  )}
                />
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="container mt-3">
            <h6 className="fw-b" style={{ fontSize: 'medium' }}>
              Add photos of your property?
            </h6>
            <div className="container d-flex justify-content-center">
              <div className="row">
                {files.map((file) => (
                  <div className="col-lg-6 d-flex justify-content-center">
                    <img
                      src={file.preview}
                      className="my-2"
                      height={'180px'}
                      width={'100%'}
                      alt="abc"
                    />
                  </div>
                ))}
                <div
                  className={
                    'col-md-12  d-flex justify-content-center ' +
                    (files.length > 0 ? 'col-lg-6' : 'col-lg-12')
                  }
                >
                  <div
                    className={'my-2 w-100' + (files.length === 0 && ' px-5')}
                    style={{
                      background: '#F0F9FF',
                      textAlign: 'center',
                      height: '180px',
                      width: '100%'
                    }}
                  >
                    <img src={fileimage} alt="123" />
                    <h6
                      className={'fw-b' + (files.length <= 0 && ' mb-2')}
                      style={{
                        marginTop: '5px',
                        marginBottom: '0px',
                        padding: '0px',
                        color: '#38A3A5'
                      }}
                    >
                      + Add Photos
                    </h6>
                    <div
                      {...getRootProps()}
                      style={{ cursor: 'pointer', padding: '5px', borderRadius: '10px' }}
                    >
                      <input {...getInputProps()} />
                      <Typography variant="body2">Drag and drop your photos here</Typography>
                      <Typography variant="body2" style={{}}>
                        Upto 20 photos | Max size 5 MB | Formats: png, jpg, jpeg, webp, heic, heif,
                        gif
                      </Typography>
                    </div>
                    <Button
                      onClick={(e) => {
                        imageRef.current.click();
                      }}
                      className={files.length <= 0 && 'mt-2'}
                      size="small"
                      variant="outlined"
                      color="primary"
                      style={{ backgroundColor: 'white', borderColor: '#407EC4' }}
                    >
                      Upload photos
                    </Button>
                    <input
                      multiple
                      onChange={(e) => onDrop(Array.from(e.target.files))}
                      ref={imageRef}
                      className="d-none"
                      type="file"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
    }
  };
  // const { isMobile, width } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <div className={window.innerWidth < 1200 && window.innerWidth > 900 ? 'm-3' : 'my-3'}>
      <Grid container spacing={2}>
        {isMobile && (
          <Grid item xs={12} sm={12} md={7} lg={4}>
            {/* Right side - Stepper */}
            {
              <h6 className="fw-b mt-4">
                <Link
                  style={{ cursor: 'pointer', textDecoration: 'none', color: 'black' }}
                  as={Link}
                  to={'/marketplace/market-place-buy-sell-post'}
                >
                  Home
                </Link>{' '}
                <span>Add Post</span> <span>{steps[activeStep]}</span>
              </h6>
            }
            <Paper className="h-75 p-0 m-0  ">
              <Stepper activeStep={activeStep} orientation="horizontal" connector={false}>
                <Step className="mb-5">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="position-relative">
                      <CircularProgress
                        variant="determinate"
                        value={(activeStep / (steps.length - 1)) * 100}
                        style={{
                          color: '#48ABAC', // Greenish color for progress
                          boxShadow: 'inset 0 0 1px 6px #d1d1d1',
                          borderRadius: '50%',
                          height: '80px',
                          width: '80px'
                        }}
                      />
                      <div
                        className="fw-b"
                        style={{
                          color: 'black',
                          position: 'absolute',
                          top: '47%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 1
                        }}
                      >
                        <span>
                          {activeStep} of {steps.length}
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <span
                        className="fw-b"
                        style={{
                          color: '#616061',
                          marginLeft: '20px',
                          fontWeight: '500',
                          fontSize: '15px'
                        }}
                      >
                        {steps[activeStep]}
                      </span>
                      <div
                        className="fw-b"
                        style={{
                          color: '#616061',
                          marginLeft: '32px',
                          fontWeight: '500',
                          fontSize: '12px'
                        }}
                      >
                        Next: {steps[activeStep + 1]}
                      </div>
                    </div>
                  </div>
                </Step>
              </Stepper>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={7} lg={8}>
          {/* Left side - Form */}
          {!isMobile && (
            <h6 className="fw-b mt-4">
              <Link
                style={{ cursor: 'pointer', textDecoration: 'none', color: 'black' }}
                as={Link}
                to={'/marketplace/market-place-buy-sell-post'}
              >
                Home
              </Link>{' '}
              <span>Add Post</span> <span>{steps[activeStep]}</span>
            </h6>
          )}

          <Paper style={{ padding: '10px', height: '100%', width: '100%' }}>
            <form>
              {getStepContent(activeStep)}
              <div style={{ marginTop: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: '20px', backgroundColor: '#38A3A5' }}
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Submit' : 'Continue'}
                </Button>
                {activeStep !== 0 ? (
                  <Button onClick={handleBack} color="primary">
                    Back
                  </Button>
                ) : (
                  <Button onClick={() => window.history.back()} color="primary">
                    Back
                  </Button>
                )}
              </div>
            </form>
          </Paper>
        </Grid>
        {!isMobile && (
          <Grid item sm={12} md={5} lg={4}>
            {/* Right side - Stepper */}
            <Paper className="w-100 mt-5 p-0 m-0  " style={{ maxHeight: '30%' }}>
              <Stepper activeStep={activeStep} orientation="vertical" connector={false}>
                {steps.map((step, idx) => (
                  <Step key={idx} className={idx < steps.length - 1 ? 'mb-5' : 'mb-3'}>
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      {/* Text before the step circle */}
                      <div style={{ color: '#000000', fontSize: '12px', marginRight: '8px' }}>
                        Step {idx + 1}
                      </div>

                      {/* Step circle */}
                      <div className="d-flex flex-column position-relative">
                        <div
                          style={
                            activeStep == idx
                              ? {
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '50%',
                                  border: '3px solid #48ABAC',
                                  color: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginRight: '8px'
                                }
                              : activeStep > idx
                              ? {
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '50%',
                                  border: '3px solid #48ABAC',
                                  color: 'white',
                                  display: 'flex',
                                  backgroundColor: '#48ABAC',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginRight: '8px'
                                }
                              : {
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '50%',
                                  border: '3px solid #CDCCCB',
                                  color: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginRight: '8px'
                                }
                          }
                        >
                          <svg
                            width="13"
                            height="10"
                            viewBox="0 0 13 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 5.8L4.14286 9L12 1"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        {idx != steps.length - 1 && (
                          <div
                            style={{
                              position: 'absolute',
                              top: '100%',
                              height: '42px',
                              borderLeft: '2px solid #E3E2E2',
                              right: '60%'
                            }}
                          ></div>
                        )}
                      </div>
                      <span
                        className="fw-bold"
                        style={{
                          color: '#616061',
                          fontWeight: '500',
                          fontSize: '14px'
                        }}
                      >
                        {step}
                      </span>
                    </div>
                  </Step>
                ))}
              </Stepper>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AddPostBuySell;
