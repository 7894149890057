import React, { useEffect, useState } from 'react';

// scss
import s from './mediaUploadPreview.module.scss';
import cns from 'classnames';

// Icons
import cross from '../../../../../../assets/img/cross-red.png';
import { Tooltip } from '@material-ui/core';
import { validateMediaSize } from '../../../../../../helpers';

const renderMediaPreview = (type, e, deleteMedia) => {
  let errorMessage = (
    <p className={s.sizeError}>
      {validateMediaSize(type, e) ? '' : 'File size is greater than maximum limit'}
    </p>
  );

  switch (type) {
    case 'image':
      return (
        <div className={s.imageContainer}>
          <Tooltip title="Delete">
            <img
              className={s.imageDeleteBtn}
              src={cross}
              alt="cross"
              onClick={() => deleteMedia(e.name)}
            />
          </Tooltip>
          <img
            src={e.name.startsWith('https') ? e.name : URL.createObjectURL(e)}
            className={cns('img-thumbnail', s.imageMain)}
            alt=""
            style={{ border: validateMediaSize(type, e) ? '' : '2px solid #bb2f3d' }}
          />
          {errorMessage}
        </div>
      );
    case 'video':
      return (
        <div className={cns(s.errorContainer, s.vidContainer)}>
          <Tooltip title="Delete">
            <img
              className={s.vidDeleteBtn}
              src={cross}
              alt="cross"
              onClick={() => deleteMedia(e.name)}
            />
          </Tooltip>
          <video
            controls
            className={cns('vid-thumbnail', s.vidMain)}
            preload="metadata"
            src={URL.createObjectURL(e)}
            type="video/mp4"
            style={{ border: validateMediaSize(type, e) ? '' : '2px solid #bb2f3d' }}
          ></video>
          {errorMessage}
        </div>
      );
    default:
  }
};

const MediaUploadPreview = ({ media, deleteMedia }) => {
  const [files, setFiles] = useState([]);
  let sortType = 'image';
  // sort the array with all images on top and all videos in bottom.
  const sorter = (a, b) => {
    if (a.type.split('/')[0] === sortType) {
      return -1;
    }
    if (b.type.split('/')[0] === sortType) {
      return 1;
    }
    return a.type.split('/')[0] < b.type.split('/')[0] ? -1 : 1;
  };

  useEffect(() => {
    let newMedia = [...media];
    newMedia = newMedia.sort(sorter);
    setFiles([...newMedia]);
  }, [JSON.stringify(media)]);

  return (
    <div className={s.uploadPreview}>
      {files.map((e, index) => {
        let file = e.type.split('/');
        return (
          <React.Fragment key={index}>{renderMediaPreview(file[0], e, deleteMedia)}</React.Fragment>
        );
      })}
    </div>
  );
};

export default MediaUploadPreview;
