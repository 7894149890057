import React, { useEffect, useRef, useState } from 'react';
import s from './tags.module.scss';
import cns from 'classnames';
import { Tooltip } from '@material-ui/core';
import closeIcon from '../../../../../../assets/icons/close.svg';
import deleteIcon from '../../../../../../assets/icons/delete-bin.svg';
import tagIcon from '../../../../../../assets/icons/tagIcon.svg';
import {
  createCustomTag,
  getCustomTagsByClubId,
  deleteCustomTag
} from '../../../../../../redux/actionCreators/customTags.actionCreator';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const CustomTags = ({ clubId, handleTagAdd, prevTagId, ...props }) => {
  const [editTag, setEditTag] = useState(false);
  const [toggleOptions, setToggleOptions] = useState(false);
  const [selectedTag, setSelectedTag] = useState(prevTagId ?? 'Select Tag');
  const [addNewTag, setAddNewTag] = useState(false);
  const [newTagInput, setNewTagInput] = useState('');
  const [hoveredTag, setHoveredTag] = useState(false);

  let wrapper = useRef();

  //Handler
  const handler = (event) => {
    if (!wrapper.current.contains(event.target)) {
      setEditTag(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [editTag]);

  //Toggle Handler
  useEffect(() => {
    let handler = (event) => {
      if (!wrapper.current.contains(event.target)) {
        setToggleOptions(false);
        setNewTagInput('');
        setAddNewTag(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  const handleInputChange = (e) => {
    setNewTagInput(e.target.value);
  };

  //Handle save with ENTER key
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && newTagInput.trim() !== '') {
        props.createCustomTag(newTagInput, clubId);
        setNewTagInput(''); // Clear the input field after saving
        setAddNewTag(false);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [newTagInput]);

  //Get custom tags API call
  useEffect(() => {
    props.getCustomTagsByClubId(clubId);
  }, []);

  const handleChangeTagName = (tag) => {
    setSelectedTag(tag.tagName);
    handleTagAdd(tag.tagId, tag.tagName);
    setToggleOptions(false);
  };

  const handleDeleteTag = (tagId) => {
    props.deleteCustomTag(tagId, clubId);
  };

  const onCancelTag = (e) => {
    e.stopPropagation();
    handleTagAdd(0);
    setSelectedTag('Select Tag');
  };

  return (
    <div className={s.tagsContainer}>
      <div className={s.postTypeContainer} ref={wrapper}>
        <div ref={wrapper} className={cns(s.dropDown, toggleOptions ? s.active : '')}>
          <button
            className={cns(s.filterButton, s.customTagButtonCSS)}
            onClick={() => {
              setToggleOptions(!toggleOptions);
              setNewTagInput('');
              setAddNewTag(false);
            }}
          >
            <img referrerPolicy="no-referrer" src={tagIcon} alt="Tag Type" height={18} width={18} style={{marginRight:'0.5rem'}}/>
            {selectedTag}

            {selectedTag !== 'Select Tag' && (
              <Tooltip title="Click to remove tag">
                <img
                  referrerPolicy="no-referrer"
                  src={closeIcon}
                  alt="close Icon"
                  onClick={onCancelTag}
                  height={18}
                  width={20}
                />
              </Tooltip>
            )}
          </button>
          <ul>
            {props?.customTags.map((tag) => {
              const isHovered = tag === hoveredTag;

              return (
                <li
                  key={tag.tagName}
                  onClick={() => handleChangeTagName(tag)}
                  className={s.tagsItems}
                  onMouseEnter={() => setHoveredTag(tag)}
                  onMouseLeave={() => setHoveredTag(null)}
                >
                  <div className={s.inputDiv}>
                    {tag.tagName}
                    {isHovered && (
                      <Tooltip title="Delete Tag">
                        <img
                          src={deleteIcon}
                          alt="delete-icon"
                          className={s.closeImg}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTag(tag.tagId);
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </li>
              );
            })}
            <li className={s.tagsItems}>
              {addNewTag ? (
                <div className={s.inputDiv}>
                  <Tooltip title="Press ENTER key to save">
                    <input
                      autoFocus
                      autoComplete="off"
                      className={s.addText}
                      type="text"
                      value={newTagInput}
                      onChange={handleInputChange}
                      name="newTag"
                      id="newTag"
                    />
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <img
                      src={closeIcon}
                      alt="close-icon"
                      className={s.closeImg}
                      onClick={() => {
                        setAddNewTag(false);
                        setNewTagInput('');
                      }}
                    />
                  </Tooltip>
                </div>
              ) : (
                <p
                  className={s.addButton}
                  onClick={() => {
                    setAddNewTag(true);
                  }}
                >
                  + Create New Tag
                </p>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    customTags: state.customTagsReducer.customTags,
    loading: state.customTagsReducer.loading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCustomTagsByClubId: getCustomTagsByClubId,
      createCustomTag: createCustomTag,
      deleteCustomTag: deleteCustomTag
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTags);
