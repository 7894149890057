import {
  ADD_COMMENT_FAILURE,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_LOADING,
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_LOADING,
  ADD_MARKET_COMMENT_FAILURE,
  ADD_MARKET_COMMENT_SUCCESS,
  ADD_MARKET_COMMENT_LOADING,
  DELETE_MARKET_COMMENT_FAILURE,
  DELETE_MARKET_COMMENT_SUCCESS,
  DELETE_MARKET_COMMENT_LOADING
} from '../actions/actions';

const initialState = {
  addLoading: false,
  addError: '',
  getLoading: false,
  getError: '',
  deleteLoading: false,
  deleteError: '',
  likeLoading: false,
  likeError: ''
  // likeCount: null,
};

const commentReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case ADD_COMMENT_LOADING:
      newState.addLoading = true;
      break;
    case ADD_COMMENT_SUCCESS:
      newState.addLoading = false;
      newState.addError = '';
      break;
    case ADD_COMMENT_FAILURE:
      newState.addLoading = false;
      newState.addError = action.payload.error;
      break;
    case DELETE_COMMENT_LOADING:
      newState.deleteLoading = true;
      break;
    case DELETE_COMMENT_SUCCESS:
      newState.deleteLoading = false;
      newState.deleteError = '';
      break;
    case DELETE_COMMENT_FAILURE:
      newState.deleteLoading = false;
      newState.deleteError = action.payload.error;
      break;
    // case LIKE_COMMENT_LOADING:
    //   newState.likeLoading = true;
    //   break;
    // case LIKE_COMMENT_SUCCESS:
    //   newState.likeLoading = false;
    //   newState.likeError = "";
    //   newState.likeCount = action.payload.likeCount;
    //   break;
    // case LIKE_COMMENT_FAILURE:
    //   newState.likeLoading = false;
    //   newState.likeError = action.payload.error;
    //   break;
    // case UNLIKE_COMMENT_LOADING:
    //   newState.likeLoading = true;
    //   break;
    // case UNLIKE_COMMENT_SUCCESS:
    //   newState.likeLoading = false;
    //   newState.likeError = "";
    //   break;
    // case UNLIKE_COMMENT_FAILURE:
    //   newState.likeLoading = false;
    //   newState.likeError = action.payload.error;
    //   break;
    case ADD_MARKET_COMMENT_LOADING:
      newState.addLoading = true;
      break;
    case ADD_MARKET_COMMENT_SUCCESS:
      newState.addLoading = false;
      newState.addError = '';
      break;
    case ADD_MARKET_COMMENT_FAILURE:
      newState.addLoading = false;
      newState.addError = action.payload.error;
      break;
    case DELETE_MARKET_COMMENT_LOADING:
      newState.deleteLoading = true;
      break;
    case DELETE_MARKET_COMMENT_SUCCESS:
      newState.deleteLoading = false;
      newState.deleteError = '';
      break;
    case DELETE_MARKET_COMMENT_FAILURE:
      newState.deleteLoading = false;
      newState.deleteError = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default commentReducer;
