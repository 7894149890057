import {
  GET_WORKANNIVERSARY_FAILURE,
  GET_WORKANNIVERSARY_LOADING,
  GET_WORKANNIVERSARY_SUCCESS
} from '../actions/actions';

const initialState = {
  loading: false,
  anniversary: {},
  error: '',
  addLoading: false,
  addError: ''
};

const anniversaryReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_WORKANNIVERSARY_LOADING:
      newState.loading = true;
      break;
    case GET_WORKANNIVERSARY_SUCCESS: {
      newState.anniversary = { ...state.details, ...action.payload.details };
      newState.loading = false;
      break;
    }
    case GET_WORKANNIVERSARY_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default anniversaryReducer;
