import axios from 'axios';
import { toast } from 'react-toastify';
import {
  EDIT_CLUB_DESCRIPTION_LOADING,
  EDIT_CLUB_DESCRIPTION_SUCCESS,
  EDIT_COVERIMAGE_LOADING,
  EDIT_COVERIMAGE_SUCCESS,
  EDIT_PROFILEIMAGE_LOADING,
  EDIT_PROFILEIMAGE_SUCCESS
} from '../actions/actions';
import React from 'react';

export const editDescription = (data) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append('coverImage', '');
    formData.append('profileImage', '');
    formData.append('name', '');
    formData.append('description', data.description);

    dispatch({
      type: EDIT_CLUB_DESCRIPTION_LOADING
    });
    axios
      .put(process.env.REACT_APP_CLUBS_URL + `/clubs/${data.clubId}`, formData, {
        headers: {
          accept: '*/*'
        }
      })
      .then((res) => {
        const details = res.data.description;
        toast.success('Saved Succesfully');
        dispatch({
          type: EDIT_CLUB_DESCRIPTION_SUCCESS,
          payload: {
            details: details
          }
        });
      });
  };
};

export const editCoverImage = (data) => {
  return (dispatch) => {
    const toastId = React.createRef();
    const formData = new FormData();
    Array.from(data.coverImage).forEach((file) => {
      formData.append('coverImage', file);
    });
    formData.append('profileImage', '');
    formData.append('name', '');
    formData.append('description', '');

    dispatch({
      type: EDIT_COVERIMAGE_LOADING
    });
    axios
      .put(process.env.REACT_APP_CLUBS_URL + `/clubs/${data.clubId}`, formData, {
        headers: {
          accept: '*/*'
        },
        onUploadProgress: (p) => {
          const progress = p.loaded / p.total;

          // check if we already displayed a toast
          if (toastId.current === null && progress < 1) {
            toastId.current = toast('Updating Cover Image...', {
              progress: progress
            });
          } else {
            toast.update(toastId.current, { progress });
          }
        }
      })
      .then((res) => {
        const details = res.data.clubCoverUrl;
        toast.success('Cover Image Updated!');
        dispatch({
          type: EDIT_COVERIMAGE_SUCCESS,
          payload: {
            details: details
          }
        });
      });
  };
};

export const editProfileImage = (data) => {
  return (dispatch) => {
    const toastId = React.createRef();
    const formData = new FormData();
    formData.append('coverImage', '');
    Array.from(data.profileImage).forEach((file) => {
      formData.append('profileImage', file);
    });
    formData.append('name', '');
    formData.append('description', '');
    dispatch({
      type: EDIT_PROFILEIMAGE_LOADING
    });
    axios
      .put(process.env.REACT_APP_CLUBS_URL + `/clubs/${data.clubId}`, formData, {
        headers: {
          accept: '*/*'
        },
        onUploadProgress: (p) => {
          const progress = p.loaded / p.total;

          // check if we already displayed a toast
          if (toastId.current === null && progress < 1) {
            toastId.current = toast('Updating Profile Image...', {
              progress: progress
            });
          } else {
            toast.update(toastId.current, { progress });
          }
        }
      })
      .then((res) => {
        const details = res.data.clubProfileUrl;
        toast.success('Profile Image Updated!');
        dispatch({
          type: EDIT_PROFILEIMAGE_SUCCESS,
          payload: {
            details: details
          }
        });
      });
  };
};
