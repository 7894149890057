import React, { useEffect, useState } from 'react';

//components
import BuySellPostCard from './BuySellPostCard';
import FlatRentPostCard from './FlatRentPostCard';

//redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

//actions
import { saveLikeForMarketPost } from '../../../redux/actionCreators/postLike.actionCreator';
import { deleteMarketPost } from '../../../redux/actionCreators/marketplace.actionCreator';
import { getAllMarketPost } from '../../../redux/actionCreators/marketplace.actionCreator';
import {
  addMarketComment,
  deleteMarketComment,
  likeMarketComment
} from '../../../redux/actionCreators/comment.actionCreator';
import { getEmpIdByEmail } from '../../../redux/actionCreators/profile.actionCreator';
import { getSingleMarketPostById } from '../../../redux/actionCreators/post.actionCreator';

//assests
import backIconBlue from '../../../assets/icons/backIconBlue.svg';
import noResults from '../../../assets/img/clubsNoPost.svg';

//mui
import { Grid, CircularProgress } from '@material-ui/core';

//SCSS
import s from '../marketplace.module.scss';
import { useMediaQuery } from 'react-responsive';
import CarPool from './CarPool';

const BuySell = ({ ...props }) => {
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const postUrl =
    window.location.href.includes('/postId/') && window.location.href.split('/').pop();
  const dispatch = useDispatch();

  const [value, setValue] = useState(useSelector((state) => state.marketPlace.value));
  const userData = useSelector((state) => state.marketPlace.post);

  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState('All Posts');
  const [trackValue, setTrackValue] = useState(value);
  const [valueforurl, setValueForurl] = useState(0);

  const [currentEditedPost, setCurrentEditedPost] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const urlParts = window.location.href.split('/'); // Splitting the URL into parts

    const valueforUrlIndex = urlParts.findIndex((part) => part.includes('v-'));

    if (valueforUrlIndex !== -1 && urlParts[valueforUrlIndex]) {
      setValueForurl(urlParts[valueforUrlIndex].split('-')[1]);
    }
  }, []);

  useEffect(() => {
    setPage((prev) => prev - prev);
    setTrackValue(value);
  }, [value]);

  useEffect(() => {
    const currentUrl = window.location.href;
  
    // Check for CARPOOL and URL contains v-1
    if (props?.post[0]?.postType === 'CARPOOL' && currentUrl.includes('v-1')) {
      console.log('yesss for CARPOOL');
      localStorage.setItem('tabValue', '2');
      
      const newUrl = currentUrl.replace(/v-\d+/, 'v-2');
      window.location.href = newUrl;
      window.location.reload(); 
    }
    
    // Check for FLAT_RENTING and URL contains v-2
    else if (props?.post[0]?.postType === 'FLAT_RENTING' && currentUrl.includes('v-2')) {
      console.log('yesss for FLAT_RENTING');
      localStorage.setItem('tabValue', '1'); 
  
      const newUrl = currentUrl.replace(/v-\d+/, 'v-1'); 
      window.location.href = newUrl; 
      window.location.reload(); 
    }
  }, [props?.post]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        if (postUrl) {
          dispatch(getSingleMarketPostById(postUrl));
        } else {
          await dispatch(getAllMarketPost(page, value));
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, page, trackValue]);

  const handleInfiniteScrolling = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setPage(0);
    window.addEventListener('scroll', handleInfiniteScrolling);
    return () => window.removeEventListener('scroll', handleInfiniteScrolling);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleInfiniteScrolling);
    return () => window.removeEventListener('scroll', handleInfiniteScrolling);
  }, []);

  useEffect(() => {
    if (value === 0) {
      setData1([]);
      setData2([]);
      if (page === 0) {
        setData([...Object.values(userData)]);
      } else {
        setData((prev) => {
          const newData = Object.values(userData).filter(
            (item) =>
              !prev.some((existingItem) =>
                existingItem.id ? existingItem.id === item.id : existingItem.postId === item.postId
              )
          );
          return [...prev, ...newData];
        });
      }
    } else if (value === 1) {
      setData([]);
      setData2([]);
      if (page === 0) {
        setData1([...Object.values(userData)]);
      } else {
        setData1((prev) => {
          const newData = Object.values(userData).filter(
            (item) =>
              !prev.some((existingItem) =>
                existingItem.id ? existingItem.id === item.id : existingItem.postId === item.postId
              )
          );
          return [...prev, ...newData];
        });
      }
    } else {
      setData([]);
      setData1([]);
      if (page === 0) {
        setData2([...Object.values(userData)]);
      } else {
        setData2((prev) => {
          const newData = Object.values(userData).filter(
            (item) =>
              !prev.some((existingItem) =>
                existingItem.id ? existingItem.id === item.id : existingItem.postId === item.postId
              )
          );
          return [...prev, ...newData];
        });
      }
    }
    setIsLoading(true);
  }, [value, filterData, Object.values(userData)[0], page]);

  const redirectHomepage = () => {
    const url = window.location.origin + '/#/marketplace/market-place-buy-sell-post/';
    window.open(url, '_self');
  };

  return (
    <div className={isMobile ? 'm-2' : 'my-5 mx-4'}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={7} lg={8}>
          <div className={`px-1 ${s.posts}`}>
            <div className={s.goBackDiv}>
              <button className={s.goBackButton} onClick={redirectHomepage}>
                <img src={backIconBlue} alt="back Icon" />
                <p className={s.newPost}>Go to Market Place</p>
              </button>
            </div>
            {props.post && props.post.length > 0 ? (
              <>
                {Number(valueforurl) === 1 && (
                  <BuySellPostCard
                    key={props.post[0].id ? props.post[0].id : props.post[0].postId}
                    users={props.users}
                    deleteComment={props.deleteMarketComment}
                    addComment={props.addMarketComment}
                    likeComment={props.likeMarketComment}
                    post={props.post[0].rentPost}
                    postData={props.post[0]}
                    data1={data1}
                    setData1={setData1}
                    likePost={props.saveLikeForMarketPost}
                    userData={finalData}
                    getEmpIdByEmail={props.getEmpIdByEmail}
                    addLoading={props.addCommentLoading}
                    deleteMarketPost={props.deleteMarketPost}
                    getValue={value}
                    pageNum={page}
                    setPage={setPage}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    currentEditedPost={currentEditedPost}
                    setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
                    setCurrentIndex={setCurrentIndex}
                    loading={props.user}
                    postType="single"
                  />
                )}
                {value === 0 && (
                  <FlatRentPostCard
                    key={props.post.id ? props.post.id : props.post.postId}
                    users={props.users}
                    deleteComment={props.deleteMarketComment}
                    addComment={props.addMarketComment}
                    likeComment={props.likeMarketComment}
                    post={props.post[0]}
                    likePost={props.saveLikeForMarketPost}
                    userData={finalData}
                    getEmpIdByEmail={props.getEmpIdByEmail}
                    addLoading={props.addCommentLoading}
                    deleteMarketPost={props.deleteMarketPost}
                  />
                )}
                {Number(valueforurl) === 2 && (
                  <CarPool
                    key={props.post[0].postId}
                    users={props.users}
                    deleteComment={props.deleteMarketComment}
                    addComment={props.addMarketComment}
                    likeComment={props.likeMarketComment}
                    post={props.post[0]}
                    postData={props.post[0].carPool}
                    data1={data1}
                    setData1={setData1}
                    likePost={props.saveLikeForMarketPost}
                    userData={finalData}
                    getEmpIdByEmail={props.getEmpIdByEmail}
                    addLoading={props.addCommentLoading}
                    deleteMarketPost={props.deleteMarketPost}
                    getValue={Number(valueforurl)}
                    pageNum={page}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    currentEditedPost={currentEditedPost}
                    setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
                    setCurrentIndex={setCurrentIndex}
                    loading={props.user}
                    postType="single"
                  />
                )}
              </>
            ) : (
              <div className={s.postError}>
                <img src={noResults} alt="no results" />
                <strong className={s.postErrorMessage}>Nothing to see for now.</strong>
                <span>"We couldn't find any matches for your search."</span>
              </div>
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {loading && <CircularProgress color="inherit" />}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    usersProfile: state.userReducer.usersProfile,
    users: state.userReducer.users,
    post: state.marketPlace.post,
    addCommentLoading: state.commentReducer.addLoading
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveLikeForMarketPost: saveLikeForMarketPost,
      addMarketComment: addMarketComment,
      deleteMarketComment: deleteMarketComment,
      likeMarketComment: likeMarketComment,
      deleteMarketPost: deleteMarketPost,
      getEmpIdByEmail: getEmpIdByEmail
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(BuySell);
