import { createStore, combineReducers } from 'redux';

// Reducers
import postReducer from './reducers/post.reducer';
import toolLinksReducer from './reducers/toolLinks.reducer';
import postTypesReducer from './reducers/postTypes.reducer';
import postLikeReducer from './reducers/postLike.reducer';
import commentReducer from './reducers/comment.reducer';
import eventReducer from './reducers/event.reducer';
import awardReducer from './reducers/award.reducer';
import profileReducer from './reducers/profile.reducer';
import skillReducer from './reducers/profile/skill.reducer';
import hobbiesReducer from './reducers/profile/hobbies.reducer';
import certificationReducer from './reducers/profile/certification.reducer';
import achievementReducer from './reducers/profile/achievement.reducer';
import trainingReducer from './reducers/profile/training.reducer';
import projectReducer from './reducers/profile/project.reducer';
import googleReducer from './reducers/auth/auth.reducer';
import profileShareReducer from './reducers/profileShare.reducer';
import userReducer from './reducers/user.reducer';
import entitlementReducer from './reducers/entitlement.reducer';
import searchedProfileReducer from './reducers/searchedProfile.reducer';
import searchedPostReducer from './reducers/searchedPost.reducer';
import anniversaryReducer from './reducers/anniversary.reducer';
import newMemberReducer from './reducers/newMembers.reducer';
import birthdaysReducer from './reducers/birthdays.reducer';
import managerDataReducer from './reducers/managerData.reducer';
import storageReducer from './reducers/storage.reducer';
import clubDetailsReducer from './reducers/clubDetails.reducer';
import clubsReducer from './reducers/clubs.reducer';
import addressReducer from './reducers/profile/address.reducer';
import notificationReducer from './reducers/notification.reducer';
import hashtagReducer from './reducers/hashtag.reducer';
import pollsReducer from './reducers/polls.reducer';
import customTagsReducer from './reducers/customTags.reducer';
import educationReducer from './reducers/profile/education.reducer';
import marketPlaceReducer from './reducers/marketplace.reducer';
import publicationsReducer from './reducers/publications.reducer';
import hrDashboardReducer from './reducers/hrDashboard.reducer';

const rootReducer = combineReducers({
  postReducer,
  toolLinksReducer,
  postTypesReducer,
  postLikeReducer,
  commentReducer,
  eventReducer,
  awardReducer,
  profileReducer,
  skillReducer,
  hobbiesReducer,
  certificationReducer,
  achievementReducer,
  trainingReducer,
  projectReducer,
  googleReducer,
  profileShareReducer,
  userReducer,
  searchedProfileReducer,
  searchedPostReducer,
  anniversaryReducer,
  newMemberReducer,
  birthdaysReducer,
  entitlementReducer,
  managerDataReducer,
  storageReducer,
  clubDetailsReducer,
  clubsReducer,
  addressReducer,
  notificationReducer,
  hashtagReducer,
  pollsReducer,
  customTagsReducer,
  educationReducer,
  publicationsReducer,
  marketPlace: marketPlaceReducer,
  hrDashboardReducer
});

// Redux Store
const redux = require('redux');
const thunkMiddleware = require('redux-thunk').default;
const applyMiddleware = redux.applyMiddleware;

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
