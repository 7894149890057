import React, { useState, useEffect } from 'react';
import Details from './details';
import s from '../sidebar.module.scss';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
const yearOptions = [
  { name: 'Select Year', value: '' },
  ...Array(parseInt(new Date().getFullYear()) - 2019 + 1)
    .fill()
    .map((val, idx) => ({
      name: `${2019 + idx}`,
      value: `${2019 + idx}`
    }))
    .reverse()
];

const Search = (props) => {
  const [year, setYear] = useState(yearOptions[0].value);
  const [award, setAward] = useState('');

  const getErrors = () => {
    let errorsCopy = {
      year: '',
      award: ''
    };
    if (!year) errorsCopy.year = 'This field is required';
    if (!award) errorsCopy.award = 'This field is required';
    return errorsCopy;
  };
  const [errors, setErrors] = useState(getErrors());

  useEffect(() => {
    setErrors(getErrors());
  }, [year, award]);
  useEffect(() => {
    setYear(parseInt(new Date().getFullYear()));
    props.getAwards(parseInt(new Date().getFullYear()));
  }, []);

  const handleChange = (e) => {
    let value = e.target.value;
    switch (e.target.name) {
      case 'year': {
        setYear(value);
        setAward('');
        if (value.length > 0) props.getAwards(value);

        break;
      }
      case 'award': {
        if (value.length > 0) {
          setAward(value);
          props.getAwardsWinners(value);
        }
        break;
      }
      default:
        break;
    }
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2014 + 1 }, (_, idx) => 2014 + idx).reverse();

  return (
    <div className="p-2 ">
      <form>
        <div className={s.awardContainer}>
          <select value={year || ''} className={s.yearTab} name="year" onChange={handleChange}>
            {[
              { name: 'Year', value: '' },
              ...years.map((y) => ({ name: `${y}`, value: `${y}` }))
            ].map((option, idx) => (
              <option className={s.options} value={option.value} key={idx}>
                {option.name}
              </option>
            ))}
          </select>
          {!props.loading ? (
            <select className={s.awardTab} value={award} name="award" onChange={handleChange}>
              <option value={''} style={{ display: year !== '' ? 'none' : 'block' }}>
                Select Event
              </option>
              {year !== '' &&
                (props.awards.length > 0 ? (
                  [...props.awards].map((option, idx) => (
                    <option value={option.eventRecognitionId} key={idx}>
                      {option.name ? option.name : <Skeleton variant="rect" width={80} />}
                    </option>
                  ))
                ) : (
                  <option value="" className={s.employee}>
                    No event Found
                  </option>
                ))}
            </select>
          ) : (
            <div className={s.loadingTab}>
              <CircularProgress size={'10px'} />
            </div>
          )}
        </div>
      </form>
      {year !== '' && award !== '' && (
        <Details winners={props.winners} year={year} awards={award} loading={props.loading} />
      )}
    </div>
  );
};

export default Search;
