//awardIcons
import onthespot from '../assets/icons/OnTheSpot.svg';
import risingstar from '../assets/icons/RisingStar.svg';
import employeeofthequarter from '../assets/icons/EmployeeOfTheQuarter.svg';
import employeeoftheyear from '../assets/icons/EmployeeOfTheYear.svg';
import gemaward from '../assets/icons/Gem Award.svg';
import rolemodel from '../assets/icons/RoleModel.svg';
import longservice5years from '../assets/icons/LongService.svg';
import longservice10years from '../assets/icons/LongService10.svg';
import LIKE from '../assets/icons/like.png';
import LOVE from '../assets/icons/Love hover.svg';
import HAPPY from '../assets/icons/Happy.svg';
import SUPPORT from '../assets/icons/Support.svg';
import SAD from '../assets/icons/Sad.svg';
import CELEBRATE from '../assets/icons/celebrate.svg';
import SURPRISE from '../assets/icons/Surprised.svg';
import techinnovation from '../assets/icons/Tech Innovation.svg';
import equality from '../assets/icons/Gem Award - Equality.svg';
import leadership from '../assets/icons/Gem Award - Leadership.svg';
import commitment from '../assets/icons/Gem Award - Commitment.svg';
import influence from '../assets/icons/Gem Award - Influence.svg';
import integrity from '../assets/icons/Gem Award - Integrity.svg';
import excellenceincollaboration from '../assets/icons/Excellence in Collaboration.svg';
import highestpointsfortheinterviewstaken from '../assets/icons/Interview Taken.svg';
import highestpointsforthetrainingsessiontaken from '../assets/icons/Training Taken.svg';

export const awardIcons = {
  onthespot,
  risingstar,
  employeeofthequarter,
  employeeoftheyear,
  gemaward,
  rolemodel,
  longservice5years,
  'gemaward-techinnovation': techinnovation,
  'gemaward-equality': equality,
  'gemaward-leadership': leadership,
  'gemaward-commitment': commitment,
  'gemaward-influence': influence,
  'gemaward-integrity': integrity,
  'longservice(10years)': longservice10years,
  'longservice(5years)': longservice5years,
  excellenceincollaboration,
  highestpointsfortheinterviewstaken,
  highestpointsforthetrainingsessiontaken
};
console.log();
export const reactions = { LIKE, LOVE, CELEBRATE, HAPPY, SUPPORT, SAD, SURPRISE };
