import React, { useEffect, useState } from 'react';
import s from '../marketplace.module.scss';
import BuySellBody from './BuySellBody';
import { useParams } from 'react-router-dom';
import marketPlace from '../../../../src/assets/img/marketPlace/marketPlace.png';
import locationImage from '../../../../src/assets/img/marketPlace/locImage.png';
import lending from '../../../../src/assets/img/marketPlace/lending.png';
import looking from '../../../../src/assets/img/marketPlace/looking.png';
import buy from '../../../../src/assets/img/marketPlace/buy.svg';
import sell from '../../../../src/assets/img/marketPlace/sell.svg';
import apartment from '../../../../src/assets/img/marketPlace/apartment.png';
import price from '../../../../src/assets/img/marketPlace/price.png';
import furniture from '../../../../src/assets/img/marketPlace/furniture.png';
import flatApartment from '../../../../src/assets/img/marketPlace/flatApartment.svg';
import flatRooms from '../../../../src/assets/img/marketPlace/flatRooms.svg';
import flatBudget from '../../../../src/assets/img/marketPlace/flatBudget.svg';
import flatFurnishing from '../../../../src/assets/img/marketPlace/flatFurnishing.svg';
import { Box, Chip } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PostCardComment from '../PostCommon/PostCardComment';
import PostCardLike from '../PostCommon/PostCardLike';
import { Button, Carousel, Modal } from 'react-bootstrap';
import cns from 'classnames';

const FlatRentPostCard = ({
  usersProfile,
  post,
  likePost,
  userData,
  users,
  deleteComment,
  addLoading,
  getEmpIdByEmail,
  commentInputRef,
  likeComment,
  addComment,
  deleteMarketPost,
  setFinalData,
  finalData,
  ...props
}) => {
  // let isEdited = post.postTime === post.updateTime;
  const [showModal, setShowModal] = useState(false);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const [timestampArray, setTimestampArray] = useState([]);
  // const timestampArray = post?.postedOn;
  useEffect(() => {
    if (post?.postedOn && post?.postedOn != null) setTimestampArray(post?.postedOn);
  }, [post?.postedOn]);
  const timestampInMilliseconds = new Date(
    timestampArray[0], // Year
    timestampArray[1] - 1, // Month (months are 0-based in JavaScript)
    timestampArray[2], // Day
    timestampArray[3], // Hours
    timestampArray[4], // Minutes
    timestampArray[5], // Seconds
    timestampArray[6] / 1000 // Milliseconds (converted to seconds)
  )?.getTime();

  let isEdited = timestampInMilliseconds;
  const params = useParams();

  useEffect(() => {
    if (params?.id) {
      toggleExpand();
    }
  }, [params?.id]);

  const [expand, setExpand] = useState(false);
  const [expandDesc, setExpandDesc] = useState(false);

  const [landmarkDisplayCount, setLandmarkDisplayCount] = useState(3);

  const toggleLandExpand = () => {
    if (landmarkDisplayCount === 3) {
      // Show all landmarks when expanding
      setLandmarkDisplayCount(landmarks.length);
    } else {
      // Show only three landmarks when collapsing
      setLandmarkDisplayCount(3);
    }
  };

  const landmarks = post?.landmark;
  const toggleExpand = () => {
    setExpand(!expand);
  };

  const toggleExpandDesc = () => {
    setExpandDesc(!expandDesc);
  };
  const maxWidth = 80;
  const renderDescription = () => {
    const description = post.propertyDesc;

    if (!description) {
      return null; // Handle the case where description is not available
    }

    if (expandDesc || description.length <= 30) {
      // If expanded or text is within the max length, show the entire text with "Show Less" button
      const containerStyle = {
        maxHeight: expandDesc ? 'none' : '60px', // Adjust the max height as needed
        // overflow: 'hidden',
        wordWrap: 'break-word',
        position: 'relative'
      };

      const textStyle = {
        whiteSpace: expandDesc ? 'normal' : 'nowrap'
      };
      return (
        <div style={containerStyle}>
          <p style={textStyle}>{description}</p>
          {expandDesc && (
            <button onClick={toggleExpandDesc} style={{ color: '#38A3A5' }}>
              Show Less
            </button>
          )}
        </div>
      );
    } else {
      // If not expanded and text exceeds max length, show truncated text with "Show More" button
      const truncatedText = `${description.substring(0, 30)}...`;
      return (
        <div>
          {truncatedText}
          <button onClick={toggleExpandDesc} style={{ color: '#38A3A5' }}>
            Show More
          </button>
        </div>
      );
    }
  };
  return (
    <div className={s.post}>
      <div className={s.postBody}>
        <BuySellBody
          // usersProfile={usersProfile}
          users={users}
          time={isEdited}
          isEdited={isEdited ? '' : 'Edited'}
          post={post}
          deleteMarketPost={deleteMarketPost}
          postId={post?.id ? post?.id : post?.postId}
          setFinalData={setFinalData}
          finalData={finalData}
        />
        {/* <BuySellBody
        userData={userData}
        /> */}
      </div>
      <div className={cns(s.postContent, 'container-fluid')}>
        {post.transactionType === 'BUY' ? (
          <div className={cns(s.cardContainer, 'row')}>
            <div className={cns(s.card, 'col-md-4 m-0 p-0')}>
              <Carousel
                style={{ position: 'relative' }}
                className="w-100"
                controls={true}
                variant="dark"
                nextIcon={
                  <a
                    className="rounded-circle bg-white"
                    style={{
                      position: 'absolute',
                      right: '5px',
                      border: '2px solid white',
                      height: '25px',
                      width: '25px',
                      color: '#38A3A5',
                      padding: 0,
                      margin: 0
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    </svg>
                  </a>
                }
                prevIcon={
                  <Button
                    className="rounded-circle bg-white"
                    style={{
                      position: 'absolute',
                      left: '5px',
                      border: '2px solid white',
                      height: '25px',
                      width: '25px',
                      color: '#38A3A5',
                      padding: 0,
                      margin: 0
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                    </svg>
                  </Button>
                }
              >
                {post?.images?.length > 0 ? (
                  post?.images?.map((file, index) => (
                    <Carousel.Item key={index} onClick={handleModalShow}>
                      <img
                        className={cns(s.cardImage, 'w-100')}
                        src={file.fileName}
                        alt={`Slide ${index + 1}`}
                        style={{ minHeight: '170px', objectFit: 'cover', cursor: 'pointer' }}
                      />
                    </Carousel.Item>
                  ))
                ) : (
                  <Carousel.Item>
                    <img
                      className={cns(s.cardImage, 'w-100')}
                      src={marketPlace}
                      alt={`Slide 99`}
                      style={{ minHeight: '170px', objectFit: 'cover' }}
                    />
                  </Carousel.Item>
                )}
              </Carousel>
              <div>
                <Modal
                  show={showModal}
                  onHide={handleModalClose}
                  centered
                  className="custom-tranparent-modal"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    {/* Use the same Carousel code inside the modal */}
                    <Carousel
                      style={{ position: 'relative' }}
                      className="w-100"
                      controls={true}
                      variant="dark"
                      nextIcon={
                        <a
                          className="rounded-circle bg-white"
                          style={{
                            position: 'absolute',
                            right: '5px',
                            border: '2px solid white',
                            height: '25px',
                            width: '25px',
                            color: '#38A3A5',
                            padding: 0,
                            margin: 0
                          }}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                          </svg>
                        </a>
                      }
                      prevIcon={
                        <Button
                          className="rounded-circle bg-white"
                          style={{
                            position: 'absolute',
                            left: '5px',
                            border: '2px solid white',
                            height: '25px',
                            width: '25px',
                            color: '#38A3A5',
                            padding: 0,
                            margin: 0
                          }}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                          </svg>
                        </Button>
                      }
                    >
                      {post?.images?.length > 0 ? (
                        post?.images?.map((file, index) => (
                          <Carousel.Item key={index} onClick={handleModalShow}>
                            <img
                              className={cns(s.cardImage, 'w-100')}
                              src={file.fileName}
                              alt={`Slide ${index + 1}`}
                              style={{ minHeight: '170px', objectFit: 'cover', cursor: 'pointer' }}
                            />
                          </Carousel.Item>
                        ))
                      ) : (
                        <Carousel.Item>
                          <img
                            className={cns(s.cardImage, 'w-100')}
                            src={marketPlace}
                            alt={`Slide 99`}
                            style={{ minHeight: '170px', objectFit: 'cover' }}
                          />
                        </Carousel.Item>
                      )}
                    </Carousel>
                  </Modal.Body>
                  {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                      Close
                    </Button>
                  </Modal.Footer> */}
                </Modal>
              </div>
            </div>

            <div className={cns(s.cardContent, 'col-md-8')}>
              <div style={{ float: 'right' }}>
                {' '}
                {post.isLookingOrLending === 'LENDING' && <img src={lending} alt="lending img" />}
                {post.isLookingOrLending === 'LOOKING' && <img src={looking} alt="looking img" />}
                {post.transactionType === 'BUY' && <img src={buy} alt="buy img" />}
                {post.transactionType === 'SELL' && <img src={sell} alt="sell img" />}
              </div>
              <div>
                <div className={s.heading}> {post.title}</div>
                <div className={s.locationImage}>
                  <img src={locationImage} alt="Card Image" /> {post.location}
                </div>
              </div>
              <div className={s.postDescription}>{renderDescription()}</div>

              <div className={s.apartmentInfoContainer}>
                <div className={s.apartmentInfo}>
                  {' '}
                  <div
                    className={s.imageInfoContainer}
                    class="col-4  pe-1 d-flex align-items-center justify-content-center"
                  >
                    <img src={furniture} alt="Card Image" />
                  </div>
                  <div class="col p-0  w-75">
                    {/* <div style={{ fontWeight: 600 }} class="text-nowrap">
                      Property Type
                    </div> */}
                    <div className={s.apartmentDesc}>
                      {post.furnishingDetails ? post.furnishingDetails : '---'}
                    </div>
                  </div>
                </div>

                <div className={s.apartmentInfo}>
                  {' '}
                  <div
                    className={s.imageInfoContainer}
                    class="col-3 p-0 d-flex align-items-center justify-content-center"
                  >
                    <img src={apartment} alt="Card Image" />
                  </div>
                  <div class="col p-0 w-75">
                    {/* <div style={{ fontWeight: 600 }} class="text-nowrap">
                      Apartment Type
                    </div> */}
                    <div className={s.apartmentDesc}>
                      {post.propertyType ? post.propertyType : '---'}
                    </div>
                  </div>
                </div>

                <div className={s.apartmentInfo}>
                  {' '}
                  <div
                    className={s.imageInfoContainer}
                    class="col-4 ms-1 d-flex align-items-center justify-content-center"
                  >
                    <img src={price} alt="Card Image" />
                  </div>
                  <div class="col p-0 w-75">
                    {/* <div style={{ fontWeight: 600 }} class="text-nowrap">
                      Rent
                    </div> */}
                    <div className={s.apartmentDesc}>Rs {post.price ? post.price : '---'}</div>
                  </div>
                </div>
              </div>
              <div className={s.landmark}>
                <div className={s.chipContainer}>
                  Landmark
                  <Chip
                    label={post.landmark}
                    className={s.chip}
                    style={{
                      backgroundColor: '#FFF',
                      boxShadow: '0px 0px 2px 0px',
                      border: '0.5px solid #407EC4',
                      marginLeft: '10px',
                      fontWeight: '550',
                      color: '#407EC4'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={s.cardContainer}>
            <div className={s.cardContent}>
              {/* <div style={{ float: 'right' }}>
                {' '}
                <img src={looking} alt="looking img" />
              </div> */}
              <div style={{ float: 'right' }}>
                {' '}
                {post.isLookingOrLending === 'LENDING' && <img src={lending} alt="lending img" />}
                {post.isLookingOrLending === 'LOOKING' && <img src={looking} alt="looking img" />}
                {post.transactionType === 'BUY' && <img src={buy} alt="buy img" />}
                {post.transactionType === 'SELL' && <img src={sell} alt="sell img" />}
              </div>
              <div>
                <div className={s.heading}> {post.title}</div>
                <div className={s.locationImage}>
                  <img src={locationImage} alt="Card Image" /> {post.location}
                </div>
              </div>
              <div className={s.postDescription}>{renderDescription()}</div>

              <div className={s.apartmentLookingInfoContainer}>
                <div className={s.apartmentLookingInfo}>
                  {' '}
                  <div
                    className={s.imageLookingInfoContainer}
                    class="col-4 d-flex align-items-center justify-content-center"
                  >
                    <img src={flatFurnishing} alt="furniture Image" />
                  </div>
                  <div class="col p-0 w-75">
                    <div class="text-nowrap">Fully Furnished</div>
                    <div className={s.apartmentLookingDesc}>Furnishing</div>
                  </div>
                </div>

                <div className={s.apartmentLookingInfo}>
                  {' '}
                  <div
                    className={s.imageLookingInfoContainer}
                    class="col-4 d-flex align-items-center justify-content-center"
                  >
                    <img src={flatApartment} alt="apartment Image" />
                  </div>
                  <div class="col p-0 w-75">
                    <div class="text-nowrap">{post.propertyType ? post.propertyType : '---'}</div>
                    <div className={s.apartmentLookingDesc}>Property&nbsp;Type</div>
                  </div>
                </div>

                <div className={s.apartmentLookingInfo}>
                  {' '}
                  <div
                    className={s.imageLookingInfoContainer}
                    class="col-4 d-flex align-items-center justify-content-center"
                  >
                    <img src={flatBudget} alt="price Image" />
                  </div>
                  <div class="col p-0 w-75">
                    <div class="text-nowrap">Rs {post.price ? post.price : '---'}</div>
                    <div className={s.apartmentLookingDesc}>Rent</div>
                  </div>
                </div>
                <div className={s.apartmentLookingInfo}>
                  {' '}
                  <div
                    className={s.imageLookingInfoContainer}
                    class="col-4 d-flex align-items-center justify-content-center"
                  >
                    <img src={flatRooms} alt="room Image" />
                  </div>
                  <div class="col p-0 w-75">
                    <div class="text-nowrap">
                      {post.numberOfBedrooms ? post.numberOfBedrooms : '---'}
                    </div>
                    <div className={s.apartmentLookingDesc}>No&nbsp;of&nbsp;Rooms</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className={s.line}></div> */}
        <div className={s.postFooter}>
          <div className=""></div>

          <PostCardLike
            users={users}
            likePost={likePost}
            likes={post.reactions ? post.reactions : post.postReaction}
            comments={post.comments ?? []}
            userReaction={post.reactions ? post.reactions : post.postReaction}
            toggleExpand={toggleExpand}
            postId={post.id ? post.id : post.postId}
          />
          {expand ? (
            <PostCardComment
              users={users}
              post={post}
              comments={post.comments ?? []}
              deleteComment={deleteComment}
              addComment={addComment}
              addLoading={addLoading}
              postId={post.id ? post.id : post.postId}
              likeComment={likeComment}
              toggleExpand={toggleExpand}
              commentInputRef={commentInputRef}
              getEmpIdByEmail={getEmpIdByEmail}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default FlatRentPostCard;
