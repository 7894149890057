import axios from 'axios';
import { toast } from 'react-toastify';
import { sortEventsByDate } from '../../helpers';
import {
  GET_EVENT_FAILURE,
  GET_EVENT_SUCCESS,
  GET_EVENT_LOADING,
  ADD_EVENT_LOADING,
  EDIT_EVENT_LOADING,
  DELETE_EVENT_LOADING,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  GET_ORGANISERS_FAILURE,
  GET_ORGANISERS_LOADING,
  GET_ORGANISERS_SUCCESS,
  GET_PAST_EVENTS_FAILURE,
  GET_PAST_EVENTS_SUCCESS,
  GET_PAST_EVENTS_LOADING,
  GET_EVENT_BYID_FAILURE,
  GET_EVENT_BYID_SUCCESS,
  GET_EVENT_BYID_LOADING,
  GET_SUBCHANNELS_LOADING,
  GET_SUBCHANNELS_SUCCESS,
  GET_SUBCHANNELS_FAILURE
} from '../actions/actions';

export const getEvents = () => {
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_LOADING
    });
    axios
      .get(process.env.REACT_APP_EVENTS_URL + '/events', {
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const allEvents = response.data;
          const sortedEvents = allEvents;
          dispatch({
            type: GET_EVENT_SUCCESS,
            payload: {
              events: [...sortedEvents]
            }
          });
        }
        dispatch({
          type: GET_EVENT_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_EVENT_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getPastEvents = (pageNum) => {
  return (dispatch) => {
    dispatch({
      type: GET_PAST_EVENTS_LOADING
    });
    axios
      .get(process.env.REACT_APP_EVENTS_URL + `/events/past-events?pageNum=${pageNum}`, {
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const allEvents = response.data;
          const sortedEvents = sortEventsByDate(allEvents, 'startDate');
          dispatch({
            type: GET_PAST_EVENTS_SUCCESS,
            payload: {
              events: [...sortedEvents]
            }
          });
        }
        dispatch({
          type: GET_PAST_EVENTS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PAST_EVENTS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getClubSubChannels = (clubId) => {
  return (dispatch) => {
    dispatch({
      type: GET_SUBCHANNELS_LOADING
    });
    axios
      .get(process.env.REACT_APP_EVENTS_URL + `/events/sub-channels?clubId=${clubId}`, {
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const details = response.data;
          dispatch({
            type: GET_SUBCHANNELS_SUCCESS,
            payload: {
              details: details
            }
          });
        }
        dispatch({
          type: GET_SUBCHANNELS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SUBCHANNELS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const addEvent = (data, clubId) => {
  return (dispatch) => {
    dispatch({
      type: ADD_EVENT_LOADING
    });
    return axios.post(process.env.REACT_APP_EVENTS_URL + '/events', data, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*/*'
      },
      params: {
        clubId: clubId
      }
    });
  };
};

export const editEvent = (eventId, updatedEvent, clubId) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_EVENT_LOADING
    });
    return axios.put(process.env.REACT_APP_EVENTS_URL + `/events/${eventId}`, updatedEvent, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*/*'
      },
      params: {
        clubId: clubId
      }
    });
  };
};

export const deleteEvent = (eventId) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_EVENT_LOADING
    });
    toast.error('Deleting the Event.', { autoClose: 1000 });
    axios
      .delete(process.env.REACT_APP_EVENTS_URL + `/events/${eventId}`, {
        headers: {
          accept: '*/*'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: DELETE_EVENT_SUCCESS
          });
          toast.success('Event deleted successfully.');
          getEvents()(dispatch);
        }

        dispatch({
          type: DELETE_EVENT_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_EVENT_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getOrganisers = () => {
  return (dispatch) => {
    dispatch({
      type: GET_ORGANISERS_LOADING
    });
    axios
      .get(process.env.REACT_APP_EVENTS_URL + '/events/clubs-associated-with-user', {
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*'
        },
        params: {
          userId: localStorage.getItem('email')
        }
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_ORGANISERS_SUCCESS,
            payload: {
              organisers: response.data
            }
          });
        }
        dispatch({
          type: GET_ORGANISERS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ORGANISERS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getEventsById = (eventId) => {
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_BYID_LOADING
    });

    axios
      .get(`${process.env.REACT_APP_EVENTS_URL}/events?eventId=${eventId}`, {
        headers: {
          accept: '*/*'
        }
      })

      .then((response) => {
        const details = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_EVENT_BYID_SUCCESS,
            payload: {
              events: [...details]
            }
          });
        }
        dispatch({
          type: GET_EVENT_BYID_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_EVENT_BYID_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
