import React, { useState } from 'react';

import backIcon from '../../../../../assets/icons/backIcon.svg';

// Components
import Event from './event/event';
import Award from './award/award';
import Birthday from './birthday/birthday';
import WorkAnniversary from './workAnniversary/workAnniversary';
import NewMember from './newMember/index';
import TrendingHash from './trendingHashTag/TrendingHash';
import Viewport from '../../../../helpers/viewport';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const RightSidebar = React.memo((props) => {
  const ALLSIDEBARACTIVITIES = [
    { component: <Event />, name: 'Events' },
    { component: <Award />, name: 'Awards' },
    {
      component: (
        <TrendingHash
          setShowRightWrapperMobile={props.setShowRightWrapperMobile}
          showRightWrapperMobile={props?.showRightWrapperMobile}
        />
      ),
      name: 'Trending'
    },
    { component: <Birthday />, name: 'Birthdays' },
    { component: <WorkAnniversary />, name: 'Anniversary' },
    { component: <NewMember />, name: 'New Members' }
  ];
  const [allActivities, setAllActivities] = useState(ALLSIDEBARACTIVITIES);
  let sortType = props?.activeMobileActivity;

  // const { width } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });

  useEffect(() => {
    if (!isMobile && props.setShowRightWrapperMobile && props.setActiveMobileActivity) {
      props.setShowRightWrapperMobile(false);
      props.setActiveMobileActivity('');
      setAllActivities(ALLSIDEBARACTIVITIES);
    }

    if (props.activeMobileActivity?.length) {
      let newActivities = allActivities.reduce(activitySortReducer, []);
      setAllActivities([...newActivities]);
    }
  }, [props?.activeMobileActivity?.length, isMobile]);

  const activitySortReducer = (acc, activity) => {
    if (activity.name === sortType) return [activity, ...acc];
    return [...acc, activity];
  };

  return (
    <div
      className="right-sidebar-expand"
      style={{
        display: props?.showRightWrapperMobile || !isMobile ? 'block' : 'none',
        width: props?.showRightWrapperMobile || !isMobile ? '100%' : ''
      }}
    >
      {isMobile ? (
        <img
          src={backIcon}
          alt="back icon"
          onClick={() => props?.setShowRightWrapperMobile(false)}
        />
      ) : null}

      {allActivities.map((activity) => {
        activity.id = crypto.randomUUID();
        return (
          <div className="card-events mt-3" key={activity.id}>
            <div className="card-body">{activity.component}</div>
          </div>
        );
      })}
    </div>
  );
});

export default RightSidebar;
