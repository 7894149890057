import axios from 'axios';

import {
  GET_PROFILE_LOADING,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_EMPID_BY_EMAIL_FAILURE,
  GET_EMPID_BY_EMAIL_LOADING,
  GET_PROFILE_BY_EMPID_FAILURE,
  GET_PROFILE_BY_EMPID_LOADING,
  GET_PROFILE_BY_EMPID_SUCCESS,
  GET_SEARCHED_PROFILES_DETAIL_LOADING,
  GET_SEARCHED_PROFILES_DETAIL_SUCCESS,
  GET_SEARCHED_PROFILES_DETAIL_FAILURE,
  GET_MANAGER_DETAIL_BY_ID_SUCCESS,
  GET_MANAGER_DETAIL_BY_ID_FAILURE,
  GET_MANAGER_DETAIL_BY_ID_LOADING
} from '../actions/actions';

export const getProfileData = () => {
  return (dispatch) => {
    dispatch({
      type: GET_PROFILE_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/profile/employee-details-by-id', {
        headers: {
          accept: '*/*'
        },
        params: {
          employeeId: localStorage.getItem('id')
        }
      })
      .then((response) => {
        const details = response.data;
        dispatch({
          type: GET_PROFILE_SUCCESS,
          payload: {
            details: {
              ...details,
              imageURL: localStorage.getItem('imageURL')
            }
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROFILE_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
export const getProfileDataByEmpId = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_PROFILE_BY_EMPID_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/profile/employee-details-by-id', {
        headers: {
          accept: '*/*'
        },
        params: {
          employeeId: data.empId
        }
      })
      .then((response) => {
        const details = response.data;
        dispatch(
          {
            type: GET_PROFILE_BY_EMPID_SUCCESS,
            payload: {
              details: {
                ...details,
                imageURL: data.imageURL
              }
            }
          },
          getManagerDataById({ empId: data.empId })
        );
      })
      .catch((error) => {
        dispatch({
          type: GET_PROFILE_BY_EMPID_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getEmpIdByEmail = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_EMPID_BY_EMAIL_LOADING
    });

    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/profile/emp-id-by-email', {
        headers: {
          accept: '*/*'
        },
        params: {
          email: data.email
        }
      })
      .then((response) => {
        const details = {
          empId: response.data,
          imageURL: data.image
        };
        dispatch(getProfileDataByEmpId(details));
      })
      .catch((error) => {
        dispatch({
          type: GET_EMPID_BY_EMAIL_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getSearchedProfileDetail = (searchValue) => {
  return (dispatch) => {
    dispatch({
      type: GET_SEARCHED_PROFILES_DETAIL_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + `/search/emp-profile/`, {
        headers: {
          accept: '*/*'
        },
        params: {
          'emp-profile': searchValue
        }
      })
      .then((response) => {
        const details = response.data;
        dispatch({
          type: GET_SEARCHED_PROFILES_DETAIL_SUCCESS,
          payload: {
            details: details
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SEARCHED_PROFILES_DETAIL_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getManagerDataById = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_MANAGER_DETAIL_BY_ID_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/profile/manager-details/', {
        headers: {
          accept: '*/*'
        },
        params: {
          employeeId: data.empId
        }
      })
      .then((response) => {
        const data = response.data;
        dispatch({
          type: GET_MANAGER_DETAIL_BY_ID_SUCCESS,
          payload: {
            data: data
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_MANAGER_DETAIL_BY_ID_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
