import { GET_TOOLS_LOADING, GET_TOOLS_SUCCESS, GET_TOOLS_FAILURE } from '../actions/actions';

const initialState = {
  loading: false,
  links: [],
  error: ''
};

const toolLinksReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_TOOLS_LOADING:
      newState = {
        loading: true,
        links: [],
        error: ''
      };
      break;
    case GET_TOOLS_SUCCESS:
      newState = {
        loading: false,
        links: [...action.payload.links],
        error: ''
      };
      break;
    case GET_TOOLS_FAILURE:
      newState = {
        loading: false,
        links: newState.links,
        error: action.payload.error
      };
      break;
    default:
      return state;
  }
  state = newState;
  return state;
};

export default toolLinksReducer;
