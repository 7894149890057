import React, { useState, useEffect } from 'react';

//other imports
import Picker from 'emoji-picker-react';
import cns from 'classnames';
import { useRef } from 'react';
import ReactQuill from 'react-quill';
import SearchedPeople from '../../../pages/dashboard/components/home/searched-people';
import { useParams } from 'react-router-dom';
import { formats, modules } from '../../helpers/editorToolbar';
import MediaUploadPreview from '../../dashboard/components/home/postContainer/writePost/mediaUploadPreview';
import ImageModal from '../../../components/Modal/imageModal';
import TimeAgo from '../../helpers/timer';
import { toast } from 'react-toastify';

//Components

//helpers
import { getProfileByEmail, isUserActive, validateMediaSize } from '../../../helpers/index';
import clsx from 'classnames';
import _, { uniqueId } from 'lodash';

// Scss
import s from '../marketplace.module.scss';

//Icons and Images
import smiley from '../../../assets/icons/SmileyGrey.svg';
import userIcon from '../../../assets/img/user.png';
import manager from '../../../assets/img/manager.png';
import likeIcon from '../../../assets/icons/like.png';

//mui
import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import CommentNames from './commentNames';

const likedByUser = (likes) => {
  for (let i = 0; i < likes.length; i++) {
    if (
      likes[i].commentIdentity?.userId === localStorage.getItem('email') &&
      likes[i].commentLikeFlag === 1
    ) {
      return true;
    }
  }
};

const getLikesCount = (likes) => {
  let count = 0;
  likes.forEach((like) => {
    if (like.commentLikeFlag) {
      count++;
    }
  });
  return count ? count : null;
};

const PostCardComment = ({
  comments,
  deleteComment,
  addComment,
  addLoading,
  postId,
  likeComment,
  commentInputRef,
  users,
  post,
  toggleExpand
}) => {
  const [expand, setExpand] = useState(false);
  const [commentContent, setCommentContent] = useState('');
  const [isToggled, setIsToggled] = useState(false);
  const [isBlank, setIsBlank] = useState(true);
  const [searchInput, setSearchInput] = useState({ searchValue: '', isPost: false });
  const [postEmails, setPostEmails] = useState([]);
  const [uploaderInput, setUploaderInput] = useState([]);
  const [isMediaValidated, setIsMediaValidated] = useState(true);
  const [showImageModal, setShowImageModal] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [rawText, setRawText] = useState('');
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const params = useParams();
  let wrapper = useRef();
  const editorConfig = {
    id: 'comment',
    modules: modules({ id: 'comment' })
  };
  useEffect(() => {
    if (commentInputRef.hasOwnProperty('current')) {
      const listener = (event) => {
        if (event.keyCode === 13 && event.target.value !== '') {
          handleAddComment(event.target.value);
        }
      };
      commentInputRef.current.addEventListener('keypress', listener);
      return () => {
        commentInputRef.current.removeEventListener('keypress', listener);
      };
    }
    commentInputRef.focus();
  }, []);

  // ************************************
  // Handlers
  // ************************************

  useEffect(() => {
    toggleViewMoreCommentsExpand();
  }, [params?.id]);

  const toggleViewMoreCommentsExpand = () => {
    setExpand(!expand);
  };

  const handleLike = (commentId, flag) => {
    let data = {
      commentId,
      commentLikeFlag: flag ? 0 : 1,
      postId
    };

    likeComment(data);
  };

  // const addContentToEditor = (content, pos) => {
  //   let ind = pos;
  //   const editor = commentInputRef.getEditor();
  //   if (!ind) {
  //     ind = editor.getLength() - 1;
  //   }
  //   editor.insertText(ind, content);
  //   editor.setSelection(ind + 1, 0);
  // };
  const addContentToEditor = (content) => {
    const editor = commentInputRef.getEditor();
    const selection = editor.getSelection(true);
    editor.insertEmbed(selection.index, 'emoji', content);
  };

  useEffect(() => {
    let handler = (event) => {
      if (!wrapper.current.contains(event.target)) {
        setIsToggled(false);
      }
    };
    let escapeClose = (event) => {
      if (event.keyCode === 27) {
        setIsToggled(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('keydown', escapeClose);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('keydown ', escapeClose);
    };
  }, []);
  const onEmojiClick = (event, emojiObject) => {
    const editor = commentInputRef.getEditor();
    const range = editor.getSelection(true);
    editor.insertText(range.index, emojiObject.emoji);
  };

  // const onEmojiClick = (event, emojiObject) => {
  //   setCommentContent(commentContent + emojiObject.emoji);
  //   addContentToEditor(emojiObject.emoji);
  // };

  const handleUpload = (e) => {
    let files = Array.from(e.target.files);
    e.target.value = ''; // allows to upload same file multiple times

    if (
      files[0].type.split('/')[0] === 'image' ||
      (files[0].type.split('/')[0] === 'video' && files[0].type.split('/')[1] === 'mp4')
    ) {
      let newFiles = [...files, ...uploaderInput];

      let isUnderSizeLimit = true;

      newFiles.forEach((file) => {
        isUnderSizeLimit = validateMediaSize(file.type.split('/')[0], file) && isUnderSizeLimit;
      });

      if (newFiles.length || rawText.trim().length > 1) {
        setIsBlank(false);
      }

      setUploaderInput(newFiles);
      setIsMediaValidated(isUnderSizeLimit);
    } else {
      toast.error('Unsupported media file');

      return;
    }
  };
  const handleDeleteUpload = (name) => {
    let newFiles = [...uploaderInput];
    let newUploaderInput = [];
    let isUnderSizeLimit = true;

    newFiles.forEach((e) => {
      if (e.name !== name) {
        newUploaderInput.push(e);
        isUnderSizeLimit = validateMediaSize(e.type.split('/')[0], e) && isUnderSizeLimit;
      }
    });

    if (!newUploaderInput.length && rawText.trim().length <= 1) {
      setIsBlank(true);
    }

    setUploaderInput([...newUploaderInput]);
    setIsMediaValidated(isUnderSizeLimit);
  };

  const handleDelete = (commentId, idx) => {
    let data = {
      commentId: commentId,
      postId: postId
    };
    deleteComment(data);
  };

  const validate = () => {};

  const handleAddComment = (e) => {
    validate();

    let content = handleEditCommentContent(commentContent, 'save');

    content = content.replace(/<span style="color: green;">(.*?)<\/span>/g, function (match, g1) {
      return g1.split(' ').join('.').toLowerCase() + '@geminisolutions.com';
    });

    let data = {
      commentDetails: {
        commentContent: content,
        userId: localStorage.getItem('email'),
        postId: postId
      },
      files: uploaderInput
    };
    addComment(data);
    setCommentContent('');
    setUploaderInput([]);
  };

  const handleChange = (content, delta, source, editor) => {
    setCommentContent(content);

    // checking if input is blank
    if (
      ['<p><br></p>', '<ol><li><br></li></ol>', '<ul><li><br></li></ul>'].includes(
        content.trim()
      ) &&
      !uploaderInput.length
    ) {
      setIsBlank(true);
    } else if (content.trim()) {
      setIsBlank(false);
    }
    if (!rawText.trim().length) {
      setIsBlank(true);
    }

    // while tagging, getting the name after '@'
    const text = editor.getText(content);
    setRawText(text);

    if (text.includes('@')) {
      let getName = text.match('(?<=@).*');
      if (getName && getName[0].split(' ')[0].length >= 3) {
        handleSearch(getName[0]);
      }
    } else {
      setSearchInput({ searchValue: '', isPost: false });
    }
  };

  const handleSearch = (name) => {
    setSearchInput({ searchValue: name.split(' ')[0], isPost: false });
  };

  const handleCommentContent = (user) => {
    const h1 = document.createElement('span');
    const h2 = document.createElement('span');
    const textNode = document.createTextNode(
      _.startCase(user.email.split('@')[0].split('.').join(' '))
    );

    h1.appendChild(textNode);
    h2.appendChild(document.createTextNode(' '));
    h1.style.cssText = 'color:green;';

    let content = handleEditCommentContent(commentContent, 'edit');

    let regex = content.match(/@(.*?)<\/li>/g) ? /@(.*?)<\/li>/g : /@(.*?)<\/p>/g;
    let tag = content.match(/@(.*?)<\/li>/g) ? '</li>' : '</p>';
    let name = content.replace(regex, function (match, g1) {
      return h1.outerHTML + h2.outerHTML + tag;
    });

    let newEmailArray = [...postEmails];
    newEmailArray.push(user.email);

    setCommentContent(name);
    setSearchInput({ searchValue: '', isPost: false });
    setPostEmails(newEmailArray);
  };

  const handleEditCommentContent = (postText, type) => {
    let matches = postText.match(/<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g);
    if (matches) {
      matches.forEach((item) => {
        let name = item.match('<strong>(.*?)</strong>')[1];
        if (type === 'save')
          postText = postText.replace(
            item,
            name.split(' ').join('.').toLowerCase() + '@geminisolutions.com'
          );
        else {
          postText = postText.replace(item, `<span style="color: green";>${name}</span>`);
        }
      });
    }
    return postText;
  };

  const CommentsToggler = () => {
    return (
      <>
        <p
          onClick={toggleViewMoreCommentsExpand}
          className={cns(s.moreComment, 'cursor-pointer m-0 fs-14 w-100 hover-underline py-2')}
        >
          <b>{expand ? 'View Less Comments' : 'View More Comments'}</b>
        </p>
      </>
    );
  };

  const getProfile = async (userId, e) => {
    e.stopPropagation();
    getProfileByEmail(userId);
  };

  const renderNameInComments = (content) => {
    let div = document.createElement('div');
    const textNode = document.createTextNode(content);
    div.appendChild(textNode);

    let email = content.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);

    if (email) {
      let newInput = content;
      email.forEach((id) => {
        let anchor = document.createElement('a');
        let anchorHref = localStorage.getItem('email') === id ? '/#/profile/' : `/#/profile/${id}`;
        anchor.href = anchorHref;
        const textNode = document.createTextNode(id.split('@')[0].split('.').join(' '));
        anchor.appendChild(textNode);
        anchor.style.cssText = 'color:green; text-transform:capitalize; font-weight:bold';
        newInput = newInput.replace(id, anchor.outerHTML);
      });
      return newInput;
    } else {
      return content;
    }
  };

  const toggleImageModal = (index) => {
    setShowImageModal(!showImageModal);
    setSelectedImageIndex(index);
  };

  const handleSkeletonLoading = (state, type) => {
    const style = { filter: state ? 'invert(1)' : 'invert(0)', display: state ? 'none' : 'block' };

    switch (type) {
      case 'post':
        return <Skeleton variant="rect" className={s.videoSkeleton} style={style} />;
      default:
        break;
    }
  };

  return (
    <>
      <div className={s.commentContainer}>
        <div className={s.addComment}>
          <div>
            <img
              referrerPolicy="no-referrer"
              src={localStorage.getItem('imageURL')}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = manager;
              }}
              alt="user"
              className={s.img}
            />
          </div>
          <div className={cns(s.inputGroup, addLoading ? s.disabled : '', 'input-group')}>
            <ReactQuill
              placeholder={'Add a comment'}
              ref={(el) => {
                commentInputRef = el;
              }}
              value={commentContent}
              preserveWhitespace={true}
              formats={formats}
              modules={{
                toolbar: false,
                history: {
                  delay: 500,
                  maxStack: 100,
                  userOnly: true
                },
                keyboard: editorConfig.modules.keyboard
              }}
              className={clsx(s.input)}
              onChange={handleChange}
            />

            {uploaderInput.length > 0 && (
              <>
                <hr className={s.hr} />
                <MediaUploadPreview media={uploaderInput} deleteMedia={handleDeleteUpload} />
              </>
            )}
          </div>
          <div className={s.icons}>
            <div
              id="button-addon1"
              type="submit"
              className={cns('btn btn-link text-primary d-flex', s.buttons)}
              style={{ zIndex: 10 }}
            >
              <img
                referrerPolicy="no-referrer"
                alt="smiley"
                src={smiley}
                width={19}
                onClick={() => setIsToggled(!isToggled)}
              />
              <div className={cns(s.emojiContainer, isToggled ? s.active : '')} ref={wrapper}>
                {isToggled ? (
                  <Picker
                    pickerStyle={{
                      height: 'fitContent',
                      zIndex: 10
                    }}
                    onEmojiClick={onEmojiClick}
                  />
                ) : (
                  ''
                )}
              </div>
              <Tooltip title="Upload Media">
                <label className={s.btnCustom}>
                  <input
                    multiple
                    type="file"
                    name="uploaderInput"
                    accept="image/png, image/jpeg"
                    className={s.uploaderInput}
                    onChange={handleUpload}
                  />
                  <span className="px-1 d-flex align-items-center">
                    <img
                      referrerPolicy="no-referrer"
                      alt="photo"
                      src={process.env.PUBLIC_URL + '/assets/icons/photo.svg'}
                      className={cns(s.icon, 'mr-2')}
                      height={18} 
                      width={18} 
                    />
                  </span>
                </label>
              </Tooltip>
            </div>
          </div>
          <div>
            <button
              className={s.sendButton}
              onClick={() => handleAddComment(commentContent)}
              disabled={isBlank || !isMediaValidated}
            >
              <p>Send</p>
            </button>
          </div>
        </div>
        <div className={s.uploadCommentImages}></div>
        {searchInput.searchValue && (
          <SearchedPeople
            handlePostContent={handleCommentContent}
            searchValue={searchInput.searchValue}
            tag={true}
            postContent={commentContent}
            emails={postEmails}
          />
        )}

        {[...comments]
          .reverse()
          .slice(0, expand ? comments?.length : 1)
          .map((comment, idx) => {
            const data = Object.values(users).filter((value) => value.email === comment.userId);
            const userData = data[0];

            return (
              <div key={idx} className="d-flex pb-2 w-100">
                <div onClick={userData && getProfile}>
                  <img
                    referrerPolicy="no-referrer"
                    src={userData?.photoUrl ?? userIcon}
                    className="rounded-circle mr-2 mt-2 bg-light-purple commentPic"
                    alt="profile"
                    width={35}
                    height={35}
                    onClick={(e) =>
                      userData &&
                      isUserActive(users, userData?.email) &&
                      getProfile(comment?.userId, e)
                    }
                    style={{ cursor: isUserActive(users, userData?.email) ? 'pointer' : 'auto' }}
                  />
                </div>
                <div className="w-100">
                  <div className={s.commentBox}>
                    <div className={s.nameContent}>
                      <div className="d-flex justify-content-between mb-2">
                        <button
                          className={`${s.click} `}
                          onClick={(e) => userData && getProfile(comment?.userId, e)}
                          style={{
                            cursor: isUserActive(users, userData?.email) ? 'pointer' : 'auto'
                          }}
                        >
                          <p style={{ fontWeight: '700', display: 'flex', fontSize: '13px' }}>
                            {isUserActive(users, userData?.email) ? (
                              userData?.name
                            ) : (
                              <i>Unknown User</i>
                            )}
                          </p>
                          <p className={s.nameDesig1} style={{ fontSize: '10px' }}>
                            {userData?.designation}
                          </p>
                        </button>

                        <span className={s.commentTime} style={{ fontSize: '10px' }}>
                          {TimeAgo(comment.commentTime)}
                        </span>
                      </div>
                      <div
                        className={s.textContainer}
                        dangerouslySetInnerHTML={{
                          __html: renderNameInComments(comment.commentContent)
                        }}
                      ></div>

                      {comment.gemFiles.length > 0 && (
                        <div className={s.commentImages}>
                          {comment?.gemFiles.length ? (
                            comment?.gemFiles?.map((photo, index) => {
                              photo.unique_id = uniqueId();
                              return (
                                photo.fileType.split('/')[0] === 'image' && (
                                  <>
                                    {handleSkeletonLoading(isImageLoaded, 'post')}
                                    <img
                                      key={photo.unique_id}
                                      onClick={() => toggleImageModal(index)}
                                      referrerPolicy="no-referrer"
                                      src={photo?.fileName}
                                      alt="Not available"
                                      loading="eager"
                                      style={{ opacity: isImageLoaded ? 1 : 0 }}
                                      onLoad={() => setIsImageLoaded(true)}
                                      className={s.image}
                                    />
                                    <ImageModal
                                      showModal={showImageModal}
                                      toggle={toggleImageModal}
                                      setShowImageModal={setShowImageModal}
                                      files={comment?.gemFiles}
                                      onSuccess={(e) => {
                                        toggleImageModal();
                                      }}
                                      selectedImageIndex={selectedImageIndex}
                                    />
                                  </>
                                )
                              );
                            })
                          ) : (
                            <div>
                              <Skeleton
                                variant="rect"
                                style={{ filter: 'invert(0)', minHeight: '150px' }}
                                width="10rem"
                                className={s.image}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={s.commentAction}>
                    {/* <span
                      className={cns(
                        'cursor-pointer mr-2 align-middle',
                        s.commentLikeBtn,
                        likedByUser(comment.commentLikes) ? s.active : null
                      )}
                      onClick={() =>
                        handleLike(comment.commentId, likedByUser(comment.commentLikes))
                      }
                    >
                      Like
                    </span> */}
                    <button
                      className={cns(
                        'mr-2 align-middle',
                        s.commentLikeBtn,
                        likedByUser(comment.commentLikes) ? s.active : null
                      )}
                      onClick={() =>
                        handleLike(comment.commentId, likedByUser(comment.commentLikes))
                      }
                      disabled={!isUserActive(users, userData?.email)}
                      style={{
                        cursor: isUserActive(users, userData?.email) ? 'pointer' : 'not-allowed'
                      }}
                    >
                      Like
                    </button>
                    {getLikesCount(comment.commentLikes) > 0 ? (
                      <div className={s.likeCounter}>
                        <img
                          alt="like icon"
                          width={15}
                          height={15}
                          src={likeIcon}
                          className="reaction-like"
                          style={{display:'inline'}}
                        />
                        <span className={s.noOfLikes}>
                          {getLikesCount(comment.commentLikes) > 1
                            ? getLikesCount(comment.commentLikes)
                            : ''}
                        </span>
                        <div className={s.dropdownContent}>
                          <CommentNames users={users} names={comment.commentLikes} post={post} />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {localStorage.getItem('email') === comment?.userId ? (
                      <span
                        className={cns('cursor-pointer ml-1', s.commentDeleteBtn)}
                        onClick={() => handleDelete(comment.commentId, idx)}
                      >
                        Delete
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        {comments.length > 1 ? <CommentsToggler /> : null}
      </div>
    </>
  );
};

export default PostCardComment;
