/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import cns from 'classnames';

import s from './details.module.scss';
import axios from 'axios';

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

import {
  deleteEvent,
  editEvent,
  getClubSubChannels,
  getEvents,
  getOrganisers,
  getPastEvents
} from '../../../../../../redux/actionCreators/event.actionCreator';
import { getClubs } from '../../../../../../redux/actionCreators/clubs.actionCreator';
import { getAllUsers } from '../../../../../../redux/actionCreators/user.actionCreator';
import {
  addVote,
  deletePoll,
  editVote,
  getPollResponse,
  getPollResponseByUser,
  getPollResponseDownload
} from '../../../../../../redux/actionCreators/polls.actionCreator';

import Polls from './polls';
import DisplayPoll from './displayPoll';
import { toast } from 'react-toastify';
import { Skeleton } from '@material-ui/lab';
import { Tooltip } from '@material-ui/core';
import { DATE_FORMAT, TIME_FORMAT, getDateFromArray } from '../../../../../../helpers';
import _ from 'lodash';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import EventBtns from './eventBtns';
import locationIcon from '../../../../../../assets/icons/locationIcon.svg';
import AddEvent from './addEvent';

const Details = React.memo(
  ({
    event,
    isPastEvent = false,
    deleteEvent,
    events,
    pastEvents,
    editEvent,
    organisers,
    addVote,
    editVote,
    hrArr,
    activeTab,
    deletePoll,
    profileImg,
    ...props
  }) => {
    const today = new Date().toISOString().split('T')[0];
    const eventEndDate = getDateFromArray(event?.endDate, DATE_FORMAT.YYYY_MM_DD);
    const eventStartDate = getDateFromArray(event?.startDate, DATE_FORMAT.YYYY_MM_DD);
    const clubIdData = useSelector((state) => state?.clubsReducer?.clubsIdByClubName);

    const isCurrentEventActive = eventEndDate >= today;
    let currentDateYear = moment(new Date()).format('MMMM YYYY').toLocaleLowerCase();
    const [eventToggle, setEventToggle] = useState('');
    const [editedEvent, setEditedEvent] = useState({
      eventName: event?.eventName || '',
      eventDescription: event?.eventDescription || '',
      location: event?.location || '',
      startDate: eventStartDate || '',
      endDate: eventEndDate || '',
      startTime: getDateFromArray(event?.startDate, TIME_FORMAT.HH_MM) || '',
      endTime: getDateFromArray(event?.endDate, TIME_FORMAT.HH_MM) || '',
      winners: event?.winners || '',
      organizerName: event?.organizerName || '',
      organizerEmail: event?.organizerEmail || '',
      rewards: event?.rewards || '',
      tagEvent: event?.tagEvent || 'General',
      poll: event?.poll ? 'yes' : 'no',
      pollData: event?.poll
    });

    const pollWithEvent = event?.poll;

    useEffect(() => {
      event?.poll?.pollId &&
        props.getPollResponse(event?.poll?.pollId).then((res) => {
          pollWithEvent.scores = res.data;
        });
    }, [props?.polls]);

    const [organiserLogo, setOrganiserLogo] = useState(null);
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'September',
      'October',
      'November',
      'December'
    ];

    const handleDelete = () => {
      if (event?.eventType === 'past') {
        deleteEvent(event?.eventId);
        const pastEventsLeft = pastEvents.filter((e) => e.eventId !== event?.eventId);
        return pastEventsLeft;
      } else {
        deleteEvent(event?.eventId);
        const eventsLeft = events.filter((e) => e.eventId !== event?.eventId);
        return eventsLeft;
      }
    };

    const copyHandler = (e) => {
      const url = window.location.origin;
      const clubVal = clubIdData.find((club) => club.clubName === event?.organizerName)?.clubID;
      const val = url + '/#/clubs/' + clubVal + '/event/' + event?.eventId;
      navigator.clipboard.writeText(val);
      toast.success('Copied!');
    };

    useEffect(() => {
      if (props.clubs?.length) {
        props.clubs.forEach(async (club) => {
          if (club.club.name === event?.organizerName) {
            setOrganiserLogo(club.club.clubProfileUrl);
            return;
          }
        });
      } else if (profileImg) {
        setOrganiserLogo(profileImg);
      }
    }, [props.clubs.length, profileImg]);

    const handleDownload = (pollId) => {
      const info = toast.info('Downloading Results...');
      const apiUrl = `${process.env.REACT_APP_EVENTS_URL}/poll-response/downloadExcel?pollId=${pollId}`;

      axios({
        url: apiUrl,
        method: 'GET',
        responseType: 'blob'
      })
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const fileName = `Poll Results_${event?.eventName}.xlsx`;
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          toast.dismiss(info);
          toast.success('Poll Results downloaded successfully.');
        })
        .catch((error) => {
          console.error('Error downloading file:', error);
        });
    };

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
      setShowFullDescription(!showFullDescription);
    };

    const truncatedDescription = event?.eventDescription?.length
      ? event?.eventDescription?.slice(0, 100)
      : '';

    return (
      <div
        className={s.eventWrapper}
        style={{ boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)', marginBottom: '1.5rem' }}
      >
        <div className={s.eventTop}>
          {eventToggle === 'edit' ? (
            <div className={s.eventHeading}>Edit Event</div>
          ) : (
            <div className="flex-column">
              <div>
                <p className={s.eventDates}>
                  {eventStartDate === eventEndDate
                    ? getDateFromArray(event?.startDate, DATE_FORMAT.DATE)
                    : `${event?.startDate[2]} - ${event?.endDate[2]}`}
                </p>
                <p className={s.eventMonth}>{month[event?.endDate[1] - 1]}</p>
              </div>
              <div className="align-items-center">
                <AccessAlarmIcon style={{ fontSize: '12px', color: '#636972' }} />
                <p className={s.time}>
                  {getDateFromArray(event?.startDate, TIME_FORMAT.HH_MM_A)} -{' '}
                  {getDateFromArray(event?.endDate, TIME_FORMAT.HH_MM_A)} (IST)
                </p>
              </div>
              {event?.location && (
                <div className={s.location}>
                  <img src={locationIcon} alt="Location Icon" height={12} width={12} />
                  {event?.location}
                </div>
              )}
            </div>
          )}
          <div>
            {!event?.organizerName ? (
              <></>
            ) : organiserLogo && event?.organizerName ? (
              <img alt="organiser logo" className={s.organiserLogo} src={organiserLogo} />
            ) : (
              <Skeleton variant="circle" className={s.organiserLogo} />
            )}
          </div>

          <EventBtns
            title={{ edit: 'Edit Event', delete: 'Delete Event' }}
            handleEdit={() => {
              setEventToggle('edit');
            }}
            handleDelete={handleDelete}
            editCondition={eventToggle !== 'edit' && isCurrentEventActive}
            pollData={pollWithEvent}
            copyHandler={copyHandler}
            hrArr={hrArr}
            event={event}
          />
        </div>
        <div className={s.eventDetails}>
          {eventToggle === 'edit' ? (
            <AddEvent
              setAddEventToggle={setEventToggle}
              editEventData={event}
              // clubName={event?.organizerName}
            />
          ) : (
            <div className={s.detailWrapper}>
              <div className={s.details}>
                <div className={s.descWrapper}>
                  <p className={s.eventName}>
                    {event?.eventName?.includes(currentDateYear)
                      ? editedEvent?.eventName?.split(currentDateYear)[0] +
                        _.startCase(currentDateYear)
                      : editedEvent?.eventName}
                  </p>
                  <div className={s.description} style={{ whiteSpace: 'pre-line' }}>
                    {showFullDescription ? event?.eventDescription : truncatedDescription} &nbsp;
                    {event?.eventDescription?.length > 100 && (
                      <button onClick={toggleDescription} className={s.showToggle}>
                        {showFullDescription ? 'Show Less' : 'Show More'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {!isPastEvent && (
                <DisplayPoll
                  poll={pollWithEvent}
                  event={event}
                  users={props?.users}
                  addVote={addVote}
                  editVote={editVote}
                  setEventToggle={setEventToggle}
                  getPollResponseByUser={props.getPollResponseByUser}
                  deletePoll={deletePoll}
                  hrArr={hrArr}
                  handleDownload={handleDownload}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

function mapStateToProps(state) {
  return {
    events: state.eventReducer.events,
    pastEvents: state.eventReducer.pastEvents,
    organisers: state.eventReducer.organisers,
    polls: state.pollsReducer.polls,
    users: state.userReducer.users,
    clubs: state.clubsReducer.clubs,
    clubDetails: state.clubsReducer.clubDetails,
    subChannels: state.eventReducer.subChannels
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteEvent: deleteEvent,
      getEvents: getEvents,
      getPastEvents: getPastEvents,
      editEvent: editEvent,
      getOrganisers: getOrganisers,
      addVote: addVote,
      editVote: editVote,
      deletePoll: deletePoll,
      getAllUsers: getAllUsers,
      getClubs: getClubs,
      getPollResponse: getPollResponse,
      getPollResponseByUser: getPollResponseByUser,
      getClubSubChannels: getClubSubChannels
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
