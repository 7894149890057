import React from 'react';

// Component
import Skill from '../components/Skill';
import Certification from '../components/Certification';
import Achievement from '../components/Achievement';
import Training from '../components/Training';
import Project from '../components/Project';

// Stylesheet
import s from './leftBottom.module.scss';

//Helper
import Viewport from '../../../../helpers/viewport';
import Education from '../components/education';
import AboutMe from './AboutMe';
import { useMediaQuery } from 'react-responsive';

const LeftBottom = React.memo(() => {
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });

  return (
    <div className={isMobile ? s.mobileLeftBottom : s.desktopLeftBottom}>
      <div className={isMobile ? s.leftBottom : s.leftBottomInner}>
        <AboutMe />
        <Skill />
        <Education />
        <Certification />
        <Achievement />
        <Training />
        <Project />
      </div>
    </div>
  );
});

export default LeftBottom;
