import React from 'react';
import { Quill } from 'react-quill';
import Emoji from './emoji';
import s from './editor.module.scss';
import cns from 'classnames';
import { Tooltip } from '@material-ui/core';
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

let bindings = {
  custom: {
    key: 'backspace',
    collapsed: true,
    format: { color: 'green' },
    handler: function (range, context) {
      this.quill.deleteText(range.index - context.prefix.length, context.prefix.length);
    }
  },
  custom2: {
    key: 'backspace',
    collapsed: true,
    format: { color: '#00376b' },
    handler: function (range, context) {
      this.quill.deleteText(range.index - context.prefix.length, context.prefix.length);
    }
  },
  custom3: {
    key: 'backspace',
    collapsed: true,
    format: { color: '#2c6db3' },
    handler: function (range, context) {
      this.quill.deleteText(range.index - context.prefix.length, context.prefix.length);
    }
  }
};

// Modules object for setting up the Quill editor
export const modules = ({ id }) => ({
  toolbar: {
    container: `#${id}`,
    handlers: {
      undo: undoChange,
      redo: redoChange
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  },
  keyboard: {
    bindings: bindings
  }
});

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'color',
  'code-block'
];

// Quill Toolbar component
export const EditorToolbar = ({ id, onEmojiClick, handleUpload }) => (
  <div id={id} className={cns(s.toolbar, 'border-0')}>
    <span className={cns(s.tools, 'ql-formats w-100 ', 'd-flex align-items-baseline')}>
      <Tooltip title="Bold">
        <button className="ql-bold" />
      </Tooltip>
      <Tooltip title="Italic">
        <button className="ql-italic" />
      </Tooltip>
      <Tooltip title="Underline">
        <button className="ql-underline" />
      </Tooltip>
      <Tooltip title="Strikethrough">
        <button className="ql-strike" />
      </Tooltip>
      <Tooltip title="Ordered List">
        <button className="ql-list" value="ordered" />
      </Tooltip>
      <Tooltip title="Bulleted List">
        <button className="ql-list" value="bullet" />
      </Tooltip>
      <Tooltip title="Alignment" disableFocusListener>
        <span>
          <select className="ql-align" />
        </span>
      </Tooltip>
      <Tooltip title="Font Color">
        <span>
          <select className="ql-color" />
        </span>
      </Tooltip>
      <Tooltip title="Emoji">
        <span>
          <Emoji onEmojiClick={onEmojiClick} />
        </span>
      </Tooltip>
      <Tooltip title="Upload Media">
        <label className={s.btnCustom}>
          <input
            multiple
            type="file"
            name="uploaderInput"
            accept="image/png, image/jpeg, image/gif, image/avif, image/jpeg, image/pjpeg"
            className={s.uploaderInput}
            onChange={handleUpload}
          />
          <span className="px-3 d-flex align-items-center">
            <img
              referrerPolicy="no-referrer"
              alt="img-upload"
              src={process.env.PUBLIC_URL + '/assets/icons/photo.svg'}
              className="mr-2"
              height={20}
              width={20}
            />
          </span>
        </label>
      </Tooltip>
    </span>
  </div>
);
