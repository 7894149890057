import React, { useCallback } from 'react';
import cns from 'classnames';

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Scss
import s from '../postContainer.module.scss';

// Icons
import likeIcon from '../../../../../../assets/icons/like.png';
import commentIcon from '../../../../../../assets/icons/comment.svg';
import likePostIcon from '../../../../../../assets/icons/likePost.svg';

// ************************************
// HELPERS
import LikeNames from './likeNames';
import { useState } from 'react';
import { reactions } from '../../../../../../helpers/iconsHelper';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { isUserActive } from '../../../../../../helpers';

// ************************************
const preprocessLikes = (reactions) => {
  let likeCount = 0;
  let reactFlag = false;
  const names = [];
  const loveNames = [];
  const likeNames = [];
  const happyNames = [];
  const sadNames = [];
  const celebrateNames = [];
  const supportNames = [];
  const surpriseNames = [];
  reactions &&
    reactions.forEach((reaction) => {
      if (reaction.reactFlag === 1) {
        if (reaction.postReactIdentity?.email === localStorage.getItem('email')) {
          reactFlag = true;
        } else {
          likeCount++;
        }
        names.push(reaction.postReactIdentity);
        const reactionTypes = {
          LIKE: likeNames,
          LOVE: loveNames,
          HAPPY: happyNames,
          SAD: sadNames,
          SUPPORT: supportNames,
          SURPRISE: surpriseNames,
          CELEBRATE: celebrateNames
        };

        if (reaction.reactionType in reactionTypes) {
          reactionTypes[reaction.reactionType].push(reaction.postReactIdentity);
        }
      }
    });
  return {
    likeCount,
    reactFlag,
    names,
    loveNames,
    celebrateNames,
    likeNames,
    happyNames,
    supportNames,
    sadNames,
    surpriseNames
  };
};
const PostCardLike = ({
  likes,
  postId,
  likePost,
  toggleExpand,
  comments,
  users,
  userReaction,
  userData
}) => {
  const {
    reactFlag,
    likeCount,
    names,
    loveNames,
    likeNames,
    happyNames,
    sadNames,
    supportNames,
    celebrateNames,
    surpriseNames
  } = preprocessLikes(likes);
  const postLikeCount = likeCount;
  const [postLikeFlag, setpostLikeFlag] = useState(reactFlag);
  const [showReactions, setShowReactions] = useState(false);
  const [activeReaction, setActiveReaction] = useState(null);

  const REACTIONS = ['Like', 'Love', 'Celebrate', 'Sad', 'Happy', 'Support', 'Surprise'];

  // HANDLERS
  const handleReact = () => {
    if (isUserActive(users, userData?.email)) {
      setShowReactions(!showReactions);
      if (postLikeFlag) {
        setShowReactions(!showReactions);
      }
    }
  };

  const handlePostLike = useCallback(
    (event, reactionType) => {
      event.stopPropagation();
      setActiveReaction(reactionType.toUpperCase());

      if (!postLikeFlag) {
        setpostLikeFlag(reactFlag ^ 1);
      }
      const data = {
        reactFlag: 1,
        reactionType: reactionType.toUpperCase(),
        postId: postId
      };

      likePost(data);
    },
    [reactFlag, postLikeFlag]
  );

  const handleClick = (event) => {
    const userReaction = likes.find(
      (reaction) => reaction.postReactIdentity?.email === localStorage.getItem('email')
    );

    setpostLikeFlag(reactFlag ^ 1);
    let data = {
      reactFlag: userReaction ? userReaction.reactFlag ^ 1 : reactFlag ^ 1,
      reactionType: 'LIKE',
      postId: postId
    };
    if (userReaction?.reactFlag === 0) {
      setActiveReaction('LIKE');
      data = {
        reactFlag: 1,
        reactionType: 'LIKE',
        postId: postId
      };
    } else {
      setActiveReaction(null);
    }
    likePost(data);
  };

  const reactionIcon = () => {
    const reactionCounts = {};
    likes.forEach((reaction) => {
      if (reaction.reactFlag === 1) {
        const reactionType = reaction.reactionType;

        reactionCounts[reactionType] = (reactionCounts[reactionType] || 0) + 1;
      }
    });

    const uniqueReactionTypes = Object.keys(reactionCounts);

    if (uniqueReactionTypes.length === 1) {
      // For 1 reaction type icon
      const reactionType = activeReaction ?? uniqueReactionTypes[0];
      return (
        <img
          src={reactions[reactionType.toUpperCase()]}
          alt={`${reactionType}-icon`}
          height={20}
          width={20}
        />
      );
    } else if (uniqueReactionTypes.length === 2 || uniqueReactionTypes.length === 3) {
      // For 2 or 3 reactionType icon
      return (
        <>
          {uniqueReactionTypes.map((reactionType) => (
            <img
              key={reactionType}
              src={reactions[reactionType.toUpperCase()]}
              alt={`${reactionType}-icon`}
              width={20}
              height={20}
              className={s.overlapIcon}
            />
          ))}
        </>
      );
    } else {
      // For 3 or more reactionType icon
      const lastReactionTypes = uniqueReactionTypes.slice(-3);
      return (
        <>
          {lastReactionTypes.map((reactionType) => (
            <img
              key={reactionType}
              src={reactions[reactionType.toUpperCase()]}
              alt={`${reactionType}-icon`}
              height={20}
              width={20}
              className={s.overlapIcon}
            />
          ))}
        </>
      );
    }
  };

  const userReacIcon = () => {
    if (postLikeFlag) {
      const userReaction = likes.find(
        (reaction) => reaction.postReactIdentity?.email === localStorage.getItem('email')
      );

      if (userReaction || activeReaction) {
        const reactionType = activeReaction ?? userReaction.reactionType;
        return (
          <>
            <img
              src={reactions[activeReaction] ?? reactions[reactionType.toUpperCase()]}
              alt={`reaction-icon`}
              height={20}
              width={20}
            />
            <span className={`${s.reacLabel} ${s[reactionType]}`}>
              {_.capitalize(reactionType)}
            </span>
          </>
        );
      }
    }
  };
  return (
    <>
      <div
        className={s.likeCommentWrapper}
        style={{ height: postLikeCount + postLikeFlag > 0 || comments?.length ? '30px' : '' }}
      >
        {postLikeCount + postLikeFlag > 0 ? (
          <div className={s.likeCount}>
            {likes.length ? reactionIcon() : ''}
            <span className={s.likeCountText}>
              {postLikeCount + postLikeFlag > 0 ? postLikeCount + postLikeFlag : ''}
            </span>
            <div className={s.dropdownContent}>
              <LikeNames
                names={names}
                loveNames={loveNames}
                likeNames={likeNames}
                happyNames={happyNames}
                sadNames={sadNames}
                supportNames={supportNames}
                celebrateNames={celebrateNames}
                surpriseNames={surpriseNames}
                users={users}
                userReaction={likes}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {comments.length > 0 ? (
          <div onClick={() => toggleExpand()} className={s.commentCount}>
            {comments.length} comment{comments.length > 1 ? 's' : ''}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={s.footerBtns}>
        <button
          className={cns(s.postLikeBtn, postLikeFlag ? s.active : '')}
          onMouseEnter={handleReact}
          onMouseLeave={handleReact}
          onClick={handleClick}
          disabled={!isUserActive(users, userData?.email)}
          style={{ cursor: isUserActive(users, userData?.email) ? 'pointer' : 'not-allowed' }}
        >
          <div className="d-flex align-items-center" style={{ zIndex: 1 }}>
            {postLikeFlag ? (
              userReacIcon()
            ) : (
              <img
                src={activeReaction ? reactions[activeReaction] : likePostIcon}
                alt="like-icon"
                width={15}
                height={15}
                className={(cns(s.postLikeIcon, postLikeFlag ? s.active : ''), 'mr-2')}
              />
            )}
            {postLikeFlag ? '' : 'Like'}
            {showReactions ? (
              <div className={s.reactionContainer}>
                {REACTIONS.map((reaction, index) => {
                  let vars = reaction.toUpperCase();
                  return (
                    <div
                      role="button"
                      key={index}
                      className={s.reactionButton}
                      onClick={(e) => handlePostLike(e, reaction)}
                    >
                      <Tooltip title={reaction}>
                        <img
                          src={reactions[vars]}
                          alt={`reaction-${index}`}
                          width={20}
                          height={20}
                          style={{
                            transform: reaction === 'Celebrate' ? 'scale(1.15)' : 'none',
                            marginBottom: reaction === 'Celebrate' ? '2px' : ''
                          }}
                          className={s.reacIcon}
                        />
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </button>
        <button className={s.postCommentBtn} onClick={() => toggleExpand()}>
          <img src={commentIcon} alt="comment-icon" width={15} height={15} className="mr-2" />
          Comment
        </button>
      </div>
    </>
  );
};

export default PostCardLike;
