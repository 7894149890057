import React, { useState } from 'react';
import { TextField, MenuItem, Grid, Checkbox, ListItemText, Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import { getCouncilList } from '../../redux/actionCreators/hrdashboard.actionCreator';
import {
  getDownloadReport,
  getDownloadReportForAwards
} from '../../redux/actionCreators/hrdashboard.actionCreator';
import './hrDashboard.module.scss';
import { bindActionCreators } from 'redux';

const HRDashboardFile = ({ ...props }) => {
  const [state, setState] = useState({
    councilType: '',
    councils: [],
    startDate: null,
    endDate: null,
    reportType: ''
  });

  const handleCouncilTypeChange = (e) => {
    const selectedCouncilType = e.target.value;
    props.getCouncilList(selectedCouncilType);  // Fetch councils basis of selected type
    setState({ 
      ...state, 
      councilType: selectedCouncilType, // Update councilType
      councils: [] // Clear previously selected councils
    });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  const handleCouncilChange = (e) => {
    setState({ ...state, councils: e.target.value });
  };

  const handleStartDateChange = (date) => {
    setState((prev) => ({ ...prev, startDate: date }));
  };

  const handleEndDateChange = (date) => {
    setState((prev) => ({ ...prev, endDate: date }));
  };

  const handleReportTypeChange = (e) => {
    setState({ ...state, reportType: e.target.value });
  };

  const handleDownload = () => {
    const { councilType, councils, startDate, endDate, reportType } = state;

    const councilIds = councils
      .map((councilName) => {
        const councilId = Object.keys(props.councilList).find(
          (key) => props.councilList[key] === councilName
        );
        return councilId;
      })
      .filter(Boolean);

    const formattedStartDate = startDate ? new Date(startDate).toISOString().split('T')[0] : null;
    const formattedEndDate = endDate ? new Date(endDate).toISOString().split('T')[0] : null;

    const adjustedReportType = reportType ? reportType.toUpperCase() : null;

    if ((councilType || !councilType) && adjustedReportType && startDate && endDate) {
      if (adjustedReportType === 'AWARDS') {
        props.getDownloadReportForAwards(
          councilType,
          councilIds,
          formattedStartDate,
          formattedEndDate,
          adjustedReportType
        );
      } else {
        props.getDownloadReport(
          councilType,
          councilIds,
          formattedStartDate,
          formattedEndDate,
          adjustedReportType
        );
      }
    } else {
      alert('Please fill all fields before downloading the report');
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="dashboard-container" style={{ padding: '30px' }}>
        {/* Card Layout */}
        <Card elevation={5} style={{ padding: '10px' }}>
          <CardHeader title="HR Dashboard" style={{ textAlign: 'center' }} />
          <CardContent>
            <Grid container spacing={4} alignItems="center">
              {/* Council Type Dropdown */}
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  label="Council Type"
                  value={state.councilType}
                  onChange={handleCouncilTypeChange}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: { padding: '15px 10px' }
                  }}
                >
                  <MenuItem value="EC">EC</MenuItem>
                  <MenuItem value="DC">DC</MenuItem>
                </TextField>
              </Grid>

              {/* Select Councils Dropdown with Checkboxes */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Select Councils"
                  select
                  open={dropdownOpen}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  value={state.councils}
                  onChange={handleCouncilChange}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: { padding: '15px 10px' }
                  }}
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) => selected.join(', '),
                    MenuProps: {
                      PaperProps: {
                        onMouseLeave: handleCloseDropdown
                      }
                    }
                  }}
                >
                  {Object.entries(props?.councilList).map(([key, name]) => (
                    <MenuItem key={key} value={name}>
                      <Checkbox checked={state.councils.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Start Date Picker */}
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  label="Start Date"
                  variant="inline"
                  format="yyyy-MM-dd"
                  value={state.startDate}
                  onChange={handleStartDateChange}
                  fullWidth
                  inputVariant="outlined"
                  InputProps={{
                    style: { padding: '15px 10px' }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Grid>

              {/* End Date Picker */}
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  label="End Date"
                  variant="inline"
                  format="yyyy-MM-dd"
                  value={state.endDate}
                  onChange={handleEndDateChange}
                  fullWidth
                  inputVariant="outlined"
                  InputProps={{
                    style: { padding: '15px 10px' }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Grid>

              {/* Report Type Dropdown */}
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  label="Report Type"
                  value={state.reportType}
                  onChange={handleReportTypeChange}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: { padding: '15px 10px' }
                  }}
                >
                  <MenuItem value="AWARDS">AWARDS</MenuItem>
                  <MenuItem value="CERTIFICATE">CERTIFICATE</MenuItem>
                  <MenuItem value="ANNIVERSARY">ANNIVERSARY</MenuItem>
                </TextField>
              </Grid>

              {/* Download Report Button */}
              <Grid item xs={12} sm={3}>
                <Button variant="contained" color="primary" onClick={handleDownload} fullWidth>
                  Download Report
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MuiPickersUtilsProvider>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.hrDashboardReducer.loading,
    error: state.hrDashboardReducer.error,
    success: state.hrDashboardReducer.success,
    councilList: state.hrDashboardReducer.councilList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCouncilList: getCouncilList,
      getDownloadReport: getDownloadReport,
      getDownloadReportForAwards: getDownloadReportForAwards
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HRDashboardFile);
