import {
  GET_ALL_POLLS_LOADING,
  GET_ALL_POLLS_SUCCESS,
  GET_ALL_POLLS_FAILURE,
  ADD_VOTES_LOADING,
  ADD_VOTES_SUCCESS,
  ADD_VOTES_FAILURE,
  DELETE_POLL_LOADING,
  DELETE_POLL_SUCCESS,
  DELETE_POLL_FAILURE,
  EDIT_POLL_LOADING,
  EDIT_POLL_SUCCESS,
  EDIT_POLL_FAILURE,
  GET_RESPONSE_LOADING,
  GET_RESPONSE_SUCCESS,
  GET_RESPONSE_FAILURE,
  GET_POLL_BY_ID_LOADING,
  GET_POLL_BY_ID_SUCCESS,
  GET_POLL_BY_ID_FAILURE
} from '../actions/actions';

const initialState = {
  polls: [],
  loading: false,
  error: '',
  fetchAgain: false,
  pollResponse: {},
  poll: {}
};

const pollsReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_ALL_POLLS_LOADING:
      newState.loading = true;
      break;
    case GET_ALL_POLLS_SUCCESS:
      newState.polls = action.payload.polls;
      newState.error = '';
      newState.loading = false;
      break;
    case GET_ALL_POLLS_FAILURE:
      newState.error = action.payload.error;
      newState.loading = false;
      break;
    case GET_POLL_BY_ID_LOADING:
      newState.loading = true;
      break;
    case GET_POLL_BY_ID_SUCCESS:
      newState.poll = action.payload.poll;
      newState.error = '';
      newState.loading = false;
      break;
    case GET_POLL_BY_ID_FAILURE:
      newState.error = action.payload.error;
      newState.loading = false;
      break;
    case GET_RESPONSE_LOADING:
      newState.loading = true;
      break;
    case GET_RESPONSE_SUCCESS:
      newState.pollResponse = action.payload.pollResponse;
      newState.error = '';
      newState.loading = false;
      break;
    case GET_RESPONSE_FAILURE:
      newState.error = action.payload.error;
      newState.loading = false;
      break;

    case ADD_VOTES_LOADING:
      newState.loading = true;
      break;
    case ADD_VOTES_SUCCESS:
      newState.fetchAgain = true;
      break;
    case ADD_VOTES_FAILURE:
      newState.error = action.payload.error;
      newState.loading = false;
      break;
    case EDIT_POLL_LOADING:
      newState.loading = true;
      break;
    case EDIT_POLL_SUCCESS:
      newState.fetchAgain = true;
      break;
    case EDIT_POLL_FAILURE:
      newState.error = action.payload.error;
      newState.loading = false;
      break;
    case DELETE_POLL_LOADING:
      newState.loading = true;
      break;
    case DELETE_POLL_SUCCESS:
      newState.fetchAgain = true;
      break;
    case DELETE_POLL_FAILURE:
      newState.error = action.payload.error;
      newState.loading = false;
      break;
  }
  return newState;
};

export default pollsReducer;
