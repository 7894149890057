import React from 'react';

import FilterIcon from '../../../../../../assets/icons/FilterIcon.svg';
import s from '../postContainer.module.scss';
import cns from 'classnames';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';

const PostTypeList = ({
  wrapper,
  setToggleOptions,
  toggleOptions,
  postTypes,
  postTypeId,
  setPostType,
  postType,
  getPosts,
  getFilteredPosts,
  clubId
}) => {
  const [name, setName] = useState('All Posts');

  const allPost = {
    postType: 'All Posts',
    postTypeId: 0
  };

  useEffect(() => {
    if (postTypeId === -1) {
      setName('All Posts');
    }
  }, [postTypeId]);

  const handleChangePostType = useCallback(
    (postType) => {
      setName(postType.postType);
      let value = postType.postTypeId;
      setPostType(value);
      let data = {
        pageNum: 0,
        postType: value
      };
      if (clubId) data.clubId = clubId;
      let location = window.location.href;
      if (location.includes('clubs')) getPosts(data);
      else {
        getFilteredPosts({
          ...data,
          postsFilterType: data.postType == 0 ? 'GENERAL_POSTS' : 'POST_TYPE_SPECIFIC'
        });
      }
      window.scrollTo({ top: 0 });
      setToggleOptions(false);
    },
    [postType]
  );

  return (
    <div ref={wrapper} className={cns(s.dropDown, s.filterDiv, toggleOptions ? s.active : '')}>
      <button className={s.filterButton} onClick={() => setToggleOptions(!toggleOptions)}>
        <img className={s.img} src={FilterIcon} alt="filter"></img>
        {name}
      </button>
      <ul className={s.listHead}>
        {postTypes.map((postType, idx) => {
          return (
            <li className={s.listItem} onClick={() => handleChangePostType(postType)} key={idx}>
              <img src={postType.iconFile} alt="post type" className={s.icons}></img>
              {postType.postType}
            </li>
          );
        })}
        <li className={s.listItem} onClick={() => handleChangePostType(allPost)} key={-1}>
          All{' '}
        </li>
      </ul>
    </div>
  );
};

export default PostTypeList;
