import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'classnames';
import s from '../../dashboard.module.scss';
import Sidebar from '../../../../layouts/layout1/sidebar/index';
import Navbar from '../../../../layouts/layout1/navbar/index';
import Footer from '../../../../layouts/layout1/footer/index';
import ProfileId from './profileShare';
import ProfileLoader from './helpers/profileLoader';
import { getProfileData } from '../../../../redux/actionCreators/profile.actionCreator';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import SearchedPeople from '../home/searched-people';
import Posts from '../home/postContainer/posts';
import postClass from './../home/postContainer/postContainer.module.scss';
import peopleClass from './../home/home.module.scss';
import useCheckJwtExpiration from '../../../../hooks/useCheckJwtExpiration';

function ProfileShare(props) {
  const [inputChangeData, setInputChangeData] = useState({
    searchValue: '',
    isPost: false
  });
  const [inputVal, setInputVal] = useState('');
  const [load, setLoad] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [editOnePost, setEditOnePost] = useState(false);
  const [currentEditedPost, setCurrentEditedPost] = useState(0);

  const onChange = (value, isPost) => {
    setInputChangeData({ searchValue: value, isPost: isPost });
  };

  const debouncedOnChange = useCallback(_.debounce(onChange, 500), []);

  useEffect(() => {
    props.getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const useJwtExpiration = useCheckJwtExpiration();
  return load ? (
    <ProfileLoader />
  ) : (
    <div className={clsx(s.dashboard, !toggle ? s.active : null)}>
      <section className={s.profileSection}>
        <Navbar
          setToggle={setToggle}
          toggle={toggle}
          profileDetails={props.details}
          onChange={debouncedOnChange}
          inputVal={inputVal}
          setInputVal={setInputVal}
        />
        <div className={s.profileWrapper}>
          <Sidebar toggle={toggle} setToggle={setToggle} onChange={debouncedOnChange} />
          {inputChangeData.searchValue.length ? (
            <div className={peopleClass.home}>
              {!inputChangeData.isPost ? (
                <div className={peopleClass.containerWrapper}>
                  <SearchedPeople searchValue={inputChangeData.searchValue} />
                </div>
              ) : (
                <div className={peopleClass.containerWrapper}>
                  <div className={postClass.postContainer}>
                    <Posts
                      searchValue={inputChangeData.searchValue}
                      editOnePost={editOnePost}
                      setEditOnePost={(val) => setEditOnePost(val)}
                      currentEditedPost={currentEditedPost}
                      setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <ProfileId
              setInput={setInputVal}
              setInputVal={setInputChangeData}
              toggle={toggle}
              edit={false}
              data={props.match.params.id}
            />
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    profileDetails: state.profileShareReducer.details,
    details: state.profileReducer.details
  };
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProfileData: getProfileData
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileShare);
