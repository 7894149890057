import { sanitizeObj } from '../../helpers';

// storageReducerTypes
export const UPDATE_STORAGE_ALL = 'update_storage_all';
export const UPDATE_STORAGE_USER = 'update_storage_user';

// storageReducerActions
export const updateStorageUser = (details) => {
  return {
    type: UPDATE_STORAGE_USER,
    payload: sanitizeObj(details)
  };
};

const initialState = {
  user: {
    email: localStorage.getItem('email'),
    id: localStorage.getItem('id'),
    imageURL: localStorage.getItem('imageURL')
  }
};

const storageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_STORAGE_ALL:
      return { ...state, ...payload };
    case UPDATE_STORAGE_USER:
      return { ...state, user: { ...state.user, ...payload } };
    default:
      return state;
  }
};

export default storageReducer;
