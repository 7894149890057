import axios from 'axios';
import {
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_SUCCESS
} from '../actions/actions';

export const getNotifications = () => {
  return (dispatch) => {
    dispatch({
      type: GET_NOTIFICATIONS_LOADING
    });
    axios
      .get(`${process.env.REACT_APP_NOTIFICATIONS_URL}/${localStorage.getItem('email')}`, {
        headers: {
          accept: '*/*'
        }
      })
      .then((response) => {
        const notifications = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_NOTIFICATIONS_SUCCESS,
            payload: {
              notifications: [...notifications]
            }
          });
        }
        dispatch({
          type: GET_NOTIFICATIONS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_NOTIFICATIONS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const updateNotifications = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_NOTIFICATIONS_LOADING
    });
    return axios.patch(`${process.env.REACT_APP_NOTIFICATIONS_URL}/update-status/${id}`, {
      headers: {
        accept: '*/*'
      }
    });
  };
};
