import React, { useState } from 'react';
import s from '.././sidebar.module.scss';

const SearchEmployees = (props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [rows, setRows] = useState();
  const requestSearch = (e) => {
    const searchedVal = e.target.value;
    setSearchQuery(searchedVal);
    const filteredRows = Object.values(props?.users)
      .filter(
        (row) => row.isActive && row?.name?.toLowerCase().startsWith(searchedVal?.toLowerCase())
      )
      .map((row) => ({
        name: row.name,
        empId: row.empCode,
        photoUrl: row.photoUrl,
        email: row.email
      }));
    setRows(filteredRows);
  };
  const winnerName = (name, email) => {
    props.setWinnerName(name, email);
    setRows([]);
    setSearchQuery(name);
  };
  return (
    <div>
      <input
        autofocus
        placeholder="Enter Winner's Name"
        value={searchQuery}
        onChange={requestSearch}
        className={s.searchQuery}
      />

      <div>
        {searchQuery ? (
          <div className={s.employeeName}>
            {rows.map((user, id) => {
              return (
                <div
                  className={s.employee}
                  key={id}
                  onClick={() => winnerName(user?.name, user.email)}
                >
                  <img src={user.photoUrl} height={25} width={25} className={s.awardImg} />
                  {user?.name + ' [' + user?.empId + ']'}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default SearchEmployees;
