import React from 'react';
import { Link } from 'react-router-dom';

// Images and Icons
import logoImg from '../../../assets/img/GembookIcon.png';

// SCSS
import s from './logo.module.scss';

const onClick = () => {
  const x = window.location.hash;
  if (x === '#/dashboard') {
    window.location.reload();
  }
};
const Logo = () => (
  <div className={s.header}>
    <Link to="/dashboard" onClick={onClick}>
      <span className={s.headerText}>
        <img alt="Gembook Logo" src={logoImg} className="gembook-logo"></img>
        <span className={s.headerTextHelper}>Gembook</span>
      </span>
    </Link>
  </div>
);

export default Logo;
