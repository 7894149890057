import React, { useState, useEffect } from 'react';
import Viewport from '../../../../helpers/viewport';
import CardComponent from '../cardComponent';
import { uniqueId } from 'lodash';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

//scss
import s from './../clubs.module.scss';
import { useMediaQuery } from 'react-responsive';

const AllClubsComponent = React.memo(({ data }) => {
  const [clubsData, setClubsData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setClubsData(data);
  }, [data]);

  const history = useHistory();

  const handleClick = (clubName, toastId) => {
    history.push({
      pathname: `/clubs/${clubName}`,
      state: { toastId: toastId, cid: clubName }
    });
  };

  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      <div className={s.class}>
        {loader ? (
          <div className={s.clubsLoader}>
            Loading clubs...
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div className={isMobile ? s.boxMobile : s.box}>
            {clubsData.map((item) => {
              item.unique_id = uniqueId();
              return <CardComponent item={item} key={item.unique_id} handleClick={handleClick} />;
            })}
          </div>
        )}
      </div>
    </>
  );
});

export default AllClubsComponent;
