import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// ActionCreators
import {
  getPosts,
  deletePost,
  updatePostById,
  getSearchedPosts,
  getFilteredPosts
} from '../../../../../../redux/actionCreators/post.actionCreator';
import { saveLikeForPost } from '../../../../../../redux/actionCreators/postLike.actionCreator';
import {
  deleteComment,
  addComment,
  likeComment
  // unLikeComment,
} from '../../../../../../redux/actionCreators/comment.actionCreator';
import { getEmpIdByEmail } from '../../../../../../redux/actionCreators/profile.actionCreator';

// Components
import PostCard from './postCard';
import PostLoader from './postLoader';
import SearchedPostsList from './searchedPostList';
import LoaderAnimatedIcon from '../../../../../../assets/icons/loader-ellipse-animated.svg';
import refreshIcon from '../../../../../../assets/icons/refreshIcon.svg';
import noResults from '../../../../../../assets/img/clubsNoPost.svg';

// Scss
import s from '../postContainer.module.scss';
import { getAllHashtags } from '../../../../../../redux/actionCreators/hashtag.actionCreator';
import { withRouter } from 'react-router-dom';
import { getClubDetails } from '../../../../../../redux/actionCreators/clubs.actionCreator';
import Media from './MediaSkeleton';

class Posts extends Component {
  constructor(props) {
    super(props);
    this.intervalRef = React.createRef();
  }
  state = {
    width: window.innerWidth,
    search: this.props.searchValue,
    willRefresh: false,
    cid: this.props?.clubData?.id ?? null,
    clubDetails: this.props?.clubData ?? null,
    editOnePost: false,
    currentEditedPost: 0
  };

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
    if (this.props.searchValue) {
      this.getSearchedPosts(this.props.searchValue);
    } else if (this.state.cid) {
      this.props.getPosts({ pageNum: 0, clubId: this.state.cid });
    } else {
      this.props.getFilteredPosts({ pageNum: 0, postsFilterType: 'GENERAL_POSTS' });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.search !== this.props.searchValue) {
      this.getSearchedPosts(this.props.searchValue);
      this.setState({ search: this.props.searchValue });
    }

    if (this.props.searchedPosts.length) {
      let filtereedPosts = this.filterPostsContainingTags(
        this.props.searchedPosts,
        this.props.searchValue
      );
      this.props.setTagPosts && this.props.setTagPosts(filtereedPosts);
    }
  }
  filterPostsContainingTags = (data, tag) => {
    let postsWithTags = [];
    postsWithTags = data?.filter((post) => post.postContent.includes(tag));
    return postsWithTags;
  };

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    if (!this.props.searchValue) {
      const wrappedElement = document.getElementById('postContainer');
      if (
        !this.props.loading &&
        this.props.posts.length &&
        this.isBottom(wrappedElement) &&
        this.props.postTypeId === -1
      ) {
        this.getPosts();
      }
    }
  };

  getPosts = () => {
    const data = {
      pageNum: this.props.pageNum + 1
    };
    if (this.props.name && this.props.name !== -1) data.postType = this.props.name;
    if (this.props.tagType && this.props.tagType !== 0) data.tagType = this.props.tagType;
    if (this.state.cid && !this.props.tagType) {
      data.clubId = this.state.cid;
    }
    let location = window.location.href;
    if (location.includes('clubs')) this.props.getPosts(data);
    else {
      this.props.getFilteredPosts({
        ...data,
        postsFilterType: !this.props.name ? 'GENERAL_POSTS' : 'POST_TYPE_SPECIFIC'
      });
    }
  };

  getSearchedPosts = (value) => {
    this.props.getSearchedPosts(value);
  };

  isBottom(el) {
    let pageCoveredLength = (100 * window.innerHeight) / 15; // get next page of posts after user scrolls 15% of page
    return el.getBoundingClientRect().bottom <= pageCoveredLength;
  }

  render() {
    return (
      <div className={s.posts}>
        {this.props.searchValue ? (
          !this.props.searchedPostsLoader && this.props.searchedPosts.length ? (
            <div className={s.searchBox}>
              <span className={s.searchHeading}>Top Results</span>
              <SearchedPostsList
                posts={
                  this.props.searchValue.includes('#')
                    ? this.filterPostsContainingTags(
                        this.props?.searchedPosts,
                        this.props.searchValue
                      )
                    : this.props.searchedPosts
                }
                clubDetails={this.state.clubDetails}
                likePost={this.props.saveLikeForPost}
                deleteComment={this.props.deleteComment}
                addComment={this.props.addComment}
                addLoading={this.props.addCommentLoading}
                deletePost={this.props.deletePost}
                likeComment={this.props.likeComment}
                updatePostById={this.props.updatePostById}
                getEmpIdByEmail={this.props.getEmpIdByEmail}
                postTypes={this.props.postTypes}
                hashtags={this.props.hashtags}
                currentEditedPost={this.props?.currentEditedPost ?? this.state?.currentEditedPost}
                setCurrentEditedPost={(val) => {
                  if (typeof this.props.setCurrentEditedPost === 'function') {
                    this.props.setCurrentEditedPost(val);
                  } else {
                    this.setState({ currentEditedPost: val });
                  }
                }}
                setEditOnePost={(val) => {
                  if (typeof this.props.setEditOnePost === 'function') {
                    this.props.setEditOnePost(val);
                  } else {
                    this.setState({ editOnePost: val });
                  }
                }}
              />
            </div>
          ) : (
            <div className="text-center">
              {this.props.searchedPostsLoader ? (
                <img src={LoaderAnimatedIcon} alt="loader" />
              ) : null}
            </div>
          )
        ) : (
          this.props.posts.map((post) => {
            return (
              <PostCard
                key={post.postId}
                clubId={post.clubId}
                postTagId={post.tagId}
                post={post}
                clubDetails={this.state.clubDetails}
                likePost={this.props.saveLikeForPost}
                deleteComment={this.props.deleteComment}
                addComment={this.props.addComment}
                addLoading={this.props.addCommentLoading}
                deletePost={this.props.deletePost}
                likeComment={this.props.likeComment}
                updatePostById={this.props.updatePostById}
                getEmpIdByEmail={this.props.getEmpIdByEmail}
                postTypes={this.props.postTypes}
                hashtags={this.props.hashtags}
                currentEditedPost={this.props.currentEditedPost ?? this.state.currentEditedPost}
                setCurrentEditedPost={(val) => {
                  if (typeof this.props.setCurrentEditedPost === 'function') {
                    this.props.setCurrentEditedPost(val);
                  } else {
                    this.setState({ currentEditedPost: val });
                  }
                }}
                setEditOnePost={(val) => {
                  if (typeof this.props.setEditOnePost === 'function') {
                    this.props.setEditOnePost(val);
                  } else {
                    this.setState({ editOnePost: val });
                  }
                }}
                users={this.props.users}
              />
            );
          })
        )}
        {this.props.loading && !this.props.searchValue && !this.props.error.length ? (
          <>
            {['', ''].map((item, ind) => (
              <Media key={ind} loading />
            ))}
          </>
        ) : (
          <div>
            {(!this.props.posts.length && !this.props.loading) ||
            (this.props.searchValue &&
              !this.props.searchedPostsLoader &&
              !this.props.searchedPosts.length) ? (
              <div className={s.postError}>
                <img src={noResults} alt="no results" />
                <strong className={s.postErrorMessage}>Nothing to see for now.</strong>
                <span>
                  {this.state.cid
                    ? 'Articles, posts, images, videos, or events that the Admin adds will appear here.'
                    : "We couldn't find any matches for your search."}
                </span>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    posts: state.postReducer.posts,
    postTypeId: state.postReducer.postTypeId,
    loading: state.postReducer.loading,
    error: state.postReducer.error,
    pageNum: state.postReducer.pageNum,
    addCommentLoading: state.commentReducer.addLoading,
    searchedPosts: state.searchedPostReducer.posts,
    searchedPostsLoader: state.searchedPostReducer.loading,
    notFound: state.searchedPostReducer.error,
    postTypes: state.postTypesReducer.postTypes,
    hashtags: state.hashtagReducer.hashtags,
    clubs: state.clubsReducer.clubs,
    users: state.userReducer.users
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPosts: getPosts,
      getFilteredPosts: getFilteredPosts,
      getSearchedPosts: getSearchedPosts,
      saveLikeForPost: saveLikeForPost,
      deleteComment: deleteComment,
      addComment: addComment,
      deletePost: deletePost,
      likeComment: likeComment,
      updatePostById: updatePostById,
      getEmpIdByEmail: getEmpIdByEmail,
      getAllHashtags: getAllHashtags,
      getEmpIdByEmail: getEmpIdByEmail,
      getClubDetails: getClubDetails
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Posts));
