import axios from 'axios';
import { toast } from 'react-toastify';
import {
  ADD_MARKETPLACE_POST_LOADING,
  ADD_MARKETPLACE_POST_SUCCESS,
  ADD_MARKETPLACE_POST_FAILURE,
  ADD_CARPOOL_POST_LOADING,
  ADD_CARPOOL_POST_SUCCESS,
  ADD_CARPOOL_POST_FAILURE,
  GET_ALL_POST_LOADING,
  GET_ALL_POST_SUCCESS,
  GET_ALL_POST_FAILURE,
  GET_ALL_LOCALITIES_LOADING,
  GET_ALL_LOCALITIES_SUCCESS,
  GET_ALL_LOCALITIES_FAILURE,
  DELETE_MARKET_POST_LOADING,
  DELETE_MARKET_POST_FAILURE,
  SET_FINAL_DATA,
  REFRESH_MARKET_POST_BY_ID_SUCCESS,
  GET_TOTAL_PAGES_LOADING,
  GET_TOTAL_PAGES_SUCCESS,
  GET_TOTAL_PAGES_FAILURE,
  GET_INACTIVE_ACTIVE_POST_LOADING,
  GET_INACTIVE_ACTIVE_POST_SUCCESS,
  GET_INACTIVE_ACTIVE_POST_FAILURE,
  SET_POST,
  LOADING_FALSE,
  GET_FLATRENTPOST_LOADING,
  GET_FLATRENTPOST_FAILURE,
  GET_FLATRENTPOST_SUCCESS,
  GET_CARPOOLPOST_LOADING,
  GET_CARPOOLPOST_FAILURE,
  GET_CARPOOLPOST_SUCCESS,
  GET_ALL_DEPT_LOCALITIES_LOADING,
  GET_ALL_DEPT_LOCALITIES_SUCCESS,
  GET_ALL_DEPT_LOCALITIES_FAILURE,
  REFRESH_MARKET_POST_BY_ID_DESTINATION
} from '../actions/actions';
import { getSingleMarketPostById } from './post.actionCreator';

export const tabSwitch = (destination) => {
  return async (dispatch) => {
    dispatch({
      type: REFRESH_MARKET_POST_BY_ID_DESTINATION,
      payload: { destination: destination }
    });
    return Promise.resolve();
  };
};
//to ADD MARKETPLACE club
export const updatePostMarketPlaceRentPost = (postId, rentPost, promises) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_MARKETPLACE_POST_LOADING
    });
    let postData = new FormData();
    postData.append('rentPostDetails', JSON.stringify(rentPost));

    await Promise.all(promises).then((files) => {
      Array.from(files).forEach(async (file) => {
        postData.append('myFile', file);
      });
    });
    await axios
      .put(`${process.env.REACT_APP_MARKETPLACE_URL}/update-post/post-id`, postData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: '*/*'
        },
        params: {
          postId: postId,
          email: localStorage.getItem('email')
        }
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: REFRESH_MARKET_POST_BY_ID_SUCCESS,
            payload: {
              post: response.data,
              postType: 'FLAT_RENTING'
            }
          });
          toast.success('Post Updated!');
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_MARKETPLACE_POST_FAILURE,
          payload: {
            error: error.message
          }
        });
        toast.error('Internal Server Error ');
      });
  };
};
//add market post
export const addPostMarketPlace = (postType, rentPost, files) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_MARKETPLACE_POST_LOADING
    });
    let postData = new FormData();
    let postDataKey =
      postType == 'FLAT_RENTING' ? 'rentPost' : postType == 'BUY_SELL' ? 'buySellPost' : 'carPool';
    postData.append('postData', JSON.stringify({ [postDataKey]: rentPost }));
    Array.from(files).forEach((file) => {
      postData.append('myFile', file);
    });
    await axios
      .post(`${process.env.REACT_APP_MARKETPLACE_URL}/addPost`, postData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: '*/*'
        },
        params: {
          postType: postType,
          email: localStorage.getItem('email')
        }
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: ADD_MARKETPLACE_POST_SUCCESS,
            payload: {
              postType: postType
            }
          });
          toast.success('Post Created!');
        }
      });
  };
};

//carpool post
export const addPostCarPool = (postType, carPool) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_CARPOOL_POST_LOADING
    });
    let postData = new FormData();

    let postDataKey =
      postType === 'CARPOOL' ? 'carPool' : postType === 'FLAT_RENTING' ? 'rentPost' : 'buySellPost';
    postData.append('postData', JSON.stringify({ [postDataKey]: carPool }));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MARKETPLACE_URL}/addPost`,
        postData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            accept: '*/*'
          },
          params: {
            postType: postType,
            email: localStorage.getItem('email')
          }
        }
      );

      if (response.status === 200) {
        dispatch({
          type: ADD_CARPOOL_POST_SUCCESS,
          payload: {
            postType: postType
          }
        });
        toast.success('Post Created!');
      }
    } catch (error) {
      dispatch({
        type: ADD_CARPOOL_POST_FAILURE,
        payload: {
          error: error.message
        }
      });
      throw error;
    }
  };
};

//update pool post
export const updateCarPoolPost = (poolId, values) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_CARPOOL_POST_LOADING
    });
    await axios
      .put(`${process.env.REACT_APP_MARKETPLACE_URL}/carpool/update`, values, {
        params: {
          carPoolId: poolId,
          email: localStorage.getItem('email')
        }
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: REFRESH_MARKET_POST_BY_ID_SUCCESS,
            payload: {
              post: response.data,
              postType: 'CARPOOL'
            }
          });
          toast.success('Post Updated!');
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_CARPOOL_POST_FAILURE,
          payload: {
            error: error.message
          }
        });
        toast.error('Internal Server Error ');
      });
  };
};
//getPost

export const getAllMarketPost = (pageNum, postType) => {
  const postTypeValue = postType == 0 ? 'BUY_SELL' : postType == 1 ? 'FLAT_RENTING' : 'CARPOOL';
  return (dispatch) => {
    dispatch({
      type: GET_ALL_POST_LOADING
    });

    axios
      .get(
        `${process.env.REACT_APP_MARKETPLACE_URL}/getRecentPosts?pageNum=${pageNum}&postType=${postTypeValue}`,
        {
          headers: {
            accept: '*/*'
          }
        }
      )

      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_ALL_POST_SUCCESS,
            payload: {
              details: response.data,
              pageNum: pageNum,
              postType: postType
            }
          });
        }
        dispatch({
          type: GET_ALL_POST_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_POST_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getFlatRentPost = (pageNum, body) => {
  return (dispatch) => {
    dispatch({
      type: GET_FLATRENTPOST_LOADING
    });

    axios
      .post(`${process.env.REACT_APP_MARKETPLACE_URL}/getFilteredPosts?pageNum=${pageNum}`, body, {
        headers: {
          accept: '*/*'
        }
      })

      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_FLATRENTPOST_SUCCESS,
            payload: { details: response.data, pageNum: pageNum }
          });
        }
        dispatch({
          type: GET_FLATRENTPOST_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_FLATRENTPOST_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getCarPoolPost = (pageNum, body) => {
  return (dispatch) => {
    dispatch({
      type: GET_CARPOOLPOST_LOADING
    });

    axios
      .post(
        `${process.env.REACT_APP_MARKETPLACE_URL}/carpool/getFilteredPosts?pageNum=${pageNum}`,
        body,
        {
          headers: {
            accept: '*/*'
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_CARPOOLPOST_SUCCESS,
            payload: { details: response.data, pageNum: pageNum }
          });
        }
        dispatch({
          type: GET_CARPOOLPOST_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CARPOOLPOST_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getAllLocations = (postType, val) => {
  const postTypeValue =
    postType === 'Looking'
      ? 'LOOKING'
      : postType === 'Lending'
      ? 'LENDING'
      : postType === 'Ride'
      ? 'DRIVER_POST'
      : postType === 'Passengers'
      ? 'PASSENGER_POST'
      : 'ALL_POSTS';
  const postUrl =
    val === 1 ? 'getAllLocations?flatRentingFilters' : 'carpool/getAllDestLocations?carPoolType';
  return (dispatch) => {
    dispatch({
      type: GET_ALL_LOCALITIES_LOADING
    });

    axios
      .get(`${process.env.REACT_APP_MARKETPLACE_URL}/${postUrl}=${postTypeValue}`, {
        headers: {
          accept: '*/*'
        }
      })

      .then((response) => {
        const details = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_ALL_LOCALITIES_SUCCESS,
            payload: {
              details: {
                ...details
              }
            }
          });
        }
        dispatch({
          type: GET_ALL_LOCALITIES_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_LOCALITIES_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getAllDeptLocations = (postType) => {
  const postTypeValue =
    postType === 'Ride'
      ? 'DRIVER_POST'
      : postType === 'Passengers'
      ? 'PASSENGER_POST'
      : 'ALL_POSTS';

  return (dispatch) => {
    dispatch({
      type: GET_ALL_DEPT_LOCALITIES_LOADING
    });

    axios
      .get(
        `${process.env.REACT_APP_MARKETPLACE_URL}/carpool/getAllDepartLocations?carPoolType=${postTypeValue}`,
        {
          headers: {
            accept: '*/*'
          }
        }
      )

      .then((response) => {
        const details = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_ALL_DEPT_LOCALITIES_SUCCESS,
            payload: {
              details: {
                ...details
              }
            }
          });
        }
        dispatch({
          type: GET_ALL_DEPT_LOCALITIES_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_DEPT_LOCALITIES_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const deleteMarketPost = (postId) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_MARKET_POST_LOADING
    });

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_MARKETPLACE_URL}/deletePostById`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            accept: '*/*'
          },
          params: {
            postId: postId
          }
        }
      );

      if (response.status === 200) {
        toast.success('Post deleted Successfully!');
        // dispatch({
        //   type: DELETE_MARKET_POST_SUCCESS,
        //   payload: {
        //     PostId: postId
        //   }
        // });
      }
    } catch (error) {
      toast.error('Error deleting the post');
      dispatch({
        type: DELETE_MARKET_POST_FAILURE,
        payload: {
          error: error.message
        }
      });
    }
  };
};

export const setFinalData1 = (data) => {
  return {
    type: SET_FINAL_DATA,
    payload: data
  };
};

export const getTotalPages = (postType) => {
  const postTypeValue = postType == 0 ? 'BUY_SELL' : postType == 1 ? 'FLAT_RENTING' : 'CARPOOL';
  return (dispatch) => {
    dispatch({
      type: GET_TOTAL_PAGES_LOADING
    });

    axios
      .get(`${process.env.REACT_APP_MARKETPLACE_URL}/count?postType=${postTypeValue}`, {
        headers: {
          accept: '*/*'
        }
      })

      .then((response) => {
        const totalPages = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_TOTAL_PAGES_SUCCESS,
            payload: {
              totalPages: totalPages
            }
          });
        }
        dispatch({
          type: GET_TOTAL_PAGES_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOTAL_PAGES_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
export const setPost = (data) => {
  return {
    type: SET_POST,
    payload: data
  };
};
//action for loading post
export const loadingPost = () => {
  return {
    type: LOADING_FALSE
  };
};

export const getInctiveActivePost = (
  postId,
  postAv,
  data,
  pageNum,
  val,
  postType,
  selectedOption
) => {
  const status = postAv === 'UNAVAILABLE' ? 'Inactive' : 'Active';
  return (dispatch) => {
    dispatch({
      type: GET_INACTIVE_ACTIVE_POST_LOADING
    });

    axios
      .get(
        `${process.env.REACT_APP_MARKETPLACE_URL}/markPostStatus?postId=${postId}&postAvailability=${postAv}`,
        {
          headers: {
            accept: '*/*'
          }
        }
      )

      .then((response) => {
        const statusPost = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_INACTIVE_ACTIVE_POST_SUCCESS,
            payload: {
              statusPost: statusPost
            }
          });

          postType === 'single'
            ? dispatch(getSingleMarketPostById(postId))
            : dispatch(
                val === 1 ? getFlatRentPost(pageNum, data, val) : getCarPoolPost(pageNum, data, val)
              );
          dispatch(getAllLocations(selectedOption, val));
          val === 2 && dispatch(getAllDeptLocations(selectedOption));
          toast.success(`Post marked as ${status}!`);
        }
        dispatch({
          type: GET_INACTIVE_ACTIVE_POST_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_INACTIVE_ACTIVE_POST_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
