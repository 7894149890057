import {
  GET_EDUCATIONDETAILS_FAILURE,
  GET_EDUCATIONDETAILS_SUCCESS,
  GET_EDUCATIONDETAILS_LOADING
} from '../../actions/actions';

const initialState = {
  educationDetails: [],
  getLoading: false,
  getError: '',
  addLoading: false,
  addError: ''
};

const educationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EDUCATIONDETAILS_SUCCESS:
      return {
        ...state,
        getLoading: false,
        educationDetails: action.payload.educationDetails,
        getError: ''
      };
    case GET_EDUCATIONDETAILS_LOADING:
      return {
        ...state,
        getLoading: true
      };
    case GET_EDUCATIONDETAILS_FAILURE:
      return {
        ...state,
        getLoading: false,
        getError: action.payload.error
      };

    default:
      return state;
  }
};

export default educationReducer;
