import React, { useState, useEffect } from 'react';

import { Card } from 'react-bootstrap';
import Viewport from '../../../../helpers/viewport';

//scss
import s from '../clubs.module.scss';

//icons
import members from '../../../../../assets/icons/members.png';
import clock from '../../../../../assets/icons/clock.svg';
import adminIcon from '../../../../../assets/icons/admin.svg';
import requestIcon from '../../../../../assets/icons/pendingIcon.svg';
import { Skeleton } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';

const CardComponent = ({ item, handleClick, ...props }) => {
  const [clubJoined, setClubJoined] = useState('notJoined');
  const [memberType, setMemberType] = useState('');
  const [profileImg, setProfileImg] = useState(null);
  const [coverImg, setCoverImg] = useState(null);

  useEffect(() => {
    checkMember(item);
  }, [item.clubMemberDetails, clubJoined, memberType]);

  const checkMember = (item) => {
    item.clubMemberDetails.forEach((data) => {
      if (data.userId === localStorage.getItem('email') && data.clubRoleId === 2) {
        data.clubJoiningStatus === 'REQUEST_APPROVED'
          ? setMemberType('member')
          : setMemberType('requested');
      } else if (data.userId === localStorage.getItem('email') && data.clubRoleId === 1) {
        setMemberType('admin');
      }
    });
  };

  var toastId = '';

  const MembersCount = () => {
    let count = 0;
    item.clubMemberDetails.forEach((item) => {
      if (item.clubRoleId === 2) {
        count++;
      }
    });
    return count;
  };

  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <Card className={s.card} onClick={() => handleClick(item.club.clubId, toastId)}>
      <div
        style={{
          backgroundImage: `url("${item.club.clubCoverUrl}")`
        }}
        className={s.cardImage}
      >
        {memberType !== '' && (
          <div
            className={
              memberType === 'admin'
                ? s.adminTag
                : memberType === 'member'
                ? s.memberTag
                : s.requestedTag
            }
          >
            <p>{memberType}</p>
          </div>
        )}
      </div>

      <div className={isMobile ? s.detailsMobile : s.details}>
        {item.club.clubProfileUrl ? (
          <img src={item.club.clubProfileUrl} className={s.image} alt="Club Profile" />
        ) : (
          <Skeleton variant="circle" className={s.image} />
        )}
        <div className={s.clubName}>{item.club.name}</div>{' '}
        <div className={s.desc}>{item.club.description.slice(0, 220)}... </div>
        <div className={s.members}>
          <div>
            <img src={members} /> {MembersCount()}
            {MembersCount() === 1 ? ' Member ' : ' Members '}
          </div>
          <div>
            <img src={clock} alt="clock Image" /> {item.club.createDate}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CardComponent;
