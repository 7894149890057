import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fontawesome';
import 'react-toastify/dist/ReactToastify.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './pages/auth/authconfig';
import 'sweetalert2/src/sweetalert2.scss';

// Redux
import { Provider } from 'react-redux';

// Store
import { store } from './redux/createStore';

import '@fontsource/montserrat';
import '@fontsource/lato';

const msalInstance = new PublicClientApplication(msalConfig);

const callback = (status) => {
  // I could hook the webfont status to for example Redux here.
};

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
