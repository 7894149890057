
import axios from 'axios';
import {
  GET_HRDASHBOARD_FAILURE,
  GET_HRDASHBOARD_LOADING,
  GET_HRDASHBOARD_SUCCESS,
  GET_REPORT_LOADING,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  GET_AWARDS_REPORT_LOADING,
  GET_AWARDS_REPORT_SUCCESS,
  GET_AWARDS_REPORT_FAILURE

} from '../actions/actions';

const initialState = {
  councilList: [],
  loading: false,
  error: '',
  success: false,
  reportData: null,
  awardsReportData: null, 
};

const hrDashboardReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_HRDASHBOARD_LOADING:
      return { ...state, loading: true, success: false };
    case GET_HRDASHBOARD_SUCCESS:
      return { ...state, councilList: action.payload.details, success: true, loading: false };
    case GET_HRDASHBOARD_FAILURE:
      return { ...state, loading: false, success: false, error: action.payload.error };

    case GET_REPORT_LOADING:
      return { ...state, loading: true, success: false, error: '' };
    case GET_REPORT_SUCCESS:
      return { ...state, loading: false, success: true, reportData: action.payload.details };
    case GET_REPORT_FAILURE:
      return { ...state, loading: false, success: false, error: action.payload.error };

      case GET_AWARDS_REPORT_LOADING:
        return { ...state, loading: true, success: false, error: '' };
      case GET_AWARDS_REPORT_SUCCESS:
        return { ...state, loading: false, success: true, awardsReportData: action.payload.details };
      case GET_AWARDS_REPORT_FAILURE:
        return { ...state, loading: false, success: false, error: action.payload.error };
  
    default:
      return state;


  }
};

export default hrDashboardReducer;