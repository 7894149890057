import {
  GET_POST_LOADING,
  GET_POST_SUCCESS,
  GET_POST_FAILURE,
  GET_POST_SSE_SUCCESS,
  ADD_POST_FAILURE,
  ADD_POST_SUCCESS,
  ADD_POST_LOADING,
  DELETE_POST_LOADING,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  REFRESH_POST_BY_ID_SUCCESS,
  GET_POST_BY_ID_FAILURE,
  GET_POST_BY_ID_LOADING,
  GET_POST_BY_ID_SUCCESS,
  GET_POST_BY_TYPE_ID_FAILURE,
  GET_POST_BY_TYPE_ID_SUCCESS,
  GET_POST_BY_TYPE_ID_LOADING,
  GET_REFRESH_POST_LOADING,
  GET_REFRESH_POST_SUCCESS,
  GET_REFRESH_POST_FAILURE
} from '../actions/actions';

const initialState = {
  loading: false,
  posts: [],
  ssePost: {},
  error: '',
  addLoading: false,
  addError: '',
  pageNum: -1,
  deleteLoading: false,
  deleteError: '',
  filteredPosts: [],
  postTypeId: -1,
  findPostError: '',
  filterPostLoading: false,
  refreshPost: false
};

const postReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_POST_LOADING:
      newState.loading = true;
      if (action.payload) {
        if (action.payload.pageNum === 0 || action.payload.postTypeId) {
          newState.posts = [];
          newState.error = '';
        }
      }
      break;
    case GET_POST_SUCCESS:
      let postTypeId = action.payload.postTypeId;
      newState.postTypeId = action.payload.postTypeId;
      if (postTypeId === -1) {
        if (action.payload?.pageNum === 0) newState.posts = [];
        if (action.payload.posts?.length) {
          newState.pageNum = action.payload.pageNum;
          newState.posts = [...newState.posts, ...action.payload.posts];
          newState.error = '';
        } else {
          newState.error = 'No more posts available.';
        }
      } else {
        newState.posts = [...action.payload.posts].reverse();
        newState.pageNum = -1;
      }

      newState.loading = false;
      break;
    case GET_POST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case GET_POST_SSE_SUCCESS:
      newState.ssePost = [...newState.ssePost, ...action.payload.ssePost];
      newState.error = '';
      break;
    case GET_POST_BY_TYPE_ID_LOADING:
      newState.filterPostLoading = true;
      newState.filteredPosts = [];
      break;
    case GET_POST_BY_TYPE_ID_SUCCESS:
      newState.postTypeId = action.payload.postTypeId;
      newState.filteredPosts = [...action.payload.posts].reverse();
      newState.pageNum = -1;
      newState.filterPostLoading = false;
      break;
    case GET_POST_BY_TYPE_ID_FAILURE:
      newState.error = '';
      newState.filterPostLoading = false;
      break;
    case GET_POST_BY_ID_SUCCESS:
      newState.posts = [action.payload.post];
      newState.loading = false;
      break;
    case GET_POST_BY_ID_LOADING:
      newState.loading = true;
      break;
    case GET_POST_BY_ID_FAILURE:
      newState.loading = false;
      newState.findPostError = action.payload.error;
      break;
    case ADD_POST_LOADING:
      newState.addLoading = true;
      break;
    case ADD_POST_SUCCESS:
      newState = initialState;
      newState.addLoading = false;
      break;
    case ADD_POST_FAILURE:
      newState.addLoading = false;
      newState.addError = action.payload.error;
      break;
    case REFRESH_POST_BY_ID_SUCCESS:
      const post = action.payload.post;
      newState.posts = newState.posts.map((p) => {
        if (parseInt(p.postId) === parseInt(post.postId)) return post;
        return p;
      });
      newState.loading = false;
      break;
    case DELETE_POST_SUCCESS:
      const index = action.payload.postId;
      newState.deleteError = '';
      newState.deleteLoading = false;
      newState.posts = newState.posts.filter((p) => {
        return p.postId !== index;
      });
      break;
    case DELETE_POST_LOADING:
      newState.deleteLoading = true;
      break;
    case DELETE_POST_FAILURE:
      newState.deleteLoading = false;
      newState.deleteError = action.payload.error;
      break;
    case GET_REFRESH_POST_LOADING:
      newState.loading = true;
      break;
    case GET_REFRESH_POST_SUCCESS: {
      newState.refreshPost = action.payload.details;
      newState.loading = false;
      break;
    }
    case GET_REFRESH_POST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    default:
      return state;
  }
  return newState;
};

export default postReducer;
