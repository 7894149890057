import React from 'react';
import { getProfileByEmail } from '../../../../../../helpers';
import { uniqueId } from 'lodash';

// Scss
import s from '../postContainer.module.scss';

//icons
import likeIcon from '../../../../../../assets/icons/like.png';

const CommentNames = ({ names, users }) => {
  const getProfile = (email) => {
    getProfileByEmail(email);
  };

  return (
    <div className={s.likeName}>
      <div className={s.likeBox}>
        <div className={s.likeHeader}>
          <img alt="like icon" width={18} height={18} src={likeIcon} style={{display:'inline'}} className="reaction-like" />
          <span className={s.likeReaction}>Like Reactions</span>
        </div>
        {names.map((user) => {
          const data = Object.values(users).filter(
            (value) => value.email === user.commentIdentity.email
          );

          const userData = data[0];
          user.unique_id = uniqueId();
          return (
            <div key={user.unique_id} onClick={() => getProfile(user.commentIdentity.email)}>
              {user.commentLikeFlag === 1 ? (
                <div className={s.content}>
                  <img
                    referrerPolicy="no-referrer"
                    className={s.image}
                    src={`${userData?.photoUrl}`}
                    style={{
                      width: '30px', 
                      height: '30px', 
                    }}
                    alt="employee image"
                  />
                  <span className={s.userName}>{userData?.name}</span>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CommentNames;
