import React, { useEffect, useState } from 'react';
import s from './writePost.module.scss';

const HashtagDropdown = ({
  searchHashtag,
  toggleHashtagDropdown,
  hashtags,
  handleHashtagContent
}) => {
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    let result = hashtags?.filter((tag) => tag.startsWith(searchHashtag));
    setSearchResults(result);
  }, [searchHashtag]);

  return (
    <>
      {toggleHashtagDropdown && searchHashtag && searchHashtag.length > 0 && (
        <div className={s.hashtagContainer}>
          <h6 className="font-weight-bold">Suggestions</h6>
          {searchResults && searchResults.length > 0 ? (
            searchResults.map((hash, index) => {
              ['</p>', '</span>'].forEach(
                (val) => (hash = hash.match(`(.*?)${val}`) ? hash.match(`(.*?)${val}`)[1] : hash)
              );
              return (
                <p key={index} onClick={() => handleHashtagContent(hash)} className={s.hashtag}>
                  {hash}
                </p>
              );
            })
          ) : (
            <p className={s.hashtag} onClick={() => handleHashtagContent(searchHashtag[0])}>
              {searchHashtag}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default HashtagDropdown;
