import React from 'react';
import { useMsal } from '@azure/msal-react';

import s from './auth.module.scss';

// Icons
import LogoutIcon from '../../assets/icons/logoutIcon.svg';

function handleLogout(instance) {
  const currentAccount = instance.getAccountByHomeId(localStorage.getItem('homeAccountId'));
  instance.logoutRedirect({ account: currentAccount });
  localStorage.clear();
}
const Logout = () => {
  const { instance } = useMsal();

  return (
    <button onClick={() => handleLogout(instance)}>
      <img src={LogoutIcon} alt="logout" width={15} height={15} className="text-danger" style={{display:"inline"}}/>{' '}
      <span className="ml-1">Logout</span>
    </button>
  );
};

export default Logout;
