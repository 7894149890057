import React from 'react';

import tagIcon from '../../../../../../assets/icons/tagIconGrey.svg';
import s from '../postContainer.module.scss';
import cns from 'classnames';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { getCustomTagsByClubId } from '../../../../../../redux/actionCreators/customTags.actionCreator';
import { connect } from 'react-redux';

const TagTypeList = ({
  wrapper,
  setToggleOptions,
  toggleOptions,
  setTagType,
  tagType,
  getPosts,
  clubId,
  ...props
}) => {
  const [name, setName] = useState('All Tags');

  const allPost = {
    tagType: 'All Tags',
    tagId: 0
  };

  useEffect(() => {
    props.getCustomTagsByClubId(clubId);
  }, [clubId]);

  useEffect(() => {
    if (props.customTags.tagId === 0) {
      setName('All Tags');
    }
  }, [props.customTags.tagId]);

  const handleChangeTagType = useCallback(
    (tagData) => {
      setName(tagData.tagName);
      let value = tagData.tagId;
      setTagType(value);
      let data = {
        pageNum: 0,
        tagType: value
      };
      if (clubId && data.tagType === 0) data.clubId = clubId;
      getPosts(data);
      window.scrollTo({ top: 0 });
      setToggleOptions(false);
    },
    [tagType]
  );

  return (
    <div
      ref={wrapper}
      className={cns(s.dropDown, s.filterDiv, toggleOptions ? s.active : '', 'mr-4')}
    >
      <button className={s.filterButton} onClick={() => setToggleOptions(!toggleOptions)}>
        <img className={cns(s.img)} src={tagIcon} alt="filter" height={20} />
        {name}
      </button>
      <ul className={s.listHead}>
        {props?.customTags.map((tagData, idx) => {
          return (
            <li
              className={cns(s.listItem, 'px-3')}
              onClick={() => handleChangeTagType(tagData)}
              key={idx}
            >
              {tagData.tagName}
            </li>
          );
        })}
        <li
          className={cns(s.listItem, 'px-3')}
          onClick={() => handleChangeTagType(allPost)}
          key={0}
        >
          All Posts{' '}
        </li>
      </ul>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    customTags: state.customTagsReducer.customTags,
    loading: state.customTagsReducer.loading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCustomTagsByClubId: getCustomTagsByClubId
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TagTypeList);
