import {
  ADD_MARKETPLACE_POST_LOADING,
  ADD_MARKETPLACE_POST_SUCCESS,
  ADD_MARKETPLACE_POST_FAILURE,
  ADD_CARPOOL_POST_LOADING,
  ADD_CARPOOL_POST_SUCCESS,
  ADD_CARPOOL_POST_FAILURE,
  GET_ALL_POST_FAILURE,
  GET_ALL_POST_SUCCESS,
  GET_ALL_POST_LOADING,
  REFRESH_MARKET_POST_BY_ID_SUCCESS,
  GET_MARKET_POST_BY_ID_LOADING,
  SAVE_LIKE_FOR_MARKET_POST_LOADING,
  SAVE_LIKE_FOR_MARKET_POST_SUCCESS,
  SAVE_LIKE_FOR_MARKET_POST_FAILURE,
  DELETE_LIKE_FOR_MARKET_POST_LOADING,
  DELETE_LIKE_FOR_MARKET_POST_SUCCESS,
  DELETE_LIKE_FOR_MARKET_POST_FAILURE,
  GET_ALL_LOCALITIES_FAILURE,
  GET_ALL_LOCALITIES_SUCCESS,
  GET_ALL_LOCALITIES_LOADING,
  DELETE_MARKET_POST_SUCCESS,
  DELETE_MARKET_POST_LOADING,
  DELETE_MARKET_POST_FAILURE,
  REFRESH_MARKET_POST_BY_ID,
  GET_TOTAL_PAGES_LOADING,
  GET_TOTAL_PAGES_SUCCESS,
  GET_TOTAL_PAGES_FAILURE,
  GET_INACTIVE_ACTIVE_POST_LOADING,
  GET_INACTIVE_ACTIVE_POST_SUCCESS,
  GET_INACTIVE_ACTIVE_POST_FAILURE,
  SET_FINAL_DATA,
  SET_POST,
  LOADING_FALSE,
  GET_FLATRENTPOST_LOADING,
  GET_FLATRENTPOST_SUCCESS,
  GET_FLATRENTPOST_FAILURE,
  GET_CARPOOLPOST_LOADING,
  GET_CARPOOLPOST_FAILURE,
  GET_CARPOOLPOST_SUCCESS,
  GET_ALL_DEPT_LOCALITIES_LOADING,
  GET_ALL_DEPT_LOCALITIES_SUCCESS,
  GET_ALL_DEPT_LOCALITIES_FAILURE,
  REFRESH_MARKET_POST_BY_ID_DESTINATION
} from '../actions/actions';

const initialState = {
  loading: false,
  value: 1,
  totalPageCount: 0,
  clubDetails: [],
  membersDetails: [],
  requests: [],
  error: '',
  data: [],
  post: {},
  postById: {},
  posts: [],
  allposts: [],
  flatRentPosts: [],
  finalData: [],
  localities: [],
  deptLocalities: [],
  deleteError: '',
  deleteLoading: false
};

const marketPlaceReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case SET_POST:
      newState.post = action.payload;
      break;
    case SET_FINAL_DATA:
      newState.finalData = action.payload;
      break;
    case ADD_MARKETPLACE_POST_LOADING:
      newState.loading = true;
      break;
    case ADD_MARKETPLACE_POST_SUCCESS: {
      const postType = action.payload.postType;
      newState = initialState;
      if (postType === 'FLAT_RENTING') {
        newState.value = 1;
      }
      newState.loading = false;
      break;
    }
    case ADD_MARKETPLACE_POST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case ADD_CARPOOL_POST_LOADING:
      newState.loading = true;
      break;
    case ADD_CARPOOL_POST_SUCCESS: {
      const postType1 = action.payload.postType;
      newState = initialState;
      if (postType1 === 'CARPOOL') {
        newState.value = 2;
      }
      newState.loading = false;
      break;
    }
    case ADD_CARPOOL_POST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case GET_ALL_POST_LOADING:
      newState.loading = true;
      break;

    case GET_ALL_POST_SUCCESS: {
      newState.post = { ...state.details, ...action.payload.details };
      newState.allposts = [...state.allposts, ...action.payload.details];
      newState.loading = false;
      break;
    }

    case GET_ALL_POST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    case GET_FLATRENTPOST_LOADING:
      newState.loading = true;
      break;

    case GET_FLATRENTPOST_SUCCESS: {
      if (action.payload.pageNum === 0) {
        state.finalData = [];
      }
      newState.finalData = [...state.finalData, ...action.payload.details];

      newState.loading = false;
      break;
    }

    case GET_FLATRENTPOST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    case GET_CARPOOLPOST_LOADING:
      newState.loading = true;
      break;

    case GET_CARPOOLPOST_SUCCESS: {
      if (action.payload.pageNum === 0) {
        state.finalData = [];
      }
      newState.finalData = [...state.finalData, ...action.payload.details];

      newState.loading = false;
      break;
    }

    case GET_CARPOOLPOST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    case GET_MARKET_POST_BY_ID_LOADING:
      // newState.loading = true;
      break;

    // case REFRESH_MARKET_POST_BY_ID:
    //   newState.post = [
    //     action.payload.post.data.rentPost ??
    //       action.payload.post.data.buySellPost ??
    //       action.payload.post.data
    //   ];
    //   newState.postById = [
    //     action.payload.post.data.rentPost ??
    //       action.payload.post.data.buySellPost ??
    //       action.payload.post.data
    //   ];
    //   newState.allposts = [
    //     action.payload.post.data.rentPost ??
    //       action.payload.post.data.buySellPost ??
    //       action.payload.post.data
    //   ];
    //   break;

    case REFRESH_MARKET_POST_BY_ID:
      newState.post = [action.payload.post.data ?? action.payload.post.data.buySellPost];
      newState.postById = [action.payload.post.data ?? action.payload.post.data.buySellPost];
      newState.allposts = [action.payload.post.data ?? action.payload.post.data.buySellPost];
      break;

    case REFRESH_MARKET_POST_BY_ID_DESTINATION:
      newState.value = action.payload.destination;

      break;

    case REFRESH_MARKET_POST_BY_ID_SUCCESS: {
      const post = action.payload.post;
      if (action.payload.postType === 'CARPOOL') {
        newState.value = 2;
      }
      if (action.payload.postType === 'FLAT_RENTING') {
        newState.value = 1;
      }
      try {
        if (newState.finalData.length > 0) {
          newState.post = Object.values(newState.finalData).map((p) => {
            if (
              parseInt(p.postId ? p.postId : p.poolId ? p.poolId : p.id) ===
              parseInt(post.postId ? post.postId : p.poolId ? p.poolId : p.id)
            ) {
              if (p.reactions) p.reactions = post.reactions ?? post.postReaction;
              else {
                p.postReaction = post.postReaction ?? post.reactions;
              }
              if (p.comments) {
                p.comments = post.comments ?? post.comment;
              } else {
                p.comment = post.comments ?? post.comment;
              }
              return p;
            }
            return p;
          });
        } else {
          let newPost = newState.post[0];
          if (
            parseInt(
              newPost.postId ? newPost.postId : newPost.poolId ? newPost.poolId : newPost.id
            ) === parseInt(post.postId ? post.postId : post.poolId ? post.poolId : post.id)
          ) {
            if (newPost.reactions) newPost.reactions = post.reactions ?? post.postReaction;
            else {
              newPost.postReaction = post.postReaction ?? post.reactions;
            }
            if (newPost.comments) {
              newPost.comments = post.comments ?? post.comment;
            } else {
              newPost.comment = post.comments ?? post.comment;
            }
          }
          newState.post = [newPost];
        }
      } catch (e) {
        console.log('some error occured', e);
      }
      newState.loading = false;
      break;
    }
    case SAVE_LIKE_FOR_MARKET_POST_LOADING:
      newState.saveLoading = true;
      break;
    case SAVE_LIKE_FOR_MARKET_POST_SUCCESS:
      newState.saveLoading = false;
      newState.saveError = '';
      break;
    case SAVE_LIKE_FOR_MARKET_POST_FAILURE:
      newState.saveLoading = false;
      newState.saveError = action.payload.error;
      break;

    case DELETE_LIKE_FOR_MARKET_POST_LOADING:
      newState.deleteLoading = true;
      break;
    case DELETE_LIKE_FOR_MARKET_POST_SUCCESS:
      newState.deleteLoading = false;
      newState.deleteError = '';
      break;
    case DELETE_LIKE_FOR_MARKET_POST_FAILURE:
      newState.deleteLoading = false;
      newState.deleteError = action.payload.error;
      break;
    case GET_ALL_LOCALITIES_LOADING:
      newState.loading = true;
      break;
    case GET_ALL_LOCALITIES_SUCCESS: {
      newState.localities = { ...state.details, ...action.payload.details };
      newState.loading = false;
      break;
    }
    case GET_ALL_LOCALITIES_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    case GET_ALL_DEPT_LOCALITIES_LOADING:
      newState.loading = true;
      break;
    case GET_ALL_DEPT_LOCALITIES_SUCCESS: {
      newState.deptLocalities = { ...state.details, ...action.payload.details };
      newState.loading = false;
      break;
    }
    case GET_ALL_DEPT_LOCALITIES_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    case GET_TOTAL_PAGES_LOADING:
      newState.loading = true;
      break;
    case GET_TOTAL_PAGES_SUCCESS: {
      newState.totalPageCount = action.payload.totalPages;
      newState.loading = false;
      break;
    }
    case GET_TOTAL_PAGES_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case GET_INACTIVE_ACTIVE_POST_LOADING:
      newState.loading = true;
      break;
    case GET_INACTIVE_ACTIVE_POST_SUCCESS: {
      newState.statusPost = action.payload.statusPost;
      newState.loading = false;
      break;
    }
    case GET_INACTIVE_ACTIVE_POST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    default:
      return newState;
    // case DELETE_MARKET_POST_SUCCESS:
    //   // const deletedPostId = action.payload.postId;
    //   // const updatedPosts = newState?.post?.filter((post) => post.id !== deletedPostId);
    //   // return {
    //   //   ...newState,
    //   //   post: updatedPosts,
    //   //   deleteError: '', // Assuming you want to clear deleteError on success
    //   //   deleteLoading: false // Assuming you want to set deleteLoading to false on success
    //   // };
    //   const deletedPostId = action.payload.postId;

    //   newState.deleteError = '';
    //   newState.deleteLoading = false;
    //   newState.post = Object.fromEntries(
    //     Object.entries(newState?.post).filter(([key, value]) => value.postId !== deletedPostId)
    //   );
    //   // newState.post = newState?.post?.filter((p) => p?.postId !== deletedPostId);

    //   newState.allposts = Object.fromEntries(
    //     Object.entries(newState?.allposts).filter(([key, value]) => value.postId !== deletedPostId)
    //   );
    //   // newState.allposts = newState?.allposts?.filter((p) => p?.postId !== deletedPostId);
    //   const index = action.payload.postId;
    //   newState.deleteError = '';
    //   newState.deleteLoading = false;
    //   newState.post = newState.post.filter((p) => {
    //     return p.postId !== index;
    //   });

    //   break;
    // case DELETE_MARKET_POST_LOADING:
    //   newState.deleteLoading = true;
    //   break;
    // case DELETE_MARKET_POST_FAILURE:
    //   newState.deleteLoading = false;
    //   newState.deleteError = action.payload.error;
    //   break;

    case DELETE_MARKET_POST_LOADING:
      newState.deleteLoading = true;
      break;
    case DELETE_MARKET_POST_SUCCESS:
      newState.deleteLoading = false;
      newState.deleteError = '';

      // Update state after successful post deletion
      const updatedAllPosts = Object.values(state.posts)?.filter(
        (post) => post.postId !== action.payload?.PostId
      );

      newState.posts = updatedAllPosts;
      break;
    case DELETE_MARKET_POST_FAILURE:
      newState.deleteLoading = false;
      newState.deleteError = action.payload.error;
      // Add logic here to handle post deletion failure
      break;
    case LOADING_FALSE:
      newState.loading = false;
  }
  return newState;
};

export default marketPlaceReducer;
