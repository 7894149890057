import React, { useEffect, useState } from 'react';
import s from './membersPage.module.scss';
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { uniqueId } from 'lodash';
import adminIcon from '../../../../../../assets/icons/admin.svg';
import { getProfileByEmail } from '../../../../../../helpers';
import SearchEmployee from './searchEmployee';

const MembersListTable = ({ membersInClub, handleAddAdmin, checkAdmin }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [rows, setRows] = useState();
  const [data, setData] = useState(membersInClub);

  const getProfile = (email) => {
    getProfileByEmail(email);
  };

  useEffect(() => {
    setData(membersInClub);
    if (searchQuery) {
      setData(rows);
    } else setData(membersInClub);
  }, [searchQuery, membersInClub]);

  return (
    <div>
      {membersInClub?.length ? (
        <div className={s.wrapper}>
          <SearchEmployee
            list={membersInClub}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setRows={setRows}
          />
          <Table>
            <TableBody>
              {data?.map((item) => {
                item.unique_id = uniqueId();
                return (
                  <TableRow key={item.unique_id}>
                    <TableCell className={s.tableCell}>
                      <div className={s.employeeName} onClick={() => getProfile(item?.email)}>
                        <img src={item?.photoUrl ?? item?.misPhotoUrl} alt="employee-image" />
                        <div>
                          {item?.name}
                          <br />
                          <p className={s.subInformation}>{item?.designation}</p>
                        </div>
                      </div>
                    </TableCell>

                    {checkAdmin() && (
                      <>
                        <TableCell className={s.tableCell}>
                          <Tooltip title="Set as admin">
                            <img
                              src={adminIcon}
                              onClick={() => {
                                handleAddAdmin(item?.email);
                              }}
                              className={s.icons}
                              height={16}
                              alt="admin Icon"
                            />
                          </Tooltip>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className={s.noRequest}>No Members Present</div>
      )}
    </div>
  );
};

export default MembersListTable;
