import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_AWARDS_FAILURE,
  GET_AWARDS_SUCCESS,
  GET_AWARDS_LOADING,
  ADD_AWARD_FAILURE,
  ADD_AWARD_SUCCESS,
  GET_AWARDS_WINNER_FAILURE,
  GET_AWARDS_WINNER_SUCCESS,
  GET_AWARDS_WINNER_LOADING,
  GET_AWARDS_WIN_FAILURE,
  GET_AWARDS_WIN_SUCCESS,
  GET_AWARDS_WIN_LOADING,
  GET_AWARDS_BY_EMAIL_FAILURE,
  GET_AWARDS_BY_EMAIL_SUCCESS,
  GET_AWARDS_BY_EMAIL_LOADING
} from '../actions/actions';

export const getAwards = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_AWARDS_LOADING
    });
    axios
      .get(
        `${process.env.REACT_APP_AWARDS_URL}/winners/events-and-recognitions/winners?year=${data}`
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_AWARDS_SUCCESS,
            payload: {
              awards: [...response.data]
            }
          });
        }
        dispatch({
          type: GET_AWARDS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_AWARDS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getAddAwards = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_AWARDS_LOADING
    });
    axios
      .get(`${process.env.REACT_APP_AWARDS_URL}/winners/events-and-recognitions?year=${data}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_AWARDS_SUCCESS,
            payload: {
              awards: [...response.data]
            }
          });
        }
        dispatch({
          type: GET_AWARDS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_AWARDS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getAwardsWin = () => {
  return (dispatch) => {
    dispatch({
      type: GET_AWARDS_WIN_LOADING
    });
    axios
      .get(process.env.REACT_APP_AWARDS_URL + '/winners/events', {
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_AWARDS_WIN_SUCCESS,
            payload: {
              awardsWin: response.data
            }
          });
        }
        dispatch({
          type: GET_AWARDS_WIN_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_AWARDS_WIN_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getAwardsWinners = (data) => {
  const url = process.env.REACT_APP_AWARDS_URL + '/winners/event-recognition/' + `${data}`;
  return (dispatch) => {
    dispatch({
      type: GET_AWARDS_WINNER_LOADING
    });
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_AWARDS_WINNER_SUCCESS,
            payload: {
              awards: [...response.data]
            }
          });
        }
        dispatch({
          type: GET_AWARDS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_AWARDS_WINNER_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getAwardsByEmail = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_AWARDS_BY_EMAIL_LOADING
    });
    axios
      .get(process.env.REACT_APP_AWARDS_URL + '/recognition-winner/awards', {
        params: {
          email: data.email ?? data
        }
      })
      .then((response) => {
        const details = Array.isArray(response.data) ? [...response.data] : [];
        if (response.status === 200) {
          dispatch({
            type: GET_AWARDS_BY_EMAIL_SUCCESS,
            payload: {
              awardsByEmail: [...details]
            }
          });
        }
        dispatch({
          type: GET_AWARDS_BY_EMAIL_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_AWARDS_BY_EMAIL_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const addAward = (data) => {
  return (dispatch) => {
    return axios
      .post(process.env.REACT_APP_AWARDS_URL + '/recognition-winner', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch({
            type: ADD_AWARD_SUCCESS,
            payload: {
              awards: response.data
            }
          });
          toast.success('Recognition added successfully.');
        }
      })

      .catch((error) => {
        if (
          error.response &&
          error.response.data === 'This person has already been awarded for this event. '
        ) {
          toast.error('This person already has this award.');
        } else {
          toast.error('Failed to add award. Please try again.');
        }
        dispatch({
          type: ADD_AWARD_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const addWinner = (data, rankTitle) => {
  return (dispatch) => {
    return axios
      .post(
        process.env.REACT_APP_AWARDS_URL + `/winners/events?rankTitle=${rankTitle.toUpperCase()}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: ADD_AWARD_SUCCESS,
            payload: {
              awards: response.data
            }
          });
          toast.success('Winner added successfully.');
        }
      })

      .catch((error) => {
        if (
          error.response &&
          error.response.data === 'This person has already been awarded for this event. '
        ) {
          toast.error('This person already has this award.');
        } else {
          toast.error('Failed to add award. Please try again.');
        }

        dispatch({
          type: ADD_AWARD_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
