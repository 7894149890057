import axios from 'axios';
import {
  SAVE_LIKE_FOR_POST_LOADING,
  UPDATE_LIKE_FOR_POST_LOADING,
  UPDATE_LIKE_FOR_POST_SUCCESS,
  UPDATE_LIKE_FOR_POST_FAILURE,
  UPDATE_LIKE_FOR_MARKET_POST_SUCCESS,
  UPDATE_LIKE_FOR_MARKET_POST_FAILURE,
  SAVE_LIKE_FOR_POST_SUCCESS,
  SAVE_LIKE_FOR_MARKET_POST_SUCCESS
} from '../actions/actions';

export const saveLikeForPost = (data) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_LIKE_FOR_POST_LOADING
    });
    axios
      .post(process.env.REACT_APP_GEMBOOK_SVC_URL + '/post-reaction', null, {
        headers: {
          accept: '*/*'
        },
        params: {
          reactFlag: parseInt(data.reactFlag),
          reactionType: data.reactionType,
          postId: parseInt(data.postId),
          userId: localStorage.getItem('email')
        }
      })
      .then((res) => {
        const details = { data: [...res.data], postId: data.postId };
        if (res.status === 201) {
          dispatch({
            type: SAVE_LIKE_FOR_POST_SUCCESS,
            payload: {
              details: details
            }
          });
        }
      })
      .catch(() => {});
  };
};

export const updateLikeForPost = (postId) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LIKE_FOR_POST_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/post-reaction', {
        // headers: {
        //   accept: "*/*",
        // },
        params: {
          postId: postId
        }
      })
      .then((response) => {
        const likes = response.data.object;

        dispatch({
          type: UPDATE_LIKE_FOR_POST_SUCCESS,
          payload: {
            postId: postId,
            likes: likes
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_LIKE_FOR_POST_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

//.////////////MARKET PLACE/////////////////

export const saveLikeForMarketPost = (data) => {
  return (dispatch) => {
    // dispatch({
    //   type: SAVE_LIKE_FOR_MARKET_POST_LOADING
    // });
    axios
      .post(process.env.REACT_APP_MARKETPLACE_URL + '/post-reaction', null, {
        headers: {
          accept: '*/*'
        },
        params: {
          reactFlag: parseInt(data.reactFlag),
          reactionType: data.reactionType,
          postId: parseInt(data.postId),
          userId: localStorage.getItem('email')
        }
      })
      .then((res) => {
        const details = { data: [...res.data], postId: data.postId };
        if (res.status === 201) {
          dispatch({
            type: SAVE_LIKE_FOR_MARKET_POST_SUCCESS,
            payload: {
              details: details
            }
          });
        }
      })
      .catch(() => {});
  };
};

export const updateLikeForMarketPost = (postId) => {
  return (dispatch) => {
    // dispatch({
    //   type: UPDATE_LIKE_FOR_MARKET_POST_LOADING
    // });
    axios
      .get(process.env.REACT_APP_MARKETPLACE_URL + '/post-reaction', {
        params: {
          postId: postId
        }
      })
      .then((response) => {
        const likes = response.data.object;

        dispatch({
          type: UPDATE_LIKE_FOR_MARKET_POST_SUCCESS,
          payload: {
            postId: postId,
            likes: likes
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_LIKE_FOR_MARKET_POST_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
