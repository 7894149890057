import { GET_HR_USER_SUCCESS, GET_HR_USER_LOADING } from '../actions/actions';

const initialState = {
  loading: false,
  error: '',
  addLoading: false,
  addError: '',
  hrUsers: []
};

const entitlementReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_HR_USER_LOADING:
      newState.loading = true;
      break;
    case GET_HR_USER_SUCCESS:
      newState.hrUsers = action.payload.hrData;
      newState.loading = false;
      break;
    default:
      return state;
  }
  return newState;
};

export default entitlementReducer;
