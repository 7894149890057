import axios from 'axios';
import {
  GET_CERTIFICATIONS_LOADING,
  GET_CERTIFICATIONS_SUCCESS,
  GET_CERTIFICATIONS_FAILURE,
  EDIT_CERTIFICATION_LOADING,
  EDIT_CERTIFICATION_FAILURE,
  EDIT_CERTIFICATION_SUCCESS,
  ADD_CERTIFICATION_LOADING,
  DELETE_CERTIFICATION,
  GET_TECH_TYPES_LOADING,
  GET_TECH_TYPES_SUCCESS,
  GET_TECH_TYPES_FAILURE
} from '../../actions/actions';
import { toast } from 'react-toastify';

export const getCertification = () => {
  return (dispatch) => {
    dispatch({
      type: GET_CERTIFICATIONS_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/certificates/by-emp-id', {
        headers: {
          accept: '*/*'
        },
        params: {
          employeeId: localStorage.getItem('id')
        }
      })
      .then((res) => {
        dispatch({
          type: GET_CERTIFICATIONS_SUCCESS,
          payload: {
            certifications: res.data
          }
        });
      })
      .catch(() => {
        dispatch({
          type: GET_CERTIFICATIONS_FAILURE,
          payload: {
            error: 'Error'
          }
        });
      });
  };
};

export const editCertification = (data, id) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_CERTIFICATION_LOADING
    });
    const formData = new FormData();
    formData.append('certificates', JSON.stringify(data.certificates));
    formData.append('certFile', data.certFile);
    formData.append('customTechName', data.certificates.customTechName);
    axios
      .put(process.env.REACT_APP_GEMBOOK_SVC_URL + '/certificates', formData, {
        headers: {
          accept: '*/*'
        },
        params: {
          certificateId: parseInt(id),
          techTypeId: parseInt(data.certificates.tech.techTypeId)
        }
      })
      .then((res) => {
        dispatch({
          type: EDIT_CERTIFICATION_SUCCESS,
          payload: {
            certifications: res.data
          }
        });
        toast.success('Certificate updated successfully!');
      })
      .catch(() => {
        dispatch({
          type: EDIT_CERTIFICATION_FAILURE,
          payload: {
            error: 'Error'
          }
        });
      });
  };
};

export const addCertification = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_CERTIFICATION_LOADING
    });

    const formData = new FormData();
    formData.append('certificatesDetails', JSON.stringify(data.certificates));
    formData.append('employeeId', localStorage.getItem('id'));
    formData.append('certFile', data.certFile);

    return axios.post(process.env.REACT_APP_GEMBOOK_SVC_URL + '/certificates', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*'
      }
    });
  };
};

export const deleteCertification = (id) => {
  return (dispatch) => {
    axios
      .delete(process.env.REACT_APP_GEMBOOK_SVC_URL + '/certificates', {
        headers: {
          accept: '*/*'
        },
        params: {
          certificateId: id
        }
      })
      .then((response) => {
        dispatch({
          type: DELETE_CERTIFICATION
        });
        toast.success(response.data);
        dispatch(getCertification());
      })
      .catch((error) => {
        dispatch({
          type: DELETE_CERTIFICATION,
          payload: {
            error: error.message
          }
        });
        toast.error('Error: ' + error.message);
      });
  };
};

export const getCertiTechTypes = () => {
  return (dispatch) => {
    dispatch({
      type: GET_TECH_TYPES_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/tech-type', {
        headers: {
          accept: '*/*'
        }
      })
      .then((res) => {
        dispatch({
          type: GET_TECH_TYPES_SUCCESS,
          payload: {
            techTypes: res.data
          }
        });
      })
      .catch(() => {
        dispatch({
          type: GET_TECH_TYPES_FAILURE,
          payload: {
            error: 'Error'
          }
        });
      });
  };
};
