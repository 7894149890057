import React, { useEffect } from 'react';

import { getSearchedProfileDetail } from '../../../../../redux/actionCreators/profile.actionCreator';

import p from './searched-people.module.scss';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoaderAnimatedIcon from '../../../../../assets/icons/loader-ellipse-animated.svg';
import notFound from '../../../../../assets/img/peopleNotFound.svg';

import PeopleList from './peopleList';

const SearchedPeople = ({
  searchValue,
  profileDetail,
  details,
  tag,
  handlePostContent,
  postContent,
  emails
}) => {
  const errorMessage = useSelector((state) => state.searchedProfileReducer.error);

  useEffect(() => {
    profileDetail(searchValue);
    if (!tag) {
      window.scrollTo(0, 0);
    }
  }, [searchValue]);

  const RenderPeopleList = ({ type, options }) => {
    switch (type) {
      case 'people':
        return (
          <>
            <div className={p.heading}>Top Results</div>
            <PeopleList
              people={options}
              tag={tag}
              handlePostContent={handlePostContent}
              postContent={postContent}
              emails={emails}
            />
          </>
        );
      case 'error':
        return (
          <div className={options.searchError}>
            {!errorMessage ? (
              <img src={LoaderAnimatedIcon} alt="loader" />
            ) : (
              <div className={p.error}>
                {!tag && <img src={notFound} />}
                <strong className={p.postErrorMessage}>No Records Found.</strong>
                {!tag && (
                  <span>
                    There was no records based on the details you've entered. Please try again.
                  </span>
                )}
              </div>
            )}
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={tag ? p.tagPeople : p.people}>
      {details.length ? (
        <RenderPeopleList type="people" options={details} />
      ) : (
        <RenderPeopleList type="error" options={p} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.searchedProfileReducer.loading,
  error: state.searchedProfileReducer.error,
  details: state.searchedProfileReducer.details
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      profileDetail: getSearchedProfileDetail
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchedPeople);
