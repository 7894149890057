import React, { useCallback, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';

// Stylesheet
import '../../assets/scss/styles.scss';

// Pages
import Home from './components/home/index';

// Layout
import Navbar from '../../layouts/layout1/navbar/index';
import Footer from '../../layouts/layout1/footer/index';

// Scss
import s from './dashboard.module.scss';

import { bindActionCreators } from 'redux';
import { getClubDetailsWithClubId, getClubs } from '../../redux/actionCreators/clubs.actionCreator';
import useCheckJwtExpiration from '../../hooks/useCheckJwtExpiration';
import { useMediaQuery } from 'react-responsive';

const Dashboard = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [toggle, setToggle] = useState(!isMobile);
  const [inputChangeData, setInputChangeData] = useState({ searchValue: '', isPost: false });
  const [inputVal, setInputVal] = useState('');
  const [showRightWrapperMobile, setShowRightWrapperMobile] = useState(false);
  const [activeMobileActivity, setActiveMobileActivity] = useState('');
  const id = 'id' in props.match.params ? props.match.params.id : null;
  const tag = 'tag' in props.match.params ? props.match.params.tag : null;
  const dispatch = useDispatch();

  const onChange = (value, isPost) => {
    setInputChangeData({ searchValue: value, isPost: isPost });
  };

  useEffect(() => {
    setToggle(!isMobile);
    props.getClubs();
  }, [isMobile]);

  useEffect(() => {
    dispatch(getClubDetailsWithClubId());
  }, []);

  const useJwtExpiration = useCheckJwtExpiration();
  const debouncedOnChange = useCallback(_.debounce(onChange, 500), [onChange]);
  return (
    <div className={s.dashboard}>
      <section className={s.mainSection}>
        <div className="d-flex flex-column w-100">
          <Navbar
            setToggle={setToggle}
            toggle={toggle}
            profileDetails={props.profileDetails}
            onChange={debouncedOnChange}
            inputVal={inputVal}
            setInputVal={setInputVal}
            showRightWrapperMobile={showRightWrapperMobile}
            setShowRightWrapperMobile={setShowRightWrapperMobile}
            activeMobileActivity={activeMobileActivity}
            setActiveMobileActivity={setActiveMobileActivity}
          />
          <Home
            inputChangeData={inputChangeData}
            id={id}
            tag={tag}
            showRightWrapperMobile={showRightWrapperMobile}
            setShowRightWrapperMobile={setShowRightWrapperMobile}
            activeMobileActivity={activeMobileActivity}
            setActiveMobileActivity={setActiveMobileActivity}
            toggle={toggle}
            setToggle={setToggle}
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    profileDetails: state.profileReducer.details,
    clubs: state.clubsReducer.clubs
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getClubs: getClubs
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
