import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import p from './searched-people.module.scss';
import { getEmpIdByEmail } from '../../../../../redux/actionCreators/profile.actionCreator';
import { uniqueId } from 'lodash';
import user from '../../../../../assets/img/user.png';

class PeopleList extends Component {
  state = {
    allpeople: [],
    people: [],
    hasMore: true,
    curpage: 0,
    pagesize: 10,
    totalPage: 0,
    total: 0
  };
  getProfile(id) {
    let email = localStorage.getItem('email');
    let url = '';
    if (id === email) {
      url = window.location.origin + `/#/profile/`;
    } else {
      let data = {
        email: id
      };
      getEmpIdByEmail(data);
      url = window.location.origin + `/#/profile/${id}`;
    }
    window.location.href = url;
    window.location.reload();
  }
  componentWillMount() {
    let curpage = this.state.curpage;
    let people = this.props.people.slice(
      curpage * this.state.pagesize,
      (curpage + 1) * this.state.pagesize
    );
    this.setState({
      allpeople: this.props.people,
      people: people,
      total: this.props.people.length,
      totalPage: Math.ceil(this.props.people.length / this.state.pagesize)
    });
  }
  loadmoreItem() {
    if (this.state.curpage + 1 < this.state.totalPage) {
      let curpage =
        this.state.curpage < this.state.totalPage ? this.state.curpage + 1 : this.state.curpage;
      let people = this.state.allpeople.slice(0, (curpage + 1) * this.state.pagesize);
      this.setState({ people: people, curpage });
    } else {
      this.setState({ hasMore: false });
    }
  }
  handleGetProfile = (profile) => {
    this.getProfile(profile.email);
  };

  handleTagProfile = (user) => {
    this.props.handlePostContent(user);
  };

  render() {
    return (
      <div>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => this.loadmoreItem()}
          hasMore={this.state.hasMore}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
          threshold={200}
        >
          <div className={this.props.tag ? p.tagList : p.peopleList}>
            {this.state.people.map((profile) => {
              profile.unique_id = uniqueId();
              return (
                <React.Fragment key={profile.unique_id}>
                  {!this.props.emails?.includes(profile.email) && (
                    <div
                      style={{
                        display:
                          !this.props.tag || profile.email !== localStorage.getItem('email')
                            ? ''
                            : 'none'
                      }}
                      className={this.props.tag ? p.tagMedia : p.media}
                    >
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => (this.props.tag ? null : this.getProfile(profile.email))}
                        referrerPolicy="no-referrer"
                        className={'mr-3 ' + p.image}
                        src={profile?.photoUrl ? profile.photoUrl : user}
                        width={90}
                        height={90}
                        alt="profile"
                      />
                      <div
                        className={p.mediaBody}
                        onClick={() =>
                          this.props.tag
                            ? this.handleTagProfile(profile)
                            : this.handleGetProfile(profile)
                        }
                      >
                        <div className={p.name}>{profile.name}</div>
                        <div className={p.details}>
                          <div className={p.email}>{profile.email} </div>
                          <div>{profile.designation}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}
export default PeopleList;
