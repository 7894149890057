import { GOOGLE_OAUTH2_ERROR, GOOGLE_OAUTH2_SUCCESS } from '../../actions/actions';

const getTokenId = () => {
  return localStorage.getItem('googleResponse')
    ? localStorage.getItem('googleResponse').tokenId
    : null;
};

let initialState = {
  tokenId: getTokenId(),
  isLoggedIn: getTokenId() !== null ? true : false
};

const googleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GOOGLE_OAUTH2_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        tokenId: action.payload.googleResponse.tokenId
      };
    case GOOGLE_OAUTH2_ERROR: {
      return {
        ...state,
        isLoggedIn: false,
        tokenId: null
      };
    }

    default:
      return { ...state };
  }
};

export default googleReducer;
