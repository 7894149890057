import React from 'react';
import RightWrapper from './rightWrapper';
import TopSection from './topSection.js';
import AboutPage from './aboutPage.js';
import { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import {
  getClubDetailsWithClubId,
  getClubMembers,
  getRequests
} from '../../../../../../redux/actionCreators/clubs.actionCreator';
import { connect, useDispatch } from 'react-redux';

//scss
import s from './clubPage.module.scss';

//Helper
import Discussion from './Discussion';
import { Skeleton } from '@material-ui/lab';
import EventsCard from './eventsCard';
import EventsPage from './eventsPage';
import EventsPageForSingleEvent from './eventsPageForSingleEvent.js';
import { getEventsById } from '../../../../../../redux/actionCreators/event.actionCreator.js';

//Icon
import backIconBlue from '../../../../../../assets/icons/backIconBlue.svg';
import { useMediaQuery } from 'react-responsive';

const ClubPage = React.memo(({ data, clubIdSelected, loadingState, dataLoading, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState('about');
  const [members, setMembers] = useState([]);
  const [viewRightWrapper, setViewRightWrapper] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [coverImg, setCoverImg] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    window.location.hash.includes(clubIdSelected) && props.getClubMembers(clubIdSelected);
  }, [clubIdSelected]);

  useEffect(() => {
    window.location.hash.includes(data.clubId) && setMembers([...props.membersDetails]);
    return () => {
      setMembers([]);
    };
  }, [props.membersDetails.length, data.clubId]);
  useEffect(() => {
    data.name === 'Tech Titans' && props.getRequests(data.clubId);
  }, [data.clubId]);

  useEffect(() => {
    dispatch(getClubDetailsWithClubId());
  }, []);

  const redirectHomepage = () => {
    const url = window.location.origin + '/#/dashboard';
    window.open(url, '_self');
  };

  const eventId = window.location.href.includes('/event/') && window.location.href.split('/').pop();
  useEffect(() => {
    if (eventId && !isMobile) {
      setSelectedTab('events');
      dispatch(getEventsById(eventId));
      dispatch(getClubDetailsWithClubId());
    }
  }, [isMobile]);
  const handleClick = () => {
    const url = window.location.origin + '/#/clubs';
    window.open(url, '_self');
  };
  const Desktop = () => {
    return (
      <>
        <div className={s.wrapper}>
          {eventId && (
            <div className="w-100 d-flex justify-content-center">
              <button className={s.goBackBtn} onClick={redirectHomepage}>
                <img src={backIconBlue} alt="back Icon" />
                Go to News Feed
              </button>
            </div>
          )}
          <div className={s.subHeading}>
            {!dataLoading ? (
              <span onClick={() => handleClick()}>
                All Clubs {'>'} <span>{data.name}</span>
              </span>
            ) : (
              <Skeleton variant="text" width={200} />
            )}
          </div>
          <div className={s.insideWrapper}>
            <div className={s.leftWrapper}>
              {data ? (
                <TopSection
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  data={data}
                  membersData={props.membersDetails}
                  setViewRightWrapper={setViewRightWrapper}
                  clubIdSelected={clubIdSelected}
                  loadingState={loadingState}
                  clubName={data.name}
                  clubId={data.clubId}
                  profileImg={profileImg}
                  setProfileImg={setProfileImg}
                  coverImg={coverImg}
                  setCoverImg={setCoverImg}
                />
              ) : (
                <Skeleton variant="rect" height="16rem" width="100%" />
              )}
              {selectedTab === 'about' && (
                <AboutPage
                  data={data}
                  membersData={props.membersDetails}
                  dataLoading={dataLoading}
                />
              )}
              {selectedTab === 'discuss' && (
                <Discussion
                  clubData={{ name: data.name, id: clubIdSelected, imgs: [profileImg, coverImg] }}
                  clubId={clubIdSelected}
                  membersDetails={props.membersDetails}
                />
              )}
              {selectedTab === 'events' && (
                <>
                  {!eventId ? (
                    <EventsPage
                      clubName={data.name}
                      members={members}
                      users={Object.values(props.users)}
                    />
                  ) : (
                    <EventsPageForSingleEvent
                      clubName={data.name}
                      members={members}
                      users={Object.values(props.users)}
                    />
                  )}
                </>
              )}
            </div>
            <div className={s.rightWrapper}>
              <RightWrapper
                setViewRightWrapper={setViewRightWrapper}
                membersData={members}
                clubName={data.name}
                clubId={clubIdSelected}
                getClubMembers={props.getClubMembers}
                profileImg={profileImg}
                loading={props.loading}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const Mobile = () => {
    return (
      <div className={s.mobileWrapper}>
        {!viewRightWrapper && (
          <TopSection
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setViewRightWrapper={setViewRightWrapper}
            membersData={props.membersDetails}
            data={data}
            clubName={data.name}
            clubIdSelected={clubIdSelected}
            loadingState={loadingState}
            clubId={data.clubId}
            profileImg={profileImg}
            setProfileImg={setProfileImg}
            coverImg={coverImg}
            setCoverImg={setCoverImg}
          />
        )}
        {!viewRightWrapper && selectedTab === 'about' && (
          <AboutPage data={data} membersData={props.membersDetails} />
        )}
        {!viewRightWrapper && selectedTab === 'discuss' && (
          <Discussion
            clubData={{ name: data.name, id: clubIdSelected, imgs: [profileImg, coverImg] }}
            clubId={clubIdSelected}
            membersDetails={props.membersDetails}
          />
        )}
        {!viewRightWrapper && selectedTab === 'events' && (
          <EventsCard data={data} membersData={props.membersDetails} />
        )}
        {viewRightWrapper && (
          <RightWrapper
            setViewRightWrapper={setViewRightWrapper}
            membersData={props.membersDetails}
            clubName={data.name}
            clubId={data.clubId}
            getClubMembers={props.getClubMembers}
          />
        )}
      </div>
    );
  };

  return <>{isMobile ? <Mobile /> : <Desktop />}</>;
});

const mapStateToProps = (state) => {
  return {
    loading: state.clubsReducer.loading,
    error: state.clubsReducer.error,
    membersDetails: state.clubsReducer.membersDetails,
    profileDetails: state.profileReducer.details,
    events: state.eventReducer.events,
    pastEvents: state.eventReducer.pastEvents,
    users: state.userReducer.users
  };
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClubMembers: getClubMembers,
      getRequests: getRequests
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubPage);
