import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useEffect } from 'react';
import {
  addEducationDetails,
  deleteEducationDetails,
  getEducationDetails,
  updateEducationDetails
} from '../../../../../redux/actionCreators/profile/education.actionCreator';
import s from './education.module.scss';
import { TOOLTIP_MSSG } from '../../../../../helpers';
import { Tooltip } from '@material-ui/core';
import addIcon from '../../../../../assets/icons/addIcon.svg';
import educationLogo from '../../../../../assets/icons/Edcucation.svg';
import editpost from '../../../../../assets/icons/editpost.svg';
import deleteBin from '../../../../../assets/icons/delete-bin.svg';
import save from '../../../../../assets/icons/saveDetails.png';
import close from '../../../../../assets/icons/close.svg';
import { toast } from 'react-toastify';
import { Skeleton } from '@material-ui/lab';
import cns from 'classnames';

const Education = ({ ...props }) => {
  const [addDetails, setAddDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [collegeName, setCollegeName] = useState('');
  const [degree, setDegree] = useState('');
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');
  const [inputErrors, setInputErrors] = useState({
    collegeName: false,
    degree: false,
    startYear: false,
    endYear: false
  });

  const [educationDetailsVar, setEducationDetailsVar] = useState(props?.educationDetails ?? '');

  const handleSave = async () => {
    const id = localStorage.getItem('id');

    // Check if any field is empty
    if (!collegeName || !degree || !startYear || !endYear) {
      setInputErrors({
        collegeName: !collegeName,
        degree: !degree,
        startYear: !startYear,
        endYear: !endYear
      });
      toast.error('Please fill in all fields before saving.');
      return;
    }

    // Check if the start and end years are valid numbers
    if (startYear.length !== 4 || endYear.length !== 4 || isNaN(startYear) || isNaN(endYear)) {
        setInputErrors({
            ...inputErrors,
            startYear: startYear.length !== 4 || isNaN(startYear),
            endYear: endYear.length !== 4 || isNaN(endYear)
        });
        toast.error('Invalid year format.');
        return;
    }

    // Parse years as integers
    const start = parseInt(startYear, 10);
    const end = parseInt(endYear, 10);

    // Validate year range
    if (start < 1900 || start > 2099 || end < 1900 || end > 2099) {
        toast.error('Please enter a valid year within a reasonable range.');
        return;
    }

    // Ensure the end year is greater than the start year and within a reasonable range
    if (end <= start || end - start > 10) {  // You can adjust the range as needed
        setInputErrors({
            ...inputErrors,
            endYear: true
        });
        toast.error('End Year must be greater than Start Year and within a reasonable range.');
        return;
    }

    // Ensure college and degree fields have valid lengths
    if (collegeName.length > 70 || degree.length > 50) {
      toast.error('Please Enter valid Details');
      return;
    }

    const educationData = {
      college: collegeName,
      degree: degree,
      fromYear: startYear,
      toYear: endYear
    };

    try {
      await props.addEducationDetails(educationData);
      toast.success('Education Details added successfully');
      await props.getEducationDetails(id);
    } catch (error) {
      toast.error('Please Enter valid Details');
    }
  };

  const handleChange = (e, idx, identifier) => {
    const updatedEducationDetails = [...educationDetailsVar];
    const editedEducationDetail = updatedEducationDetails[idx];

    if (identifier === 'toYear' || identifier === 'fromYear') {
      const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
      editedEducationDetail[identifier] = sanitizedValue;
    } else {
      editedEducationDetail[identifier] = e.target.value;
    }
    setEducationDetailsVar(updatedEducationDetails);
  };

  const handleUpdate = async () => {
    const id = localStorage.getItem('id');
    let errorOccurred = false;
  
    for (let i = 0; i < educationDetailsVar?.length; i++) {
      const updatedDetail = educationDetailsVar[i];
      const { college, degree, fromYear, toYear } = updatedDetail;
  
      // Check if any field is empty
      if (!college || !degree || !fromYear || !toYear) {
        setInputErrors({
          collegeName: !college,
          degree: !degree,
          startYear: !fromYear,
          endYear: !toYear,
        });
        toast.error('Please fill in all fields before saving.');
        errorOccurred = true;// Set the error flag to true
        break;// Exit the loop
      }
  
      // Convert year inputs to string before checking length
      const fromYearStr = String(fromYear);
      const toYearStr = String(toYear);
  
      // Check if the start and end years are valid 4-digit numbers
      if (fromYearStr.length !== 4 || toYearStr.length !== 4 || isNaN(Number(fromYear)) || isNaN(Number(toYear))) {
        setInputErrors({
          ...inputErrors,
          startYear: fromYearStr.length !== 4 || isNaN(Number(fromYear)),
          endYear: toYearStr.length !== 4 || isNaN(Number(toYear)),
        });
        toast.error('Invalid year format.');
        errorOccurred = true;
        break;
      }
  
      // Parse years as integers
      const start = parseInt(fromYear, 10);
      const end = parseInt(toYear, 10);
  
      // Validate year range
      if (start < 1900 || start > 2099 || end < 1900 || end > 2099) {
        toast.error('Please enter a valid year within a reasonable range.');
        errorOccurred = true;
        break;
      }
  
      // Ensure the end year is greater than the start year and within a reasonable range
      if (end <= start || end - start > 10) {
        setInputErrors({
          ...inputErrors,
          endYear: true,
        });
        toast.error('End Year must be greater than Start Year and within a reasonable range.');
        errorOccurred = true;
        break;
      }
  
      // Ensure college and degree fields have valid lengths
      if (college.length > 70 || degree.length > 50) {
        toast.error('Please Enter valid Details');
        errorOccurred = true;
        break;
      }
  
      // If validation passes, update the details
      const educationData = {
        college: college,
        degree: degree,
        fromYear: fromYear,
        toYear: toYear,
      };
  
      try {
        await props.updateEducationDetails(updatedDetail.educationId, educationData);
      } catch (error) {
        toast.error('Error updating education details.');
        errorOccurred = true;// Set the error flag to true
        break;// Exit the loop
      }
    }
  
    if (!errorOccurred) {
      setEditDetails(false);
      await props.getEducationDetails(id);
      toast.success('Education Details updated successfully');
    }
  };
  

  const handleDelete = (id) => {
    if (educationDetailsVar?.length) {
      props.deleteEducationDetails(id);
    } else {
      setEditDetails(false);
    }
  };

  const handleCloseEdit = () => {
    setEditDetails(false);
    props.getEducationDetails(localStorage.getItem('id'));
  };

  const handleCloseAdd = () => {
    setCollegeName('');
    setDegree('');
    setStartYear('');
    setEndYear('');
    setAddDetails(!addDetails);
    setInputErrors({ collegeName: false, degree: false, startYear: false, endYear: false });
  };

  return (
    <div>
      <div className={s.heading}>
        <h5>Education</h5>
        {props.educationDetails.length ? (
          <div className={s.buttons}>
            {editDetails ? (
              <>
                <button onClick={handleUpdate} className={cns(s.saveButton, 'mr-2')}>
                  <Tooltip title={TOOLTIP_MSSG.SAVE}>
                    <img src={save} alt="save Icon" />
                  </Tooltip>
                </button>
                <button onClick={handleCloseEdit} className={s.saveButton}>
                  <Tooltip title="Click to Close">
                    <img src={close} alt="close Icon" />
                  </Tooltip>
                </button>
              </>
            ) : addDetails ? (
              ''
            ) : (
              <>
                <div className={s.addButton}>
                  <button onClick={() => setAddDetails(!addDetails)}>
                    <Tooltip title={TOOLTIP_MSSG.ADD}>
                      <img className={s.addButton} src={addIcon} alt="add Button" />
                    </Tooltip>
                  </button>
                </div>
                <div className={s.editIcon}>
                  <button className={s.editBtn} onClick={() => setEditDetails(true)}>
                    <Tooltip title={TOOLTIP_MSSG.EDIT}>
                      <img src={editpost} alt="edit post Icon" width={18} height={18} />
                    </Tooltip>
                  </button>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className={s.editIcon}>
            <button className={s.addButton} onClick={() => setAddDetails(!addDetails)}>
              <Tooltip title={TOOLTIP_MSSG.ADD}>
                <img src={addIcon} alt="add Icon" />
              </Tooltip>
            </button>
          </div>
        )}
      </div>
      <div className={s.detailsContainer}>
        {props.getLoading ? (
          <div className={s.containerLoader}>
            <div>
              <Skeleton variant="circle" className={s.circleLoader} />
            </div>
            <div className={s.detailsLoadingContainer}>
              <Skeleton variant="text" className={s.textLoad} />
              <Skeleton variant="text" className={s.textLoad} />
              <Skeleton variant="text" className={s.textLoad} />
            </div>
          </div>
        ) : props.educationDetails.length ? (
          <>
            {editDetails ? (
              educationDetailsVar.map((details, idx) => (
                <div className={s.inputWrapper} key={idx}>
                  <div className={s.innerInputWrapper}>
                    <input
                      placeholder="College Name"
                      className={
                        (inputErrors.collegeName && details?.college === '') ||
                        details.college.length > 70
                          ? s.inputError
                          : s.collegeName
                      }
                      value={details?.college}
                      onChange={(e) => handleChange(e, idx, 'college')}
                      name="college"
                      type="text"
                      maxLength={71}
                    />
                  </div>
                  {details.college.length > 70 && (
                    <p className={s.errorMsg}>College Name should not be more than 70 characters</p>
                  )}
                  <div className={s.innerInputWrapper}>
                    <input
                      placeholder="Degree"
                      className={
                        (inputErrors.degree && details?.degree === '') || details.degree.length > 50
                          ? s.inputError
                          : s.collegeName
                      }
                      name="degree"
                      type="text"
                      value={details?.degree}
                      onChange={(e) => handleChange(e, idx, 'degree')}
                      maxLength={51}
                    />
                  </div>
                  {details.degree.length > 50 && (
                    <p className={s.errorMsg}>Degree should not be more than 50 characters</p>
                  )}
                  <div className={s.innerInputWrapper}>
                    <input
                      placeholder="Start Year"
                      className={
                        inputErrors.startYear &&
                        (details?.fromYear === '' ||
                          parseInt(details?.fromYear) < 1900 ||
                          parseInt(details?.fromYear) > 2099)
                          ? s.inputYearError
                          : s.year
                      }
                      name="startYear"
                      value={details?.fromYear}
                      onChange={(e) => handleChange(e, idx, 'fromYear')}
                      type="text"
                      maxLength={4}
                    />
                    <input
                      placeholder="End Year"
                      className={
                        inputErrors.endYear && details?.toYear === '' ? s.inputYearError : s.year
                      }
                      value={details?.toYear}
                      onChange={(e) => handleChange(e, idx, 'toYear')}
                      name="endYear"
                      type="text"
                      maxLength={4}
                    />
                    <Tooltip title="Click to Delete">
                      <button onClick={() => handleDelete(details?.educationId)}>
                        <img src={deleteBin} height={24} width={24} alt="delete Icon" />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              ))
            ) : (
              <>
                {props.educationDetails.map((data, index) => {
                  return (
                    <div key={index} className={s.detailsWrapper}>
                      <img src={educationLogo} className={s.logo} alt="Add icon" />
                      <div className={s.details}>
                        <p className={s.college}>{data.college}</p>
                        <p className={s.degree}>{data.degree}</p>
                        <p className={s.year}>
                          {data.fromYear} - {data.toYear}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        ) : !addDetails ? (
          <i className="text-secondary">No Education Details added.</i>
        ) : (
          ''
        )}
        {addDetails ? (
          <>
            {props.educationDetails.length ? <hr /> : ''}
            <div className={s.newEducationCard}>
              <div className={s.addEducationHeading}>
                ADD NEW EDUCATION
                <div className={s.buttonContainer}>
                  <button height={23} className={cns(s.saveButton, 'mr-2')} onClick={handleSave}>
                    <Tooltip title={TOOLTIP_MSSG.SAVE}>
                      <img alt="save Button" src={save} />
                    </Tooltip>
                  </button>

                  <button height={23} className={s.saveButton} onClick={handleCloseAdd}>
                    <Tooltip title="Click to Close">
                      <img alt="close Button" src={close} />
                    </Tooltip>
                  </button>
                </div>
              </div>
              <div className={s.inputWrapper}>
                <div className={s.innerInputWrapper}>
                  <input
                    autoFocus
                    autoComplete="off"
                    placeholder="College Name"
                    className={
                      collegeName.length > 70 || (inputErrors.collegeName && collegeName.length < 1)
                        ? s.inputError
                        : s.collegeName
                    }
                    value={collegeName}
                    onChange={(e) => setCollegeName(e.target.value)}
                    name="college"
                    type="text"
                    maxLength={71}
                  />
                </div>
                {collegeName.length > 70 && (
                  <p className={s.errorMsg}>College Name should not be more than 70 characters</p>
                )}

                <div className={s.innerInputWrapper}>
                  <input
                    autoComplete="off"
                    placeholder="Degree"
                    className={
                      degree.length > 50 || (inputErrors.degree && degree.length < 1)
                        ? s.inputError
                        : s.collegeName
                    }
                    name="degree"
                    type="text"
                    value={degree}
                    onChange={(e) => setDegree(e.target.value)}
                    maxLength={51}
                  />
                </div>
                {degree.length > 50 && (
                  <p className={s.errorMsg}>Degree should not be more than 50 characters</p>
                )}
                <div className={s.innerInputWrapper}>
                  <input
                    placeholder="Start Year"
                    className={
                      (inputErrors.startYear && startYear.length < 1) ||
                      parseInt(startYear) < 1900 ||
                      parseInt(startYear) > 2099
                        ? s.inputYearError
                        : s.year
                    }
                    name="startYear"
                    value={startYear}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                      setStartYear(sanitizedValue);
                    }}
                    type="text"
                    min={1900}
                    max={2099}
                    maxLength={4}
                  />
                  <input
                    placeholder="End Year"
                    className={
                      (inputErrors.endYear && endYear.length < 1) ||
                      parseInt(endYear) < 1900 ||
                      parseInt(endYear) > 2099
                        ? s.inputYearError
                        : s.year
                    }
                    value={endYear}
                    maxLength={4}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                      setEndYear(sanitizedValue);
                    }}
                    name="endYear"
                    type="text"
                    min={1900}
                    max={2099}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    educationDetails: state.educationReducer.educationDetails,
    getLoading: state.educationReducer.getLoading
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEducationDetails: getEducationDetails,
      addEducationDetails: addEducationDetails,
      updateEducationDetails: updateEducationDetails,
      deleteEducationDetails: deleteEducationDetails
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Education);
