import React, { useEffect, useState } from 'react';
import s from '../marketplace.module.scss';
import PostCardComment from '../PostCommon/PostCardComment';
import PostCardLike from '../PostCommon/PostCardLike';
import { useParams } from 'react-router-dom';
import BuySellBody from './BuySellBody';
import cns from 'classnames';
import destinationIcon from '../../../../src/assets/img/marketPlace/destinationIcon.svg';
import dateOfBookingIcon from '../../../../src/assets/img/marketPlace/dateOfCarBookingIcon.svg';
import dropLocation from '../../../../src/assets/img/marketPlace/dropLocation.svg';
import carPoolBar from '../../../../src/assets/img/marketPlace/carPoolBar.svg';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';

// Styles for border removal
const useStyles = makeStyles({
  accordion: {
    border: 'none',
    boxShadow: 'none'
  },
  summary: {
    '&.Mui-expanded': {
      borderBottom: 'none'
    }
  }
});

const CarPool = ({
  data1,
  setData1,
  usersProfile,
  post,
  likePost,
  userData,
  users,
  deleteComment,
  addLoading,
  getEmpIdByEmail,
  commentInputRef,
  likeComment,
  addComment,
  deleteMarketPost,
  getValue,
  pageNum,
  setFinalData,
  finalData,
  currentEditedPost,
  setCurrentEditedPost,
  setCurrentIndex,
  postData,
  user,
  loading,
  postType = 'all',
  selectedSortOption,
  ...props
}) => {
  let carPoolPosts = {};
  const classes = useStyles();
  if (!postData?.postId && postData !== null && postData) {
    carPoolPosts = Object.fromEntries(
      Object.entries(postData).map(([key, value]) =>
        key === 'poolId' ? ['postId', value] : [key, value]
      )
    );
  }
  const [expand, setExpand] = useState(false);
  const [timestampArray, setTimestampArray] = useState([]);
  const params = useParams();

  const [newReactions, setNewReactions] = useState(post?.reactions ?? []);

  const dateArray = carPoolPosts?.postedOn;
  const dateObject =
    carPoolPosts?.postedOn &&
    new Date(
      Date.UTC(
        dateArray[0],
        dateArray[1] - 1,
        dateArray[2],
        dateArray[3],
        dateArray[4],
        dateArray[5],
        Math.floor(dateArray[6] / 1000000)
      )
    ).getTime();

  const dateArray1 = carPoolPosts?.updateDate;
  const dateObject1 =
    carPoolPosts?.updateDate &&
    new Date(
      Date.UTC(
        dateArray1[0],
        dateArray1[1] - 1,
        dateArray1[2],
        dateArray1[3],
        dateArray1[4],
        dateArray1[5],
        Math.floor(dateArray1[6] / 1000000)
      )
    ).getTime();

  useEffect(() => {
    if (props?.updatedReaction && props?.updatedReaction?.postId === postData?.poolId) {
      setNewReactions(props?.updatedReaction?.data);
    }
  }, [props?.updatedReaction]);

  useEffect(() => {
    if (post.carPool?.postedOn && post.carPool?.postedOn != null && dateObject === dateObject1)
      setTimestampArray(post.carPool?.postedOn);
    if (post.carPool?.updateDate && post.carPool?.updateDate != null && dateObject !== dateObject1)
      setTimestampArray(post.carPool?.updateDate);
  }, [post.carPool?.postedOn, post.carPool?.updateDate]);

  const timestampInMilliseconds = new Date(
    timestampArray[0], // Year
    timestampArray[1] - 1, // Month (months are 0-based in JavaScript)
    timestampArray[2], // Day
    timestampArray[3], // Hours
    timestampArray[4], // Minutes
    timestampArray[5], // Seconds
    timestampArray[6] / 1000 // Milliseconds (converted to seconds)
  )?.getTime();
  let isEdited = timestampInMilliseconds;

  function formatDepartureDateTime(departureDateTime) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    const year = departureDateTime[0];
    const month = months[departureDateTime[1] - 1];
    const day = departureDateTime[2];
    const hour = departureDateTime[3];
    const minute = departureDateTime[4];
    const dayOfWeek = daysOfWeek[new Date(year, departureDateTime[1] - 1, day).getDay()];
    const timeZone = 'IST';

    return `${dayOfWeek}, ${day} ${month} | ${hour}:${
      minute < 10 ? '0' + minute : minute
    } ${timeZone}`;
  }

  function formatDuration(durationInSeconds) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  }

  function calculateDuration(deptTime, destTime) {
    const deptDate = new Date(...deptTime);
    const destDate = new Date(...destTime);
    const durationInSeconds = Math.abs((destDate - deptDate) / 1000);
    return formatDuration(durationInSeconds);
  }

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  useEffect(() => {
    if (params?.id) {
      toggleExpand();
    }
  }, [params?.id]);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  return (
    <>
      <div className={s.post}>
        <div className={s.postBody}>
          <BuySellBody
            data1={data1}
            setData1={setData1}
            users={users}
            time={isEdited}
            isEdited={dateObject === dateObject1 ? '' : 'Edited'}
            post={carPoolPosts}
            deleteMarketPost={deleteMarketPost}
            postId={post.poolId ? post.poolId : post.postId}
            setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
            getValue={getValue}
            pageNum={pageNum}
            setFinalData={setFinalData}
            finalData={finalData}
            currentEditedPost={currentEditedPost}
            userWithoutFilterData={userData}
            user={carPoolPosts}
            setCurrentIndex={setCurrentIndex}
            loading={loading}
            postType={postType}
            selectedSortOption={selectedSortOption}
          />
        </div>
        <div className={cns(s.postContent, 'container-fluid')}>
          {postData?.carPoolType === 'DRIVER_POST' && (
            <div
              className={
                postData?.status === 'AVAILABLE' ? s.carPoolCardCont : s.carPoolCardContInactive
              }
            >
              <div style={{ position: 'absolute', right: '0' }}>
                <div className={s.imageContainerPassenger}>Looking for Passengers </div>
              </div>
              <div className="d-flex justify-content-between  md:w-75 pr-5">
                <div
                  className="font-weight-bold pb-2"
                  style={{ wordBreak: 'break-word', width: '75%' }}
                >
                  {capitalizeFirstLetter(postData?.title)}
                </div>
              </div>
              <div className={s.carPoolCardSubCont}>
                <div>
                  <div className="d-flex pb-1">
                    <div className={s.carPoolIcon}>
                      <img src={destinationIcon} />
                    </div>
                    <div className={s.textHead}>Departure</div>
                  </div>

                  <div className="d-flex pb-1">
                    <div className={s.carPoolIcon}>
                      <img src={dropLocation} style={{height:'35px'}}/>
                    </div>
                    <div className={s.textLoc}>
                      {capitalizeFirstLetter(postData?.departLocation)}
                    </div>
                  </div>

                  <div className="d-flex pb-1">
                    <div className={s.carPoolIcon}>
                      <img src={dateOfBookingIcon} />
                    </div>
                    <div className={s.textTime}>
                      {postData?.departDateTime !== null &&
                        formatDepartureDateTime(postData?.departDateTime)}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex"></div>
                </div>
                <div>
                  <div className="d-flex">
                    <div className={s.carPoolIcon}>
                      <img src={destinationIcon} />
                    </div>
                    <div className={s.textHead}>Destination</div>
                  </div>
                  <div className="d-flex">
                    <div className={s.carPoolIcon}>
                      <img src={dropLocation} style={{height:'35px'}} />
                    </div>
                    <div className={s.textLoc}>
                      {' '}
                      {capitalizeFirstLetter(postData?.destLocation)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex pb-2">
                <div
                  className={postData?.status === 'AVAILABLE' ? s.chipStyle : s.chipStyleInactive}
                >
                  Available seats - {postData?.totalSeats ? postData?.totalSeats : 'NA'}
                </div>
                <div
                  className={postData?.status === 'AVAILABLE' ? s.chipStyle : s.chipStyleInactive}
                >
                  Price ₹{postData?.costPerSeat ? postData?.costPerSeat : 'NA'}
                </div>
                <div
                  className={postData?.status === 'AVAILABLE' ? s.chipStyle : s.chipStyleInactive}
                >
                  {postData?.vehicleInfo ? capitalizeFirstLetter(postData?.vehicleInfo) : 'NA'}
                </div>
              </div>

              {postData.additionalInfo !== '' && (
                <Accordion
                  className={classes.accordion}
                  style={{
                    backgroundColor: postData?.status !== 'AVAILABLE' ? '#D4D4D4' : 'transparent'
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                    className={s.textHead}
                  >
                    Additonal Information
                  </AccordionSummary>
                  <AccordionDetails className={s.textLoc}>
                    {' '}
                    {capitalizeFirstLetter(postData?.additionalInfo)}
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          )}
          {postData?.carPoolType === 'PASSENGER_POST' && (
            <div
              className={
                postData?.status === 'AVAILABLE' ? s.carPoolCardCont : s.carPoolCardContInactive
              }
            >
              <div style={{ position: 'absolute', right: '0' }}>
                <div className={s.imageContainerCarPoolRide}>Looking For Ride</div>
              </div>
              <div
                className="font-weight-bold pb-2 md:w-75 pr-2 md:pr-5"
                style={{ wordBreak: 'break-word', width: '75%' }}
              >
                {capitalizeFirstLetter(postData?.title)}
              </div>
              <div className="d-flex">
                <div className={s.textHead2}>Journey Date:</div>
                <div className={s.textTime2}>
                  {postData?.departDateTime !== null &&
                    formatDepartureDateTime(postData?.departDateTime)}
                </div>
              </div>
              <div className={s.subCardCont}>
                <img src={carPoolBar} className={s.carPoolProgress} />
                <div>
                  <div className="d-flex pb-1 px-3">
                    <div className={s.textHead2}>Departure:</div>
                    <div className="d-flex">
                      <div className="px-1">
                        <img src={dropLocation} style={{height:'25px'}}  />
                      </div>
                      <div className={s.textLoc2}>
                        {' '}
                        {capitalizeFirstLetter(postData?.departLocation)}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex px-3 pt-2">
                    <div className={s.textHead2}>Destination:</div>
                    <div className="d-flex">
                      <div className="px-1">
                        <img src={dropLocation} style={{height:'25px'}} />
                      </div>
                      <div className={s.textLoc2}>
                        {' '}
                        {capitalizeFirstLetter(postData?.destLocation)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={s.postFooter}>
            <div className=""></div>
            <PostCardLike
              users={users}
              likePost={likePost}
              likes={newReactions ?? []}
              comments={post.comments ?? post.comment ?? []}
              userReaction={post.reactions ? post.reactions : post.postReactions}
              toggleExpand={toggleExpand}
              postId={post.poolId ? post.poolId : post.postId}
            />
            {expand ? (
              <PostCardComment
                users={users}
                post={carPoolPosts}
                comments={post.comments ?? post.comment ?? []}
                deleteComment={deleteComment}
                addComment={addComment}
                addLoading={addLoading}
                postId={post.poolId ? post.poolId : post.postId}
                likeComment={likeComment}
                toggleExpand={toggleExpand}
                commentInputRef={commentInputRef}
                getEmpIdByEmail={getEmpIdByEmail}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    updatedReaction: state.postLikeReducer.marketPlaceUpdatedReaction
  };
}

export default connect(mapStateToProps, null)(CarPool);
