import React, { useContext } from 'react';

// MUI
import {
  Box,
  Button,
  Tab,
  Tabs,
  makeStyles,
  withStyles,
  Tooltip // Import Tooltip
} from '@material-ui/core';

// Other imports
import { useHistory } from 'react-router-dom';
// SCSS
import s from './filter.module.scss';
import { MyContext } from '../../context/MyContext';

const FilterHeader = ({ value, setValue }) => {
  const tabs = [{ label: 'Buy/Sell' }, { label: 'Flats/Rents' }, { label: 'Car Pool' }];
  const history = useHistory();
  const { marketplaceFilterBody, setMarketplaceFilterBody } = useContext(MyContext);

  const useStyles = makeStyles((theme) => ({
    customTabs: {
      '& .MuiTabs-indicator': {
        backgroundColor: '#38A3A5 !important'
      }
    }
  }));
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      fontFamily: 'Montserrat',
      fontWeight: 500
    }
  }))(Tooltip);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('tabValue', newValue);

    setMarketplaceFilterBody({
      isLookingOrLending: ['LOOKING', 'LENDING'],
      propertyTypes: ['APARTMENT', 'PG', 'INDEPENDENT_FLOOR', 'FULL_RESIDENCE'],
      numberOfBedrooms: [],
      furnishingTypes: ['FURNISHED', 'SEMI_FURNISHED', 'UNFURNISHED'],
      minPrice: 0,
      maxPrice: 100000,
      postAvailability: 'AVAILABLE',
      suitableFor: ['FAMILY', 'BACHELORS', 'ANY'],
      locations: [],
      landmarks: []
    });
  };

  const handleAddPostClick = () => {
    if (value === 1) {
      history.push('/marketplace/flat-renting-add-post');
    } else if (value === 2) {
      history.push('/marketplace/car-pool-add-post');
    }
  };

  const CustomTabs = withStyles({
    indicator: {
      backgroundColor: '#38A3A5',
      height: 2
    }
  })(Tabs);

  const getTooltipTitle = () => {
    switch (value) {
      case 1:
        return 'Add Flat Rent Post';
      case 2:
        return 'Add Carpool Post';
      default:
        return 'Add Buy Sell Post';
    }
  };

  return (
    <div
      className={`d-flex flex-column flex-lg-row justify-content-between py-4 w-100 ${s.fixedHeader}`}
    >
      <div className={s.customContainer}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            className={classes.customTabs}
            style={{
              backgroundColor: 'transparent',
              boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.4)'
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                value={index}
                style={{
                  flex: 1,
                  minWidth: 0,
                  maxWidth: `${100 / tabs.length}%`,
                  fontSize: value === index ? '15px' : '14px',
                  fontWeight: value === index ? 600 : 550,
                  color: value === index ? '#19191A' : 'rgba(25, 25, 26, 0.6)',
                  textTransform: 'none'
                }}
              />
            ))}
          </Tabs>
        </Box>
      </div>

      <div className="d-flex justify-content-end ml-4 my-auto ">
        {value !== 0 && (
          <div>
            <LightTooltip title={getTooltipTitle()} placement="bottom">
              <Button
                variant="contained"
                color="primary"
                className="px-5 py-2"
                style={{
                  backgroundColor: '#38A3A5',
                  font: 'white',
                  textTransform: 'none',
                  fontSize: '13px',
                  fontWeight: 500
                }}
                onClick={handleAddPostClick}
              >
                <span className="text-nowrap">Add Post</span>
              </Button>
            </LightTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterHeader;
