import React, { useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS

// icons
import edit from '../../../../../assets/icons/editpost.svg';
import Tooltip from '@material-ui/core/Tooltip';
import save from '../../../../../assets/icons/saveDetails.png';
import close from '../../../../../assets/icons/close.svg';
import addIcon from '../../../../../assets/icons/addIcon.svg';
// CSS
import s from './components.module.scss';
import cns from 'classnames';

//Helper
import Viewport from '../../../../helpers/viewport';
import { useSelector, useDispatch } from 'react-redux';
import { editTraining } from '../../../../../redux/actionCreators/profile/training.actionCreator';
import { TOOLTIP_MSSG } from '../../../../../helpers';
import { Skeleton } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';

const Training = () => {
  const dispatch = useDispatch();
  const trainingsDone = useSelector((state) => state?.profileReducer?.details?.trainingsDone);
  const loading = useSelector((state) => state?.profileReducer?.loading);
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [editFlag, setEditFlag] = useState(false);
  const [trainingInput, setTrainingInput] = useState(trainingsDone);
  let wrapper = useRef(null);

  const handleChange = (e) => {
    setTrainingInput(e.target.value);
  };

  const handleSave = () => {
    if (!trainingInput.trim()) {
      toast.warn('Training field cannot be empty!', { // Show a toast warning
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const data = {
      trainingsDone: trainingInput,
    };
    editTraining(data)(dispatch);
    setEditFlag(!editFlag);
  };
  const handleClose = () => {
    setTrainingInput(trainingsDone);
    setEditFlag(!editFlag);
  };

  return (
    <div className={cns(s.trainingWrapper, isMobile ? s.trainingResponsive : '')} ref={wrapper}>
      {editFlag ? (
        <div className={s.labelWrapper}>
          <div className={s.heading}>
            <h5>Training</h5>
            <div className={s.buttonContainer}>
              <Tooltip title={TOOLTIP_MSSG.SAVE}>
                <button onClick={handleSave}>
                  <img alt="save Icon" src={save} />
                </button>
              </Tooltip>
              <Tooltip title={TOOLTIP_MSSG.CLOSE}>
                <button onClick={handleClose}>
                  <img alt="close Icon" src={close} />
                </button>
              </Tooltip>
            </div>
          </div>
          <div className={s.description}>
            <Tooltip title="Insert comma to add multiple trainings">
              <input
                className={cns(s.formControl, s.editInput)}
                name="trainingsDone"
                onChange={handleChange}
                value={trainingInput}
                type="text"
                id="trainingsDone"
              />
            </Tooltip>
          </div>
        </div>
      ) : (
        <>
          <div className={cns(s.trainingWrapper, isMobile ? s.trainingResponsive : '')}>
            <div className={s.heading}>
              <h5>Trainings</h5>
              {trainingInput?.length ? (
                <Tooltip title={TOOLTIP_MSSG.EDIT}>
                  <div className={s.editIcon}>
                    <button onClick={() => setEditFlag(!editFlag)}>
                      <img src={edit} alt="edit Icon" width={18} height={18}></img>
                    </button>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={TOOLTIP_MSSG.ADD}>
                  <div className={s.editIcon}>
                    <button className={s.addButton} onClick={() => setEditFlag(!editFlag)}>
                      <img src={addIcon} alt="add Icon" />
                    </button>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={s.description}>
              {loading ? (
                <>
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                </>
              ) : trainingInput?.length ? (
                trainingInput.split(',').map((training, idx) => {
                  let isEmpty = /^ *$/.test(training);
                  return (
                    <React.Fragment key={idx}>
                      {!isEmpty && <div className={s.displayDescription}>{training}</div>}
                    </React.Fragment>
                  );
                })
              ) : (
                <i className="text-secondary">No Trainings added.</i>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Training;
