import axios from 'axios';
import {
  GET_WORKANNIVERSARY_FAILURE,
  GET_WORKANNIVERSARY_LOADING,
  GET_WORKANNIVERSARY_SUCCESS
} from '../actions/actions';

export const getAnniversary = () => {
  return (dispatch) => {
    dispatch({
      type: GET_WORKANNIVERSARY_LOADING
    });
    axios
      .get(`${process.env.REACT_APP_GEMBOOK_SVC_URL}/profile/anniversaries`, {
        headers: {
          accept: '*/*'
        }
      })
      .then((response) => {
        const details = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_WORKANNIVERSARY_SUCCESS,
            payload: {
              details: {
                ...details
              }
            }
          });
        }
        dispatch({
          type: GET_WORKANNIVERSARY_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_WORKANNIVERSARY_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
