import React, { useEffect, useState } from 'react';
import Admin from './admin';
import Members from './members';
import MembersPage from './membersPage';
import { connect } from 'react-redux';

//icons
import arrowBackward from '../../../../../../assets/icons/arrowBackward.svg';

//scss
import s from './rightWrapper.module.scss';

//Helper
import { Drawer } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

const RightWrapper = React.memo(
  ({
    setViewRightWrapper,
    membersData,
    clubName,
    clubId,
    getClubMembers,
    profileImg,
    loading,
    ...props
  }) => {
    // const { isMobile } = Viewport();
    const isMobile = useMediaQuery({ maxWidth: 992 });
    const [showMembers, setShowMembers] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showRequests, setShowRequests] = useState(false);

    return (
      <>
        <div className={s.desktopRightWrapper}>
          <div className={s.rightWrapper}>
            {isMobile && (
              <div className={s.clubNameMobile}>
                <img
                  src={arrowBackward}
                  alt="arrow"
                  onClick={() => {
                    setViewRightWrapper(false);
                  }}
                />
                <h4 className={s.headingMobile}>{clubName}</h4>
              </div>
            )}

            <Admin
              clubId={clubId}
              loading={loading}
              users={Object.values(props.users)}
              membersData={membersData}
              getClubMembers={getClubMembers}
            />

            <Members
              users={Object.values(props.users)}
              setShowDrawer={setShowDrawer}
              setShowMembers={setShowMembers}
              setShowRequests={setShowRequests}
              joinRequestData={props.requests}
            />

            <Drawer
              open={showDrawer}
              anchor={isMobile ? 'bottom' : 'right'}
              onClose={() => setShowDrawer(false)}
            >
              <MembersPage
                users={Object.values(props.users)}
                showMembers={showMembers}
                setShowMembers={setShowMembers}
                setShowDrawer={setShowDrawer}
                showRequests={showRequests}
                setShowRequests={setShowRequests}
                clubName={clubName}
                clubId={clubId}
                membersData={membersData}
                getClubMembers={getClubMembers}
                joinRequestData={props.requests}
              />
            </Drawer>
          </div>
        </div>
      </>
    );
  }
);
function mapStateToProps(state) {
  return {
    users: state.userReducer.users,
    requests: state.clubsReducer.requests
  };
}

export default connect(mapStateToProps)(RightWrapper);
