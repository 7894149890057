import React, { useEffect, useRef, useState } from 'react';

// Components
import PostCardComment from './postCardComment';
import PostCardBody from './postCardBody';
import PostCardLike from './postCardLike';

// Scss
import s from '../postContainer.module.scss';
import { connect } from 'react-redux';
import Media from './MediaSkeleton';
import { useParams } from 'react-router-dom';

const PostCard = ({
  setImageUrl,
  post,
  clubId,
  postTagId,
  likePost,
  deleteComment,
  addComment,
  addLoading,
  deletePost,
  likeComment,
  updatePostById,
  getEmpIdByEmail,
  postTypes,
  hashtags,
  currentEditedPost,
  setCurrentEditedPost,
  setEditOnePost,
  clubDetails,
  users,
  ...props
}) => {
  let commentInputRef = useRef();
  let isEdited = post.postTime === post.updateTime;
  const params = useParams();
  useEffect(() => {
    if (params?.id) {
      toggleExpand();
    }
  }, [params?.id]);
  const [expand, setExpand] = useState(false);
  const [editFlag, setEditFlag] = useState(false);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const [newReactions, setNewReactions] = useState(post.postReactions ?? []);

  useEffect(() => {
    if (props?.updatedReaction && props.updatedReaction.postId === post.postId) {
      setNewReactions(props.updatedReaction.data);
    }
  }, [props.updatedReaction]);

  const getUserData = () => {
    const filteredUsers = users.filter((user) => user.email === post.email);

    if (filteredUsers.length === 0) return null;
    if (filteredUsers.length === 1) return filteredUsers[0];

    // Two entries: return the active one if it exists
    const activeUser = filteredUsers.find((user) => user.isActive);
    return activeUser || filteredUsers[0];
  };

  const userData = getUserData();

  return (
    <div className={s.post}>
      <div className={s.postBody}>
        <PostCardBody
          setImageUrl={setImageUrl}
          clubId={clubId}
          postTagId={postTagId}
          files={post.gemFiles ?? []}
          content={post.postContent}
          postType={post.postType}
          user={post}
          time={isEdited ? post.postTime : post.updateTime}
          isEdited={isEdited ? '' : 'Edited'}
          postId={post.postId}
          deletePost={deletePost}
          updatePostById={updatePostById}
          getEmpIdByEmail={getEmpIdByEmail}
          postTypes={postTypes}
          hashtags={hashtags}
          editFlag={editFlag}
          setEditFlag={setEditFlag}
          currentEditedPost={currentEditedPost}
          setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
          setEditOnePost={(val) => setEditOnePost(val)}
          clubDetails={clubDetails}
          users={users}
          userData={userData}
        />

        <div className={s.postFooter}>
          <PostCardLike
            users={users}
            likePost={likePost}
            likes={newReactions ?? []}
            postId={post.postId}
            toggleExpand={toggleExpand}
            comments={post.comments ?? []}
            userData={userData}
          />
          {expand ? (
            <PostCardComment
              users={users}
              post={post}
              comments={post.comments ?? []}
              deleteComment={deleteComment}
              addComment={addComment}
              addLoading={addLoading}
              postId={post.postId}
              likeComment={likeComment}
              toggleExpand={toggleExpand}
              commentInputRef={commentInputRef}
              getEmpIdByEmail={getEmpIdByEmail}
              userData={userData}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userDataLoading: state.userReducer.loading,
    updatedReaction: state.postLikeReducer.updatedReaction
  };
}

export default connect(mapStateToProps, null)(React.memo(PostCard));
