import React from 'react';
import { Link } from 'react-router-dom';
import s from './footer.module.scss';
import newsfeed from '../../../assets/icons/news feed.svg';
import poll from '../../../assets/icons/poll.svg';
import award from '../../../assets/icons/award.svg';
import notificationBell from '../../../assets/icons/notification.svg';

function Footer() {
  return (
    <div className={s.footer}>
      <ul>
        <li>
          <Link to="">
            <img src={newsfeed} alt="newsfeed" />
          </Link>
        </li>
        <li>
          <Link to="">
            <img src={poll} alt="poll" />
          </Link>
        </li>
        <li>
          <Link to="">
            <img src={award} alt="award" />
          </Link>
        </li>
        <li>
          <Link to="">
            <img src={notificationBell} alt="notificationBell" />
          </Link>
        </li>
        <li>
          <Link to="/profile">
            <img
              className={s.imgProfile}
              width={30}
              height={30}
              src={localStorage.getItem('imageURL')}
              alt="imgProfile"
            />
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
