import React, { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from './authconfig';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import s from './auth.module.scss';
import { InteractionStatus } from '@azure/msal-browser';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

function handleLogin(instance, inProgress, isAuthenticated) {
  if (inProgress === InteractionStatus.None && !isAuthenticated)
    instance.loginRedirect(loginRequest).catch((e) => {
      toast.error('Login Failed. Please try again.');
    });
}

const Login = ({ display, mail, setMail }) => {
  const { instance, inProgress } = useMsal();
  let history = useHistory();

  // if access token is present in the URL, (active session)
  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const token = urlParams.get('token');

    if (token) {
      localStorage.setItem('neo_access_token', token);
      Axios.get('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setMail(response.data.mail);
        localStorage.setItem('email', response.data.mail.toLowerCase());
      });
    }
  }, [mail, history.location.search]);

  const isAuthenticated = useIsAuthenticated();

  return display ? (
    <Button
      variant="secondary"
      className={s.signin}
      onClick={() => handleLogin(instance, inProgress, isAuthenticated)}
    >
      Continue with Gemini mail
    </Button>
  ) : (
    <></>
  );
};
export default Login;
