import {
  GET_AWARDS_FAILURE,
  GET_AWARDS_SUCCESS,
  GET_AWARDS_LOADING,
  ADD_AWARD_FAILURE,
  ADD_AWARD_SUCCESS,
  ADD_AWARD_LOADING,
  DELETE_AWARD_LOADING,
  DELETE_AWARD_SUCCESS,
  DELETE_AWARD_FAILURE,
  GET_AWARDS_WINNER_FAILURE,
  GET_AWARDS_WINNER_LOADING,
  GET_AWARDS_WINNER_SUCCESS,
  GET_AWARDS_WIN_FAILURE,
  GET_AWARDS_WIN_LOADING,
  GET_AWARDS_WIN_SUCCESS,
  GET_AWARDS_BY_EMAIL_FAILURE,
  GET_AWARDS_BY_EMAIL_SUCCESS,
  GET_AWARDS_BY_EMAIL_LOADING
} from '../actions/actions';

const initialState = {
  getLoading: false,
  awards: [],
  getError: '',
  addLoading: false,
  addError: '',
  winners: [],
  awardsByEmail: [],
  awardsWin: []
};

const awardReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_AWARDS_LOADING:
      newState.getLoading = true;
      newState.winners = [];
      break;
    case GET_AWARDS_SUCCESS: {
      newState.awards = [...action.payload.awards];
      newState.error = '';
      newState.getLoading = false;
      break;
    }
    case GET_AWARDS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    case GET_AWARDS_BY_EMAIL_LOADING:
      newState.getLoading = true;
      newState.awardsByEmail = [];
      break;
    case GET_AWARDS_BY_EMAIL_SUCCESS: {
      newState.awardsByEmail = [...action.payload.awardsByEmail];
      newState.error = '';
      newState.getLoading = false;
      break;
    }
    case GET_AWARDS_BY_EMAIL_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    case GET_AWARDS_WINNER_LOADING:
      newState.getLoading = true;
      newState.winners = [];
      break;
    case GET_AWARDS_WINNER_SUCCESS: {
      newState.winners = [...action.payload.awards];
      newState.error = '';
      newState.getLoading = false;
      break;
    }
    case GET_AWARDS_WINNER_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    case GET_AWARDS_WIN_LOADING:
      newState.getLoading = true;
      newState.awardsWin = [];
      break;
    case GET_AWARDS_WIN_SUCCESS: {
      newState.awardsWin = [...action.payload.awardsWin];
      newState.error = '';
      newState.getLoading = false;
      break;
    }
    case GET_AWARDS_WIN_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    case ADD_AWARD_LOADING:
      newState.addLoading = true;
      break;
    case ADD_AWARD_SUCCESS:
      break;
    case ADD_AWARD_FAILURE:
      newState.addLoading = false;
      newState.error = action.payload.error;
      break;
    case DELETE_AWARD_LOADING:
      break;
    case DELETE_AWARD_SUCCESS:
      break;
    case DELETE_AWARD_FAILURE:
      break;
    default:
      return state;
  }
  return newState;
};

export default awardReducer;
