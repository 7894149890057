import {
  GET_EVENT_FAILURE,
  GET_EVENT_SUCCESS,
  GET_EVENT_LOADING,
  ADD_EVENT_FAILURE,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_LOADING,
  EDIT_EVENT_FAILURE,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_LOADING,
  DELETE_EVENT_LOADING,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  GET_ORGANISERS_FAILURE,
  GET_ORGANISERS_SUCCESS,
  GET_ORGANISERS_LOADING,
  GET_PAST_EVENTS_FAILURE,
  GET_PAST_EVENTS_SUCCESS,
  GET_PAST_EVENTS_LOADING,
  GET_EVENT_BYID_FAILURE,
  GET_EVENT_BYID_SUCCESS,
  GET_EVENT_BYID_LOADING,
  GET_SUBCHANNELS_LOADING,
  GET_SUBCHANNELS_SUCCESS,
  GET_SUBCHANNELS_FAILURE
} from '../actions/actions';

const initialState = {
  getLoading: false,
  events: [],
  organisers: [],
  getError: '',
  subChannels: ['General'],
  addLoading: false,
  addError: '',
  fetchAgain: true,
  pastEvents: [],
  newPastEventsLength: -1,
  deleteLoading: false,
  deleteError: '',
  editEvent: [],
  editLoading: false,
  editError: '',
  eventsById: []
};

const eventReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_EVENT_LOADING:
      newState.getLoading = true;
      break;
    case GET_EVENT_SUCCESS:
      newState.events = [...action.payload.events];
      newState.getError = '';
      newState.getLoading = false;
      break;
    case GET_EVENT_FAILURE:
      newState.getLoading = false;
      newState.getError = action.payload.error;
      newState.fetchAgain = false;
      break;
    case GET_PAST_EVENTS_LOADING:
      newState.getLoading = true;
      break;
    case GET_PAST_EVENTS_SUCCESS:
      newState.fetchAgain = false;
      if (action.payload.events.length && typeof action.payload.events == 'object') {
        newState.pastEvents = [...newState.pastEvents, ...action.payload.events.reverse()];
        newState.getError = '';
      } else newState.getError = 'No more events available.';
      newState.newPastEventsLength = action.payload.events.length;
      newState.getLoading = false;
      break;
    case GET_PAST_EVENTS_FAILURE:
      newState.getLoading = false;
      newState.getError = action.payload.error;
      newState.fetchAgain = false;
      break;
    case GET_ORGANISERS_LOADING:
      newState.getLoading = true;
      break;
    case GET_ORGANISERS_SUCCESS:
      newState.organisers = action.payload.organisers;
      break;
    case GET_ORGANISERS_FAILURE:
      newState.getLoading = false;
      newState.getError = action.payload.error;
      break;

    case GET_SUBCHANNELS_LOADING:
      newState.getLoading = true;
      break;
    case GET_SUBCHANNELS_SUCCESS:
      newState.subChannels = ['General', ...action.payload.details];
      break;
    case GET_SUBCHANNELS_FAILURE:
      newState.getLoading = false;
      newState.getError = action.payload.error;
      break;

    case ADD_EVENT_LOADING:
      newState.addLoading = true;
      break;
    case ADD_EVENT_SUCCESS:
      newState.fetchAgain = true;
      break;
    case ADD_EVENT_FAILURE:
      newState.addLoading = false;
      newState.addError = action.payload.error;
      break;
    case EDIT_EVENT_LOADING:
      newState.editLoading = true;
      break;
    case EDIT_EVENT_SUCCESS:
      newState.fetchAgain = true;

      break;
    case EDIT_EVENT_FAILURE:
      newState.editLoading = false;
      newState.editError = action.payload.error;
      break;
    case DELETE_EVENT_LOADING:
      newState.deleteLoading = true;
      break;
    case DELETE_EVENT_SUCCESS:
      newState.fetchAgain = true;
      break;
    case DELETE_EVENT_FAILURE:
      newState.deleteLoading = false;
      newState.deleteError = action.payload.error;
      break;
    case GET_EVENT_BYID_LOADING:
      newState.getLoading = true;
      break;
    case GET_EVENT_BYID_SUCCESS:
      newState.eventsById = [...action.payload.events];
      newState.getError = '';
      newState.getLoading = false;
      break;
    case GET_EVENT_BYID_FAILURE:
      newState.getLoading = false;
      newState.getError = action.payload.error;
      newState.fetchAgain = false;
      break;
    default:
      return state;
  }
  return newState;
};

export default eventReducer;
