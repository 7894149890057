import React, { useState } from 'react';

// Components
import SearchedPeople from './searched-people';
import RightSidebar from './sidebar';
import PostContainer from './postContainer';
import Posts from './postContainer/posts';
import PostId from './postContainer/posts/postId';

// Import Scss
import s from './home.module.scss';
import p from './postContainer/postContainer.module.scss';
import SearchedTagContainer from './sidebar/trendingHashTag/searchedHashtag/SearchedTagContainer';
import Sidebar from '../../../../layouts/layout1/sidebar';

const Home = ({
  showRightWrapperMobile,
  setShowRightWrapperMobile,
  activeMobileActivity,
  setActiveMobileActivity,
  inputChangeData,
  id,
  tag,
  toggle,
  setToggle
}) => {
  const [editOnePost, setEditOnePost] = useState(false);
  const [currentEditedPost, setCurrentEditedPost] = useState(0);

  return (
    <div className={s.home}>
      <div className={s.mobileHide}>
        <Sidebar toggle={toggle} setToggle={setToggle} />
      </div>
      {inputChangeData.searchValue.length ? (
        !inputChangeData.isPost ? (
          <div className={s.containerWrapper}>
            <SearchedPeople searchValue={inputChangeData.searchValue} />
          </div>
        ) : (
          <div className={s.containerWrapper}>
            <div className={p.postContainer}>
              <Posts
                searchValue={inputChangeData.searchValue}
                editOnePost={editOnePost}
                setEditOnePost={(val) => setEditOnePost(val)}
                currentEditedPost={currentEditedPost}
                setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
              />
            </div>
          </div>
        )
      ) : (
        <div
          className={s.containerWrapper}
          style={{ display: showRightWrapperMobile === false ? 'block' : 'none' }}
        >
          {tag ? (
            <SearchedTagContainer
              showRightWrapperMobile={showRightWrapperMobile}
              setShowRightWrapperMobile={setShowRightWrapperMobile}
              tag={tag}
            />
          ) : !id ? (
            <PostContainer
              showRightWrapperMobile={showRightWrapperMobile}
              setShowRightWrapperMobile={setShowRightWrapperMobile}
            />
          ) : (
            <PostId
              id={id}
              setEditOnePost={(val) => setEditOnePost(val)}
              setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
            />
          )}
        </div>
      )}
      <div className={s.sidebarWrapper} style={{ width: showRightWrapperMobile ? '100%' : '' }}>
        <RightSidebar
          showRightWrapperMobile={showRightWrapperMobile}
          setShowRightWrapperMobile={setShowRightWrapperMobile}
          activeMobileActivity={activeMobileActivity}
          setActiveMobileActivity={setActiveMobileActivity}
        />
      </div>
    </div>
  );
};

export default Home;
