import React, { useEffect, useState } from 'react';
import clsx from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import { getImageURL } from '../../../pages/auth/api';
import {
  getNotifications,
  updateNotifications
} from '../../../redux/actionCreators/notification.actionCreator';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfileCard from './profileCard';
import Searchbar from './searchbar';

// Scss
import s from './navbar.module.scss';

//Logo
import MenuIcon from '../../../assets/icons/menu.svg';
import notificationIcon from '../../../assets/icons/notification.svg';
import CombinedShapeIcon from '../../../assets/img/combinedShape.png';
import manager from '../../../assets/img/manager.png';
import searchIcon from '../../../assets/icons/Search.svg';
import logoImg from '../../../assets/img/GembookIcon.png';

//Helper
import Notification from './Notification';
import { NOTIFICATION_REFETCH_INTERVAL } from '../../../helpers';
import { getAllUsers } from '../../../redux/actionCreators/user.actionCreator';
import { getEvents, getPastEvents } from '../../../redux/actionCreators/event.actionCreator';
import ActivityMobile from './ActivityMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../sidebar/logo';
import { getPostTypes } from '../../../redux/actionCreators/postTypes.actionCreator';
import Sidebar from '../sidebar';
import ReportDialogue from './ReportDialogue';
import ReportIcon from '@material-ui/icons/Report';
import { useMediaQuery } from 'react-responsive';
import Tooltip from '@material-ui/core/Tooltip';

const Navbar = ({
  profileDetails,
  toggle,
  setToggle,
  onChange,
  inputVal,
  setInputVal,
  showRightWrapperMobile,
  setShowRightWrapperMobile,
  activeMobileActivity,
  setActiveMobileActivity,
  ...props
}) => {
  const [imageURL, setImageURL] = useState('');
  const [notificationModal, setNotificationModal] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [reportDialogueOpen, setReportDialogueOpen] = useState(false);

  const userDetails = useSelector((state) => state.storageReducer.user);
  const history = useHistory();

  useEffect(() => {
    props.getNotifications();
    props.getAllUsers();
    if (
      history?.location?.pathname !== '/profile' &&
      !history?.location?.pathname.includes('/marketplace') &&
      history?.location?.pathname !== '/clubs'
    ) {
      props.getEvents();
      props.getPastEvents(0);
    }
    if (
      history?.location?.pathname.includes('/dashboard') ||
      history?.location?.pathname.includes('/clubs')
    ) {
      props.getPostTypes();
    }
    const interval = setInterval(() => {
      props.getNotifications();
    }, NOTIFICATION_REFETCH_INTERVAL);
  }, []);

  useEffect(() => {
    setImageURL(userDetails?.imageURL);
  }, [userDetails?.imageURL]);

  useEffect(() => {
    handleReadNotificationsCount(props.notifications);
  }, [props.notifications]);

  const profilePage = () => {
    if (window.location.hash !== '#/profile') {
      history.push('/profile');
    }
    onChange('');
  };
  // const { width } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [searchMobile, setSearchMobile] = useState(false);

  const handleReadNotificationsCount = (notifications) => {
    let count = 0;
    notifications.forEach((notification) => {
      if (notification.read === false) count++;
    });
    setNotificationCount(count);
  };

  const onClick = () => {
    const x = window.location.hash;
    if (x === '#/dashboard') {
      window.location.reload();
    }
  };

  const toggleDialogue = () => {
    setReportDialogueOpen(!reportDialogueOpen);
  };

  const [flg, setFlg] = useState(false);
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      {!isMobile ? (
        <div className={s.navWrapper}>
          <div className={s.navbar}>
            <ul className={s.navbarWrapper}>
              <div className={s.leftNavItem}>
                <Logo bookColor={'#fff'} />
                <Searchbar
                  inputVal={inputVal}
                  onChangeVal={onChange}
                  setInputVal={setInputVal}
                  setSearchMobile={setSearchMobile}
                />
              </div>
              <li className="nav-item">
                <Tooltip title="Report an Issue" open={!flg && isHover}>
                  <div>
                    <ReportIcon
                      className={s.reportIcon}
                      style={{ color: reportDialogueOpen ? '#3da9a1' : '#000' }}
                      onClick={toggleDialogue}
                      onMouseOver={() => setIsHover(true)}
                      onMouseLeave={() => setIsHover(false)}
                    />
                    {reportDialogueOpen && (
                      <ReportDialogue
                        prevOpen={reportDialogueOpen}
                        setPrevOpen={setReportDialogueOpen}
                      />
                    )}
                  </div>
                </Tooltip>
                <FontAwesomeIcon
                  icon="bell"
                  className={s.notificationIcon}
                  style={{ color: notificationModal ? '#3da9a1' : '#000' }}
                  onClick={() => {
                    setNotificationModal(!notificationModal);
                  }}
                />
                {notificationCount > 0 && (
                  <span
                    className={s.notiCount}
                    onClick={() => {
                      setNotificationModal(!notificationModal);
                    }}
                  >
                    {notificationCount}
                  </span>
                )}
                <Notification
                  notificationModal={notificationModal}
                  setNotificationModal={setNotificationModal}
                  data={props.notifications}
                  update={props.updateNotifications}
                  getNotifications={props.getNotifications}
                  handleReadNotificationsCount={handleReadNotificationsCount}
                  users={Object.values(props.users)}
                  notificationCount={notificationCount}
                />
                <div className={clsx(s.dropdown, s.profileButton, 'nav-link')}>
                  <img
                    src={imageURL}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = manager;
                    }}
                    alt="profile image"
                    className={s.img}
                    onClick={profilePage}
                  />
                </div>
                <div className={clsx(s.dropdown, 'nav-link')}>
                  <img
                    height={16}
                    width={4}
                    src={CombinedShapeIcon}
                    alt="dropdown icon"
                    className="ml-2 mr-4"
                  />

                  <div className={s.dropdownContent}>
                    <ProfileCard onChange={onChange} details={profileDetails} users={props.users} />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <>
          <div className={s.navWrapperMobile}>
            {!searchMobile && (
              <>
                <div className={s.navbarMobile}>
                  <ul className={s.navbarWrapperMobile}>
                    <img
                      width={20}
                      height={20}
                      src={MenuIcon}
                      alt="Menu Icon"
                      className={s.menuIconMobile}
                      onClick={() => setToggle((prev) => !prev)}
                    />
                    <Link to="/dashboard" onClick={onClick}>
                      <span className={s.headerTextMobile}>
                        <img alt="Gembook Logo" src={logoImg} className="gembook-logo"></img>
                        <span className={s.headerTextHelperMobile}>Gembook</span>
                      </span>
                    </Link>
                    <li className={clsx(s.marginLeftAutoMobile, 'nav-item')}>
                      <img
                        src={searchIcon}
                        alt="Search Icon"
                        className={s.searchIconMobile}
                        onClick={() => setSearchMobile(true)}
                      />
                      <Tooltip
                        title="Report an Issue"
                        open={!flg && isHover}
                        disableFocusListener
                        disableTouchListener
                      >
                        <div>
                          <ReportIcon
                            className={s.reportMobileIcon}
                            style={{ color: reportDialogueOpen ? '#3da9a1' : '#000' }}
                            onClick={toggleDialogue}
                            onMouseOver={() => setIsHover(true)}
                            onMouseLeave={() => setIsHover(false)}
                          />
                          {reportDialogueOpen && (
                            <ReportDialogue
                              prevOpen={reportDialogueOpen}
                              setPrevOpen={setReportDialogueOpen}
                            />
                          )}
                        </div>
                      </Tooltip>

                      <FontAwesomeIcon
                        icon="bell"
                        className={s.notificationIcon}
                        style={{ color: notificationModal ? '#3da9a1' : '#000' }}
                        onClick={() => {
                          setNotificationModal(!notificationModal);
                        }}
                      />
                      {notificationCount > 0 && (
                        <span
                          className={clsx(s.notiCount, s.notiCountMobile)}
                          onClick={() => {
                            setNotificationModal(!notificationModal);
                          }}
                        >
                          {notificationCount}
                        </span>
                      )}
                      <Notification
                        notificationModal={notificationModal}
                        setNotificationModal={setNotificationModal}
                        data={props.notifications}
                        update={props.updateNotifications}
                        getNotifications={props.getNotifications}
                        handleReadNotificationsCount={handleReadNotificationsCount}
                        users={Object.values(props.users)}
                        notificationCount={notificationCount}
                      />
                      <div className={clsx(s.dropdownMobile, 'nav-link')}>
                        <img
                          src={imageURL}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = manager;
                          }}
                          alt="Profile"
                          className={s.imgMobile}
                          onClick={profilePage}
                        />
                      </div>
                      <div className={clsx(s.dropdownMobile, 'nav-link')}>
                        <img src={CombinedShapeIcon} alt="icon" style={{height:'12px'}}/>
                        <div className={s.dropdownContentMobile}>
                          <ProfileCard
                            onChange={onChange}
                            details={profileDetails}
                            users={props.users}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <Sidebar toggle={toggle} setToggle={setToggle} />
              </>
            )}
            {searchMobile && (
              <Searchbar
                inputVal={inputVal}
                onChangeVal={onChange}
                setInputVal={setInputVal}
                setSearchMobile={setSearchMobile}
              />
            )}
            {window.location.hash === '#/dashboard' && !searchMobile ? (
              <ActivityMobile
                showRightWrapperMobile={showRightWrapperMobile}
                setShowRightWrapperMobile={setShowRightWrapperMobile}
                activeMobileActivity={activeMobileActivity}
                setActiveMobileActivity={setActiveMobileActivity}
              />
            ) : null}
          </div>
        </>
      )}
    </>
  );
};
function mapStateToProps(state) {
  return {
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    details: state.profileReducer.details,
    notifications: state.notificationReducer.notifications,
    users: state.userReducer.users,
    events: state.eventReducer.events
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getImageURL: getImageURL,
      getNotifications: getNotifications,
      updateNotifications: updateNotifications,
      getAllUsers: getAllUsers,
      getEvents: getEvents,
      getPastEvents: getPastEvents,
      getPostTypes: getPostTypes
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
