import {
  EDIT_HOBBIES_LOADING,
  EDIT_HOBBIES_SUCCESS,
  EDIT_HOBBIES_FAILURE
} from '../../actions/actions';

const initialState = {
  editLoading: false,
  editError: '',
  hobbies: null
};

const hobbiesReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case EDIT_HOBBIES_LOADING:
      newState.editLoading = true;
      break;
    case EDIT_HOBBIES_SUCCESS:
      newState.editLoading = false;
      newState.hobbies = action.payload.details;
      break;
    case EDIT_HOBBIES_FAILURE:
      newState.editLoading = false;
      newState.editError = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default hobbiesReducer;
