import React, { useEffect } from 'react';
import s from '../sidebar.module.scss';
import { getProfileByEmail } from '../../../../../../helpers';
import user from '../../../../../../assets/img/user.png';
import { connect } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import goldBadge from '../../../../../../assets/icons/goldBadge.svg';
import silverBadge from '../../../../../../assets/icons/silverBadge.svg';
import bronzeBadge from '../../../../../../assets/icons/bronzeBadge.svg';

const Details = ({ winners, awards, year, loading, ...props }) => {
  const getProfilePicture = (email) => {
    return Object.values(props.users).filter((user) => {
      if (user.email === email) return true;
    })[0]?.photoUrl;
  };

  const getProfile = (email) => {
    if (email) {
      getProfileByEmail(email);
    }
  };

  const winnersList = winners.sort((a, b) => {
    if (a.rank !== null && b.rank !== null) {
      if (a.rank !== b.rank) return a.rank - b.rank;
    }

    if (a.rank !== null && (b.rank === null || b.rank === undefined)) return -1;
    if (b.rank !== null && (a.rank === null || a.rank === undefined)) return 1;

    return a.winnerName.localeCompare(b.winnerName);
  });

  const badges = (rank) => {
    if (rank === 1) return goldBadge;
    if (rank === 2) return silverBadge;
    if (rank === 3) return bronzeBadge;

    return null;
  };

  return (
    <div className={s.description}>
      {!loading ? (
        <ul>
          {winnersList.map((winner, idx) => {
            const email = winner.recWinnerEmail || winner.eventWinnerEmail;
            const profilePicture = getProfilePicture(email);

            return email && profilePicture ? (
              <li className={s.existingEmployee} onClick={() => getProfile(email)} key={idx}>
                <div className={s.employeeInfo}>
                  <div>
                    <img
                      className={s.employeeImg}
                      style={{ marginBottom: winner.eventWinnerTitle ? '-14px' : '' }}
                      src={profilePicture ?? user}
                      alt="employee image"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = user;
                      }}
                    />
                    <span>
                      {winner.winnerName}
                      {winner.eventWinnerTitle ? (
                        <p className={s.title}>{winner.eventWinnerTitle} </p>
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  {winner.rank && winner.rank > 0 && winner.rank < 4 ? (
                    <img
                      className={s.badge}
                      src={badges(winner.rank)}
                      alt={`Rank ${winner.rank}`}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </li>
            ) : null;
          })}
        </ul>
      ) : (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </>
      )}
      {!loading && winnersList.length === 0 && <div className={s.winners}>No Record Found</div>}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    users: state.userReducer.users
  };
}

export default connect(mapStateToProps)(Details);
