import moment from 'moment';

export const getProfileByEmail = (email) => {
  let userEmail = localStorage.getItem('email');
  let url = '';
  if (userEmail === email) {
    url = window.location.origin + `/#/profile/`;
  } else {
    url = window.location.origin + `/#/profile/${email}`;
  }
  window.location.href = url;
};

export const mediaSize = {
  image: 10,
  video: 20
};

export const validateMediaSize = (type, file) => {
  switch (type) {
    case 'image':
      return (file.size / 1024 / 1024).toFixed(2) > mediaSize.image ? false : true;
    case 'video':
      return (file.size / 1024 / 1024).toFixed(2) > mediaSize.video ? false : true;
  }
};

export const popUpMessages = {
  DELETE_POST: 'Delete Post ?',
  DELETE_CERTIFICATION: 'Delete Certification ?',
  DELETE_PROJECT: 'Delete Project ?',
  CONFIRM_DELETE_POST: 'Are you sure you want to permanently remove this post from Gembook ?',
  CONFIRM_SAVE_CERTIFICATION: 'Are You Sure You Want To Save This Certification ?',
  CONFIRM_DELETE_CERTIFICATION:
    'Are you sure you want to permanently remove this certificate from Gembook ?',
  CONFIRM_SAVE_PROJECT: 'Are You Sure You Want To Save This Project ?',
  CONFIRM_DELETE_PROJECT: 'Are you sure you want to permanently remove this project from Gembook ?',
  INACTIVE_POST: 'Inactivate Post?',
  CONFIRM_INACTIVE_POST: 'Are you sure you want to mark this post as Inactive?',
  ACTIVE_POST: 'Activate Post?',
  CONFIRM_ACTIVE_POST: 'Are you sure you want to mark this post as Active?'
};

export const TOOLTIP_MSSG = {
  EDIT: 'Click To Edit',
  ADD: 'Click To Add',
  CLOSE: 'Click To Close',
  SAVE: 'Click To Save',
  ADD_CERTIFICATION: 'Click To Add Certification',
  ADD_PROJECT: 'Click To Add Project',
  VIEW_PROFILE: 'Click To Visit Profile',
  CLICK_MAIL: 'Click To Send Mail',
  CLICK_MOBILE: 'Click To Call'
};

export const sortEventsByDate = (events, key) => {
  const sortedEvents = events.sort((a, b) => {
    const [y1, m1, d1] = a[key];
    const [y2, m2, d2] = b[key];
    const dateA = new Date(y1, m1 - 1, d1);
    const dateB = new Date(y2, m2 - 1, d2);
    return dateA - dateB || a.eventName.localeCompare(b.eventName);
  });

  return sortedEvents;
};

export const POST_REFETCH_INTERVAL = 1000 * 60 * 1; // 1 minute
export const NOTIFICATION_REFETCH_INTERVAL = 1000 * 60 * 3; // 3 minute
export const getDateFromArray = (dateArr, format) => {
  if (Array.isArray(dateArr)) {
    const [y, M, d, h, m, s] = dateArr;
    let newDate = moment();

    switch (format) {
      case 'YYYY-MM-DD':
        newDate = moment({ y: y, M: M - 1, d: d });
        return newDate.format(format);
      case 'MMM-DD':
        newDate = moment({ M: M - 1, d: d });
        return newDate.format(format);
      case 'DD':
        newDate = moment({ d: d });
        return newDate.format(format);
      case 'MM':
        newDate = moment({ M: M - 1 });
        return newDate.format(format);
      case 'hh:mm a':
        newDate = moment({ h: h, m: m });
        return newDate.format(format);
      case 'HH:mm:ss a':
        newDate = moment({ h: h, m: m, s: s });
        return newDate.format(format);
      case 'HH:mm':
        newDate = moment({ h: h, m: m });
        return newDate.format(format);
      case 'default':
        return;
    }
  }
  return dateArr;
};

export const DATE_FORMAT = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  DATE: 'DD',
  MM: 'MM',
  MMM_DD: 'MMM-DD'
};
export const TIME_FORMAT = {
  HH_MM_A: 'hh:mm a',
  HH_MM: 'HH:mm',
  HH_MM_SS_A: 'HH:mm:ss a'
};

export const sanitizeObj = (obj) => {
  const data = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null || obj[key] !== undefined) {
      data[key] = obj[key];
    }
  });
  return data;
};

export const isUserActive = (users, email) => {
  let activeCount = 0;
  let inactiveCount = 0;

  for (const user of users) {
    if (user.email === email) {
      if (user.isActive) {
        activeCount++;
      } else {
        inactiveCount++;
      }

      // If there's at least one active entry, return true immediately
      if (activeCount > 0) {
        return true;
      }
    }
  }

  // If there are entries with the email but none are active, return false
  if (inactiveCount > 0) {
    return false;
  }

  // If no entries found with the given email, return false
  return false;
};
