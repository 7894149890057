import React, { useEffect, useState } from 'react';

import s from './details.module.scss';
import p from '../sidebar.module.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deletePoll, editPoll } from '../../../../../../redux/actionCreators/polls.actionCreator';
import { DATE_FORMAT, TIME_FORMAT, getDateFromArray } from '../../../../../../helpers';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getEvents } from '../../../../../../redux/actionCreators/event.actionCreator';

const Polls = ({ event, setPollData, isError = false, setIsPollTimeValid }) => {
  const [pollInput, setPollInput] = useState({
    question: event?.pollData?.question ?? '',
    selectedDate: event?.pollData?.selectedDate ?? '',
    selectedTime: event?.pollData?.selectedTime ?? ''
  });
  const [error, setError] = useState({
    question: '',
    selectedDate: '',
    selectedTime: ''
  });
  const [inputType, setInputType] = useState({
    date: 'text',
    time: 'text'
  });

  let startTimeText = `${getDateFromArray(event.startDate, DATE_FORMAT.MMM_DD)} (${getDateFromArray(
    event.startDate,
    TIME_FORMAT.HH_MM_A
  )})`;
  let endTimeText = `${getDateFromArray(event.endDate, DATE_FORMAT.MMM_DD)} (${getDateFromArray(
    event.endDate,
    TIME_FORMAT.HH_MM_A
  )})`;

  useEffect(() => {
    if (pollInput.selectedDate && pollInput.selectedTime) {
      setError(checkIsTimeValid(error, pollInput.selectedTime));
    }
  }, [
    pollInput?.selectedDate,
    pollInput?.selectedTime,
    event?.startDate,
    event?.startTime,
    event?.endDate,
    event?.endTime
  ]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setPollInput({ ...pollInput, [name]: value });
    setPollData({ ...pollInput, [name]: value });
  };

  const checkIsTimeValid = (err, val) => {
    const selectedDate = moment.utc(pollInput.selectedDate, 'YYYY-MM-DD');
    const eventStartDate = moment.utc(event.startDate, 'YYYY-MM-DD');
    const eventEndDate = moment.utc(event.endDate, 'YYYY-MM-DD');
    const isBeforeStartDate = selectedDate.isBefore(eventStartDate, 'day');
    const isAfterEndDate = selectedDate.isAfter(eventEndDate, 'day');

    const selectedTime = moment.utc(pollInput.selectedTime, 'HH:mm');
    const eventStartTime = moment.utc(event.startTime, 'HH:mm');
    const eventEndTime = moment.utc(event.endTime, 'HH:mm');
    // const isBeforeStart = selectedTime.isBefore(eventStartTime, 'minute');
    // const isAfterEnd = selectedTime.isAfter(eventEndTime, 'minute');

    const selectedDateTime = selectedDate.clone().set({
      hour: selectedTime.get('hour'),
      minute: selectedTime.get('minute')
    });
    const eventStartDateTime = eventStartDate.clone().set({
      hour: eventStartTime.get('hour'),
      minute: eventStartTime.get('minute')
    });
    const eventEndDateTime = eventEndDate.clone().set({
      hour: eventEndTime.get('hour'),
      minute: eventEndTime.get('minute')
    });

    // Check if selected time is before event start time and after event end time
    const isBeforeStart = selectedDateTime.isBefore(eventStartDateTime, 'minute');
    const isAfterEnd = selectedDateTime.isAfter(eventEndDateTime, 'minute');

    err.selectedDate = !pollInput.selectedDate ? (
      'Please select a date'
    ) : isBeforeStartDate || isAfterEndDate ? (
      <>
        {`Poll end date should be between ${eventStartDate.format(
          'YYYY-MM-DD'
        )} and ${eventEndDate.format('YYYY-MM-DD')}`}
        {setIsPollTimeValid(false)}
      </>
    ) : (
      ''
    );

    err.selectedTime = !val ? (
      'Please select a time'
    ) : isBeforeStart || isAfterEnd ? (
      <>
        {`Poll end time should be between ${eventStartTime.format(
          'h:mm A'
        )} on ${eventStartDate.format('YYYY-MM-DD')} and ${eventEndTime.format(
          'h:mm A'
        )} on ${eventEndDate.format('YYYY-MM-DD')}`}
        {setIsPollTimeValid(false)}
      </>
    ) : (
      ''
    );

    if (err.selectedDate === '' && err.selectedTime === '') {
      setIsPollTimeValid(true);
    }

    return err;
  };

  const handleBlur = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case 'question':
        error.question = !value ? 'Please add a question' : '';
        break;
      case 'selectedDate':
        error.selectedDate = !value ? 'Please select a date' : '';
        break;
      case 'selectedTime':
        checkIsTimeValid(error, value);
        break;
      default:
        break;
    }
    setError({ ...error });
  };

  return (
    <div className="w-100">
      <div className={s.addPollWrapper}>
        <input
          value={pollInput.question}
          required
          type="text"
          name="question"
          error={error.question}
          className={`w-100 ${
            isError && (!pollInput.question.trim().length || pollInput.question.length > 50)
              ? p.eventInputError
              : p.eventInput
          }`}
          placeholder="Ask question"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {/* {error?.question !== '' && <p className={s.pollError}>{error?.question}</p>} */}

        <div className={s.pollEndDate}>
          <div className="w-50 d-flex flex-column">
            <input
              className={` ${
                isError && (!pollInput.selectedDate || error?.selectedDate !== '')
                  ? p.eventInputError
                  : p.eventInput
              } mr-1`}
              type={inputType.date}
              name="selectedDate"
              value={pollInput.selectedDate}
              onFocus={() => setInputType({ ...inputType, date: 'date' })}
              placeholder="End Date"
              onChange={handleChange}
              required
              min={getDateFromArray(event.startDate, DATE_FORMAT.YYYY_MM_DD)}
              max={getDateFromArray(event.endDate, DATE_FORMAT.YYYY_MM_DD)}
              onBlur={handleBlur}
            />
            {error?.selectedDate !== '' && <p className={s.pollError}>{error.selectedDate}</p>}
          </div>
          <div className="w-50 d-flex flex-column">
            <input
              className={` ${
                isError && (!pollInput.selectedTime || error?.selectedTime !== '')
                  ? p.eventInputError
                  : p.eventInput
              } mx-0`}
              type={inputType.time}
              name="selectedTime"
              value={pollInput.selectedTime}
              onFocus={() => setInputType({ ...inputType, time: 'time' })}
              placeholder="End Time"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={pollInput.selectedDate === ''}
              required
            />
            {error?.selectedTime !== '' && <p className={s.pollError}>{error.selectedTime}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    polls: state.pollsReducer.polls
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEvents: getEvents,
      editPoll: editPoll,
      deletePoll: deletePoll
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Polls);
