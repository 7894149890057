import axios from 'axios';
import {
  GET_POST_TYPE_LOADING,
  GET_POST_TYPE_SUCCESS,
  GET_POST_TYPE_FAILURE
} from '../actions/actions';

export const getPostTypes = () => {
  return (dispatch) => {
    dispatch({
      type: GET_POST_TYPE_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/posts/post-types', {
        headers: {
          accept: '*/*'
        }
      })
      .then((response) => {
        const postTypes = response.data;
        dispatch({
          type: GET_POST_TYPE_SUCCESS,
          payload: {
            postTypes: postTypes
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_POST_TYPE_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
