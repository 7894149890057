import React, { useState } from 'react';
import { connect } from 'react-redux';

import s from './details.module.scss';

// Icons
import EventIcon from '../../../../../../assets/icons/EventIcon.png';
import PlusIcon from '../../../../../../assets/icons/plus-blue.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Tooltip } from '@material-ui/core';

// Components
import AddEvent from './addEvent';
import Display from './displayEvents';
import Details from './details';
import Polls from './polls';
import { useEffect } from 'react';
import { getClubs } from '../../../../../../redux/actionCreators/clubs.actionCreator';
import { bindActionCreators } from 'redux';
import { store } from '../../../../../../redux/createStore';

const Event = (props) => {
  const [addEventToggle, setAddEventToggle] = useState(false);

  const data = [
    'ritika.jain@geminisolutions.com',
    'sanjay.tripathi@geminisolutions.com',
    'nikhil.sharma@geminisolutions.com',
    'smriti.pathak@geminisolutions.com',
    'manvi.jain@geminisolutions.com',
    'sagar.srivastava@geminisolutions.com',
    'radhika.narang@geminisolutions.com',
    'madhur.singhal@geminisolutions.com',
    'neha.pathak@geminisolutions.com',
    'shrishti.yadav@geminisolutions.com',
    'devesh.chandra@geminisolutions.com',
    'jayam.gupta@geminisolutions.com'
  ];
  const [adminData, setAdminData] = useState([]);
  const [hrData, setHrData] = useState([]);
  const [hrArr, setHrArr] = useState([]);

  useEffect(() => {
    if (!store.getState().clubsReducer?.clubs?.length) props.getClubs();
  }, []);
  useEffect(() => {
    let newarr = [];
    let hrArray = [];
    const additionalData = props.clubs.map((club) => {
      const adminMembers = club.clubMemberDetails.filter((member) => member.clubRoleId === 1);
      adminMembers.forEach((member) => {
        newarr.push({ email: member.userId, club: club.club.name });
      });
    });
    setAdminData(newarr);
    const hrData = Object.values(props.users)?.filter((s) => {
      if (s.teamName === 'HUMAN RESOURCE') {
        hrArray.push({ email: s.email, club: 'ALL' });
      }
    });
    setHrData(hrArray);
  }, [props.clubs, props.users]);
  useEffect(() => {
    setHrArr([...hrData, ...adminData]);
  }, [hrData, adminData]);
  const Header = () => {
    const additionalData = hrArr.push(adminData);
    return (
      <div className="header">
        <div>
          <img src={EventIcon} className="event-header" height="35" width="35" alt="" />
          <span className="event-heading">Events</span>
        </div>
        {hrArr.some(
          (hrMember) => hrMember.email?.toLowerCase() == localStorage.getItem('email')
        ) ? (
          <div className="action-button">
            {addEventToggle ? (
              <span
                className="action cursor-pointer"
                onClick={() => setAddEventToggle((add) => !add)}
              >
                <img src={PlusIcon} alt="plus" width="20" height="20" className="rotate-45" />
              </span>
            ) : (
              <button className="add-button" onClick={() => setAddEventToggle(true)}>
                + Add New
              </button>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <div className="event">
      <Header />
      {addEventToggle ? (
        <div className="p-3">
          <AddEvent setAddEventToggle={setAddEventToggle} />
        </div>
      ) : (
        <Display hrArr={hrArr} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.userReducer.users,
    clubs: state.clubsReducer.clubs
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getClubs: getClubs
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Event);
