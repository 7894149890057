import React, { useState } from 'react';
import s from './membersPage.module.scss';

const SearchEmployee = ({ list, searchQuery, setSearchQuery, setRows }) => {
  const requestSearch = (e) => {
    const searchedVal = e?.target?.value;
    setSearchQuery(e.target.value);
    const filteredRows = list?.filter((row) => new RegExp(searchedVal, 'i').test(row?.name));
    setRows(filteredRows);
  };

  return (
    <div>
      <input
        placeholder="Search"
        value={searchQuery}
        onChange={requestSearch}
        className={s.searchQuery}
      />
    </div>
  );
};

export default SearchEmployee;
