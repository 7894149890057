import {
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_LOADING,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_LOADING,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_MARKET_USERS_FAILURE,
  GET_ALL_MARKET_USERS_SUCCESS,
  GET_ALL_MARKET_USERS_LOADING
} from '../actions/actions';

const initialState = {
  loading: false,
  details: {},
  error: '',
  users: [],
  employees: [],
  usersProfile: []
};

const userReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_USER_BY_ID_LOADING:
      newState.loading = true;
      break;
    case GET_USER_BY_ID_SUCCESS:
      newState.details = { ...state.details, ...action.payload.user };
      newState.loading = false;
      break;
    case GET_USER_BY_ID_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case GET_ALL_USERS_LOADING:
      newState.loading = true;
      break;
    case GET_ALL_USERS_SUCCESS:
      // newState.users = { ...state.details, ...action.payload.users };
      newState.users = action.payload.users;
      newState.loading = false;
      break;
    case GET_ALL_USERS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case GET_ALL_MARKET_USERS_LOADING:
      newState.loading = true;
      break;
    case GET_ALL_MARKET_USERS_SUCCESS:
      newState.details = { ...state.details, ...action.payload.usersProfile };
      newState.loading = false;
      break;
    case GET_ALL_MARKET_USERS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};
export default userReducer;
