import React, { useState, useEffect } from 'react';
import cns from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components
import Logout from '../../../pages/auth/logout';

// Scss
import s from './profileCard.module.scss';
import downloadIcon from '../../../assets/icons/downloadBtn.svg';
import { toast } from 'react-toastify';
import axios from 'axios';

const ProfileCard = ({ details, onChange, users }) => {
  const [imageURL, setImageURL] = useState('');
  const history = useHistory();
  const userDetails = useSelector((state) => state.storageReducer.user);
  useEffect(() => {
    setImageURL(localStorage.getItem('imageURL'));
  }, [userDetails]);

  const hrArr = [];

  const additionalEmails = [
    'devansh.popli@geminisolutions.com',
    'smriti.pathak@geminisolutions.com',
    'neha.pathak@geminisolutions.com',
    'madhur.singhal@geminisolutions.com',
    'ritika.jain@geminisolutions.com',
    'arihant.jain@geminisolutions.com',
    'shreya.jaiswal@geminisolutions.com',
    'nikhil.sharma@geminisolutions.com',
    'sagar.srivastava@geminisolutions.com',
    'radhika.narang@geminisolutions.com',
    'shrishti.yadav@geminisolutions.com',
    'jayam.gupta@geminisolutions.com'
  ];

  Object.values(users).forEach((user) => {
    if (user.teamName && user.teamName.toLowerCase() === 'human resource') {
      hrArr.push(user.email);
    }
  });

  hrArr.push(...additionalEmails);
  const profileRouting = () => {
    if (window.location.hash !== '#/profile') {
      history.push('/profile');
    }
    onChange('');
  };

  const handleDownload = () => {
    const info = toast.info('Downloading Employees Certification Data...');
    const apiUrl = `${process.env.REACT_APP_GEMBOOK_SVC_URL}/certificates/all-certificate-download-excel`;

    axios({
      url: apiUrl,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const fileName = `Employees Certification Data.xlsx`;
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        toast.dismiss(info);
        toast.success('Employees Certification Data downloaded successfully.');
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };
  return (
    <div className={s.profileCard}>
      <div className={s.profile}>
        <div className={s.profileInfo}>
          <img
            referrerPolicy="no-referrer"
            className={s.profileImage}
            src={imageURL}
            alt="profile-img"
          />
          <div className="pl-2">
            <p className={s.name}>
              <b>{userDetails?.details?.name}</b>
            </p>
            <p className={s.designation}>{userDetails?.details?.designation}</p>
          </div>
        </div>
        <div className={cns('btn', s.viewProfileBtn)} onClick={profileRouting}>
          View Profile
        </div>
      </div>
      <hr className={s.hr} />
      <ul className={cns(s.dropdown)}>
        {hrArr.includes(localStorage.getItem('email')?.toLowerCase()) && (
          <li onClick={handleDownload}>
            <img src={downloadIcon} alt="download-img" height={15} width={15} />
            <span className="ml-1"> Employees Certification Data</span>
          </li>
        )}
        <li>
          <Logout />
        </li>
      </ul>
    </div>
  );
};
export default ProfileCard;
