import React, { useEffect, useState } from 'react';

//scss
import s from '../marketplace.module.scss';

//components
import BuySellBody from './BuySellBody';
import PostCardComment from '../PostCommon/PostCardComment';
import PostCardLike from '../PostCommon/PostCardLike';

//other imports
import { useParams } from 'react-router-dom';
import cns from 'classnames';

//assests
import marketPlace from '../../../../src/assets/img/marketPlace/marketPlace.png';
import apartment from '../../../../src/assets/img/marketPlace/apartment.png';
import price from '../../../../src/assets/img/marketPlace/price.png';
import flatApartment from '../../../../src/assets/img/marketPlace/flatApartment.svg';
import flatBudget from '../../../../src/assets/img/marketPlace/flatBudget.svg';
import flatFurnishing from '../../../../src/assets/img/marketPlace/flatFurnishing.svg';
import buy from '../../../../src/assets/img/marketPlace/buy.svg';
import sell from '../../../../src/assets/img/marketPlace/sell.svg';
import parking from '../../../../src/assets/img/marketPlace/parking.png';
import availableDateSeeking from '../../../../src/assets/img/marketPlace/availableDateSeeking.png';
import suitableAny from '../../../../src/assets/img/marketPlace/suitableAny.svg';
import suitableFam from '../../../../src/assets/img/marketPlace/suitableFam.svg';
import suitableBach from '../../../../src/assets/img/marketPlace/suitableBach.svg';
import suitableForAny from '../../../../src/assets/img/marketPlace/suitableForAny.png';
import suitableForBachelor from '../../../../src/assets/img/marketPlace/suitableForBachelor.png';
import suitableForFam from '../../../../src/assets/img/marketPlace/suitableForFam.png';

//MUI
import { Tooltip } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button, Carousel, Modal } from 'react-bootstrap';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { connect } from 'react-redux';

const BuySellPostCard = ({
  data1,
  setData1,
  usersProfile,
  post,
  postData,
  likePost,
  users,
  deleteComment,
  addLoading,
  getEmpIdByEmail,
  commentInputRef,
  likeComment,
  addComment,
  deleteMarketPost,
  getValue,
  pageNum,
  setPage,
  setFinalData,
  finalData,
  currentEditedPost,
  setCurrentEditedPost,
  setCurrentIndex,
  user,
  loading,
  postType = 'all',
  selectedSortOption,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const [expand, setExpand] = useState(false);
  const [expandDesc, setExpandDesc] = useState(false);
  const [timestampArray, setTimestampArray] = useState([]);
  const [flexWrapCont, setFlexWrapCont] = useState(false);
  const [newReactions, setNewReactions] = useState(postData?.postReaction ?? []);
  useEffect(() => {
    if (props?.updatedReaction && props?.updatedReaction?.postId === postData?.postId) {
      setNewReactions(props?.updatedReaction.data);
    }
  }, [props?.updatedReaction]);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const params = useParams();

  const dateArray = post?.postedOn;
  const dateObject =
    post?.postedOn &&
    new Date(
      Date.UTC(
        dateArray[0],
        dateArray[1] - 1,
        dateArray[2],
        dateArray[3],
        dateArray[4],
        dateArray[5],
        Math.floor(dateArray[6] / 1000000)
      )
    ).getTime();

  const dateArray1 = post?.updateDate;
  const dateObject1 =
    post?.updateDate &&
    new Date(
      Date.UTC(
        dateArray1[0],
        dateArray1[1] - 1,
        dateArray1[2],
        dateArray1[3],
        dateArray1[4],
        dateArray1[5],
        Math.floor(dateArray1[6] / 1000000)
      )
    ).getTime();

  useEffect(() => {
    if (post?.postedOn && post?.postedOn != null && dateObject === dateObject1)
      setTimestampArray(post?.postedOn);
    if (post?.updateDate && post?.updateDate != null && dateObject !== dateObject1)
      setTimestampArray(post?.updateDate);
  }, [post?.postedOn, post?.updateDate]);

  const timestampInMilliseconds = new Date(
    timestampArray[0], // Year
    timestampArray[1] - 1, // Month (months are 0-based in JavaScript)
    timestampArray[2], // Day
    timestampArray[3], // Hours
    timestampArray[4], // Minutes
    timestampArray[5], // Seconds
    timestampArray[6] / 1000 // Milliseconds (converted to seconds)
  )?.getTime();
  let isEdited = timestampInMilliseconds;

  useEffect(() => {
    if (params?.id) {
      toggleExpand();
    }
  }, [params?.id]);

  const formatDate = (dateArray) => {
    if (!dateArray || dateArray.length !== 7) {
      return '---'; // Return a default value if the date is not in the expected format
    }

    const dateObject = new Date(...dateArray);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
    const year = dateObject.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const formatDate1 = (dateArray) => {
    // Check if the dateArray is not null and has expected length
    if (!dateArray || dateArray.length !== 5) {
      return '---'; // Return a default value if the date is not in the expected format
    }

    // Destructure dateArray to get year, month, day, hour, minute
    const [year, month, day, hour, minute] = dateArray;

    // Construct a Date object
    const dateObject = new Date(year, month - 1, day, hour, minute);

    // Get individual components
    const formattedDay = dateObject.getDate();
    const formattedMonth = dateObject.getMonth() + 1; // Months are zero-based, so add 1
    const formattedYear = dateObject.getFullYear();

    // Format and return the date string
    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
  };

  const landmarks = post?.area;
  const toggleExpand = () => {
    setExpand(!expand);
  };

  const toggleExpandDesc = () => {
    setExpandDesc(!expandDesc);
    setFlexWrapCont(!flexWrapCont);
  };

  function format(input) {
    if (!input) {
      return '---';
    }

    // Use regex to replace underscores with spaces and format the string
    const formattedText = input.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());

    // Capitalize the first letter and make the rest lowercase
    const finalFormattedText =
      formattedText?.charAt(0).toUpperCase() + formattedText?.slice(1).toLowerCase();

    return finalFormattedText;
  }

  function formatDate3(dateArray) {
    if (!Array.isArray(dateArray) || dateArray.length < 3) {
      return '---'; // Return placeholder if the date array is invalid
    }

    const [year, month, day] = dateArray.slice(0, 3);
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    // Ensure month is within the range 1-12
    const formattedMonth =
      parseInt(month) >= 1 && parseInt(month) <= 12 ? months[parseInt(month) - 1] : '';

    // Ensure day is within the range 1-31
    const formattedDay = parseInt(day) >= 1 && parseInt(day) <= 31 ? parseInt(day) : '';

    // Construct the formatted date string
    const formattedDate = `${formattedDay}${getDaySuffix(formattedDay)} ${formattedMonth} ${year}`;

    return formattedDate;
  }

  // Function to get the suffix for day (e.g., "st", "nd", "rd", "th")
  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  const renderDescription = () => {
    const description = post?.propertyDesc;

    if (!description) {
      return null; // Handle the case where description is not available
    }

    const maxDescriptionLength = post?.isLookingOrLending === 'LOOKING' ? 48 : 35;
    if (expandDesc || description.length <= maxDescriptionLength) {
      // If expanded or text is within the max length, show the entire text with "Show Less" button
      const containerStyle = {
        maxHeight: expandDesc ? 'none' : '60px',
        overflow: 'hidden',
        wordWrap: 'break-word',
        position: 'relative'
      };

      const textStyle = {
        whiteSpace: expandDesc ? 'normal' : 'nowrap'
      };

      return (
        <div style={{ containerStyle }}>
          <p style={{ textStyle }} className="text-break">
            {description}
          </p>
          {expandDesc && (
            <>
              {/* Additional fields for looking posts */}
              {post?.isLookingOrLending === 'LOOKING' && (
                <ul className={s.ulStyle}>
                  <li>
                    <span className={s.fontWeightStyle}>
                      {post?.numberOfBathrooms !== undefined
                        ? post?.numberOfBathrooms === 1
                          ? 'Bathroom Required:'
                          : 'Bathrooms Required:'
                        : '---'}
                    </span>{' '}
                    {post?.numberOfBathrooms || '---'}
                  </li>
                  <li>
                    <span className={s.fontWeightStyle}>
                      {post?.numberOfBedrooms !== undefined
                        ? post?.numberOfBedrooms === 1
                          ? 'Bedroom Required:'
                          : 'Bedrooms Required:'
                        : '---'}
                    </span>{' '}
                    {post?.numberOfBedrooms || '---'}
                  </li>
                  <li>
                    <span className={s.fontWeightStyle}>Move in Date:</span>{' '}
                    {formatDate3(post?.availableFromDate) || '---'}
                  </li>
                </ul>
              )}{' '}
              {post?.isLookingOrLending === 'LENDING' && (
                <ul className={s.ulStyle}>
                  <li>
                    <span className={s.fontWeightStyle}>
                      {post?.numberOfBathrooms !== undefined
                        ? post?.numberOfBathrooms === 1
                          ? 'Bathroom Available:'
                          : 'Bathrooms Available:'
                        : '---'}
                    </span>{' '}
                    {post?.numberOfBathrooms || '---'}
                  </li>
                  <li>
                    <span className={s.fontWeightStyle}>
                      {post?.numberOfBedrooms !== undefined
                        ? post?.numberOfBedrooms === 1
                          ? 'Bedroom Available:'
                          : 'Bedrooms Available:'
                        : '---'}
                    </span>{' '}
                    {post?.numberOfBedrooms || '---'}
                  </li>
                  <li>
                    <span className={s.fontWeightStyle}>Furnishing Type:</span>{' '}
                    {format(post.furnishingType) || '---'}
                  </li>
                </ul>
              )}
              <button onClick={toggleExpandDesc} className={s.buttonStyle}>
                Show Less
              </button>
            </>
          )}
        </div>
      );
    } else {
      // If not expanded and text exceeds max length, show truncated text with "Show More" button
      const truncatedText = `${description.substring(0, maxDescriptionLength)}...`;
      return (
        <>
          {truncatedText}
          <button onClick={toggleExpandDesc} className={s.buttonStyle}>
            Show More
          </button>
        </>
      );
    }
  };

  function capitalizeFirstLetter(inputString) {
    if (inputString && typeof inputString === 'string') {
      return inputString
        .split(' ')
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
    }

    return '';
  }
  return (
    <div className={s.post}>
      <div className={s.postBody}>
        <BuySellBody
          data1={data1}
          setData1={setData1}
          users={users}
          time={isEdited}
          isEdited={dateObject === dateObject1 ? '' : 'Edited'}
          post={post}
          deleteMarketPost={deleteMarketPost}
          postId={post?.id ? post?.id : post?.postId}
          setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
          getValue={getValue}
          pageNum={pageNum}
          setPage={setPage}
          setFinalData={setFinalData}
          finalData={finalData}
          currentEditedPost={currentEditedPost}
          user={post}
          setCurrentIndex={setCurrentIndex}
          loading={loading}
          postType={postType}
          selectedSortOption={selectedSortOption}
        />
      </div>
      <div className={cns(s.postContent, 'container-fluid')}>
        {post?.isLookingOrLending === 'LENDING' ? (
          <div
            className={flexWrapCont ? cns(s.cardContainer) : cns(s.cardContainer, 'row')}
            style={post?.postAvailability === 'UNAVAILABLE' ? { pointerEvents: 'none' } : {}}
          >
            <div
              className={flexWrapCont ? cns(s.cardContent) : cns(s.cardContent, 'col-md-8')}
              style={
                post?.postAvailability === 'UNAVAILABLE'
                  ? { pointerEvents: 'none', backgroundColor: '#D4D4D4' }
                  : {}
              }
            >
              <div style={{ position: 'absolute', right: '0' }}>
                {' '}
                {post?.isLookingOrLending === 'LENDING' && (
                  <div className={s.imageContainer1}>Lending </div>
                )}
                {post?.isLookingOrLending === 'LOOKING' && (
                  <div className={s.imageContainer}>Seeking </div>
                )}
                {post?.transactionType === 'BUY' && <img src={buy} alt="buy img" />}
                {post?.transactionType === 'SELL' && <img src={sell} alt="sell img" />}
              </div>
              <div>
                <div className={s.heading}> {capitalizeFirstLetter(post?.title)}</div>
                <div className={s.locationImage}>
                  <PinDropIcon className={s.pindropIconColor} />{' '}
                  {capitalizeFirstLetter(post?.location)}
                </div>
              </div>
              <div className={s.postDescription}>{renderDescription()}</div>
              <div className={`d-flex flex-wrap ${s.infoContainer}`}>
                <div className="col-md-5 col-6  d-flex pt-2 pb-1 align-items-center">
                  <div className={s.imageLookingInfoContainer}>
                    <img className={s.imgHeight} src={apartment} alt="Card Image" />
                  </div>
                  <Tooltip title={'Property Type'}>
                    <div>
                      <div className={`text-nowrap ps-2 ${s.containerContent}`}>Property Type</div>
                      <div className={`text-nowrap ps-2 ${s.textFormat}`}>
                        {post?.propertyType ? format(post?.propertyType) : '---'}
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <div className="col-md-5 col-6  d-flex pt-2 pb-1 align-items-center">
                  <div className={s.imageLookingInfoContainer}>
                    <img className={s.imgHeight} src={parking} alt="Card Image" />
                  </div>
                  <Tooltip title={'Parking'}>
                    <div>
                      <div className={`text-nowrap ps-2 ${s.containerContent}`}>Parking</div>
                      <div className={`text-nowrap ps-2 ${s.textFormat}`}>
                        {post?.carParkingAvailable ? 'Yes' : 'No'}
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <div className="col-md-5 col-6  d-flex pt-2 pb-1 align-items-center">
                  <div className={s.imageLookingInfoContainer}>
                    <img className={s.imgHeight} src={availableDateSeeking} alt="Card Image" />
                  </div>
                  <Tooltip title={'Available From'}>
                    <div>
                      <div className={`text-nowrap ps-2 ${s.containerContent}`}>Available From</div>
                      <div className={`text-nowrap ps-2 ${s.textFormat}`}>
                        {post?.availableFromDate ? formatDate3(post?.availableFromDate) : '---'}
                      </div>
                    </div>
                  </Tooltip>
                </div>

                <div className="col-md-5 col-6 d-flex pt-1 pb-2 align-items-center">
                  <div className={s.imageLookingInfoContainer}>
                    {post?.suitableFor === 'FAMILY' && (
                      <img className={s.imgHeight} src={suitableForFam} alt="Family Image" />
                    )}
                    {post?.suitableFor === 'BACHELORS' && (
                      <img className={s.imgHeight} src={suitableForBachelor} alt="Bachelor Image" />
                    )}
                    {post?.suitableFor !== 'FAMILY' && post?.suitableFor !== 'BACHELORS' && (
                      <img className={s.imgHeight} src={suitableForAny} alt="Any Image" />
                    )}
                  </div>
                  <Tooltip title={'Suitable For'}>
                    <div>
                      <div className={`text-nowrap ps-2 ${s.containerContent}`}>Suitable For</div>
                      <div className={`text-nowrap ps-2 ${s.textFormat}`}>
                        {post?.suitableFor?.charAt(0).toUpperCase() +
                          post?.suitableFor?.substring(1).toLowerCase()}
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <div className="col-md-5 col-6  d-flex pt-1 pb-2 align-items-center">
                  <div className={s.imageLookingInfoContainer}>
                    <img className={s.imgHeight} src={price} alt="Card Image" />
                  </div>
                  <Tooltip title={'Security Asked'}>
                    <div>
                      <div className={`text-nowrap ps-2 ${s.containerContent}`}>Security Asked</div>
                      <div className={`text-nowrap ps-2 ${s.textFormat}`}>
                        ₹ {post.securityDeposit ? post.securityDeposit : '---'}
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <div className="col-md-5 col-6  d-flex pt-1 pb-2 align-items-center">
                  <div className={s.imageLookingInfoContainer}>
                    <img className={s.imgHeight} src={price} alt="Card Image" />
                  </div>
                  <Tooltip title={'Rent'}>
                    <div>
                      <div className={`text-nowrap ps-2 ${s.containerContent}`}>Rent</div>
                      <div className={`text-nowrap ps-2 ${s.textFormat}`}>
                        ₹ {post?.price ? post?.price : '---'}
                      </div>
                    </div>
                  </Tooltip>
                </div>
              </div>

              <div className={s.landmark}>
                <div className={s.lamdmarkChild}>
                  <span className={s.fontStyle}>Area:</span>

                  <div className={s.chipStyle}>
                    {capitalizeFirstLetter(post?.area) === ''
                      ? 'NA'
                      : capitalizeFirstLetter(post?.area)}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                flexWrapCont ? cns(s.card, 'col-12 m-0 pb-2') : cns(s.card, 'col-md-4 m-0 p-0')
              }
            >
              {post?.postAvailability === 'UNAVAILABLE' && (
                <div className={s.inactivePost}>This property is not available</div>
              )}
              <Carousel
                style={{ position: 'relative' }}
                className="w-100"
                controls={post?.images?.length > 1}
                variant="dark"
                nextIcon={
                  <a className={`rounded-circle bg-white ${s.corStyle}`}>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    </svg>
                  </a>
                }
                prevIcon={
                  <Button className={`rounded-circle bg-white ${s.svButton}`}>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                    </svg>
                  </Button>
                }
              >
                {postData?.files?.length > 0 ? (
                  postData?.files?.map((file, index) => (
                    <Carousel.Item key={index} onClick={handleModalShow}>
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          className={cns(s.cardImage, 'w-100')}
                          src={file.fileName}
                          alt={`Slide ${index + 1}`}
                          style={{
                            minHeight: '180px',
                            objectFit: flexWrapCont ? 'contain' : 'cover',
                            cursor: 'pointer',
                            height: '10rem'
                          }}
                        />
                      </div>
                    </Carousel.Item>
                  ))
                ) : (
                  <Carousel.Item>
                    <div>
                      <Tooltip title="Image not found" arrow>
                        <img
                          className={cns(s.cardImage, 'w-100', s.imgNotFound)}
                          src={marketPlace}
                          alt={`Slide 99`}
                        />
                      </Tooltip>
                    </div>
                  </Carousel.Item>
                )}
              </Carousel>
              <div>
                <Modal
                  show={showModal}
                  onHide={handleModalClose}
                  centered
                  className="custom-tranparent-modal"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Carousel
                      style={{ position: 'relative' }}
                      className="w-100"
                      controls={postData?.files?.length > 1}
                      variant="dark"
                      nextIcon={
                        <a className={`rounded-circle bg-white ${s.svButton}`}>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                          </svg>
                        </a>
                      }
                      prevIcon={
                        <Button className={`rounded-circle bg-white ${s.svButton}`}>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                          </svg>
                        </Button>
                      }
                    >
                      {postData?.files?.length > 0 ? (
                        postData?.files?.map((file, index) => (
                          <Carousel.Item key={index} onClick={handleModalShow}>
                            <img
                              className={cns(s.cardImage, 'w-100', s.cardImgStyles2)}
                              src={file.fileName}
                              alt={`Slide ${index + 1}`}
                            />
                          </Carousel.Item>
                        ))
                      ) : (
                        <Carousel.Item>
                          <img
                            className={cns(s.cardImage, 'w-100', s.cardImgStyles)}
                            src={marketPlace}
                            alt={`Slide 99`}
                          />
                        </Carousel.Item>
                      )}
                    </Carousel>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        ) : (
          <div className={s.cardContainer}>
            <div
              className={s.cardContent}
              style={
                post?.postAvailability === 'UNAVAILABLE'
                  ? {
                      pointerEvents: 'none',
                      backgroundColor: '#D4D4D4',
                      padding: '3px',
                      position: 'relative'
                    }
                  : { position: 'relative', padding: '3px' }
              }
            >
              <div className={s.banner} style={{ position: 'absolute', right: '0' }}>
                {' '}
                {post?.isLookingOrLending === 'LENDING' && (
                  <div className={s.imageContainer1}>Lending </div>
                )}
                {post?.isLookingOrLending === 'LOOKING' && (
                  <div className={s.imageContainer}>Seeking </div>
                )}
                {post?.transactionType === 'BUY' && <img src={buy} alt="buy img" />}
                {post?.transactionType === 'SELL' && <img src={sell} alt="sell img" />}
              </div>
              <div>
                <div className={s.heading}> {post?.title}</div>
                <div className={s.locationImage}>
                  <PinDropIcon className={s.pindropIconColor} />{' '}
                  {capitalizeFirstLetter(post?.location)}
                </div>
              </div>
              <div className={s.postDescription}>
                <p className={s.textParaStyle}>{renderDescription()}</p>
              </div>
              <div className={s.apartmentLookingInfoContainer}>
                <div className={s.apartmentLookingInfo}>
                  <div className={s.imageLookingInfoContainer}>
                    <img className={s.heightStyle} src={flatApartment} alt="apartment Image" />
                  </div>
                  <div>
                    <div className={s.apartmentLookingDesc}>Property&nbsp;Type</div>
                    <div className={`text-nowrap ps-2 ${s.paddingFont}`}>
                      {post?.propertyType ? format(post?.propertyType) : '---'}
                    </div>
                  </div>
                </div>
                <div className={s.apartmentLookingInfo}>
                  <div className={s.imageLookingInfoContainer}>
                    <img className={s.heightStyle} src={flatFurnishing} alt="furniture Image" />
                  </div>
                  <div>
                    <div className={s.apartmentLookingDesc}>Furnishing</div>
                    <div className={`text-nowrap ps-2 ${s.paddingFont}`}>
                      {format(post?.furnishingType)}
                    </div>
                  </div>
                </div>
                <div className={s.apartmentLookingInfo}>
                  <div className={s.imageLookingInfoContainer}>
                    {post?.suitableFor === 'FAMILY' && (
                      <img className={s.heightStyle} src={suitableFam} alt="Family Image" />
                    )}
                    {post?.suitableFor === 'BACHELORS' && (
                      <img className={s.heightStyle} src={suitableBach} alt="Bachelor Image" />
                    )}
                    {post?.suitableFor !== 'FAMILY' && post?.suitableFor !== 'BACHELORS' && (
                      <img className={s.heightStyle} src={suitableAny} alt="Any Image" />
                    )}
                  </div>
                  <div>
                    {' '}
                    <div className={s.apartmentLookingDesc}>Suitable&nbsp;For</div>
                    <div className={`text-nowrap ps-2 ${s.paddingFont}`}>
                      {post?.suitableFor?.charAt(0).toUpperCase() +
                        post?.suitableFor?.substring(1).toLowerCase()}
                    </div>
                  </div>
                </div>
                <div className={s.apartmentLookingInfo}>
                  <div className={s.imageLookingInfoContainer}>
                    <img className={s.heightStyle} src={flatBudget} alt="price Image" />
                  </div>
                  <div>
                    <div className={s.apartmentLookingDesc}>Rent</div>
                    <div className={`text-nowrap ps-2 ${s.paddingFont}`}>
                      ₹ {post?.price ? post?.price : '---'}
                    </div>
                  </div>
                </div>
              </div>
              <hr className={s.lineStyle} />
              <div className={s.landmark1}>
                <div className={s.landmarkNewStyle}>
                  <span className={s.fontStyle}>Preferred Area:</span>

                  <div className={s.chipStyle}>
                    {capitalizeFirstLetter(post?.area) === ''
                      ? 'NA'
                      : capitalizeFirstLetter(post?.area)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={s.postFooter}>
          <PostCardLike
            users={users}
            likePost={likePost}
            likes={newReactions ?? []}
            comments={postData.comments ?? postData.comment ?? []}
            userReaction={postData.postReaction}
            toggleExpand={toggleExpand}
            postId={post?.id ? post?.id : post?.postId}
          />
          {expand ? (
            <PostCardComment
              users={users}
              post={post}
              comments={postData.comments ?? postData.comment ?? []}
              deleteComment={deleteComment}
              addComment={addComment}
              addLoading={addLoading}
              postId={post?.id ? post?.id : post?.postId}
              likeComment={likeComment}
              toggleExpand={toggleExpand}
              commentInputRef={commentInputRef}
              getEmpIdByEmail={getEmpIdByEmail}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    updatedReaction: state.postLikeReducer.marketPlaceUpdatedReaction
  };
}

export default connect(mapStateToProps, null)(BuySellPostCard);
