import {
  GET_HASHTAG_FAILURE,
  GET_HASHTAG_LOADING,
  GET_HASHTAG_SUCCESS,
  GET_TRENDING_HASHTAG_FAILURE,
  GET_TRENDING_HASHTAG_LOADING,
  GET_TRENDING_HASHTAG_SUCCESS
} from '../actions/actions';

const initialState = {
  getLoading: false,
  hashtags: [],
  trendingHashtags: [],
  error: ''
};

const hashtagReducer = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_HASHTAG_LOADING:
      newState.getLoading = true;
      newState.hashtags = [];
      break;

    case GET_HASHTAG_SUCCESS:
      newState.hashtags = [...action.payload.hashtags];
      newState.error = '';
      newState.getLoading = false;
      break;

    case GET_HASHTAG_FAILURE:
      newState.getLoading = false;
      newState.error = action.payload.error;
      break;

    case GET_TRENDING_HASHTAG_LOADING:
      newState.getLoading = true;
      newState.trendingHashtags = [];
      break;

    case GET_TRENDING_HASHTAG_SUCCESS:
      newState.trendingHashtags = [action.payload.hashtags];
      newState.error = '';
      newState.getLoading = false;
      break;

    case GET_TRENDING_HASHTAG_FAILURE:
      newState.getLoading = false;
      newState.error = action.payload.error;
      break;
  }
  return newState;
};

export default hashtagReducer;
