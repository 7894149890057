import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { Tooltip } from '@material-ui/core';

// Stylesheet
import s from './rightWrapper.module.scss';

//Helpers
import { getProfileByEmail, TOOLTIP_MSSG } from '../../../../../helpers';
import Viewport from '../../../../helpers/viewport';
import { Skeleton } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';

export default function Reportees(props) {
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const reporteeCount = () => {
    let flag = 0;
    for (let i = 0; i < props.reportees.length; i++) {
      if (props.reportees[i].email === 'vishal.malik@geminisolutions.com') {
        flag = 1;
      }
    }
    return flag ? props.reportees.length - 1 : props.reportees.length;
  };

  const Mobile = () => {
    return (
      <div>
        <div className={s.reportee}>
          {props.reportees.map((reportee) => {
            reportee.unique_id = uniqueId();
            return (
              <>
                {reportee.email !== 'vishal.malik@geminisolutions.com' && (
                  <>
                    {props.loading ? (
                      <div className={s.detailsLoading}>
                        <Skeleton variant="circle" className={s.circleLoader} />
                        <div className={s.detailsLoadingContainer}>
                          <Skeleton variant="text" className={s.textLoader} />
                          <Skeleton variant="text" className={s.textLoader} />
                        </div>
                      </div>
                    ) : (
                      <div key={reportee.unique_id} className={s.managerDescription}>
                        <img
                          referrerPolicy="no-referrer"
                          className={s.image}
                          height={20}
                          width={20}
                          src={reportee.photoUrl}
                          alt="user"
                          onClick={() => {
                            getProfileByEmail(reportee.email);
                          }}
                        />

                        <div className={s.managerDetail}>
                          <Tooltip title={TOOLTIP_MSSG.VIEW_PROFILE}>
                            <h5
                              onClick={() => {
                                getProfileByEmail(reportee.email);
                              }}
                            >
                              {reportee.name}
                            </h5>
                          </Tooltip>
                          <p>{reportee.designation}</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const Desktop = () => {
    return (
      <div>
        <div className={s.managerTitle}>
          <h4>
            Reportees <span className={s.reporteesCount}>{reporteeCount()}</span>
          </h4>
        </div>

        <div className={s.reportee}>
          {props.reportees.map((reportee) => {
            reportee.unique_id = uniqueId();

            return (
              reportee.email !== 'vishal.malik@geminisolutions.com' &&
              (props.loading ? (
                <div className={s.detailsLoading} key={reportee.unique_id}>
                  <Skeleton variant="circle" className={s.circleLoader} />
                  <div className={s.detailsLoadingContainer}>
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                  </div>
                </div>
              ) : (
                <a
                  href={window.location.origin + `/#/profile/${reportee.email}`}
                  key={reportee.unique_id}
                  className={s.managerDescription}
                >
                  <img
                    referrerPolicy="no-referrer"
                    height={20}
                    width={20}
                    src={reportee.photoUrl}
                    alt="user"
                    onClick={() => {
                      getProfileByEmail(reportee.email);
                    }}
                    className={s.image}
                  />

                  <div className={s.managerDetail}>
                    <Tooltip title={TOOLTIP_MSSG.VIEW_PROFILE}>
                      <h5
                        onClick={() => {
                          getProfileByEmail(reportee.email);
                        }}
                      >
                        {reportee.name}
                      </h5>
                    </Tooltip>
                    <p>{reportee.designation}</p>
                  </div>
                </a>
              ))
            );
          })}
        </div>
      </div>
    );
  };
  return <>{isMobile ? <Mobile /> : <Desktop />}</>;
}
