import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Route, HashRouter, Switch, Redirect } from 'react-router-dom';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Dashboard from './pages/dashboard';
import Auth from './pages/auth';
import Profile from './pages/dashboard/components/profile/index';
import ProfileShare from './pages/dashboard/components/profile/indexShare';
import ClubDetailsPage from './pages/dashboard/components/clubs/sportsClub/index.js';
import Clubs from './pages/dashboard/components/clubs';

// Toast Container
import { ToastContainer } from 'react-toastify';

// Error Components
import NotFound from './pages/error/notFound';

// Scss
import './App.scss';

// Action Creators
import { getEmployeeIdByEmail, getImageURL, getProfileDetailsById } from './pages/auth/api';
import { updateStorageUser } from './redux/reducers/storage.reducer';
import { store } from './redux/createStore';
import Axios from 'axios';
import MarketPlace from './pages/MarketPlace/MarketPlace';
import { MyContextProvider } from './context/MyContext.js';
import HRDashboard from './pages/hrDashboard';

// Lazy load the Publications component
const Publications = lazy(() => import('./pages/publications/index.js'));

const App = (props) => {
  const [mail, setMail] = useState('');
  const { accounts, instance } = useMsal();

  const localStorageSetter = (email) => {
    localStorage.setItem('email', email.toLowerCase());
    localStorage.setItem('tabValue', 1);
    store.dispatch(updateStorageUser({ email: email.toLowerCase() }));
    getEmployeeIdByEmail(email.toLowerCase()).then((res) => {
      localStorage.setItem('id', res?.data);
      store.dispatch(updateStorageUser({ id: res?.data }));
      getProfileDetailsById(res?.data).then((response) => {
        store.dispatch(updateStorageUser({ details: response?.data }));
      });
    });

    getImageURL(email.toLowerCase()).then((res) => {
      localStorage.setItem('imageURL', res.data[0]?.photoUrl);
      store.dispatch(updateStorageUser({ imageURL: res.data[0]?.photoUrl }));
    });
  };

  Axios.interceptors.request.use((config) => {
    if (accounts.length > 0) {
      const accessTokenRequest = {
        scopes: ['api://gembook/.default'],
        account: accounts[0]
      };

      return instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          localStorage.setItem('accessToken', response.accessToken);
          config.headers.Authorization = `Bearer ${response.accessToken}`;
          return config;
        })
        .catch((error) => {
          console.log(error);
          return Promise.reject(error);
        });
    }

    return config;
  });

  useEffect(() => {
    if (accounts.length > 0) {
      localStorage.setItem('oid', accounts[0].idTokenClaims.oid);
      localStorage.setItem('homeAccountId', accounts[0].homeAccountId);
      localStorageSetter(accounts[0].username);
    } else if (mail) {
      // authentication through email - decoded from calling '/me' API from an active session
      localStorageSetter(mail);
    }
  }, [accounts, mail]);

  const renderUnAuthenticatedRoute = () => {
    return (
      <UnauthenticatedTemplate>
        <Redirect to="/" />
      </UnauthenticatedTemplate>
    );
  };

  const renderAuthenticatedRoute = (Component, props) => {
    return (
      <AuthenticatedTemplate>
        <Component {...props} />
      </AuthenticatedTemplate>
    );
  };

  return (
    <div>
      <MyContextProvider>
        <HashRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <>
                    {!mail ? (
                      <>
                        <AuthenticatedTemplate>
                          <Redirect to="/dashboard" />
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                          <Auth mail={mail} setMail={setMail}></Auth>
                        </UnauthenticatedTemplate>
                      </>
                    ) : (
                      <Redirect to="/dashboard" />
                    )}
                  </>
                )}
              ></Route>
              <Route
                exact
                path="/dashboard"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <Dashboard {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(Dashboard, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>

              <Route
                exact
                path="/profile"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <Profile {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(Profile, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>
              <Route
                exact
                path="/profile/:id"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <ProfileShare {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(ProfileShare, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>
              <Route
                exact
                path="/dashboard/:id"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <Dashboard {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(Dashboard, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>
              <Route
                exact
                path="/clubs"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <Clubs {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(Clubs, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>
              <Route
                exact
                path="/clubs/:cid"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <ClubDetailsPage {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(ClubDetailsPage, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>
              <Route
                exact
                path="/clubs/:cid/event/:eid"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <ClubDetailsPage {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(ClubDetailsPage, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>
              <Route
                exact
                path="/dashboard/tags/:tag"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <Dashboard {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(Dashboard, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>
              <Route
                path="/marketplace"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <MarketPlace {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(MarketPlace, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>
               <Route
                path="/hr-dashboard"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <HRDashboard {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(HRDashboard, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>
              <Route
                exact
                path="/publications/pollux"
                render={(props) => (
                  <>
                    {localStorage.getItem('email') ? (
                      <Publications {...props} />
                    ) : (
                      <>
                        {renderAuthenticatedRoute(Publications, props)}
                        {renderUnAuthenticatedRoute()}
                      </>
                    )}
                  </>
                )}
              ></Route>

              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </HashRouter>
      </MyContextProvider>
      <ToastContainer />
    </div>
  );
};

export default App;
