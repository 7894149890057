import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';

import { uniqueId } from 'lodash';

//Action Creator
import {
  getCertification,
  editCertification,
  addCertification,
  deleteCertification,
  getCertiTechTypes
} from '../../../../../redux/actionCreators/profile/certification.actionCreator';

import cns from 'classnames';

// icons
import editpost from '../../../../../assets/icons/editpost.svg';
import deleteBin from '../../../../../assets/icons/delete-bin.svg';
import close from '../../../../../assets/icons/close.svg';
import addIcon from '../../../../../assets/icons/addIcon.svg';
import save from '../../../../../assets/icons/saveDetails.png';
import certIcon from '../../../../../assets/icons/Certifications.svg';

//SCSS
import s from './components.module.scss';
import cls from './certification.module.scss';
import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal';
import { DATE_FORMAT, getDateFromArray, popUpMessages, TOOLTIP_MSSG } from '../../../../../helpers';
import { Tooltip, Button, Modal, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';

class Certification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageLoaded: false,
      newImage: '',
      activeCerti: -1,
      certificates: this.props?.certifications,
      newCertificate: {
        issueDate: '',
        name: '',
        provider: '',
        certFile: '',
        certificateLevel: '',
        techTypeId: '',
        customTechName: ''
      },
      certificatesWithSrc: [],
      editFlag: false,
      addFlag: false,
      error: false,
      reload: false,
      certiType: '',
      date: '',
      maxDate: new Date().toISOString().split('T')[0],
      imgLoad: true,
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      modalData: {
        showModal: false
      },
      isDeleted: false
    };
    this.inputRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  toggleModal = () => {
    this.setState({ modalData: { showModal: !this.state.modalData.showModal } });
  };

  componentDidMount() {
    this.props.getCertiTechTypes();
    if (this.props?.certifications?.length) {
      this.setState({
        imgLoad: false,
        certificates: this.props?.certifications
      });
    } else {
      this.props.getCertification();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.certifications.length !== prevProps.certifications.length) {
      this.setState({
        ...this.state,
        certificates: [...this.props.certifications],
        isDeleted: false
      });
    } else if (prevProps.certificates?.length < prevState.certifications?.length) {
      this.setState({
        imgLoad: false,
        certificates: this.props.certifications,
        certificatesWithSrc: this.props.certifications
      });
    } else if (prevProps.certifications !== this.props.certifications) {
      this.setState({
        ...this.state,
        certificates: [...this.props.certifications]
      });
    }
  }
  handleChange = async (e, idx, identifier) => {
    let certificates = [...this.state.certificates];
    let certificate = { ...certificates[idx] };
    if (identifier === 'certificateUrl') {
      certificate[identifier] = [...Array.from(e)];
      e[0].type === 'application/pdf'
        ? (certificate.pdf = URL.createObjectURL(e[0]))
        : (certificate.img = URL.createObjectURL(e[0]));
    } else {
      if (identifier === 'techTypeId') {
        certificate.tech.techTypeId = e;
      } else if (identifier === 'certiType') {
        certificate[identifier] = e;
      } else if (identifier === 'category') {
        certificate.tech.category = e.toUpperCase();
        certificate.tech.techTypeId = -1;
      } else if (identifier === 'customTechName') {
        certificate.customTechName = e;
      } else {
        certificate[identifier] = e;
      }
    }

    certificates[idx] = certificate;
    this.setState({
      ...this.state,
      certificates: certificates
    });
  };

  techOptions = (classificationType) => {
    const filteredData = this.props.techTypes.filter(
      (item) => item.category === classificationType
    );

    return filteredData.map((option) => ({
      value: option.techTypeId,
      label: option.name
    }));
  };

  handleDelete = (idx, certificates) => {
    if (this.state.certificates[idx].certificateId === null) {
      this.setState({
        ...this.state,
        certificates: [
          ...this.state.certificates.filter((certificate, index) => {
            if (index !== idx) {
              return certificate;
            }
            return null;
          })
        ]
      });
    } else {
      this.props.deleteCertification(this.state.certificates[idx].certificateId);
      let certificateAfterDelete = [
        ...this.state.certificates.filter((certificate, index) => {
          if (index !== idx) {
            return certificate;
          }
          return null;
        })
      ];
      this.setState({
        ...this.state,
        isDeleted: true,
        certificates: certificateAfterDelete,
        certificatesWithSrc: certificateAfterDelete
      });
    }
  };

  handleClose = () => {
    for (let i = this.state.certificates.length - 1; i >= 0; i--) {
      if (!this.state.certificates[i].certificateId) {
        this.state.certificates.pop();
      }
    }
    this.setState({ certificates: [...this.props?.certifications], editFlag: false, error: false });
  };
  handleCloseNewCertificate = () => {
    this.setState({
      addFlag: false,
      newCertificate: {
        issueDate: '',
        name: '',
        provider: '',
        certFile: '',
        certificateLevel: '',
        techTypeId: '',
        customTechName: ''
      },
      error: false
    });
  };

  handleAddNew = () => {
    this.setState({ addFlag: !this.state.addFlag });
  };
  refreshPage = () => {
    this.setState({ reload: true }, () => this.setState({ reload: false }));
  };
  inputRefFocus = () => {
    if (this.state.certificates.length && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };
  handleSave = async () => {
    let data = {};
    let promises = [];
    for (let i = 0; i < this.state.certificates.length; i++) {
      data.certificates = this.state.certificates[i];
      if (
        data.certificates.name === '' ||
        data.certificates.provider === '' ||
        data.certificates.issueDate === '' ||
        data.certificates.certFile === '' ||
        data.certificates.certificateLevel === '' ||
        data.certificates.tech.category === '' ||
        data.certificates.tech.techTypeId === -1
      ) {
        toast.warn('Please fill all the required fields');
        this.setState({ error: true });
        return;
      }

      if (data.certificates.name.length > 100 || data.certificates.provider.length > 80) {
        toast.error('Please Enter valid Details');
        return;
      }
      const {
        name,
        provider,
        issueDate,
        certFile,
        certificateUrl,
        techTypeId,
        tech,
        certificateLevel,
        customTechName
      } = data.certificates;
      let requestBody = {
        certificates: {
          name,
          provider,
          issueDate,
          certificateLevel: certificateLevel.toUpperCase(),
          tech: tech,
          customTechName
        },
        certFile:
          (certFile || [])[0] instanceof File
            ? certFile[0]
            : (certificateUrl || [])[0] instanceof File
            ? certificateUrl[0]
            : certificateUrl
      };

      if (this.state.certificates[i].certificateId) {
        if (
          (requestBody.certFile || [])[0] instanceof File &&
          requestBody.certFile.startsWith('https://gembook-original.s3')
        )
          requestBody.certFile = '';
        let certificateId = this.state.certificates[i].certificateId;
        this.props.editCertification(requestBody, certificateId);
        this.setState({ editFlag: false, error: false });
      }
    }
  };

  handleSaveNewCertificate = () => {
    let certificate = this.state.newCertificate;

    if (
      certificate.name === '' ||
      certificate.provider === '' ||
      certificate.issueDate === '' ||
      certificate.certFile === '' ||
      certificate.certificateLevel === '' ||
      certificate.techTypeId === ''
    ) {
      toast.warn('Please fill all the required fields');
      this.setState({ error: true });
      return;
    }

    if (certificate.name.length > 100 || certificate.provider.length > 80) {
      toast.error('Please Enter valid Details');
      return;
    }

    const {
      name,
      provider,
      issueDate,
      certFile,
      certificateUrl,
      techTypeId,
      certificateLevel,
      customTechName
    } = certificate;
    let requestBody = {
      certificates: {
        name,
        provider,
        issueDate,
        certificateLevel: certificateLevel.toUpperCase(),
        techTypeId,
        customTechName
      },
      certFile: certFile
    };
    const loaderToastId = toast.info('Saving data...', { autoClose: false });

    this.props
      .addCertification(requestBody)
      .then((response) => {
        toast.dismiss(loaderToastId);
        if (response.status === 201) {
          toast.success('Certificate Saved Successfully!');
          this.props.getCertification();
          this.props.getCertiTechTypes();
          this.setState({
            newCertificate: {
              issueDate: '',
              name: '',
              provider: '',
              certFile: '',
              certificateLevel: '',
              techTypeId: '',
              customTechName: ''
            },
            error: false
          });
        }
        return response;
      })
      .catch((error) => {
        toast.dismiss(loaderToastId);

        if (error.response.data === 'File already exists')
          toast.error('Certificate Data already exists!');
        else toast.error('Failed to add Certificate. Please try again later!');
        return;
      });
  };

  handleSetDate = (year, month, day) => {
    const val = year.toString() + '-' + month.toString() + '-' + day.toString();
    this.setState({ date: val });
  };

  style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '12px'
  };

  render() {
    return (
      <div className={cns(s.certiWrapper)} ref={this.wrapperRef}>
        {this.state.editFlag ? (
          <>
            <div className={s.heading}>
              <h5>Certification</h5>
              <div className={s.buttonContainer}>
                <button onClick={this.handleSave} className={s.saveButton}>
                  <Tooltip title={TOOLTIP_MSSG.SAVE}>
                    <img src={save} alt="save Icon" />
                  </Tooltip>
                </button>

                <button onClick={this.handleClose} className={s.saveButton}>
                  <Tooltip title="Click to Close">
                    <img src={close} alt="close Icon" />
                  </Tooltip>
                </button>
              </div>
            </div>
            {React.Children.toArray(
              this.state?.certificates.map((certi, idx) => {
                let issueDate = getDateFromArray(certi.issueDate, DATE_FORMAT.YYYY_MM_DD);
                let shouldDeleteCertification = certi.certificateId ? true : false;

                return (
                  <div className={s.inputWrapper}>
                    <div className={s.upperInputs}>
                      <input
                        placeholder="Certificate name"
                        className={
                          this.state.error && certi.name === '' ? s.inputError : s.inputName
                        }
                        value={certi.name}
                        name="certificate"
                        onChange={(e) => this.handleChange(e.target.value, idx, 'name')}
                        type="text"
                        maxLength={101}
                      />
                    </div>
                    {certi.name.length > 100 && (
                      <p className={s.errorMsg}>
                        Certificate Name should not be more than 100 characters
                      </p>
                    )}

                    <div className={s.upperInputs}>
                      <input
                        placeholder="Provided by"
                        className={
                          this.state.error && certi.provider === '' ? s.inputError : s.inputName
                        }
                        value={certi.provider}
                        name="provider"
                        onChange={(e) => this.handleChange(e.target.value, idx, 'provider')}
                        type="text"
                        maxLength={81}
                      />
                    </div>
                    {certi.provider.length > 80 && (
                      <p className={s.errorMsg}>
                        Certificate Provider Name should not be more than 80 characters
                      </p>
                    )}
                    <div className={s.lowerInputs}>
                      <select
                        value={certi.certificateLevel}
                        name="certificateLevel"
                        className={
                          this.state.error && certi.certificateLevel === ''
                            ? s.inputError
                            : s.inputName
                        }
                        onChange={(e) => this.handleChange(e.target.value, idx, 'certificateLevel')}
                      >
                        <option disabled value="">
                          Select Level
                        </option>
                        <option>Beginner</option>
                        <option>Intermediate</option>
                        <option>Expert</option>
                      </select>
                      <input
                        className={
                          this.state.error && certi.issueDate === ''
                            ? s.inputError
                            : cns(s.inputName, 'ml-2')
                        }
                        value={issueDate}
                        name="issueDate"
                        max={this.state.maxDate}
                        onChange={(e) => this.handleChange(e.target.value, idx, 'issueDate')}
                        type="date"
                      />

                      {shouldDeleteCertification && (
                        <div className={s.saveDeleteWrapper}>
                          <button
                            onClick={() => {
                              this.setState({
                                modalData: {
                                  method: 'delete',
                                  message: popUpMessages.CONFIRM_DELETE_CERTIFICATION,
                                  heading: popUpMessages.DELETE_CERTIFICATION,
                                  showModal: true,
                                  index: idx,
                                  value: certi.name
                                },
                                editFlag: !this.state.editFlag
                              });
                              this.handleClose();
                            }}
                          >
                            <img src={deleteBin} height={24} width={24} alt="delete Icon" />
                          </button>
                        </div>
                      )}
                    </div>
                    <div className={s.lowerInputs}>
                      <select
                        value={certi.tech.category}
                        name="classificationType"
                        className={
                          this.state.error && certi.tech.category === ''
                            ? s.inputError
                            : s.inputName
                        }
                        onChange={(e) => this.handleChange(e.target.value, idx, 'category')}
                      >
                        <option disabled value="">
                          Select Type
                        </option>
                        <option value="TECHNICAL">Technical</option>
                        <option value="BEHAVIOUR">Behaviour</option>
                        <option value="FUNCTIONAL">Functional</option>
                      </select>
                      <select
                        value={certi.tech.techTypeId}
                        name="techTypeId"
                        className={
                          this.state.error && certi.tech.techTypeId === -1
                            ? s.inputError
                            : cns(s.inputName, 'ml-2')
                        }
                        onChange={(e) => this.handleChange(e.target.value, idx, 'techTypeId')}
                      >
                        <option disabled value={-1}>
                          Select Technology
                        </option>
                        {this.techOptions(certi.tech.category).map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {this?.techOptions(certi.tech.category).filter(
                        (cat) => certi.tech.techTypeId == cat.value
                      )[0]?.label == 'Other' && (
                        <>
                          <input
                            autoFocus
                            autoComplete="off"
                            placeholder="Others"
                            className={cns(
                              s.parallelField,
                              'ml-2',
                              this.state.error && certi.customTechName === ''
                                ? s.inputError
                                : s.inputName
                            )}
                            value={certi.customTechName}
                            name="certificate"
                            onChange={(e) => {
                              this.handleChange(e.target.value, idx, 'customTechName');
                            }}
                            type="text"
                            maxLength={101}
                          />
                        </>
                      )}
                    </div>
                    {this.state.editFlag && !certi.certificateId ? (
                      <input
                        name="certFile"
                        type="file"
                        id="file"
                        className={
                          this.state.error && certi.certFile === ''
                            ? s.certFileInputError
                            : s.certFileLabel
                        }
                        accept="image/*,application/pdf"
                        onChange={(e) => this.handleChange(e.target.files, idx, 'certFile')}
                      />
                    ) : (
                      <>
                        <label className="d-flex">
                          <input
                            name="certFile"
                            type="file"
                            id="file"
                            className={
                              this.state.error && certi.certFile === ''
                                ? s.certFileInputError
                                : cns(s.certFileLabel, s.certFileLabelActive)
                            }
                            accept="image/*,application/pdf"
                            onChange={(e) => {
                              this.handleChange(e.target.files, idx, 'certificateUrl');
                            }}
                          />
                          <Tooltip title="Edit certificate file">
                            <span style={{ cursor: 'pointer', position: 'relative', width: '15%' }}>
                              <img
                                referrerPolicy="no-referrer"
                                src={editpost}
                                alt="edit post Icon"
                                width={18}
                                height={18}
                                className={s.editCertiFile}
                              />
                            </span>
                          </Tooltip>
                        </label>
                        {!certi.certificateUrl.includes('.pdf') &&
                        !(certi.certificateUrl[0] instanceof File) ? (
                          <>
                            {certi.img && certi.certificateUrl[0] instanceof File ? (
                              <img
                                className={`rounded ${s.certiImg}`}
                                alt="certificate image"
                                src={URL.createObjectURL(certi.certificateUrl[0])}
                              />
                            ) : (
                              <img
                                className={`rounded ${s.certiImg}`}
                                alt="certificate image"
                                src={certi?.certificateUrl}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {certi.pdf && certi.certificateUrl[0] instanceof File ? (
                              <iframe
                                src={certi.pdf}
                                style={{ borderRadius: '12px' }}
                                frameBorder="0"
                              ></iframe>
                            ) : certi.img && certi.certificateUrl[0] instanceof File ? (
                              <img
                                className={`rounded ${s.certiImg}`}
                                alt="certificate image"
                                src={certi?.img}
                              />
                            ) : (
                              <iframe
                                src={certi.certificateUrl}
                                style={{ borderRadius: '12px' }}
                                frameBorder="0"
                              ></iframe>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                );
              })
            )}
          </>
        ) : (
          <>
            <div className={s.heading}>
              <h5>Certification</h5>

              {this.state.certificates.length || !this.state.imgLoad ? (
                !this.state.addFlag ? (
                  <div className={s.buttons}>
                    <div className={s.addButton}>
                      <button onClick={() => this.handleAddNew()}>
                        <Tooltip title={TOOLTIP_MSSG.ADD}>
                          <img className={s.addButton} src={addIcon} alt="add Icon" />
                        </Tooltip>
                      </button>
                    </div>

                    <div className={s.editIcon}>
                      <button
                        className={s.editBtn}
                        onClick={() => this.setState({ editFlag: !this.state.editFlag })}
                      >
                        <Tooltip title={TOOLTIP_MSSG.EDIT}>
                          <img src={editpost} alt="edit post Icon" width={18} height={18} />
                        </Tooltip>
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )
              ) : (
                <div className={s.editIcon}>
                  <button className={s.addButton} onClick={() => this.handleAddNew()}>
                    <Tooltip title={TOOLTIP_MSSG.ADD}>
                      <img src={addIcon} alt="add Icon" />
                    </Tooltip>
                  </button>
                </div>
              )}
            </div>

            <div className={s.description}>
              {this.props.getLoading ? (
                <div className={s.containerLoader}>
                  <div>
                    <Skeleton variant="circle" className={s.circleLoader} />
                  </div>
                  <div className={s.detailsLoadingContainer}>
                    <Skeleton variant="text" className={s.textLoad} />
                    <Skeleton variant="text" className={s.textLoad} />
                    <Skeleton variant="text" className={s.textLoad} />
                  </div>
                </div>
              ) : (
                <>
                  {this.state.certificates?.length ? (
                    <div className={cls.certificates}>
                      {this.state?.certificates.map((certi, idx) => {
                        certi.uniqueKey = uniqueId();
                        return (
                          <div className={cls.certiContainer} key={certi.uniqueKey}>
                            <div>
                              <img src={certIcon} />
                            </div>

                            <div className={cls.certiInfoContainer} key={certi.uniqueKey}>
                              <div className={cls.certiInfo}>
                                <div className="d-flex align-items-center">
                                  <Button
                                    className={cls.certButton}
                                    onClick={() => {
                                      this.setState({ activeCerti: idx });
                                    }}
                                  >
                                    <Tooltip title="View Certificate">
                                      <p className={cls.certiName}>{certi.name}</p>
                                    </Tooltip>
                                  </Button>
                                  <p className={cls?.certLevel}>
                                    {_.capitalize(certi?.certificateLevel)}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center mt-1">
                                  <p className={cls.certiProvider}>{certi.provider} </p> |
                                  <p className={cns('text-secondary mx-2', cls.issueDate)}>
                                    {' '}
                                    Issued on {new Date(certi.issueDate).toDateString()}
                                  </p>
                                </div>
                                <div className="d-flex mt-1">
                                  <p className={cls.classType}>
                                    {_.capitalize(certi?.classificationType)}
                                  </p>
                                  <p className={cls.tech}>{certi?.tech?.name}</p>
                                </div>
                              </div>
                              <Modal
                                open={this.state.activeCerti === idx ? true : false}
                                onClose={() => this.setState({ activeCerti: -1 })}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={this.style}>
                                  {!certi.certificateUrl.includes('.pdf') &&
                                  !(certi.certificateUrl[0] instanceof File) ? (
                                    <img
                                      className="w-100 rounded-lg"
                                      alt="certificate image"
                                      src={certi.certificateUrl}
                                    />
                                  ) : (
                                    <iframe
                                      src={certi.pdf ?? certi.certificateUrl}
                                      style={{
                                        width: '100%',
                                        height: '500px',
                                        borderRadius: '12px'
                                      }}
                                      frameBorder="0"
                                    ></iframe>
                                  )}
                                </Box>
                              </Modal>
                              {idx !== this.state.certificates.length - 1 ? '  ' : null}{' '}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : !this.state.addFlag ? (
                    <i className="text-secondary">No Certification added.</i>
                  ) : (
                    ''
                  )}
                  {this.state.addFlag ? (
                    <>
                      <hr />
                      <div className={s.newCertiCard}>
                        <div className={s.addCertiHeading}>
                          ADD NEW CERTIFICATE
                          <div className={s.buttonContainer}>
                            <Tooltip title={TOOLTIP_MSSG.SAVE}>
                              <button onClick={this.handleSaveNewCertificate}>
                                <img height={23} src={save} alt="save Icon" />
                              </button>
                            </Tooltip>
                            <Tooltip title={TOOLTIP_MSSG.CLOSE}>
                              <button onClick={this.handleCloseNewCertificate}>
                                <img height={23} alt="close Icon" src={close} />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                        <div className={s.inputWrapper}>
                          <div className={s.upperInputs}>
                            <input
                              autoFocus
                              autoComplete="off"
                              placeholder="Certificate name"
                              className={
                                this.state.error && this.state.newCertificate.name === ''
                                  ? s.inputError
                                  : s.inputName
                              }
                              value={this.state.newCertificate.name}
                              name="certificate"
                              onChange={(e) => {
                                this.setState({
                                  newCertificate: {
                                    ...this.state.newCertificate,
                                    name: e.target.value
                                  }
                                });
                              }}
                              type="text"
                              maxLength={101}
                            />
                          </div>
                          {this.state.newCertificate.name.length > 100 && (
                            <p className={s.errorMsg}>
                              Certificate Name should not be more than 100 characters
                            </p>
                          )}

                          <div className={s.upperInputs}>
                            <input
                              autoComplete="off"
                              placeholder="Provided by"
                              className={
                                this.state.error && this.state.newCertificate.provider === ''
                                  ? s.inputError
                                  : s.inputName
                              }
                              value={this.state.newCertificate.provider}
                              name="provider"
                              onChange={(e) =>
                                this.setState({
                                  newCertificate: {
                                    ...this.state.newCertificate,
                                    provider: e.target.value
                                  }
                                })
                              }
                              type="text"
                              maxLength={81}
                            />
                          </div>
                          {this.state.newCertificate.provider.length > 80 && (
                            <p className={s.errorMsg}>
                              Certificate Provider Name should not be more than 80 characters
                            </p>
                          )}

                          <div className={s.lowerInputs}>
                            <select
                              value={this.state.newCertificate.certificateLevel}
                              name="certificateLevel"
                              className={
                                this.state.error &&
                                this.state.newCertificate.certificateLevel === ''
                                  ? s.inputError
                                  : s.inputName
                              }
                              onChange={(e) =>
                                this.setState({
                                  newCertificate: {
                                    ...this.state.newCertificate,
                                    certificateLevel: e.target.value
                                  }
                                })
                              }
                            >
                              <option disabled value="">
                                Select Level
                              </option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Expert</option>
                            </select>

                            <input
                              className={
                                this.state.error && this.state.newCertificate.issueDate === ''
                                  ? s.inputError
                                  : cns(s.inputName, 'ml-2')
                              }
                              value={this.state.newCertificate.issueDate}
                              name="issueDate"
                              max={this.state.maxDate}
                              onChange={(e) =>
                                this.setState({
                                  newCertificate: {
                                    ...this.state.newCertificate,
                                    issueDate: e.target.value
                                  }
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className={s.lowerInputs}>
                            <select
                              value={this.state.certiType}
                              name="classificationType"
                              className={cns(
                                s.parallelField,
                                this.state.error && this.state.certiType === ''
                                  ? s.inputError
                                  : s.inputName
                              )}
                              onChange={(e) =>
                                this.setState({
                                  certiType: e.target.value
                                })
                              }
                            >
                              <option disabled value="">
                                Select Type
                              </option>
                              <option>Technical</option>
                              <option>Behaviour</option>
                              <option>Functional</option>
                            </select>
                            <select
                              value={this.state.newCertificate.techTypeId}
                              name="techTypeId"
                              className={cns(
                                s.parallelField,
                                this.state.error && this.state.newCertificate.techTypeId === ''
                                  ? s.inputError
                                  : cns(s.inputName, 'ml-2')
                              )}
                              onChange={(e) =>
                                this.setState({
                                  newCertificate: {
                                    ...this.state.newCertificate,
                                    techTypeId: e.target.value
                                  }
                                })
                              }
                            >
                              <option disabled value="">
                                Select Technology
                              </option>
                              {this?.techOptions(this.state?.certiType.toUpperCase()).map(
                                (option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                )
                              )}
                            </select>
                            {this?.techOptions(this.state?.certiType.toUpperCase()).filter(
                              (cat) => this.state.newCertificate.techTypeId == cat.value
                            )[0]?.label == 'Other' && (
                              <>
                                <input
                                  autoFocus
                                  autoComplete="off"
                                  placeholder="Others"
                                  className={cns(
                                    s.parallelField,
                                    'ml-2',
                                    this.state.error &&
                                      this.state.newCertificate.customTechName === ''
                                      ? s.inputError
                                      : s.inputName
                                  )}
                                  value={this.state.newCertificate.customTechName}
                                  name="certificate"
                                  onChange={(e) => {
                                    this.setState({
                                      newCertificate: {
                                        ...this.state.newCertificate,
                                        customTechName: e.target.value
                                      }
                                    });
                                  }}
                                  type="text"
                                  maxLength={101}
                                />
                              </>
                            )}
                          </div>
                          <input
                            name="certFile"
                            type="file"
                            id="file"
                            className={
                              this.state.error && this.state.newCertificate.certFile === ''
                                ? s.certFileInputError
                                : s.certFileLabel
                            }
                            accept="image/*,application/pdf"
                            onChange={(e) =>
                              this.setState({
                                newCertificate: {
                                  ...this.state.newCertificate,
                                  certFile: e.target.files[0]
                                }
                              })
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}{' '}
                </>
              )}
            </div>
          </>
        )}
        <ConfirmationModal
          showModal={this.state.modalData.showModal}
          toggle={this.toggleModal}
          message={`Are you sure you want to permanently remove ${this.state.modalData.value} certificate from Gembook?`}
          heading={this.state.modalData.heading}
          onSuccess={() => {
            if (this.state.modalData.method === 'save') {
              this.handleSave(this.state.modalData.index);
            } else {
              this.handleDelete(this.state.modalData.index, this.state.certificates);
            }
            this.toggleModal();
          }}
          icon={this.state.modalData.icon}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    certifications: state.certificationReducer.certifications,
    editLoading: state.projectReducer.editLoading,
    getLoading: state.certificationReducer.getLoading,
    getError: state.certificationReducer.getError,

    editError: state.projectReducer.editError,
    techTypes: state.certificationReducer.techTypes
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCertification: getCertification,
      editCertification: editCertification,
      addCertification: addCertification,
      deleteCertification: deleteCertification,
      getCertiTechTypes: getCertiTechTypes
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Certification);
