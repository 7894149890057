import React, { useEffect } from 'react';
import { getProfileByEmail, TOOLTIP_MSSG } from '../../../../../helpers';
import style from './rightWrapper.module.scss';
import closeIcon from '../../../../../assets/icons/close.svg';
import { useState } from 'react';
import user from '../../../../../assets/img/user.png';
import commentIcon from '../../../../../assets/icons/commentIcon.svg';
import { awardIcons } from '../../../../../helpers/iconsHelper';
import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { connect } from 'react-redux';

const ManagerComments = ({ awardsByEmail, getLoading, ...props }) => {
  const [showManagerComments, setShowManagerComments] = useState(false);
  const [managerDetails, setManagerDetails] = useState();
  const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
  const awardYears = [
    ...Array(parseInt(new Date().getFullYear()) - 2014 + 1)
      .fill()
      .map((val, idx) => ({
        name: `${2014 + idx}`
      }))
      .reverse()
  ];

  let years = '';
  let award = '';
  let quart = '';

  awardYears.forEach((year) => {
    if (awardsByEmail.recTypeName.includes(year.name)) {
      let yearAwardName = awardsByEmail.recTypeName.split(year.name)[0];
      award = yearAwardName.replace('(', ' ').replace(')', ' ').trim();
      years = year.name;
    }
  });

  useEffect(() => {
    setManagerDetails(props?.users.find((user) => user.email === awardsByEmail.managerEmail));
  }, [awardsByEmail]);

  quarters.forEach((quarter) => {
    if (awardsByEmail.recTypeName.includes(quarter)) {
      award = awardsByEmail.recTypeName.split(quarter)[0].trim();
      years = awardsByEmail.recTypeName.split(quarter)[1].trim();
      quart = quarter;
    }
  });

  // Normalize award names to match keys in awardIcons
  const normalizedAward = award
    .toLowerCase()
    .replace(/\s+/g, '')
    .replace(/longservice10years/, 'longservice(10years)')
    .replace(/longservice5years/, 'longservice(5years)');

  return (
    <>
      {getLoading ? (
        <div className={style.detailsLoading}>
          <Skeleton variant="circle" className={style.circleLoader} />
          <div className={style.detailsLoadingContainer}>
            <Skeleton variant="text" className={style.textLoader} />
            <Skeleton variant="text" className={style.textLoader} />
          </div>
        </div>
      ) : (
        <div className={style.awardsWrapper}>
          <div className={style.awards}>
            <div className={style.awardsDetail}>
              {normalizedAward && awardIcons[normalizedAward] && (
                <img
                  src={awardIcons[normalizedAward]}
                  className={style.awardsIcon}
                  alt="Award Icon"
                />
              )}

              <div className={style.awardDescription}>
                <p className={style.awardName}>{award ? award : awardsByEmail.recTypeName}</p>
                <p className={style.awardInfo}>
                  {quart} FY {years}
                </p>
              </div>
            </div>

            {awardsByEmail.managerComment ? (
              <div>
                <img
                  src={commentIcon}
                  onClick={() => setShowManagerComments(!showManagerComments)}
                  className={style.commentIcon}
                  alt="Comment Icon"
                />
              </div>
            ) : (
              <div>
                <Tooltip title="No Comments Found">
                  <img
                    src={commentIcon}
                    onClick={() => setShowManagerComments(false)}
                    className={style.commentIcon}
                    alt="Comment Icon"
                  />
                </Tooltip>
              </div>
            )}
          </div>
          <div>
            {showManagerComments && (
              <div className={style.managerCommentsWrapper}>
                <div className={style.closeIcon}>
                  <img src={closeIcon} onClick={() => setShowManagerComments(false)} alt="close" />
                </div>

                <div className={style.managerImgWrapper}>
                  {managerDetails?.photoUrl ? (
                    <img
                      onClick={() => {
                        getProfileByEmail(managerDetails?.email);
                      }}
                      referrerPolicy="no-referrer"
                      height={20}
                      width={20}
                      src={managerDetails.photoUrl}
                      alt="user"
                      className={style.image}
                    />
                  ) : (
                    <Skeleton variant="text" className={style.image} />
                  )}
                </div>

                <div className={style.managerCommentsDetails}>
                  <Tooltip title={TOOLTIP_MSSG.VIEW_PROFILE}>
                    <h5
                      onClick={() => {
                        getProfileByEmail(managerDetails?.email);
                      }}
                      className={style.managerName}
                    >
                      {managerDetails?.name ? (
                        managerDetails.name
                      ) : (
                        <Skeleton variant="text" className={style.textLoader} />
                      )}
                    </h5>
                  </Tooltip>
                  <p className={style.managerComment}>
                    {awardsByEmail.managerComment ? (
                      awardsByEmail.managerComment
                    ) : (
                      <Skeleton variant="text" className={style.textLoader} />
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
function mapStateToProps(state) {
  return {
    users: state.userReducer.users
  };
}
export default connect(mapStateToProps, null)(ManagerComments);
