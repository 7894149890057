import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// ActionCreators
import {
  getPost,
  updatePostById,
  getPosts,
  deletePost
} from '../../../../../../redux/actionCreators/post.actionCreator';
import { saveLikeForPost } from '../../../../../../redux/actionCreators/postLike.actionCreator';
import {
  deleteComment,
  addComment,
  likeComment
  // unLikeComment,
} from '../../../../../../redux/actionCreators/comment.actionCreator';

import { getEmpIdByEmail } from '../../../../../../redux/actionCreators/profile.actionCreator';

// Components
import PostCard from './postCard';
import PostLoader from './postLoader';

// Scss
import s from '../postContainer.module.scss';

//Icons
import backIconBlue from '../../../../../../assets/icons/backIconBlue.svg';
import { getAllUsers } from '../../../../../../redux/actionCreators/user.actionCreator';

class PostId extends Component {
  state = {
    width: window.innerWidth,
    id: this.props.id,
    imageUrl:
      'https://plus.unsplash.com/premium_photo-1681400586780-81590be4ee1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1883&q=80'
  };
  constructor(props) {
    super(props);
    // Bind the setImage function to this instance
    this.setImage = this.setImage.bind(this);
  }
  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
    this.getPostById();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('postContainer');
    if (!this.props.loading && this.isBottom(wrappedElement)) {
      this.getPostById();
    }
  };
  setImage(newImageUrl) {
    this.setState({ imageUrl: newImageUrl });
  }

  getPosts = () => {
    this.props.getPosts({ pageNum: '1' });
  };

  getPostById = () => {
    const data = {
      postId: this.state.id
    };
    this.props.getPost(data);
  };

  isBottom(el) {
    let pageCoveredLength = (100 * window.innerHeight) / 15; // get next page of posts after user scrolls 15% of page
    return el?.getBoundingClientRect().bottom <= pageCoveredLength;
  }
  redirectHomepage() {
    const url = window.location.origin + '/#/dashboard';
    window.open(url, '_self');
  }
  render() {
    return (
      <div className={s.postContainer}>
        <div className={s.posts}>
          {this.props.error ? (
            <div>
              <div className={s.postError}>
                The Post might be removed or updated please contact the author for more information.
              </div>
              <div className="w-100 d-flex justify-content-center">
                <button className={s.goBackBtn} onClick={this.redirectHomepage}>
                  <img src={backIconBlue} alt="back Icon" />
                  Go to News Feed
                </button>
              </div>
            </div>
          ) : (
            <>
              {!this.props.loading && (
                <div className={s.goBackDiv}>
                  <button className={s.goBackButton} onClick={this.redirectHomepage}>
                    <img src={backIconBlue} alt="back Icon" />
                    <p className={s.newPost}>Go to News Feed</p>
                  </button>
                </div>
              )}
              {this.props.posts[0] ? (
                <div>
                  <PostCard
                    setImageUrl={this.setImage}
                    key={this.props.posts[0].postId}
                    postTagId={this.props.posts[0].tagId}
                    clubId={this.props.posts[0].clubId}
                    post={this.props.posts[0]}
                    likePost={this.props.saveLikeForPost}
                    addComment={this.props.addComment}
                    deleteComment={this.props.deleteComment}
                    addLoading={this.props.addLoading}
                    deletePost={this.props.deletePost}
                    likeComment={this.props.likeComment}
                    updatePostById={this.props.updatePostById}
                    getEmpIdByEmail={this.props.getEmpIdByEmail}
                    postTypes={this.props.postTypes}
                    setCurrentEditedPost={(val) => this.props.setCurrentEditedPost(val)}
                    setEditOnePost={(val) => this.props.setEditOnePost(val)}
                    users={this.props.users}
                  />
                </div>
              ) : (
                <PostLoader isLoading={this.props.loading} getPosts={this.getPostById} />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    posts: state.postReducer.posts,
    postTypeId: state.postReducer.postTypeId,
    loading: state.postReducer.loading,
    error: state.postReducer.findPostError,
    pageNum: state.postReducer.pageNum,
    addCommentLoading: state.commentReducer.addLoading,
    postTypes: state.postTypesReducer.postTypes,
    users: state.userReducer.users
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPost: getPost,
      saveLikeForPost: saveLikeForPost,
      deleteComment: deleteComment,
      addComment: addComment,
      deletePost: deletePost,
      likeComment: likeComment,
      updatePostById: updatePostById,
      getPosts: getPosts,
      getEmpIdByEmail: getEmpIdByEmail,
      getAllUsers: getAllUsers
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PostId);
