import {
  GET_MANAGER_DETAIL_BY_ID_SUCCESS,
  GET_MANAGER_DETAIL_BY_ID_FAILURE,
  GET_MANAGER_DETAIL_BY_ID_LOADING
} from '../actions/actions';

const initialState = {
  loading: false,
  error: '',
  addLoading: false,
  addError: '',
  managerDetails: {}
};

const managerDataReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_MANAGER_DETAIL_BY_ID_LOADING:
      newState.loading = true;
      break;
    case GET_MANAGER_DETAIL_BY_ID_SUCCESS:
      newState.managerDetails = {
        ...action.payload.data
      };
      newState.loading = false;
      break;
    case GET_MANAGER_DETAIL_BY_ID_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default managerDataReducer;
