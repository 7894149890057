import React, { useState, useEffect, useRef } from 'react';

// Components
import WritePost from './writePost/index';
import Posts from './posts/index';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//ActionCreators
import {
  getFilteredPosts,
  getPosts,
  getRefreshPost
} from '../../../../../redux/actionCreators/post.actionCreator';

//Icons
import refreshIcon from '../../../../../assets/icons/refreshIcon.svg';

// Scss
import s from './postContainer.module.scss';
import PostTypeList from './posts/postTypeList';
import { io } from 'socket.io-client';
import { EventSourcePolyfill } from 'event-source-polyfill';

const PostContainer = ({ showRightWrapperMobile, setShowRightWrapperMobile, ...props }) => {
  const [postType, setPostType] = useState();
  const [toggleOptions, setToggleOptions] = useState(false);
  const [willRefresh, setWillRefresh] = useState(false);
  const [editOnePost, setEditOnePost] = useState(false);
  const [currentEditedPost, setCurrentEditedPost] = useState(0);
  const [ssePosts, setSSEPosts] = useState([]);

  let wrapper = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (!wrapper.current.contains(event.target)) {
        setToggleOptions(false);
      }
    };
    // let keepAliveTimer = null;

    // function gotActivity() {
    //   if (keepAliveTimer != null) clearTimeout(keepAliveTimer);
    //   keepAliveTimer = setTimeout(connect, 300 * 1000);
    // }

    // function connect() {
    //   gotActivity();
    //   // Event listener for handling incoming events
    //   var EventSource = EventSourcePolyfill;
    //   let url = 'https://devapi.geminisolutions.com/gembook/posts/sse/getPost';

    //   const sse = new EventSource(url, {
    //     headers: {
    //       Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    //     },
    //     heartbeatTimeout: 120000
    //   });

    //   sse.addEventListener(
    //     'message',
    //     function (e) {
    //       gotActivity();
    //       let data = JSON.parse(e.data);
    //       let newSSEPost = [...ssePosts];
    //       newSSEPost.push(data);
    //       setSSEPosts([...newSSEPost]);
    //       setWillRefresh(true);
    //     },
    //     false
    //   );
    // }
    // connect();
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [ssePosts?.postId]);

  return (
    <div className={s.postContainer} id="postContainer">
      {willRefresh && (
        <div className={s.refreshDiv}>
          <button
            className={s.refreshButton}
            onClick={() => {
              setWillRefresh(false);
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}
          >
            <img src={refreshIcon} alt="refresh" />
            <p className={s.newPost}>New Posts</p>
          </button>
        </div>
      )}
      <WritePost
        editOnePost={editOnePost}
        setEditOnePost={(val) => setEditOnePost(val)}
        currentEditedPost={currentEditedPost}
        setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
      />
      <PostTypeList
        wrapper={wrapper}
        setToggleOptions={setToggleOptions}
        toggleOptions={toggleOptions}
        postTypes={props.postTypes}
        postTypeId={props.postTypeId}
        setPostType={setPostType}
        postType={postType}
        getPosts={props.getPosts}
        getFilteredPosts={props.getFilteredPosts}
      />
      <Posts
        name={postType}
        ssePosts={ssePosts}
        editOnePost={editOnePost}
        setEditOnePost={(val) => setEditOnePost(val)}
        currentEditedPost={currentEditedPost}
        setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    postTypes: state.postTypesReducer.postTypes,
    postTypeId: state.postReducer.postTypeId,
    posts: state.postReducer.posts
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPosts: getPosts,
      getFilteredPosts: getFilteredPosts,
      getRefreshPost: getRefreshPost
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PostContainer);
