import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'classnames';
import _ from 'lodash';
import SearchedPeople from '../../home/searched-people';
import Posts from '../../home/postContainer/posts';
import { connect } from 'react-redux';
import ClubPage from './components/clubPage';
import { getClubDetails } from '../../../../../redux/actionCreators/clubs.actionCreator';
import { bindActionCreators } from 'redux';
import { useHistory, useParams } from 'react-router-dom';

//layouts
import Sidebar from '../../../../../layouts/layout1/sidebar/index';
import Navbar from '../../../../../layouts/layout1/navbar/index';
import Footer from '../../../../../layouts/layout1/footer/index';

//scss
import postClass from './../../home/postContainer/postContainer.module.scss';
import peopleClass from './../../home/home.module.scss';
import s from '../../../dashboard.module.scss';
import RightSidebar from '../../home/sidebar';
import Viewport from '../../../../helpers/viewport';
import useCheckJwtExpiration from '../../../../../hooks/useCheckJwtExpiration';
import { useMediaQuery } from 'react-responsive';

const ClubDetailsPage = (props) => {
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [inputChangeData, setInputChangeData] = useState({
    searchValue: '',
    isPost: false
  });
  const [inputVal, setInputVal] = useState('');
  const onChange = (value, isPost) => {
    setInputChangeData({ searchValue: value, isPost: isPost });
  };
  const debouncedOnChange = useCallback(_.debounce(onChange, 500), [onChange]);
  const [toggle, setToggle] = useState(isMobile ? false : true);
  const [editOnePost, setEditOnePost] = useState(false);
  const [currentEditedPost, setCurrentEditedPost] = useState(0);

  const currentId = useParams();
  const history = useHistory();
  const useJwtExpiration = useCheckJwtExpiration();
  useEffect(() => {
    props.getClubDetails(history.location?.state?.cid ?? currentId.cid);
  }, []);

  return (
    <>
      <div className={s.dashboard}>
        <section className={s.profileSection}>
          <Navbar
            setToggle={setToggle}
            toggle={toggle}
            profileDetails={props.profileDetails}
            onChange={debouncedOnChange}
            inputVal={inputVal}
            setInputVal={setInputVal}
          />
          <div className={s.clubsWrapper}>
            <Sidebar
              toggle={toggle}
              setToggle={setToggle}
              onChange={debouncedOnChange}
              inputVal={inputVal}
              setInputVal={setInputVal}
            />
            {inputChangeData.searchValue.length ? (
              <div className={peopleClass.home}>
                {!inputChangeData.isPost ? (
                  <div className={peopleClass.containerWrapper}>
                    <SearchedPeople searchValue={inputChangeData.searchValue} />
                  </div>
                ) : (
                  <div className={peopleClass.containerWrapper}>
                    <div className={postClass.postContainer}>
                      <Posts
                        searchValue={inputChangeData.searchValue}
                        editOnePost={editOnePost}
                        setEditOnePost={(val) => setEditOnePost(val)}
                        currentEditedPost={currentEditedPost}
                        setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
                      />
                    </div>
                  </div>
                )}
                <div className={peopleClass.sidebarWrapper}>
                  <RightSidebar />
                </div>
              </div>
            ) : (
              <ClubPage
                data={props.clubDetails}
                clubIdSelected={history.location?.state?.cid ?? currentId.cid}
                loadingState={history.location?.state?.toastId}
                dataLoading={props.loading}
              />
            )}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profileDetails: state.profileReducer.details,
    loading: state.clubsReducer.loading,
    error: state.clubsReducer.error,
    clubDetails: state.clubsReducer.clubDetails
  };
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClubDetails: getClubDetails
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ClubDetailsPage);
