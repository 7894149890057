import axios from 'axios';
import {
  GET_BIRTHDAYS_FAILURE,
  GET_BIRTHDAYS_LOADING,
  GET_BIRTHDAYS_SUCCESS
} from '../actions/actions';
export const getBirthdays = () => {
  return (dispatch) => {
    dispatch({
      type: GET_BIRTHDAYS_LOADING
    });
    axios
      .get(`${process.env.REACT_APP_GEMBOOK_SVC_URL}/profile/birthdays`, {
        headers: {
          accept: '*/*'
        }
      })
      .then((response) => {
        const details = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_BIRTHDAYS_SUCCESS,
            payload: {
              details: [...details]
            }
          });
        }
        dispatch({
          type: GET_BIRTHDAYS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_BIRTHDAYS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
