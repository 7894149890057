import React from 'react';
import Reportees from './Reportees';

//Helper
import Viewport from '../../../../helpers/viewport';

// scss
import s from './rightWrapper.module.scss';

// Icon
import { getProfileByEmail, TOOLTIP_MSSG } from '../../../../../helpers';
import { Tooltip } from '@material-ui/core';
import user from '../../../../../assets/img/user.png';
import arrowDown from '../../../../../assets/icons/arrowDown.svg';
import { useState } from 'react';
import ManagerComments from './managerComments';
import { Skeleton } from '@material-ui/lab';
import Hobbies from './hobbies';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

const RightWrapper = ({
  details,
  managerDetails,
  awardsByEmail,
  loading,
  getLoading,
  setInput,
  setInputVal
}) => {
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const width = window.screen.width;
  const birthday = details.birthday ? moment(details.birthday, 'DD-MM').format('D MMMM') : null;
  const joiningDate = details?.joiningDate ? details?.joiningDate.toString().split(',') : null;
  const [expandDetails, setExpandDetails] = useState(false);
  const [expandManagerDetails, setExpandManagerDetails] = useState(false);
  const [expandReportees, setExpandReportees] = useState(false);
  const [expandRewards, setExpandRewards] = useState(false);

  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const reporteeCount = () => {
    let flag = 0;
    for (let i = 0; i < details.reportees.length; i++) {
      if (details.reportees[i].email === 'vishal.malik@geminisolutions.com') {
        flag = 1;
      }
    }
    return flag ? details.reportees?.length - 1 : details.reportees?.length;
  };

  const Mobile = () => {
    return (
      <div className={s.mobileRightWrapper}>
        <div className={s.rightWrapper}>
          <div>
            <div className={s.employeeInfo}>
              <p>Details</p>
              <img src={arrowDown} onClick={() => setExpandDetails(!expandDetails)} />
            </div>

            {expandDetails && (
              <div className={s.employeeDetailWrapper}>
                <div className={s.rightContent}>
                  <div>
                    <div>EC</div>
                    <div className={s.detailDescription}>
                      {!details.ecTech ? (
                        <Skeleton variant="text" className={s.detailDescription} />
                      ) : (
                        details.ecTech
                      )}
                    </div>
                  </div>
                  <div>
                    <div>DC</div>
                    <div className={s.detailDescription}>
                      {!details.dcTech ? (
                        <Skeleton variant="text" className={s.detailDescription} />
                      ) : (
                        details.dcTech
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={s.detailHeading}>Birthday</div>
                    <div className={s.detailDescription}>
                      {!birthday ? (
                        <Skeleton variant="text" className={s.detailDescription} />
                      ) : birthday === null ? (
                        ' '
                      ) : (
                        birthday
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={s.detailHeading}>Joining Date</div>

                    <div className={s.detailDescription}>
                      {!joiningDate ? (
                        <Skeleton variant="text" className={s.detailDescription} />
                      ) : joiningDate === null ? (
                        ' '
                      ) : (
                        `${joiningDate[2]} ${month[joiningDate[1] - 1]}, ${joiningDate[0]}`
                      )}
                    </div>
                  </div>
                  <div>
                    <div>Location</div>
                    <div className={s.detailDescription}>
                      {!details.officeLocation ? (
                        <Skeleton variant="text" className={s.detailDescription} />
                      ) : (
                        details.officeLocation
                      )}
                    </div>
                  </div>
                  <div>
                    <Hobbies details={details} />
                  </div>
                </div>
              </div>
            )}
          </div>
          {details?.email !== 'vishal.malik@geminisolutions.com' && (
            <a className={s.employeeInfo}>
              <p>DC Manager</p>
              <img src={arrowDown} onClick={() => setExpandManagerDetails(!expandManagerDetails)} />
            </a>
          )}

          {expandManagerDetails && (
            <div className={s.managerDetailWrapper}>
              {loading ? (
                <div className={s.detailsLoading}>
                  <Skeleton variant="circle" className={s.circleLoader} />
                  <div className={s.detailsLoadingContainer}>
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                  </div>
                </div>
              ) : (
                <div className={s.managerDescription}>
                  <img
                    onClick={() => {
                      getProfileByEmail(managerDetails?.email);
                    }}
                    referrerPolicy="no-referrer"
                    height={20}
                    width={20}
                    src={managerDetails?.imageUrl}
                    alt="user"
                    className={s.image}
                  />

                  <div className={s.managerDetail}>
                    <Tooltip title={TOOLTIP_MSSG.VIEW_PROFILE}>
                      <h5
                        onClick={() => {
                          getProfileByEmail(managerDetails?.email);
                        }}
                        referrerPolicy="no-referrer"
                        className="manager-img-pic"
                        height={20}
                        width={20}
                        src={managerDetails.imageUrl ? managerDetails.imageUrl : user}
                        alt="user"
                      />
                    </Tooltip>
                  </div>
                  <div className={s.managerDetail}>
                    <Tooltip title={TOOLTIP_MSSG.VIEW_PROFILE}>
                      <h5
                        onClick={() => {
                          getProfileByEmail(managerDetails?.email);
                        }}
                      >
                        {managerDetails?.name}
                      </h5>
                    </Tooltip>
                    <p>{managerDetails?.designation}</p>
                  </div>
                </div>
              )}
            </div>
          )}
          {awardsByEmail.length ? (
            <div className={s.employeeInfo}>
              <p>Rewards and Recognitions</p>
              <img src={arrowDown} onClick={() => setExpandRewards(!expandRewards)} />
            </div>
          ) : (
            ''
          )}
          {expandRewards && (
            <div className={s.awardsDetailWrapper}>
              <div>
                {awardsByEmail?.length > 0 ? (
                  <>
                    {awardsByEmail.map((awardsByEmail) => {
                      return (
                        <ManagerComments
                          awardsByEmail={awardsByEmail}
                          managerDetails={managerDetails}
                          getLoading={getLoading}
                        />
                      );
                    })}
                  </>
                ) : (
                  <p className={s.noAward}>No Record Found </p>
                )}
              </div>
            </div>
          )}

          {details && details.reportees && details.reportees?.length ? (
            <>
              <div className={s.employeeInfo}>
                <p>
                  Reportees <span className={s.reporteesCount}>{reporteeCount()}</span>
                </p>

                <img src={arrowDown} onClick={() => setExpandReportees(!expandReportees)} />
              </div>
              {expandReportees && (
                <div className={s.managerDetailWrapper}>
                  <Reportees reportees={details.reportees} loading={loading} />
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const Desktop = () => {
    return (
      <div className={s.desktopRightWrapper}>
        <div className={s.rightWrapper}>
          <div className={s.employeeDetailWrapper}>
            <div className={s.details}>
              <h4>Details</h4>
            </div>
            {
              <div className={s.rightContent}>
                <div>
                  <div className={s.detailHeading}>EC</div>
                  <div className={s.detailDescription}>
                    {!details.ecTech ? (
                      <Skeleton variant="text" className={s.detailDescription} />
                    ) : (
                      details.ecTech
                    )}
                  </div>
                </div>
                <div>
                  <div className={s.detailHeading}>DC</div>
                  <div className={s.detailDescription}>
                    {!details.dcTech ? (
                      <Skeleton variant="text" className={s.detailDescription} />
                    ) : (
                      details.dcTech
                    )}
                  </div>
                </div>
                <div>
                  <div className={s.detailHeading}>Birthday</div>
                  <div className={s.detailDescription}>
                    {!birthday ? (
                      <Skeleton variant="text" className={s.detailDescription} />
                    ) : birthday === null ? (
                      ' '
                    ) : (
                      birthday
                    )}
                  </div>
                </div>
                <div>
                  <div className={s.detailHeading}>Joining Date</div>

                  <div className={s.detailDescription}>
                    {!joiningDate ? (
                      <Skeleton variant="text" className={s.detailDescription} />
                    ) : joiningDate === null ? (
                      ' '
                    ) : (
                      `${joiningDate[2]} ${month[joiningDate[1] - 1]}, ${joiningDate[0]}`
                    )}
                  </div>
                </div>
                <div>
                  <div className={s.detailHeading}>Location</div>
                  <div className={s.detailDescription}>
                    {!details.officeLocation ? (
                      <Skeleton variant="text" className={s.detailDescription} />
                    ) : (
                      details.officeLocation
                    )}
                  </div>
                </div>
                <div>
                  <Hobbies details={details} setInput={setInput} setInputVal={setInputVal} />
                </div>
              </div>
            }
          </div>

          {details?.email !== 'vishal.malik@geminisolutions.com' && (
            <div className={s.managerDetailWrapper}>
              <div className={s.managerTitle}>
                <h4>DC Manager</h4>
              </div>
              {loading ? (
                <div className={s.detailsLoading}>
                  <Skeleton variant="circle" className={s.circleLoader} />
                  <div className={s.detailsLoadingContainer}>
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                  </div>
                </div>
              ) : (
                <a
                  href={window.location.origin + `/#/profile/${managerDetails?.email}`}
                  className={s.managerDescription}
                >
                  <img
                    onClick={() => {
                      getProfileByEmail(managerDetails?.email);
                    }}
                    referrerPolicy="no-referrer"
                    height={20}
                    width={20}
                    src={managerDetails?.imageUrl ?? user}
                    alt="user"
                    className={s.image}
                  />

                  <div className={s.managerDetail}>
                    <Tooltip title={TOOLTIP_MSSG.VIEW_PROFILE}>
                      <h5
                        onClick={() => {
                          getProfileByEmail(managerDetails?.email);
                        }}
                        referrerPolicy="no-referrer"
                        className="manager-img-pic"
                        height={20}
                        width={20}
                        src={managerDetails.imageUrl ? managerDetails.imageUrl : user}
                        alt="user"
                      />
                    </Tooltip>
                  </div>

                  <div className={s.managerDetail}>
                    <Tooltip title={TOOLTIP_MSSG.VIEW_PROFILE}>
                      <h5
                        onClick={() => {
                          getProfileByEmail(managerDetails?.email);
                        }}
                      >
                        {managerDetails?.name}
                      </h5>
                    </Tooltip>
                    <p>{managerDetails?.designation}</p>
                  </div>
                </a>
              )}
            </div>
          )}

          {/* --------------------------Awards---------------------------------- */}

          {awardsByEmail.length ? (
            <div className={s.awardsDetailWrapper}>
              <div className={s.awardsTop}>
                <div>
                  <p className={s.awardsTitle}>Rewards & Recognitions</p>
                </div>
                {awardsByEmail?.length > 0 ? (
                  <>
                    {awardsByEmail.map((awardsByEmail, index) => {
                      return (
                        <ManagerComments
                          awardsByEmail={awardsByEmail}
                          getLoading={getLoading}
                          key={index}
                        />
                      );
                    })}
                  </>
                ) : (
                  <p className={s.noAward}>No Record Found </p>
                )}
              </div>
            </div>
          ) : (
            ''
          )}

          <>
            {details && details.reportees && details.reportees.length ? (
              <div className={s.managerDetailWrapper}>
                <Reportees reportees={details.reportees} loading={loading} />
              </div>
            ) : null}
          </>
        </div>
      </div>
    );
  };

  return <>{isMobile ? <Mobile /> : <Desktop />}</>;
};

export default RightWrapper;
