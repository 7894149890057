import axios from 'axios';
import { toast } from 'react-toastify';
import React from 'react';
import { getPostById, getMarketPostById } from './post.actionCreator';
import {
  ADD_COMMENT_FAILURE,
  ADD_COMMENT_LOADING,
  ADD_COMMENT_SUCCESS,
  DELETE_COMMENT_LOADING,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  LIKE_COMMENT_LOADING,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAILURE,
  ADD_MARKET_COMMENT_FAILURE,
  ADD_MARKET_COMMENT_LOADING,
  ADD_MARKET_COMMENT_SUCCESS,
  DELETE_MARKET_COMMENT_LOADING,
  DELETE_MARKET_COMMENT_SUCCESS,
  DELETE_MARKET_COMMENT_FAILURE,
  LIKE_MARKET_COMMENT_LOADING,
  LIKE_MARKET_COMMENT_SUCCESS,
  LIKE_MARKET_COMMENT_FAILURE
} from '../actions/actions';

export const addComment = (data) => {
  return (dispatch) => {
    const toastId = React.createRef();
    const formData = new FormData();
    formData.append('commentDetails', JSON.stringify(data.commentDetails));
    Array.from(data.files).forEach((file) => {
      formData.append('files', file);
    });

    dispatch({
      type: ADD_COMMENT_LOADING
    });
    axios
      .post(process.env.REACT_APP_GEMBOOK_SVC_URL + '/post-comment', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: '*/*'
        },
        onUploadProgress: (p) => {
          const progress = p.loaded / p.total;

          // check if we already displayed a toast
          if (toastId.current === null && progress < 1) {
            toastId.current = toast('Uploading comment...', {
              progress: progress
            });
          } else {
            toast.update(toastId.current, { progress });
          }
        }
      })
      .then(() => {
        dispatch({
          type: ADD_COMMENT_SUCCESS
        });
        dispatch(getPostById({ postId: data.commentDetails.postId }));

        toast.success('Comment Posted!');
      })
      .catch((error) => {
        dispatch({
          type: ADD_COMMENT_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const likeComment = (data) => {
  return (dispatch) => {
    dispatch({
      type: LIKE_COMMENT_LOADING
    });
    axios
      .post(process.env.REACT_APP_GEMBOOK_SVC_URL + '/post-comment-like', null, {
        headers: {
          // "Content-Type": "application/json",
          accept: '*/*'
        },
        params: {
          commentId: parseInt(data.commentId),
          commentLikeFlag: parseInt(data.commentLikeFlag),
          userId: localStorage.getItem('email')
        }
      })
      .then(() => {
        dispatch({
          type: LIKE_COMMENT_SUCCESS
        });
        dispatch(getPostById({ postId: data.postId }));
      })
      .catch((error) => {
        dispatch({
          type: LIKE_COMMENT_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const deleteComment = (data) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_COMMENT_LOADING
    });
    axios
      .delete(process.env.REACT_APP_GEMBOOK_SVC_URL + '/post-comment', {
        headers: {
          accept: '*/*'
        },
        params: {
          commentId: data.commentId
        }
      })
      .then(() => {
        toast.success('Comment Deleted!');
        dispatch({
          type: DELETE_COMMENT_SUCCESS
        });
        dispatch(getPostById({ postId: data.postId }));
      })
      .catch((error) => {
        dispatch({
          type: DELETE_COMMENT_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

//Marketcomments

export const addMarketComment = (data) => {
  return (dispatch) => {
    const toastId = React.createRef();
    const formData = new FormData();
    formData.append('commentDetails', JSON.stringify(data.commentDetails));
    Array.from(data?.files).forEach((file) => {
      formData.append('myFile', file);
    });

    dispatch({
      type: ADD_MARKET_COMMENT_LOADING
    });
    axios
      .post(process.env.REACT_APP_MARKETPLACE_URL + '/post-comment/addComment', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: '*/*'
        },
        onUploadProgress: (p) => {
          const progress = p.loaded / p.total;

          // check if we already displayed a toast
          if (toastId.current === null && progress < 1) {
            toastId.current = toast('Uploading comment...', {
              progress: progress
            });
          } else {
            toast.update(toastId.current, { progress });
          }
        }
      })
      .then(() => {
        dispatch({
          type: ADD_MARKET_COMMENT_SUCCESS
        });
        dispatch(getMarketPostById(data.commentDetails.postId));

        toast.success('Comment Posted!');
      })
      .catch((error) => {
        dispatch({
          type: ADD_MARKET_COMMENT_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const likeMarketComment = (data) => {
  return (dispatch) => {
    dispatch({
      type: LIKE_MARKET_COMMENT_LOADING
    });
    axios
      .post(process.env.REACT_APP_MARKETPLACE_URL + '/post-comment-like', null, {
        headers: {
          // "Content-Type": "application/json",
          accept: '*/*'
        },
        params: {
          commentId: parseInt(data.commentId),
          commentLikeFlag: parseInt(data.commentLikeFlag),
          userId: localStorage.getItem('email')
        }
      })
      .then(() => {
        dispatch({
          type: LIKE_MARKET_COMMENT_SUCCESS
        });

        dispatch(getMarketPostById(data.postId));
      })
      .catch((error) => {
        dispatch({
          type: LIKE_MARKET_COMMENT_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const deleteMarketComment = (data) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_MARKET_COMMENT_LOADING
    });
    axios
      .delete(process.env.REACT_APP_MARKETPLACE_URL + '/post-comment', {
        headers: {
          accept: '*/*'
        },
        params: {
          commentId: data.commentId
        }
      })
      .then(() => {
        toast.success('Comment Deleted!');
        dispatch({
          type: DELETE_MARKET_COMMENT_SUCCESS
        });
        dispatch(getMarketPostById(data.postId));
      })
      .catch((error) => {
        dispatch({
          type: DELETE_MARKET_COMMENT_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
