import { toast } from 'react-toastify';
import {
  GET_ALL_POLLS_LOADING,
  GET_ALL_POLLS_SUCCESS,
  GET_ALL_POLLS_FAILURE,
  GET_RESPONSE_LOADING,
  GET_POLL_BY_ID_LOADING,
  GET_POLL_BY_ID_SUCCESS,
  GET_POLL_BY_ID_FAILURE,
  ADD_VOTES_LOADING,
  ADD_VOTES_SUCCESS,
  ADD_VOTES_FAILURE,
  DELETE_POLL_LOADING,
  DELETE_POLL_SUCCESS,
  DELETE_POLL_FAILURE,
  EDIT_POLL_LOADING,
  EDIT_POLL_SUCCESS,
  EDIT_POLL_FAILURE
} from '../actions/actions';
import axios from 'axios';

export const getAllPolls = () => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_POLLS_LOADING
    });
    axios
      .get(`${process.env.REACT_APP_EVENTS_URL}/polls`, {
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const allPolls = response.data;
          dispatch({
            type: GET_ALL_POLLS_SUCCESS,
            payload: {
              polls: [...allPolls]
            }
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_POLLS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getPollById = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_POLL_BY_ID_LOADING
    });
    axios
      .get(`${process.env.REACT_APP_EVENTS_URL}/polls/by-pollId?pollId=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const poll = response.data;
          dispatch({
            type: GET_POLL_BY_ID_SUCCESS,
            payload: {
              poll: poll
            }
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_POLL_BY_ID_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getPollResponse = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_RESPONSE_LOADING
    });
    return axios.get(`${process.env.REACT_APP_EVENTS_URL}/poll-response/poll-id?pollId=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*/*'
      }
    });
  };
};

export const getPollResponseByUser = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_RESPONSE_LOADING
    });
    let employeeId = localStorage.getItem('email');
    return axios.get(
      `${process.env.REACT_APP_EVENTS_URL}/poll-response/user-option?pollId=${id}&userId=${employeeId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*'
        }
      }
    );
  };
};

export const editVote = (pollId, option) => {
  return (dispatch) => {
    dispatch({
      type: ADD_VOTES_LOADING
    });
    let employeeId = localStorage.getItem('email');
    axios
      .patch(
        `${process.env.REACT_APP_EVENTS_URL}/poll-response/edit-response?pollId=${pollId}&option=${option}&userId=${employeeId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*'
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: ADD_VOTES_SUCCESS,
            chooseOption: response.data.chooseOption
          });
          toast.success('Vote Updated Successfully!');
          getAllPolls()(dispatch);
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_VOTES_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
export const addVote = (pollId, option) => {
  return (dispatch) => {
    dispatch({
      type: ADD_VOTES_LOADING
    });
    let employeeId = localStorage.getItem('email');
    axios
      .post(
        `${process.env.REACT_APP_EVENTS_URL}/poll-response?pollId=${pollId}&option=${option}&userId=${employeeId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*'
          }
        }
      )
      .then((response) => {
        if (response.status === 201) {
          dispatch({
            type: ADD_VOTES_SUCCESS,
            chooseOption: response.data.chooseOption
          });
          toast.success('Voted Successfully!');
          getAllPolls()(dispatch);
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_VOTES_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const deletePoll = (pollId) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_POLL_LOADING
    });
    axios
      .delete(`${process.env.REACT_APP_EVENTS_URL}/polls/poll-id?poll_id=${pollId}`, {
        headers: {
          accept: '*/*'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: DELETE_POLL_SUCCESS
          });
          toast.success('Poll deleted successfully.');
          getAllPolls()(dispatch);
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_POLL_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const editPoll = (pollId, updatedPoll) => {
  updatedPoll.endDate = updatedPoll.endDate.split(' ').join('');
  return (dispatch) => {
    dispatch({
      type: EDIT_POLL_LOADING
    });
    axios
      .put(
        `${process.env.REACT_APP_EVENTS_URL}/polls/?pollId=${pollId}&title=${updatedPoll.title}&endDate=${updatedPoll.endDate}`,
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*'
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: EDIT_POLL_SUCCESS
          });
          toast.success('Poll updated successfully!');
          getAllPolls()(dispatch);
        }
      })
      .catch((error) => {
        dispatch({
          type: EDIT_POLL_FAILURE,
          payload: {
            error: error.message
          }
        });
        toast.error(error.message);
      });
  };
};
