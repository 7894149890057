import React from 'react';
import cns from 'classnames';

// Components
import Menu from './menu';
import Logo from './logo';

// Scss
import s from './sidebar.module.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Viewport from '../../../pages/helpers/viewport';
import { useMediaQuery } from 'react-responsive';

const Sidebar = ({ toggle, setToggle, inputVal }) => {
  const { width } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <div className={cns(s.sidebar, toggle ? s.active : ``)}>
      {isMobile && toggle && (
        <ArrowBackIosIcon
          fontSize="large"
          className={s.sidebarCloseBtn}
          onClick={() => setToggle(false)}
        />
      )}
      <Menu width={width} inputVal={inputVal} />
    </div>
  );
};

export default Sidebar;
