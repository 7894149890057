import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProfileByEmail } from '../../../../../../helpers';

const NewMember = ({ details }) => {
  const { imagePath, email, employeeName } = details;

  const getProfile = async (userId) => {
    getProfileByEmail(userId);
  };

  return (
    <div className="member-body-inner">
      <div className="member-image" onClick={() => getProfile(email)}>
        <img referrerPolicy="no-referrer" src={imagePath} height="22" width="22" alt="profile" />
      </div>
      <div className="member-info">
        <div className="name" onClick={() => getProfile(email)}>
          <span> {employeeName}</span>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    error: state.newMemberReducer.error,
    loading: state.newMemberReducer.loading
  };
}

export default connect(mapStateToProps)(NewMember);
