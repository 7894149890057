import axios from 'axios';
import {
  GET_CUSTOM_TAGS_LOADING,
  GET_CUSTOM_TAGS_SUCCESS,
  GET_CUSTOM_TAGS_FAILURE,
  CREATE_CUSTOM_TAGS_LOADING,
  CREATE_CUSTOM_TAGS_SUCCESS,
  CREATE_CUSTOM_TAGS_FAILURE,
  DELETE_CUSTOM_TAGS_LOADING,
  DELETE_CUSTOM_TAGS_SUCCESS,
  DELETE_CUSTOM_TAGS_FAILURE,
  GET_CUSTOM_TAG_DATA_LOADING
} from '../actions/actions';
import { toast } from 'react-toastify';

export const getCustomTagsByClubId = (clubId) => {
  return (dispatch) => {
    dispatch({
      type: GET_CUSTOM_TAGS_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/tag', {
        headers: {
          accept: '*/*'
        },
        params: {
          clubId: clubId
        }
      })
      .then((response) => {
        const customTags = response.data;
        dispatch({
          type: GET_CUSTOM_TAGS_SUCCESS,
          payload: {
            customTags: customTags
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CUSTOM_TAGS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getCustomTagData = (tagId) => {
  return (dispatch) => {
    dispatch({
      type: GET_CUSTOM_TAG_DATA_LOADING
    });
    return axios.get(process.env.REACT_APP_GEMBOOK_SVC_URL + `/tag/${tagId}`, {
      headers: {
        accept: '*/*'
      }
    });
  };
};

export const createCustomTag = (tagName, clubId) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append('tagName', tagName);
    formData.append('clubId', clubId);
    dispatch({
      type: CREATE_CUSTOM_TAGS_LOADING
    });
    axios
      .post(process.env.REACT_APP_GEMBOOK_SVC_URL + '/tag', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: '*/*'
        }
      })
      .then(() => {
        dispatch({
          type: CREATE_CUSTOM_TAGS_SUCCESS
        });
        dispatch(getCustomTagsByClubId(clubId));

        toast.success('New Tag Created!');
      })
      .catch((error) => {
        dispatch({
          type: CREATE_CUSTOM_TAGS_FAILURE,
          payload: {
            error: error.message
          }
        });
        toast.error('Failed to create New Tag!');
      });
  };
};

export const deleteCustomTag = (tagId, clubId) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_CUSTOM_TAGS_LOADING
    });
    axios
      .delete(process.env.REACT_APP_GEMBOOK_SVC_URL + '/tag', {
        headers: {
          accept: '*/*'
        },
        params: {
          tagId: tagId
        }
      })
      .then(() => {
        dispatch({
          type: DELETE_CUSTOM_TAGS_SUCCESS
        });
        dispatch(getCustomTagsByClubId(clubId));

        toast.success('Tag Deleted!');
      })
      .catch((error) => {
        dispatch({
          type: DELETE_CUSTOM_TAGS_FAILURE,
          payload: {
            error: error.message
          }
        });
        toast.error('Failed to delete Tag!');
      });
  };
};
