import { isJwtExpired } from 'jwt-check-expiration';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

const useCheckJwtExpiration = () => {
  const [flag, setFlag] = useState(false);
  const displayedToastRef = useRef(false);
  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    if (token && isJwtExpired(token) && !displayedToastRef.current) {
      toast.error('Session Expired! Redirecting to Login Page.', { autoClose: 3000 });
      displayedToastRef.current = true;
      setTimeout(() => {
        localStorage.clear();
        window.location.href = '/';
      }, 3000);
    }
  }, [token]);

  return flag;
};

export default useCheckJwtExpiration;
