import React, { useEffect } from 'react';
import s from './eventsPage.module.scss';
import { useState } from 'react';
import EventsCard from './eventsCard';
import { connect } from 'react-redux';
import { getClubMembers } from '../../../../../../redux/actionCreators/clubs.actionCreator';
import { bindActionCreators } from 'redux';
import { getAwardsWin } from '../../../../../../redux/actionCreators/award.actionCreator';
import { FormControl, MenuItem, Paper, Select, Tooltip } from '@material-ui/core';
import cns from 'classnames';

import { getOrganisers } from '../../../../../../redux/actionCreators/event.actionCreator';
import AddEvent from '../../../home/sidebar/event/addEvent';
const EventsPage = React.memo(({ events, pastEvents, members, clubName, ...props }) => {
  const handleEventCategoryChange = (eventCategory) => {
    setSelectedEventCategory(eventCategory);
  };
  const [addToggle, setAddToggle] = useState(false);
  const [adminData, setAdminData] = useState([]);

  useEffect(() => {
    props.getAwardsWin();
    if (checkAdmin() && props.organisers.length === 0) props.getOrganisers();
  }, []);

  let adminId = [];
  useEffect(() => {
    members.forEach((item) => {
      if (item.roles?.roleName === 'admin') {
        adminId.push(item?.userId);
      }
    });

    //To remove redundant entries
    const uniqueAdminData = [...new Set(adminId)];
    let adminList = uniqueAdminData.map((admin) => {
      return { email: admin, club: clubName };
    });
    setAdminData(adminList);
  }, [members]);
  let count = 0;
  for (let i = 0; i < pastEvents.length; i++) {
    pastEvents[i].organizerName === clubName && count++;
  }
  let upcomingEventsCount = 0;
  for (let i = 0; i < events.length; i++) {
    events[i].organizerName === clubName && upcomingEventsCount++;
  }
  const [selectedEventCategory, setSelectedEventCategory] = useState(
    upcomingEventsCount > 0 ? 'upcoming' : 'past'
  );
  const checkAdmin = () => {
    let isAdmin = props.membersDetails?.find((member) => {
      return member.userId === localStorage.getItem('email') && member.roles.clubRoleId === 1
        ? true
        : false;
    });
    return isAdmin;
  };
  return (
    <div>
      <div className="d-flex align-items-center justify-content-end">
        {adminData.some((user) => user.email?.toLowerCase() === localStorage.getItem('email')) &&
          (addToggle ? (
            <button
              className={cns(s.addbutton, 'h-25 mt-3 mx-2')}
              onClick={() => setAddToggle((add) => !add)}
            >
              Events
            </button>
          ) : (
            <button
              className={cns(s.addbutton, 'h-25 mt-3 mx-2')}
              onClick={() => setAddToggle(true)}
            >
              + Add New
            </button>
          ))}
        <FormControl size="small" style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Select
            disableUnderline
            value={selectedEventCategory}
            className={s.dropDown}
            onChange={(e) => handleEventCategoryChange(e.target.value)}
          >
            <MenuItem value="upcoming" style={{ fontSize: '12px' }} key="upcoming">
              Upcoming Events
            </MenuItem>
            <MenuItem value="past" style={{ fontSize: '12px' }} key="past">
              Past Events
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {addToggle ? (
        <>
          <Paper className="mt-3">
            <p className={cns(s.eventHeading, 'mx-3 py-3')}>Create Event</p>
            <div className="p-3">
              <AddEvent setAddEventToggle={setAddToggle} clubName={clubName} />
            </div>
          </Paper>
        </>
      ) : (
        selectedEventCategory === 'upcoming' &&
        (upcomingEventsCount > 0 ? (
          events?.map((event) => {
            let isEventPresentInClub = false;
            isEventPresentInClub = event?.organizerName === clubName;
            return (
              <EventsCard
                key={event.eventId}
                event={event}
                clubName={clubName}
                membersData={members}
                users={Object.values(props.users)}
                awardsWin={props.awardsWin}
              />
            );
          })
        ) : (
          <span className={s.message}>No Upcoming Events available.</span>
        ))
      )}
      {selectedEventCategory === 'past' &&
        (count > 0 ? (
          pastEvents?.map((event) => {
            return (
              <EventsCard
                key={event.eventId}
                event={event}
                clubName={clubName}
                membersData={members}
                users={Object.values(props.users)}
                awardsWin={props.awardsWin}
                isPastEvent={true}
              />
            );
          })
        ) : (
          <span className={s.message}>No Past Events available.</span>
        ))}
    </div>
  );
});
const mapStateToProps = (state) => {
  return {
    loading: state.clubsReducer.loading,
    error: state.clubsReducer.error,
    membersDetails: state.clubsReducer.membersDetails,
    profileDetails: state.profileReducer.details,
    events: state.eventReducer.events,
    pastEvents: state.eventReducer.pastEvents,
    users: state.userReducer.users,
    organisers: state.eventReducer.organisers,
    awardsWin: state.awardReducer.awardsWin
  };
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClubMembers: getClubMembers,
      getAwardsWin: getAwardsWin,
      getOrganisers: getOrganisers
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);
