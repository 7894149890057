import React, { useEffect, useState } from 'react';
import closeIcon from '../../../../../../assets/icons/close.svg';

//scss
import s from './admin.module.scss';

//Helper
import { getProfileByEmail } from '../../../../../../helpers';
import { Skeleton } from '@material-ui/lab';
import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { updateMemberRole } from '../../../../../../redux/actionCreators/clubs.actionCreator';
import { bindActionCreators } from 'redux';

const Admin = React.memo(({ users, loading, clubId, membersData, getClubMembers, ...props }) => {
  const [adminData, setAdminData] = useState([]);
  const [adminLoading, setAdminLoading] = useState(false);
  const [membersDetails, setMembersDetails] = useState([]);

  useEffect(() => {
    window.location.hash.includes(clubId) && setMembersDetails([...props.membersDetails]);
  }, [props.membersDetails?.length, clubId]);

  useEffect(() => {
    if (loading || !users?.length || !adminData?.length || !window.location.hash.includes(clubId)) {
      setAdminLoading(true);
    } else {
      setAdminLoading(false);
    }
  }, [loading, users.length, adminData, clubId]);

  useEffect(() => {
    let temp = [];
    membersDetails.forEach((item) => {
      if (item.roles?.roleName === 'admin') {
        const newObj = users.find((obj) => obj.email === item?.userId);
        if (newObj) {
          temp.push(newObj);
        }
      }
    });

    //To remove redundant entries
    const uniqueAdminData = [...new Set(temp)];

    setAdminData([...uniqueAdminData]);
  }, [users?.length, membersDetails?.length]);

  const getProfile = (email) => {
    getProfileByEmail(email);
  };

  const handleRemoveAdmin = (email) => {
    let id = membersData?.filter((user) => {
      if (user.userId === email) {
        return user;
      }
    })[0].clubMemberId;
    let myid = membersData?.filter((user) => {
      if (user.userId === localStorage.getItem('email')) {
        return user;
      }
    })[0].clubMemberId;
    props.updateMemberRole(myid, 2, id);
    setTimeout(() => {
      getClubMembers(clubId);
    }, 1000);
  };

  const checkAdmin = () => {
    let isAdmin = membersData?.find((member) => {
      return member.userId === localStorage.getItem('email') && member.roles.clubRoleId === 1
        ? true
        : false;
    });
    return isAdmin?.userId ? true : false;
  };

  return (
    <>
      <div className={s.admin}>
        <h4 className={s.adminTitle}>{adminData.length > 1 ? 'Admins' : 'Admin'}</h4>
        {adminLoading ? (
          <div className="d-flex flex-column">
            <div className={s.adminLoading}>
              <Skeleton variant="circle" className={s.circleLoader} />
              <div className={s.adminLoadingContainer}>
                <Skeleton variant="text" className={s.textLoader} />
                <Skeleton variant="text" className={s.textLoader} />
              </div>
            </div>
            <div className={s.adminLoading}>
              <Skeleton variant="circle" className={s.circleLoader} />
              <div className={s.adminLoadingContainer}>
                <Skeleton variant="text" className={s.textLoader} />
                <Skeleton variant="text" className={s.textLoader} />
              </div>
            </div>
          </div>
        ) : props.membersDetails.length ? (
          <div className={s.adminContainer}>
            {[...adminData].map((admin, ind) => {
              admin.key = `${crypto.randomUUID()}${ind}`;
              return (
                <div className={s.adminDescription} key={admin.key}>
                  <Tooltip key={admin.key} title={`View ${admin?.name}'s profile`}>
                    <div className={s.data} onClick={() => getProfile(admin?.email)}>
                      <div className={s.adminImg}>
                        <img src={admin?.photoUrl} alt="user" />
                      </div>
                      <div className={s.adminDetail}>
                        <h4>{admin?.name}</h4>
                        <p>{admin?.designation}</p>
                      </div>
                    </div>
                  </Tooltip>
                  <div>
                    {checkAdmin() && admin?.email !== localStorage.getItem('email') ? (
                      <Tooltip key={admin.key} title={`Remove ${admin?.name} from admin`}>
                        <div className={s.icon}>
                          <img
                            src={closeIcon}
                            onClick={() => {
                              handleRemoveAdmin(admin?.email);
                            }}
                            className={s.icons}
                            height={18}
                            alt="admin Icon"
                          />
                        </div>
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={s.noAdmin}>Admin Record not Found</div>
        )}
      </div>
    </>
  );
});

function mapStateToProps(state) {
  return {
    membersDetails: state.clubsReducer.membersDetails
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMemberRole: updateMemberRole
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
