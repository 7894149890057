import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_ALLCLUBS_SUCCESS,
  GET_ALLCLUBS_LOADING,
  GET_ALLCLUBS_FAILURE,
  GET_CLUB_DETAILS_SUCCESS,
  GET_CLUB_DETAILS_LOADING,
  GET_CLUB_DETAILS_FAILURE,
  GET_CLUB_MEMBERS_SUCCESS,
  GET_CLUB_MEMBERS_LOADING,
  GET_CLUB_MEMBERS_FAILURE,
  JOIN_CLUB_SUCCESS,
  JOIN_CLUB_LOADING,
  JOIN_CLUB_FAILURE,
  LEAVE_CLUB_SUCCESS,
  LEAVE_CLUB_LOADING,
  LEAVE_CLUB_FAILURE,
  UPDATE_MEMBER_ROLE_LOADING,
  UPDATE_MEMBER_ROLE_SUCCESS,
  GET_REQUEST_LOADING,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAILURE,
  UPDATE_JOINREQUEST_LOADING,
  UPDATE_JOINREQUEST_FAILURE,
  UPDATE_JOINREQUEST_SUCCESS,
  GET_CLUB_DETAILS_WITH_CLUB_ID_LOADING,
  GET_CLUB_DETAILS_WITH_CLUB_ID_FAILURE,
  GET_CLUB_DETAILS_WITH_CLUB_ID_SUCCESS
} from '../actions/actions';

//to get All Clubs
export const getClubs = () => {
  return (dispatch) => {
    dispatch({
      type: GET_ALLCLUBS_LOADING
    });
    axios
      .get(`${process.env.REACT_APP_CLUBS_URL}/clubs`, {
        headers: {
          accept: '*/*'
        }
      })
      .then((response) => {
        const details = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_ALLCLUBS_SUCCESS,
            payload: {
              details: [...details]
            }
          });
        }
        dispatch({
          type: GET_ALLCLUBS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALLCLUBS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

//to get club details
export const getClubDetails = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_DETAILS_LOADING
    });
    axios
      .get(`${process.env.REACT_APP_CLUBS_URL}/clubs/by-club-id`, {
        headers: {
          accept: '*/*'
        },
        params: {
          clubId: id
        }
      })
      .then((response) => {
        const details = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_CLUB_DETAILS_SUCCESS,
            payload: {
              clubDetails: { ...details }
            }
          });
        }
        dispatch({
          type: GET_CLUB_DETAILS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CLUB_DETAILS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

//to get members details
export const getClubMembers = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_MEMBERS_LOADING
    });
    axios
      .get(`${process.env.REACT_APP_CLUBS_URL}/club-members/by-club-id`, {
        headers: {
          accept: '*/*'
        },
        params: {
          clubId: id
        }
      })
      .then((response) => {
        const details = Array.isArray(response.data) ? [...response.data] : [];
        if (response.status === 200) {
          dispatch({
            type: GET_CLUB_MEMBERS_SUCCESS,
            payload: {
              details: details
            }
          });
        }
        dispatch({
          type: GET_CLUB_MEMBERS_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CLUB_MEMBERS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

//to join club
export const joinClub = (id, roleId, clubName, memberType = 'participant', response = '') => {
  return (dispatch) => {
    dispatch({
      type: JOIN_CLUB_LOADING
    });
    axios
      .post(
        `${process.env.REACT_APP_CLUBS_URL}/club-members?memberType=${memberType.toUpperCase()}`,
        {
          clubId: id,
          clubRoleId: roleId,
          userId: localStorage.getItem('email'),
          response: response
        },
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*',
            'X-OID': localStorage.getItem('oid')
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: JOIN_CLUB_SUCCESS
          });
          clubName === 'Tech Titans'
            ? toast.success('Club Joining Request Sent!')
            : toast.success('Welcome to the club!');
          dispatch(getClubMembers(id));
          dispatch(getClubs());
        }
      })
      .catch((error) => {
        dispatch({
          type: JOIN_CLUB_FAILURE,
          payload: {
            error: error.message
          }
        });
        toast.error('Request to join club could not be sent!');
      });
  };
};

//to leave club
export const leaveClub = (memberId, clubId) => {
  return (dispatch) => {
    dispatch({
      type: LEAVE_CLUB_LOADING
    });
    axios
      .delete(`${process.env.REACT_APP_CLUBS_URL}/club-members`, {
        headers: {
          accept: '*/*',
          'X-OID': localStorage.getItem('oid')
        },
        params: {
          clubMemberId: memberId
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: LEAVE_CLUB_SUCCESS
          });
          toast.success('You are no longer a part of this club!');
          dispatch(getClubMembers(clubId));
          dispatch(getClubs());
        }
      })
      .catch((error) => {
        dispatch({
          type: LEAVE_CLUB_FAILURE,
          payload: {
            error: error.message
          }
        });
        toast.error('Request to leave club could not be sent!');
      });
  };
};

//To Update role of member
export const updateMemberRole = (myid, role, id) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MEMBER_ROLE_LOADING
    });
    axios
      .put(
        process.env.REACT_APP_CLUBS_URL +
          `/club-members?clubMemberId=${myid}&clubRoleId=${role}&updatedClubMemberId=${id}`,
        {
          headers: {
            accept: '*/*'
          }
        }
      )
      .then((res) => {
        const details = res.data.description;
        if (role === 1) {
          toast.success('Role updated to ADMIN');
        } else if (role === 2) {
          toast.success('Role updated to MEMBER');
        }

        dispatch({
          type: UPDATE_MEMBER_ROLE_SUCCESS,
          payload: {
            details: details
          }
        });
      });
  };
};

//To get all requests
export const getRequests = (clubId) => {
  return (dispatch) => {
    dispatch({
      type: GET_REQUEST_LOADING
    });
    axios
      .get(
        `${process.env.REACT_APP_CLUBS_URL}/club-members/get-all-members-with-joining-status?clubId=${clubId}&clubRole=2&clubJoiningStatus=REQUEST_PENDING`,
        {
          headers: {
            accept: '*/*'
          }
        }
      )
      .then((response) => {
        const details = Array.isArray(response.data) ? [...response.data] : [];
        if (response.status === 200) {
          dispatch({
            type: GET_REQUEST_SUCCESS,
            payload: {
              details: details
            }
          });
        }
        dispatch({
          type: GET_REQUEST_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_REQUEST_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

//to accept/reject club join request
export const joinRequestAction = (memberId, adminId, status, clubId) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_JOINREQUEST_LOADING
    });
    axios
      .patch(
        `${process.env.REACT_APP_CLUBS_URL}/club-members/approve/reject-join-request?clubMemberId=${memberId}&updaterId=${adminId}&request_status=${status}`,
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*'
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch(getClubMembers(clubId));
          dispatch(getRequests(clubId));
          if (status === 'REQUEST_APPROVED') {
            toast.success('You have accepted the request');
          } else toast.success('You have rejected the request');
          dispatch({
            type: UPDATE_JOINREQUEST_SUCCESS
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_JOINREQUEST_FAILURE,
          payload: {
            error: error.message
          }
        });
        // toast.error('Request to join club could not be sent!');
      });
  };
};

export const getClubDetailsWithClubId = () => {
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_DETAILS_WITH_CLUB_ID_LOADING
    });

    axios
      .get(`${process.env.REACT_APP_CLUBS_URL}/clubs/get-club-details`, {
        headers: {
          accept: '*/*'
        }
      })

      .then((response) => {
        const details = response.data;
        if (response.status === 200) {
          dispatch({
            type: GET_CLUB_DETAILS_WITH_CLUB_ID_SUCCESS,
            payload: {
              clubsID: Object.entries(details).map(([clubID, clubName]) => ({ clubID, clubName }))
            }
          });
        }
        dispatch({
          type: GET_CLUB_DETAILS_WITH_CLUB_ID_FAILURE,
          payload: {
            error: response.statusText
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CLUB_DETAILS_WITH_CLUB_ID_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
