import React from 'react';

const Viewport = () => {
  const [width, setWidth] = React.useState(
    Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  );
  const [isMobile, setIsMobile] = React.useState(
    Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0) <=
      process.env.REACT_APP_MOBILE_WIDTH
  );
  const [hasCrossedThreshold, setHasCrossedThreshold] = React.useState(false);
  React.useEffect(() => {
    const handleWindowResize = () => {
      const newWidth = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      if (newWidth <= 992 && !hasCrossedThreshold) {
        // Update width and isMobile only once when crossing below 992px
        setWidth(newWidth);
        setIsMobile(true);
        setHasCrossedThreshold(true);
      } else if (newWidth > 992 && hasCrossedThreshold) {
        // Update width and isMobile only once when crossing above 992px
        setWidth(newWidth);
        setIsMobile(false);
        setHasCrossedThreshold(false);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [hasCrossedThreshold]);

  // Return the width so we can use it in our components
  return { width, isMobile };
};

export default Viewport;
