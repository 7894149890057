import React, { useState } from 'react';
import s from './emoji.module.scss';

// Emoji
import Picker, { EmojiStyle } from 'emoji-picker-react';

import cns from 'classnames';
import { useEffect } from 'react';
import { useRef } from 'react';

const Emoji = ({ onEmojiClick }) => {
  const [toggle, setToggle] = useState(false);
  let wrapper = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!wrapper.current.contains(event.target)) {
        setToggle(false);
      }
    };
    let escapeClose = (event) => {
      if (event.keyCode === 27) {
        setToggle(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('keydown', escapeClose);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('keydown ', escapeClose);
    };
  }, []);

  return (
    <div ref={wrapper}>
      <div id="button-addon1" type="submit" className={s.emojiBtn}>
        <img
          alt="emoji"
          width={21}
          src={process.env.PUBLIC_URL + '/assets/icons/Smiley.svg'}
          className={cns(s.emojiIcon)}
          onClick={() => setToggle(!toggle)}
        />
        <div className={cns(s.emojiContainer, toggle ? s.active : '')}>
          {toggle ? <Picker onEmojiClick={onEmojiClick} /> : ''}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Emoji);
