import {
  EDIT_TRAINING_LOADING,
  EDIT_TRAINING_SUCCESS,
  EDIT_TRAINING_FAILURE
} from '../../actions/actions';

const initialState = {
  editLoading: false,
  editError: '',
  trainings: ''
};

const trainingReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case EDIT_TRAINING_LOADING:
      newState.editLoading = true;
      break;
    case EDIT_TRAINING_SUCCESS:
      newState.editLoading = false;
      break;
    case EDIT_TRAINING_FAILURE:
      newState.editLoading = false;
      newState.editError = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default trainingReducer;
