import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PostCard from './postCard';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';

class SearchedPostsList extends Component {
  state = {
    allposts: [],
    posts: [],
    hasMore: true,
    curpage: 0,
    pagesize: 5,
    totalPage: 0,
    total: 0
  };
  componentWillMount() {
    window.scrollTo(0, 0);
    let curpage = this.state.curpage;
    let posts = this.props.posts.slice(
      curpage * this.state.pagesize,
      (curpage + 1) * this.state.pagesize
    );
    this.setState({
      allposts: this.props.posts,
      posts: posts,
      total: this.props.posts.length,
      totalPage: Math.ceil(this.props.posts.length / this.state.pagesize)
    });
  }
  loadmoreItem() {
    if (this.state.curpage + 1 < this.state.totalPage) {
      let curpage =
        this.state.curpage < this.state.totalPage ? this.state.curpage + 1 : this.state.curpage;
      let posts = this.state.allposts.slice(0, (curpage + 1) * this.state.pagesize);
      this.setState({ posts: posts, curpage });
    } else {
      this.setState({ hasMore: false });
    }
  }
  render() {
    return (
      <div>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => this.loadmoreItem()}
          hasMore={this.state.hasMore}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
          threshold={200}
        >
          <div>
            {this.props.posts.map((post) => (
              <PostCard
                key={post.postId}
                post={post}
                clubId={post.clubId}
                clubDetails={this.props.clubDetails}
                setClubDetails={this.props.setClubDetails}
                likePost={this.props.likePost}
                deleteComment={this.props.deleteComment}
                addComment={this.props.addComment}
                addLoading={this.props.addCommentLoading}
                deletePost={this.props.deletePost}
                likeComment={this.props.likeComment}
                updatePostById={this.props.updatePostById}
                getEmpIdByEmail={this.props.getEmpIdByEmail}
                postTypes={this.props.postTypes}
                hashtags={this.props.hashtags}
                currentEditedPost={this.props.currentEditedPost}
                setCurrentEditedPost={(val) => this.props.setCurrentEditedPost(val)}
                setEditOnePost={this.props.setEditOnePost}
                users={this.props.users}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.userReducer.users
  };
}

export default withRouter(connect(mapStateToProps, null)(SearchedPostsList));
