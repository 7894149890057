import React, { useCallback, useEffect } from 'react';
import cns from 'classnames';

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Scss
import s from '../marketplace.module.scss';

// Icons
// import likeIcon from '../../../assets/icons/like.png';
// import commentIcon from '../../../../../../assets/icons/comment.svg';
// import likePostIcon from '../../../../../../assets/icons/likePost.svg';
import likeIcon from '../../../assets/icons/like.png';
import commentIcon from '../../../assets/icons/comment.svg';
import likePostIcon from '../../../assets/icons/likePost.svg';
// ************************************
// HELPERS
import LikeNames from '../PostCommon/likeName';
import { useState } from 'react';
// import { reactions } from '../../../../../../helpers/iconsHelper';
import { reactions as postReaction } from '../../../../src/helpers/iconsHelper';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';

// ************************************
const preprocessLikes = (reactions) => {
  let likeCount = 0;
  let reactFlag = false;
  const names = [];
  const loveNames = [];
  const likeNames = [];
  const happyNames = [];
  const sadNames = [];
  const celebrateNames = [];
  const supportNames = [];
  const surpriseNames = [];
  reactions &&
    reactions.forEach((reaction) => {
      if (reaction?.reactFlag === 1) {
        if (
          reaction?.postReactIdentity?.email === localStorage.getItem('email') ||
          reaction?.postReactIdentity?.userId === localStorage.getItem('email')
        ) {
          reactFlag = true;
        } else {
          likeCount++;
        }
        names.push(reaction?.postReactIdentity);
        const reactionTypes = {
          LIKE: likeNames,
          LOVE: loveNames,
          HAPPY: happyNames,
          SAD: sadNames,
          SUPPORT: supportNames,
          SURPRISE: surpriseNames,
          CELEBRATE: celebrateNames
        };

        if (reaction?.reactionType in reactionTypes) {
          reactionTypes[reaction?.reactionType].push(reaction?.postReactIdentity);
        }
      }
    });

  return {
    likeCount,
    reactFlag,
    names,
    loveNames,
    celebrateNames,
    likeNames,
    happyNames,
    supportNames,
    sadNames,
    surpriseNames
  };
};
const PostCardLike = ({ likes, postId, likePost, toggleExpand, comments, users, userReaction }) => {
  const {
    reactFlag,
    likeCount,
    names,
    loveNames,
    likeNames,
    happyNames,
    sadNames,
    supportNames,
    surpriseNames
  } = preprocessLikes(likes);
  const postLikeCount = likeCount;
  const [postLikeFlag, setpostLikeFlag] = useState(reactFlag);
  const [showReactions, setShowReactions] = useState(false);
  const [activeReaction, setActiveReaction] = useState(null);

  const handleClick = (event) => {
    setpostLikeFlag(reactFlag ^ 1);

    const userReaction =
      likes &&
      likes?.find(
        (reaction) =>
          reaction?.postReactIdentity?.email === localStorage.getItem('email') ||
          reaction?.postReactIdentity?.userId === localStorage.getItem('email')
      );

    let data = {
      reactFlag: userReaction ? userReaction?.reactFlag ^ 1 : reactFlag ^ 1,
      reactionType: 'LIKE',
      postId: postId
    };
    if (userReaction?.reactFlag === 0 || !userReaction) {
      setActiveReaction('LIKE');
      data = {
        reactFlag: 1,
        reactionType: 'LIKE',
        postId: postId
      };
    } else {
      setActiveReaction(null);
    }
    likePost(data);
  };

  const reactionIcon = () => {
    const reactionCounts = {};
    likes.forEach((reaction) => {
      if (reaction?.reactFlag === 1) {
        const reactionType = reaction?.reactionType;

        reactionCounts[reactionType] = (reactionCounts[reactionType] || 0) + 1;
      }
    });

    const uniqueReactionTypes = Object.keys(reactionCounts);

    if (uniqueReactionTypes?.length === 1) {
      // For 1 reaction type icon
      const reactionType = activeReaction ?? uniqueReactionTypes[0];
      return (
        <img
          src={postReaction[reactionType.toUpperCase()]}
          alt={`${reactionType}-icon`}
          height={20}
          width={20}
        />
      );
    } else if (uniqueReactionTypes?.length === 2 || uniqueReactionTypes?.length === 3) {
      // For 2 or 3 reactionType icon
      return (
        <>
          {uniqueReactionTypes.map((reactionType) => (
            <img
              key={reactionType}
              src={postReaction[reactionType.toUpperCase()]}
              alt={`${reactionType}-icon`}
              width={20}
              height={20}
              className={s.overlapIcon}
            />
          ))}
        </>
      );
    } else {
      // For 3 or more reactionType icon
      const lastReactionTypes = uniqueReactionTypes.slice(-3);
      return (
        <>
          {lastReactionTypes.map((reactionType) => (
            <img
              key={reactionType}
              src={postReaction[reactionType.toUpperCase()]}
              alt={`${reactionType}-icon`}
              height={20}
              width={20}
              className={s.overlapIcon}
            />
          ))}
        </>
      );
    }
  };

  const userReacIcon = () => {
    if (postLikeFlag) {
      const userReaction = likes?.find(
        (reaction) =>
          reaction?.postReactIdentity?.userId === localStorage.getItem('email') ||
          reaction?.postReactIdentity?.email === localStorage.getItem('email')
      );

      if (userReaction || activeReaction) {
        const reactionType = activeReaction ?? userReaction.reactionType;
        return (
          <>
            <img
              src={postReaction[activeReaction] ?? postReaction[reactionType.toUpperCase()]}
              alt={`reaction-icon`}
              height={20}
              width={20}
            />
            <span className={`${s.reacLabel} ${s[reactionType]}`}>
              {_.capitalize(reactionType)}
            </span>
          </>
        );
      }
    }
  };
  return (
    <>
      <div
        className={s.likeCommentWrapper}
        style={{ height: postLikeCount + postLikeFlag > 0 || comments?.length ? '30px' : '' }}
      >
        {postLikeCount + postLikeFlag > 0 ? (
          <div className={s.likeCount}>
            {likes?.length ? reactionIcon() : ''}
            <span className={s.likeCountText}>
              {postLikeCount + postLikeFlag > 0 ? postLikeCount + postLikeFlag : ''}
            </span>
            <div className={s.dropdownContent}>
              <LikeNames
                names={names}
                loveNames={loveNames}
                likeNames={likeNames}
                happyNames={happyNames}
                sadNames={sadNames}
                supportNames={supportNames}
                surpriseNames={surpriseNames}
                users={users}
                userReaction={userReaction}
              />
            </div>
          </div>
        ) : (
          ''
        )}

        {comments.length > 0 ? (
          <div onClick={() => toggleExpand()} className={s.commentCount}>
            {comments?.length} comment{comments?.length > 1 ? 's' : ''}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={s.footerBtns}>
        <button className={cns(s.postLikeBtn, postLikeFlag ? s.active : '')} onClick={handleClick}>
          <div className="d-flex align-items-center" style={{ zIndex: 1 }}>
            {postLikeFlag ? (
              userReacIcon()
            ) : (
              <img
                src={activeReaction ? postReaction[activeReaction] : likePostIcon}
                alt="like-icon"
                width={15}
                height={15}
                className={(cns(s.postLikeIcon, postLikeFlag ? s.active : ''), 'mr-2')}
              />
            )}
            {postLikeFlag ? '' : 'Like'}
          </div>
        </button>
        <button className={s.postCommentBtn} onClick={() => toggleExpand()}>
          <img src={commentIcon} alt="comment-icon" width={15} height={15} className="mr-2" />
          Comment
        </button>
      </div>
    </>
  );
};

export default PostCardLike;
