import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Icon
import Anniversary from '../../../../../../assets/icons/Anniversary.png';

// ActionCreators
import { getAnniversary } from '../../../../../../redux/actionCreators/anniversary.actionCreator';
import { getProfileByEmail } from '../../../../../../helpers';
import { Skeleton } from '@material-ui/lab';

const WorkAnniversary = (props) => {
  const employees = Object.values(props?.anniversary);

  useEffect(() => {
    props.getAnniversary();
  }, []);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const getProfile = (userId) => {
    getProfileByEmail(userId);
  };

  const Header = () => {
    return (
      <div className="header">
        <div>
          <img src={Anniversary} className="anniversary-header" height="35" width="35" alt="" />
          <span className="anniversary-heading">Work Anniversary</span>
        </div>
      </div>
    );
  };

  const formatDate = (dateArray) => {
    const [year, month, day] = dateArray;
    return `${day} ${monthNames[month - 1]}`;
  };

  return (
    <div className="anniversary">
      <Header />
      <div className="anniversary-body">
        {props?.loading ? (
          <div>
            <Skeleton animation="wave" />
            <hr className="my-1" />
            <Skeleton animation="wave" />
            <hr className="my-1" />
            <Skeleton animation="wave" />
            <hr className="my-1" />
          </div>
        ) : (
          employees?.map((anni) => {
            return (
              <div className="anniversary-body-inner" key={anni.empId}>
                <div className="member-image">
                  <img
                    referrerPolicy="no-referrer"
                    height="22"
                    width="22"
                    src={anni.imagePath}
                    alt="img"
                  ></img>
                </div>
                <div className="name" onClick={() => getProfile(anni.email)}>
                  {anni.employeeName}
                </div>
                <div className="date">{formatDate(anni?.date)}</div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    anniversary: state.anniversaryReducer.anniversary,
    error: state.anniversaryReducer.error,
    loading: state.anniversaryReducer.loading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAnniversary
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkAnniversary);
