import React, { useCallback, useEffect, useRef } from 'react';
import cns from 'classnames';
import cls from './ImageModal.module.scss';
import CloseIcon from '../../assets/icons/closeModal.svg';
import { Carousel } from 'react-bootstrap';
import { Modal } from '@material-ui/core';
import { useState } from 'react';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

const ImageModal = ({
  showModal = false,
  selectedImageIndex,
  onSuccess,
  files,
  setShowImageModal = () => {}
}) => {
  let modalBoxRef = useRef();

  const handleClose = () => {
    setShowImageModal(false);
  };

  const [currentImage, setCurrentImage] = useState(selectedImageIndex);

  useEffect(() => {
    const handleArrowKeys = (event) => {
      if (event.key === 'ArrowLeft' && currentImage > 0) {
        setCurrentImage((prevIndex) => prevIndex - 1);
      } else if (event.key === 'ArrowRight' && currentImage < files.length - 1) {
        setCurrentImage((prevIndex) => prevIndex + 1);
      }
    };

    document.addEventListener('keydown', handleArrowKeys);

    return () => {
      document.removeEventListener('keydown', handleArrowKeys);
    };
  }, [currentImage, files]);
  useEffect(() => {
    setCurrentImage(selectedImageIndex);
  }, [selectedImageIndex]);

  const handleImageRight = () => {
    setCurrentImage((prevIndex) => prevIndex + 1);
  };
  const handleImageLeft = () => {
    setCurrentImage((prevIndex) => prevIndex - 1);
  };

  return (
    <>
      <Modal open={showModal} onClose={handleClose}>
        <div className={cns(cls.modalWrapper)}>
          <div ref={modalBoxRef} className={cns(cls.modalBox)}>
            <button onClick={onSuccess}>
              <img src={CloseIcon} alt="close" width={35} height={35} />
            </button>

            {currentImage < files.length - 1 && (
              <ArrowForwardIos onClick={handleImageRight} className={cls.arrowForward} />
            )}

            {currentImage > 0 && (
              <ArrowBackIos onClick={handleImageLeft} className={cls.arrowBackward} />
            )}

            <div className={cns(cls.imageWrapper)}>
              <Carousel
                interval={null}
                controls={false}
                indicators={false}
                variant="dark"
                wrap={false}
                activeIndex={currentImage}
                onSelect={(index) => setCurrentImage(index)}
                defaultActiveIndex={selectedImageIndex}
              >
                {files &&
                  files?.map((photo) => {
                    return (
                      <Carousel.Item key={photo.fileId}>
                        {photo.fileType.split('/')[0] === 'image' && (
                          <>
                            <img
                              referrerPolicy="no-referrer"
                              src={photo?.fileName}
                              alt="Not available"
                              loading="eager"
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          </>
                        )}
                      </Carousel.Item>
                    );
                  })}
              </Carousel>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ImageModal);
