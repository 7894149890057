import React, { useState } from 'react';
import s from './eventsPage.module.scss';
import p from './../../../home/sidebar/event/details.module.scss';
import {
  DATE_FORMAT,
  TIME_FORMAT,
  getDateFromArray,
  getProfileByEmail
} from '../../../../../../helpers';
import calIcon from '../../../../../../assets/icons/calIcon.svg';
import DisplayPoll from '../../../home/sidebar/event/displayPoll';
import cns from 'classnames';
import {
  deleteEvent,
  getEvents,
  getOrganisers
} from '../../../../../../redux/actionCreators/event.actionCreator';
import {
  addVote,
  deletePoll,
  editVote,
  getPollResponse,
  getPollResponseByUser
} from '../../../../../../redux/actionCreators/polls.actionCreator';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAwards } from '../../../../../../redux/actionCreators/award.actionCreator';
import goldBadge from '../../../../../../assets/icons/gold.svg';
import silverBadge from '../../../../../../assets/icons/silver.svg';
import bronzeBadge from '../../../../../../assets/icons/bronze.svg';
import userIcon from '../../../../../../assets/img/user.png';
import rank4Badge from '../../../../../../assets/icons/Rank 4.svg';
import rank5Badge from '../../../../../../assets/icons/Rank 5.svg';
import locationIcon from '../../../../../../assets/icons/location.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import EventBtns from '../../../home/sidebar/event/eventBtns';

import moment from 'moment';
import AddEvent from '../../../home/sidebar/event/addEvent';
const EventsCard = ({
  events,
  pastEvents,
  clubName,
  membersData,
  event,
  users,
  awardsWin,
  editEvent,
  deleteEvent,
  organisers,
  isPastEvent = false,
  ...props
}) => {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'September',
    'October',
    'November',
    'December'
  ];
  const pollWithEvent = event?.poll;
  const [eventToggle, setEventToggle] = useState('');
  const clubIdData = useSelector((state) => state?.clubsReducer?.clubsIdByClubName);
  const [adminData, setAdminData] = useState([]);

  let adminId = [];
  useEffect(() => {
    props.membersDetails.forEach((item) => {
      if (item.roles?.roleName === 'admin') {
        adminId.push(item?.userId);
      }
    });

    //To remove redundant entries
    const uniqueAdminData = [...new Set(adminId)];
    let adminList = uniqueAdminData.map((admin) => {
      return { email: admin, club: clubName };
    });
    setAdminData(adminList);
  }, [props.membersDetails]);

  useEffect(() => {
    event?.poll?.pollId &&
      props.getPollResponse(event?.poll?.pollId).then((res) => {
        pollWithEvent.scores = res.data;
      });
  }, [props?.polls]);

  function badges(rank) {
    switch (rank) {
      case 1:
        return goldBadge;
      case 2:
        return silverBadge;
      case 3:
        return bronzeBadge;
      case 4:
        return rank4Badge;
      case 5:
        return rank5Badge;
      default:
        return null;
    }
  }

  let isEventPresentInClub = false;
  isEventPresentInClub = event?.organizerName === clubName;

  const today = new Date().toISOString().split('T')[0];

  const eventEndDate = getDateFromArray(event?.endDate, DATE_FORMAT.YYYY_MM_DD);
  const eventStartDate = getDateFromArray(event?.startDate, DATE_FORMAT.YYYY_MM_DD);

  const handleDownload = (pollId) => {
    const info = toast.info('Downloading Results...');
    const apiUrl = `${process.env.REACT_APP_EVENTS_URL}/poll-response/downloadExcel?pollId=${pollId}`;

    axios({
      url: apiUrl,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const fileName = `Poll Results_${event?.eventName}.xlsx`;
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        toast.dismiss(info);
        toast.success('Poll Results downloaded successfully.');
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };
  const handleDelete = () => {
    if (event.eventType === 'past') {
      deleteEvent(event.eventId);
    } else {
      deleteEvent(event.eventId);
    }
  };

  const isCurrentEventActive = eventEndDate >= today;

  const copyHandler = (e) => {
    const url = window.location.origin;
    const clubVal = clubIdData.find((club) => club.clubName === event.organizerName)?.clubID;
    const val = url + '/#/clubs/' + clubVal + '/event/' + event.eventId;
    navigator.clipboard.writeText(val);
    toast.success('Copied!');
  };

  return (
    <>
      {isEventPresentInClub && (
        <div className={s.eventsWrapper}>
          <div className={p.eventWrapper}>
            <div className={s.eventTop}>
              <EventBtns
                title={{ edit: 'Edit Event', delete: 'Delete Event' }}
                handleEdit={() => setEventToggle('edit')}
                handleDelete={handleDelete}
                editCondition={eventToggle !== 'edit' && isCurrentEventActive}
                pollData={pollWithEvent}
                copyHandler={copyHandler}
                hrArr={adminData}
                event={event}
              />
            </div>
            <div className={p.eventDetails}>
              {eventToggle === 'edit' ? (
                <>
                  <div className={cns(s.eventHeading, 'mb-3')}>Edit Event</div>
                  <AddEvent
                    setAddEventToggle={setEventToggle}
                    editEventData={event}
                    clubName={clubName}
                  />
                </>
              ) : (
                <>
                  <div className={s.eventName}>{event?.eventName}</div>
                  <div className={s.eventInfoWrapper}>
                    <div className={s.dateWrapper}>
                      <img src={calIcon} />
                      <div className={s.dateTime}>
                        <p className={s.eventDate}>
                          {eventStartDate === eventEndDate
                            ? getDateFromArray(event?.startDate, DATE_FORMAT.DATE)
                            : `${event?.startDate[2]} - ${event?.endDate[2]}`}
                          &nbsp;
                          {month[event?.endDate[1] - 1]}
                        </p>

                        <p className={s.time}>
                          {getDateFromArray(event?.startDate, TIME_FORMAT.HH_MM_A)} -
                          {getDateFromArray(event?.endDate, TIME_FORMAT.HH_MM_A)} (IST)
                        </p>
                      </div>
                    </div>
                    {event?.location && (
                      <div className={s.locationWrapper}>
                        <img src={locationIcon} alt="LocationIcon" />
                        <p>{event?.location}</p>
                      </div>
                    )}
                  </div>
                  <div>
                    <p className={s.heading}>About the Event</p>
                    <p className={s.description}>{event?.eventDescription}</p>
                  </div>
                  <div className={s.wrapper}>
                    {pollWithEvent && !isPastEvent && (
                      <div className="w-50">
                        <p className={s.heading}>Poll</p>
                        <DisplayPoll
                          addVote={props.addVote}
                          editVote={props.editVote}
                          poll={pollWithEvent}
                          getPollResponseByUser={props.getPollResponseByUser}
                          deletePoll={props.deletePoll}
                          users={props?.users}
                          hrArr={adminData}
                          setEventToggle={setEventToggle}
                          handleDownload={handleDownload}
                          event={{ organizerName: clubName }}
                        />
                      </div>
                    )}
                    {awardsWin?.some((award) => award.eventId === event?.eventId) && (
                      <div className="w-50 ml-2">
                        <>
                          <p className={s.heading}>Results</p>
                          <div className={s.awardWrapper}>
                            {awardsWin
                              .filter((data) => data.eventId === event?.eventId)
                              .map((award, index) => {
                                const awardsData = Object.values(users).filter(
                                  (value) => value?.email === award?.eventWinnerEmail
                                );
                                const userData = awardsData[0];

                                return (
                                  <>
                                    <div className={s.results}>
                                      {award.rank && (
                                        <img
                                          className={s.badge}
                                          src={badges(award.rank)}
                                          alt="Badge"
                                        />
                                      )}
                                      <div
                                        className={s.awardsInfo}
                                        style={{ cursor: userData?.photoUrl && 'pointer' }}
                                        onClick={() =>
                                          userData?.photoUrl &&
                                          getProfileByEmail(award?.eventWinnerEmail)
                                        }
                                      >
                                        <img
                                          referrerPolicy="no-referrer"
                                          src={userData?.photoUrl ?? userIcon}
                                          className="rounded-circle mr-2  bg-light-purple commentPic"
                                          alt="profile"
                                          width={35}
                                          height={35}
                                        />
                                        <div>
                                          <p className={s.awardName}>{award.winnerName}</p>
                                          <p className={s.awardTeam}>{userData?.teamName}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
function mapStateToProps(state) {
  return {
    editEventError: state.eventReducer.editError,
    organisers: state.eventReducer.organisers,
    polls: state.pollsReducer.polls,
    clubs: state.clubsReducer.clubs,
    clubDetails: state.clubsReducer.clubDetails,
    awards: state.awardReducer.awards,
    membersDetails: state.clubsReducer.membersDetails
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEvents: getEvents,
      deleteEvent: deleteEvent,
      addVote: addVote,
      editVote: editVote,
      getOrganisers: getOrganisers,
      deletePoll: deletePoll,
      getPollResponse: getPollResponse,
      getPollResponseByUser: getPollResponseByUser,
      getAwards: getAwards
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsCard);
