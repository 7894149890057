import React, { useEffect } from 'react';
import s from './eventsPage.module.scss';
import { useState } from 'react';
import EventsCard from './eventsCard';
import { connect } from 'react-redux';
import { getClubMembers } from '../../../../../../redux/actionCreators/clubs.actionCreator';
import { bindActionCreators } from 'redux';
import { getAwardsWin } from '../../../../../../redux/actionCreators/award.actionCreator';
import { CircularProgress, Paper, Tooltip } from '@material-ui/core';
import cns from 'classnames';
import AddEvent from '../../../home/sidebar/event/addEvent';
import Polls from '../../../home/sidebar/event/polls';
import { Cancel, CheckCircle } from '@material-ui/icons';
import Details from '../../../home/sidebar/event/details';
const EventsPage = React.memo(({ events, pastEvents, members, clubName, ...props }) => {
  const [addToggle, setAddToggle] = useState(false);
  const [adminData, setAdminData] = useState([]);
  useEffect(() => {
    props.getAwardsWin();
  }, []);

  let adminId = [];
  useEffect(() => {
    members.forEach((item) => {
      if (item.roles?.roleName === 'admin') {
        adminId.push(item?.userId);
      }
    });

    //To remove redundant entries
    const uniqueAdminData = [...new Set(adminId)];
    let adminList = uniqueAdminData.map((admin) => {
      return { email: admin, club: clubName };
    });
    setAdminData(adminList);
  }, [members]);
  let count = 0;
  for (let i = 0; i < pastEvents.length; i++) {
    pastEvents[i].organizerName === clubName && count++;
  }
  let upcomingEventsCount = 0;
  for (let i = 0; i < events.length; i++) {
    events[i].organizerName === clubName && upcomingEventsCount++;
  }
  const [selectedEventCategory, setSelectedEventCategory] = useState(
    upcomingEventsCount > 0 ? 'upcoming' : 'past'
  );
  return (
    <div>
      {addToggle ? (
        <>
          <Paper className="mt-3">
            <p className={cns(s.eventHeading, 'mx-3 py-3')}>Create Event</p>

            <div className="p-3">
              <AddEvent setAddEventToggle={setAddToggle} clubName={clubName} />
            </div>
          </Paper>
        </>
      ) : (
        selectedEventCategory === 'upcoming' &&
        (upcomingEventsCount > 0 ? (
          events?.map((event) => {
            let isEventPresentInClub = false;
            isEventPresentInClub = event?.organizerName === clubName;
            return (
              <>
                <EventsCard
                  event={event}
                  clubName={clubName}
                  membersData={members}
                  users={Object.values(props.users)}
                  awardsWin={props.awardsWin}
                />
              </>
            );
          })
        ) : (
          <span className={s.message}>No Upcoming Events available.</span>
        ))
      )}
      {selectedEventCategory === 'past' &&
        (count > 0 ? (
          pastEvents?.map((event) => {
            return (
              <>
                <EventsCard
                  event={event}
                  clubName={clubName}
                  membersData={members}
                  users={Object.values(props.users)}
                  awardsWin={props.awardsWin}
                  isPastEvent={true}
                />
              </>
            );
          })
        ) : (
          <span className={s.message}>
            <CircularProgress color="inherit" />
          </span>
        ))}
    </div>
  );
});
const mapStateToProps = (state) => {
  return {
    loading: state.clubsReducer.loading,
    error: state.clubsReducer.error,
    membersDetails: state.clubsReducer.membersDetails,
    profileDetails: state.profileReducer.details,
    events: state.eventReducer.eventsById,
    pastEvents: state.eventReducer.pastEvents,
    users: state.userReducer.users,
    awardsWin: state.awardReducer.awardsWin
  };
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClubMembers: getClubMembers,
      getAwardsWin: getAwardsWin
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);
