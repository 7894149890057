import React, { useContext, useEffect, useState } from 'react';

//mui
import { Menu, MenuItem, makeStyles, Chip } from '@material-ui/core';

//scss
import s from './filter.module.scss';
import { MyContext } from '../../context/MyContext.js';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  subHeaderMenu: {
    '& .MuiFormControlLabel-root': {
      height: '28px',
      paddingRight: '8px'
    },
    '& .Mui-checked': {
      color: '#3D64D3 !important'
    }
  }
});

const FilterSubHeader = ({
  value,

  selectedSortOption,
  setSelectedSortOption,
  setSortData,
  sortData
}) => {
  const classes = useStyles();
  const [anchorElPost, setAnchorElPost] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    marketplaceFilterBody,
    setMarketplaceFilterBody,
    marketplaceCarPoolFilterBody,
    setMarketplaceCarPoolFilterBody
  } = useContext(MyContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedSortOption('All Posts');
    if (value === 1) {
      if (
        marketplaceFilterBody?.isLookingOrLending &&
        marketplaceFilterBody.isLookingOrLending.length === 2
      ) {
        setSelectedSortOption('All Posts');
      } else if (
        marketplaceFilterBody?.isLookingOrLending &&
        marketplaceFilterBody.isLookingOrLending[0] === 'LOOKING'
      ) {
        setSelectedSortOption('Seeking');
      } else if (
        marketplaceFilterBody?.isLookingOrLending &&
        marketplaceFilterBody.isLookingOrLending[0] === 'LENDING'
      ) {
        setSelectedSortOption('Renting');
      }
    } else if (value === 2) {
      if (
        marketplaceCarPoolFilterBody?.carPoolType &&
        marketplaceCarPoolFilterBody.carPoolType.length === 2
      ) {
        setSelectedSortOption('All Posts');
      } else if (
        marketplaceCarPoolFilterBody?.carPoolType &&
        marketplaceCarPoolFilterBody.carPoolType[0] === 'DRIVER_POST'
      ) {
        setSelectedSortOption('Ride');
      } else if (
        marketplaceCarPoolFilterBody?.carPoolType &&
        marketplaceCarPoolFilterBody.carPoolType[0] === 'PASSENGER_POST'
      ) {
        setSelectedSortOption('Passengers');
      }
    }
  }, [value, marketplaceFilterBody, marketplaceCarPoolFilterBody]);

  const handleClickPost = (event) => {
    setAnchorElPost(event.currentTarget);
  };

  const handleClosePost = () => {
    setAnchorElPost(null);
  };

  const handlePostTypeClick = (sortOption) => {
    if (sortOption === 'All Posts') {
      setMarketplaceFilterBody({
        ...marketplaceFilterBody,
        isLookingOrLending: ['LOOKING', 'LENDING']
      });
      setMarketplaceCarPoolFilterBody({
        ...marketplaceCarPoolFilterBody,
        carPoolType: ['DRIVER_POST', 'PASSENGER_POST']
      });
    } else if (sortOption === 'Seeking') {
      setMarketplaceFilterBody({ ...marketplaceFilterBody, isLookingOrLending: ['LOOKING'] });
    } else if (sortOption === 'Renting') {
      setMarketplaceFilterBody({ ...marketplaceFilterBody, isLookingOrLending: ['LENDING'] });
    } else if (sortOption === 'Ride') {
      setMarketplaceCarPoolFilterBody({
        ...marketplaceCarPoolFilterBody,
        carPoolType: ['DRIVER_POST']
      });
    } else if (sortOption === 'Passengers') {
      setMarketplaceCarPoolFilterBody({
        ...marketplaceCarPoolFilterBody,
        carPoolType: ['PASSENGER_POST']
      });
    }
    setSelectedSortOption(sortOption);
    handleClosePost();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortOptionClick = (sortOption) => {
    setSortData(sortOption);
    handleClose();
  };

  const sortOptions = [
    'All Posts',
    value === 0 ? 'Buy' : value === 1 ? 'Seeking' : 'Ride',
    value === 0 ? 'Sell' : value === 1 ? 'Renting' : 'Passengers'
    // 'Sold Out',
  ];

  return (
    <div className={` ${s.fixedSubHeader}`}>
      <div className="px-2 py-1">
        {sortOptions.map((option) => (
          <Chip
            key={option}
            label={option}
            clickable
            color={selectedSortOption === option ? 'primary' : 'default'}
            onClick={() => handlePostTypeClick(option)}
            className="px-2"
            style={{
              marginRight: '10px',
              marginBottom: '4px',
              fontSize: '12px',
              fontWeight: '600',
              backgroundColor: selectedSortOption === option ? '#38A3A5' : 'white'
            }}
          />
        ))}
      </div>

      <div className="d-flex my-2">
        <div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            {['Relevance', 'Newest First', 'Price Low to High', 'Price High to Low'].map(
              (option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleSortOptionClick(option)}
                  style={{
                    backgroundColor: sortData === option ? '#EBEEF2' : 'white',
                    color: '#727373',
                    fontWeight: sortData === option ? 600 : 500
                  }}
                >
                  {option}
                </MenuItem>
              )
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default FilterSubHeader;
