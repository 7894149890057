import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Icons
import PlusIcon from '../../../../../../assets/icons/plus-blue.svg';
import AwardIcon from '../../../../../../assets/icons/award.svg';

// Components
import AddAward from './addAward';
import Search from './searchAward';

// ActionCreators
import {
  getAwards,
  getAwardsWinners,
  getAddAwards
} from '../../../../../../redux/actionCreators/award.actionCreator';
import { addAward } from '../../../../../../redux/actionCreators/award.actionCreator';
import { getHumanResourceData } from '../../../../../../redux/actionCreators/entitlement.actionCreator';
import { getAllUsers } from '../../../../../../redux/actionCreators/user.actionCreator';
const Award = (props) => {
  const [addAwardToggle, setAddAwardToggle] = useState(false);

  useEffect(() => {
    // props.getHumanResourceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const data = [
    'ritika.jain@geminisolutions.com',
    'nikhil.sharma@geminisolutions.com',
    'smriti.pathak@geminisolutions.com',
    'sagar.srivastava@geminisolutions.com',
    'radhika.narang@geminisolutions.com',
    'madhur.singhal@geminisolutions.com',
    'neha.pathak@geminisolutions.com',
    'arihant.jain@geminisolutions.com',
    'devesh.chandra@geminisolutions.com',
    'shrishti.yadav@geminisolutions.com',
    'jayam.gupta@geminisolutions.com'
  ];

  const Header = () => {
    const hrArr = [];
    const hrData = props.hrData?.filter((s) => {
      if (s.teamName === 'HUMAN RESOURCE') {
        hrArr.push(s.email);
      }
    });
    const additionalData = data.map((s) => hrArr.push(s));
    return (
      <div className="header">
        <div>
          <img src={AwardIcon} className="award-header" height="35" width="35" alt="" />
          <span className="award-heading">Awards</span>
        </div>
        {hrArr.includes(localStorage.getItem('email')?.toLowerCase()) ? (
          <div className="action-button">
            {addAwardToggle ? (
              <span
                className="action cursor-pointer"
                onClick={() => setAddAwardToggle((add) => !add)}
              >
                <img src={PlusIcon} alt="plus" width="20" height="20" className="rotate-45" />
              </span>
            ) : (
              <button className="add-button" onClick={() => setAddAwardToggle(true)}>
                + Add New
              </button>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <div className="award">
      <Header />
      {addAwardToggle ? (
        <AddAward
          addAward={props.addAward}
          awards={props.awards}
          getAwards={props.getAddAwards}
          setAddAwardToggle={setAddAwardToggle}
        />
      ) : (
        <Search
          getAwards={props.getAwards}
          awards={props.awards}
          getAwardsWinners={props.getAwardsWinners}
          winners={props.winners}
          loading={props.loading}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    awards: state.awardReducer.awards,
    loading: state.awardReducer.getLoading,
    error: state.awardReducer.getError,
    hrData: state.entitlementReducer.hrUsers,
    winners: state.awardReducer.winners
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAwards,
      addAward,
      getAddAwards,
      getHumanResourceData,
      getAwardsWinners,
      getAllUsers
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Award);
