import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Icon
import BirthdayIcon from '../../../../../../assets/icons/Birthday.png';

// ActionCreators
import { getBirthdays } from '../../../../../../redux/actionCreators/birthday.actionCreator';
import { getAllUsers } from '../../../../../../redux/actionCreators/user.actionCreator';

//helpers
import { getProfileByEmail } from '../../../../../../helpers';
import { useState } from 'react';
import { uniqueId } from 'lodash';
import { Skeleton } from '@material-ui/lab';

const Birthday = (props) => {
  const employees = Object.values(props?.birthdays);

  useEffect(() => {
    props.getBirthdays();
  }, []);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const getProfile = async (userId) => {
    getProfileByEmail(userId);
  };

  const Header = () => {
    return (
      <div className="header">
        <div>
          <img src={BirthdayIcon} className="birthday-header" height="35" width="35" alt="" />
          <span className="birthday-heading">Birthday</span>
        </div>
      </div>
    );
  };

  const formatDate = (dateArray) => {
    const [year, month, day] = dateArray;
    return `${day} ${monthNames[month - 1]}`;
  };

  return (
    <div className="birthday">
      <Header />
      <div className="birthday-body">
        {props.loading ? (
          <div>
            <Skeleton animation="wave" />
            <hr className="my-1" />
            <Skeleton animation="wave" />
            <hr className="my-1" />
            <Skeleton animation="wave" />
            <hr className="my-1" />
          </div>
        ) : (
          employees?.map((birthday) => {
            return (
              <React.Fragment key={birthday.empId}>
                <div className="birthday-body-inner">
                  <div className="member-image">
                    <img
                      referrerPolicy="no-referrer"
                      height="22"
                      width="22"
                      src={birthday.imagePath}
                      alt="u"
                    />
                  </div>
                  <div className="name" onClick={() => getProfile(birthday.email)}>
                    {birthday.employeeName}
                  </div>
                  <div className="date">{formatDate(birthday?.date)}</div>
                </div>
              </React.Fragment>
            );
          })
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    birthdays: state.birthdaysReducer.birthdays,
    error: state.birthdaysReducer.error,
    loading: state.birthdaysReducer.loading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBirthdays
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Birthday);
