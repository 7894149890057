import {
  GET_CUSTOM_TAGS_LOADING,
  GET_CUSTOM_TAGS_SUCCESS,
  GET_CUSTOM_TAGS_FAILURE,
  CREATE_CUSTOM_TAGS_LOADING,
  CREATE_CUSTOM_TAGS_SUCCESS,
  CREATE_CUSTOM_TAGS_FAILURE,
  DELETE_CUSTOM_TAGS_LOADING,
  DELETE_CUSTOM_TAGS_SUCCESS,
  DELETE_CUSTOM_TAGS_FAILURE,
  GET_CUSTOM_TAG_DATA_LOADING,
  GET_CUSTOM_TAG_DATA_SUCCESS,
  GET_CUSTOM_TAG_DATA_FAILURE
} from '../actions/actions';

const initialState = {
  loading: false,
  customTags: [],
  customTagData: [],
  error: ''
};

const customTagsReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_CUSTOM_TAGS_LOADING:
      newState.loading = true;
      break;
    case GET_CUSTOM_TAGS_SUCCESS: {
      newState.loading = false;
      newState.customTags = [...action.payload.customTags];
      newState.error = '';
      break;
    }
    case GET_CUSTOM_TAGS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case GET_CUSTOM_TAG_DATA_LOADING:
      newState.loading = true;
      break;
    case GET_CUSTOM_TAG_DATA_SUCCESS: {
      newState.loading = false;
      newState.customTagData = { ...action.payload.customTagData };
      newState.error = '';
      break;
    }
    case GET_CUSTOM_TAG_DATA_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case CREATE_CUSTOM_TAGS_LOADING:
      newState.loading = true;
      break;
    case CREATE_CUSTOM_TAGS_SUCCESS:
      newState.loading = false;
      newState.error = '';
      break;
    case CREATE_CUSTOM_TAGS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case DELETE_CUSTOM_TAGS_LOADING:
      newState.loading = true;
      break;
    case DELETE_CUSTOM_TAGS_SUCCESS:
      newState.loading = false;
      newState.error = '';
      break;
    case DELETE_CUSTOM_TAGS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default customTagsReducer;
