import {
  EDIT_ACHIEVEMENT_LOADING,
  EDIT_ACHIEVEMENT_SUCCESS,
  EDIT_ACHIEVEMENT_FAILURE
} from '../../actions/actions';

const initialState = {
  editLoading: false,
  editError: '',
  achievements: ''
};

const achievementReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case EDIT_ACHIEVEMENT_LOADING:
      newState.editLoading = true;
      break;
    case EDIT_ACHIEVEMENT_SUCCESS:
      newState.editLoading = false;
      break;
    case EDIT_ACHIEVEMENT_FAILURE:
      newState.editLoading = false;
      newState.editError = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default achievementReducer;
