import React, { useContext, useEffect, useState } from 'react';

//Components
import BuySellPostCard from './BuySellPostCard';
import FlatRentPostCard from './FlatRentPostCard';
import Filter from '../../../components/Filter/filter';
import FilterHeader from '../../../components/Filter/filterHeader';
import FilterSubHeader from '../../../components/Filter/filterSubHeader';

//Redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

//actions
import { saveLikeForMarketPost } from '../../../redux/actionCreators/postLike.actionCreator';
import {
  getAllDeptLocations,
  getCarPoolPost,
  getFlatRentPost,
  getTotalPages,
  setFinalData1
} from '../../../redux/actionCreators/marketplace.actionCreator';
import {
  getAllLocations,
  deleteMarketPost
} from '../../../redux/actionCreators/marketplace.actionCreator';
import {
  addMarketComment,
  deleteMarketComment,
  likeMarketComment
} from '../../../redux/actionCreators/comment.actionCreator';
import { getEmpIdByEmail } from '../../../redux/actionCreators/profile.actionCreator';

//MUI
import { Grid, makeStyles } from '@material-ui/core';

//SCSS
import s from '../marketplace.module.scss';

//assets
import noResults from '../../../assets/img/clubsNoPost.svg';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';
import CarPool from './CarPool';
import CarPoolFilter from '../../../components/Filter/carPoolFilter';
import { MyContext } from '../../../context/MyContext';

const BuySell = ({ ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  // const [value, setValue] = useState(useSelector((state) => state.marketPlace.value));
  const [value, setValue] = useState(parseInt(localStorage.getItem('tabValue')));
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const userData = useSelector((state) => state.marketPlace.post);
  const LandMarkData = useSelector((state) => state.marketPlace.localities);
  const deptLocations = useSelector((state) => state.marketPlace.deptLocalities);
  const [filterData, setFilteredData] = useState([]);
  const finalData = useSelector((state) => state.marketPlace.finalData);

  const setFinalData = (data) => {
    dispatch(setFinalData1(data));
  };
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState('All Posts');
  const [trackValue, setTrackValue] = useState(value);
  const [sortData, setSortData] = useState('Newest First');
  const [currentEditedPost, setCurrentEditedPost] = useState(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(Math.floor(props.totalPages / 10));
  const [currentIndex, setCurrentIndex] = useState(0);

  const { marketplaceFilterBody, marketplaceCarPoolFilterBody } = useContext(MyContext);

  useEffect(() => {
    setPage((prev) => prev - prev);
    setTrackValue(value);
  }, [value]);

  useEffect(() => {
    setTotalNumberOfPages(Math.floor(props.totalPages / 10));
  }, [props.totalPages]);
  useEffect(() => {
    dispatch(getTotalPages(value));
  }, [trackValue, props.totalPages]);

  useEffect(() => {
    dispatch(getAllLocations(selectedSortOption, value));
    if (value === 2) {
      dispatch(getAllDeptLocations(selectedSortOption));
    }
  }, [selectedSortOption, trackValue]);

  useEffect(() => {
    if (page > 0) {
      dispatch(
        value === 1
          ? getFlatRentPost(page, marketplaceFilterBody, value)
          : getCarPoolPost(page, marketplaceCarPoolFilterBody, value)
      );
    }
  }, [page, trackValue, value]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setPage(0);
    dispatch(
      value === 1
        ? getFlatRentPost(0, marketplaceFilterBody, value)
        : getCarPoolPost(0, marketplaceCarPoolFilterBody, value)
    );
  }, [marketplaceFilterBody, marketplaceCarPoolFilterBody]);

  const fetchMoreData = () => {
    if (finalData.length > 0 && page <= totalNumberOfPages - 1) setPage((prev) => prev + 1);
  };
  useEffect(() => {
    if (value === 0) {
      setData1([]);
      setData2([]);
      if (page === 0) {
        setData([...Object.values(userData)]);
      } else {
        setData((prev) => {
          const newData = Object.values(userData).filter(
            (item) =>
              !prev.some((existingItem) =>
                existingItem.id
                  ? existingItem.id === item.id
                  : existingItem.postId
                  ? existingItem.postId === item.postId
                  : existingItem.poolId === item.poolId
              )
          );
          return [...prev, ...newData];
        });
      }
    } else if (value === 1) {
      setData([]);
      setData2([]);
      if (page === 0) {
        setData1([...Object.values(userData)]);
      } else {
        setData1((prev) => {
          const newData = Object.values(userData).filter(
            (item) =>
              !prev.some((existingItem) =>
                existingItem.id
                  ? existingItem.id === item.id
                  : existingItem.postId
                  ? existingItem.postId === item.postId
                  : existingItem.poolId === item.poolId
              )
          );
          return [...prev, ...newData];
        });
      }
    } else {
      setData([]);
      setData1([]);
      if (page === 0) {
        setData2([...Object.values(userData)]);
      } else {
        setData2((prev) => {
          const newData = Object.values(userData).filter(
            (item) =>
              !prev.some((existingItem) =>
                existingItem.id
                  ? existingItem.id === item.id
                  : existingItem.postId
                  ? existingItem.postId === item.postId
                  : existingItem.poolId === item.poolId
              )
          );
          return [...prev, ...newData];
        });
      }
    }
    setIsLoading(true);
  }, [value, filterData, Object.values(userData)[0], page]);

  const parseDate = (dateArray) => {
    const [year, month, day, hours, minutes, seconds, milliseconds] = dateArray;
    return Date.UTC(year, month - 1, day, hours, minutes, seconds) + Math.floor(milliseconds / 1e6);
  };

  const applySorting = (sortOption) => {
    let sortedData = [...filterData];
    switch (sortOption) {
      case 'Price Low to High':
        sortedData.sort((a, b) => (a.price || 0) - (b.price || 0));
        break;
      case 'Price High to Low':
        sortedData.sort((a, b) => (b.price || 0) - (a.price || 0));
        break;
      case 'Newest First':
        sortedData.sort((a, b) => {
          const dateA = Array.isArray(a.postedOn) ? parseDate(a.postedOn) : 0;
          const dateB = Array.isArray(b.postedOn) ? parseDate(b.postedOn) : 0;

          return dateB - dateA;
        });
        break;
      case 'Relevance':
        sortedData.sort((a, b) => {
          const dateA = Array.isArray(a.postedOn) ? parseDate(a.postedOn) : 0;
          const dateB = Array.isArray(b.postedOn) ? parseDate(b.postedOn) : 0;

          return dateB - dateA;
        });
        break;
      default:
        sortedData.sort((a, b) => {
          const dateA = Array.isArray(a.postedOn) ? parseDate(a.postedOn) : 0;
          const dateB = Array.isArray(b.postedOn) ? parseDate(b.postedOn) : 0;

          return dateB - dateA;
        });
        break;
    }

    setFilteredData(sortedData);
  };

  useEffect(() => {
    applySorting(sortData);
  }, [sortData, finalData.length, finalData[0]]);
  const useStyles = makeStyles((theme) => ({
    card: {
      maxWidth: '100%',
      margin: theme.spacing(2)
    },
    media: {
      height: 190
    }
  }));
  const classes = useStyles();

  return (
    <div className={isMobile ? 'm-2' : 'my-0 mx-4'}>
      <FilterHeader value={value} setValue={setValue} />
      {isMobile && (
        <Grid item sm={12} md={5} lg={4}>
          {value === 1 && (
            <Filter
              isMobile={isMobile}
              userData={value === 0 ? data : value === 1 ? data1 : data2}
              page={page}
              value={value}
              landmarkData={Object.values(LandMarkData)}
              setCurrentIndex={setCurrentIndex}
            />
          )}
          {value === 2 && (
            <CarPoolFilter
              isMobile={isMobile}
              userData={value === 0 ? data : value === 1 ? data1 : data2}
              setFilteredData={setFilteredData}
              page={page}
              value={value}
              destLocationsData={Object.values(LandMarkData)}
              deptLocationsData={Object.values(deptLocations)}
              filterData={filterData}
              setCurrentIndex={setCurrentIndex}
              selectedSortOption={selectedSortOption}
            />
          )}
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item sm={12} md={7} lg={8}>
          {value !== 0 && (
            <FilterSubHeader
              value={value}
              selectedSortOption={selectedSortOption}
              setSelectedSortOption={setSelectedSortOption}
              setSortData={setSortData}
            />
          )}
          <div className={`px-1 ${s.posts}`}>
            {value === 1 || value === 2 ? (
              <div className={`px-1 ${s.posts}`}
                dataLength={Object.values(finalData).length}
                next={fetchMoreData}
                hasMore={page <= totalNumberOfPages - 1}
                loader={
                  props.loading &&
                  finalData.length > 0 && (
                    <Card className={classes.card}>
                      <CardHeader
                        avatar={
                          <Skeleton animation="wave" variant="circle" width={40} height={40} />
                        }
                        action={null}
                        title={
                          <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />
                        }
                        subheader={<Skeleton animation="wave" height={10} width="100%" />}
                      />

                      <Skeleton animation="wave" variant="rect" className={classes.media} />
                      <CardContent>
                        <React.Fragment>
                          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                          <Skeleton animation="wave" height={10} width="100%" />
                        </React.Fragment>
                      </CardContent>
                    </Card>
                  )
                }
              >
                {Object.values(finalData).length > 0 ? (
                  Object.values(finalData).map((post) =>
                    props.loading && page === 0 ? (
                      <Card className={classes.card}>
                        <CardHeader
                          avatar={
                            <Skeleton animation="wave" variant="circle" width={40} height={40} />
                          }
                          action={null}
                          title={
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="80%"
                              style={{ marginBottom: 6 }}
                            />
                          }
                          subheader={<Skeleton animation="wave" height={10} width="100%" />}
                        />

                        <Skeleton animation="wave" variant="rect" className={classes.media} />
                        <CardContent>
                          <React.Fragment>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="100%" />
                          </React.Fragment>
                        </CardContent>
                      </Card>
                    ) : (
                      <>
                        {value === 1 ? (
                          <BuySellPostCard
                            key={post.postId}
                            users={props.users}
                            deleteComment={props.deleteMarketComment}
                            addComment={props.addMarketComment}
                            likeComment={props.likeMarketComment}
                            post={post.rentPost}
                            postData={post}
                            data1={data1}
                            setData1={setData1}
                            likePost={props.saveLikeForMarketPost}
                            getEmpIdByEmail={props.getEmpIdByEmail}
                            addLoading={props.addCommentLoading}
                            deleteMarketPost={props.deleteMarketPost}
                            getValue={value}
                            pageNum={page}
                            setPage={setPage}
                            setFinalData={setFinalData}
                            finalData={finalData}
                            currentEditedPost={currentEditedPost}
                            setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
                            setCurrentIndex={setCurrentIndex}
                            loading={props.user}
                            selectedSortOption={selectedSortOption}
                          />
                        ) : (
                          value === 1 && (
                            <div className={s.postError}>
                              <img src={noResults} alt="no results" />
                              <strong className={s.postErrorMessage}>
                                Nothing to see for now.
                              </strong>
                              <span>"We couldn't find any matches for your search."</span>
                            </div>
                          )
                        )}
                        {value === 0 && (
                          <FlatRentPostCard
                            key={post.postId}
                            users={props.users}
                            deleteComment={props.deleteMarketComment}
                            addComment={props.addMarketComment}
                            likeComment={props.likeMarketComment}
                            post={post.carPool}
                            likePost={props.saveLikeForMarketPost}
                            userData={finalData}
                            getEmpIdByEmail={props.getEmpIdByEmail}
                            addLoading={props.addCommentLoading}
                            deleteMarketPost={props.deleteMarketPost}
                            getValue={value}
                            pageNum={page}
                            setFinalData={setFinalData}
                          />
                        )}
                        {value === 2 && (
                          <CarPool
                            key={post.postId}
                            users={props.users}
                            deleteComment={props.deleteMarketComment}
                            addComment={props.addMarketComment}
                            likeComment={props.likeMarketComment}
                            post={post}
                            postData={post.carPool}
                            data1={data2}
                            setData1={setData2}
                            likePost={props.saveLikeForMarketPost}
                            userData={finalData}
                            getEmpIdByEmail={props.getEmpIdByEmail}
                            addLoading={props.addCommentLoading}
                            deleteMarketPost={props.deleteMarketPost}
                            getValue={value}
                            pageNum={page}
                            setFinalData={setFinalData}
                            finalData={finalData}
                            currentEditedPost={currentEditedPost}
                            setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
                            setCurrentIndex={setCurrentIndex}
                            loading={props.user}
                            selectedSortOption={selectedSortOption}
                          />
                        )}
                      </>
                    )
                  )
                ) : (
                  <div className={s.postError}>
                    <img src={noResults} alt="no results" />
                    <strong className={s.postErrorMessage}>Nothing to see for now.</strong>
                    <span>"We couldn't find any matches for your search."</span>
                  </div>
                )}
              </div>
            ) : (
              <div className={s.postError}>
                <img src={noResults} alt="no results" />
                <strong className={s.postErrorMessage}>Coming Soon!</strong>
                <span>"Nothing to see for now."</span>
              </div>
            )}
          </div>
        </Grid>

        {!isMobile && (
          <Grid item sm={12} md={5} lg={4}>
            {value === 1 && (
              <Filter
                userData={value === 0 ? data : value === 1 ? data1 : data2}
                page={page}
                value={value}
                landmarkData={Object.values(LandMarkData)}
                finalData={finalData}
                setCurrentIndex={setCurrentIndex}
              />
            )}
            {value === 2 && (
              <CarPoolFilter
                isMobile={isMobile}
                userData={value === 0 ? data : value === 1 ? data1 : data2}
                setFilteredData={setFilteredData}
                page={page}
                value={value}
                destLocationsData={Object.values(LandMarkData)}
                deptLocationsData={Object.values(deptLocations)}
                filterData={filterData}
                setCurrentIndex={setCurrentIndex}
                selectedSortOption={selectedSortOption}
              />
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    usersProfile: state.userReducer.usersProfile,
    users: state.userReducer.users,
    user: state.userReducer.loading,
    addCommentLoading: state.commentReducer.addLoading,
    loading: state.marketPlace.loading,
    error: state.marketPlace.error,
    totalPages: state.marketPlace.totalPageCount
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveLikeForMarketPost: saveLikeForMarketPost,
      addMarketComment: addMarketComment,
      deleteMarketComment: deleteMarketComment,
      likeMarketComment: likeMarketComment,
      deleteMarketPost: deleteMarketPost,
      getEmpIdByEmail: getEmpIdByEmail
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(BuySell);
