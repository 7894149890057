import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import s from '../sidebar.module.scss';

// Icon
import Member from '../../../../../../assets/icons/Member.png';

import { getNewMembers } from '../../../../../../redux/actionCreators/newMembers.actionCreator';

import NewMember from './newMember';
import { Skeleton } from '@material-ui/lab';

const Members = (props) => {
  useEffect(() => {
    props.getNewMembers();
  }, []);

  const Header = () => {
    return (
      <div className="header">
        <div>
          <img src={Member} className="member-header" height="35" width="35" alt="member" />
          <span className="member-heading">New Members</span>
        </div>
      </div>
    );
  };
  return (
    <div className="member">
      <Header />
      <div className="member-body">
        {props.loading ? (
          <div>
            <Skeleton animation="wave" />
            <hr className="my-1" />
            <Skeleton animation="wave" />
            <hr className="my-1" />
            <Skeleton animation="wave" />
            <hr className="my-1" />
          </div>
        ) : Object.values(props.newMember).length ? (
          <>
            {Object.values(props.newMember).map((member, idx) => {
              return <NewMember key={idx} details={member} email={member.email} />;
            })}
          </>
        ) : (
          <div className={s.winners}>No new Member</div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    newMember: state.newMemberReducer.newMember,
    error: state.newMemberReducer.error,
    loading: state.newMemberReducer.loading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNewMembers
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Members);
