import {
  GET_EMPLOYEE_ADDRESS_FAILURE,
  GET_EMPLOYEE_ADDRESS_LOADING,
  GET_EMPLOYEE_ADDRESS_SUCCESS
} from '../../actions/actions';

const initialState = {
  address: [],
  loading: false,
  error: ''
};

const addressReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_EMPLOYEE_ADDRESS_LOADING:
      newState.loading = true;
      break;
    case GET_EMPLOYEE_ADDRESS_SUCCESS:
      newState.loading = false;
      newState.address = action.payload.address;
      break;
    case GET_EMPLOYEE_ADDRESS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default addressReducer;
