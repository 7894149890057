import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getClubMembers,
  joinClub,
  leaveClub
} from '../../../../../../redux/actionCreators/clubs.actionCreator';

//Image and Icons
import moreIcon from '../../../../../../assets/icons/3Dots.svg';
import arrowForward from '../../../../../../assets/icons/arrowForwardBlack.svg';
import membersIcon from '../../../../../../assets/icons/membersIcon.svg';
import timeIcon from '../../../../../../assets/icons/time.svg';
import addIcon from '../../../../../../assets/icons/add.svg';
import leaveIcon from '../../../../../../assets/icons/leaveIcon.svg';
import adminIcon from '../../../../../../assets/icons/admin.svg';
import closeIcon from '../../../../../../assets/icons/close.svg';
import clockIcon from '../../../../../../assets/icons/timeIconYellow.svg';

//scss
import s from './topSection.module.scss';
import { toast } from 'react-toastify';
import {
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextareaAutosize
} from '@material-ui/core';
import MembersPage from './membersPage';
import { getAllUsers } from '../../../../../../redux/actionCreators/user.actionCreator';
import ConfirmationModal from '../../../../../../components/Modal/ConfirmationModal';
import { Skeleton } from '@material-ui/lab';
import TopSectionEditing from './topSectionEditing';
import { useMediaQuery } from 'react-responsive';

const TopSection = React.memo(
  ({
    selectedTab,
    setSelectedTab,
    setViewRightWrapper,
    data,
    membersData,
    clubIdSelected,
    loadingState,
    clubName,
    clubId,
    users,
    coverImg,
    setCoverImg,
    profileImg,
    setProfileImg,
    ...props
  }) => {
    // const { isMobile } = Viewport();
    const isMobile = useMediaQuery({ maxWidth: 992 });
    const [isClubOptions, setIsClubOptions] = useState(false);
    const [memberId, setMemberId] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [clubJoined, setClubJoined] = useState('false');
    const [membersCount, setMembersCount] = useState();
    const [showMembers, setShowMembers] = useState(false);
    const [confirmJoinClub, setConfirmJoinClub] = useState(false);
    const [confirmLeaveClub, setConfirmLeaveClub] = useState(false);
    const [coverImgLoader, setCoverImgLoader] = useState(false);
    const [profileImgLoader, setProfileImgLoader] = useState(false);
    const [joinReqModalOpen, setJoinReqModalOpen] = useState(false);
    const [formError, setFormError] = useState(false);

    const [coverUploaderInput, setCoverUploaderInput] = useState([
      props.newCoverImage ?? data?.clubCoverUrl
    ]);
    const [profileUploaderInput, setProfileUploaderInput] = useState([
      props.newProfileImage ?? data?.clubProfileUrl
    ]);

    useEffect(() => {
      checkMember(membersData);
      const members = membersData?.filter((item) => item.roles.clubRoleId === 2);
      setMembersCount(members.length);
    }, [membersData]);

    const checkMember = (membersData) => {
      membersData.forEach((item) => {
        if (item.userId === localStorage.getItem('email') && item.roles.clubRoleId === 2) {
          if (item.clubJoiningStatus === 'REQUEST_APPROVED') {
            setClubJoined('true');
            setMemberId(item?.clubMemberId);
          } else if (item.clubJoiningStatus === 'REQUEST_PENDING') {
            setClubJoined('requested');
            setMemberId(item?.clubMemberId);
          }
        } else if (item.userId === localStorage.getItem('email') && item.roles.clubRoleId === 1) {
          setIsAdmin(true);
        }
      });
    };

    const handleLeaveClub = () => {
      toast.info('Sending request to Leave Club . . . ');
      setClubJoined('false');
      props.leaveClub(memberId, data?.clubId);
    };

    const handleImages = async (image, type) => {
      if (image) {
        if (type === 'profile') {
          setProfileImg(image);
          setProfileImgLoader(false);
        } else {
          setCoverImg(image);
          setCoverImgLoader(false);
        }
      }
    };

    useEffect(() => {
      if (!window.location.hash.includes(data.clubId)) {
        setCoverImgLoader(true);
        setProfileImgLoader(true);
      } else if (!coverImg) {
        setCoverImgLoader(true);
        handleImages(coverUploaderInput, 'cover');
      }

      if (props.newCoverImage) {
        setCoverImgLoader(true);
        handleImages(props.newCoverImage, 'cover');
      }

      if (props.newProfileImage) {
        setProfileImgLoader(true);
        handleImages(props.newProfileImage, 'profile');
      }

      if (!profileImg && window.location.hash.includes(data.clubId)) {
        setProfileImgLoader(true);
        handleImages(profileUploaderInput, 'profile');
      }
    }, [data.clubId, props.newCoverImage, props.newProfileImage]);

    //Tech Titans Flow

    const Desktop = () => {
      const [formValues, setFormValues] = useState({
        q1: '',
        q2: ''
      });

      const handleRadioChange = (e) => {
        setFormValues((prev) => ({ q1: e.target.value, q2: '' }));
      };

      const handleJoinClub = () => {
        toast.info('Sending request to Join Club . . . ');
        setClubJoined('true');
        {
          clubName === 'Tech Titans'
            ? props.joinClub(data?.clubId, '2', clubName, formValues.q1, formValues.q2)
            : props.joinClub(data?.clubId, '2', clubName);
        }
      };
      return (
        <>
          <div className={s.outerBox}>
            <div className={s.clubButtons}>
              <div className={s.profileDiv}>
                {!profileImgLoader ? (
                  <img src={profileImg} className={s.profilePhoto} alt="profile Img" />
                ) : (
                  <Skeleton variant="circle" className={s.profilePhotoLoading} />
                )}
              </div>
              {!coverImgLoader ? (
                <h4 className={s.heading}>{data.name}</h4>
              ) : (
                <Skeleton variant="text" className={s.headingLoading} />
              )}

              {!coverImgLoader && (
                <div className={s.icons}>
                  {isAdmin ? (
                    <>
                      <Button className={s.adminButton}>
                        <img src={adminIcon} className={s.adminIcon} alt="admin Button"  style={{display:"inline"}}/>
                        You are Admin
                      </Button>

                      <TopSectionEditing
                        setCoverUploaderInput={setCoverUploaderInput}
                        setProfileUploaderInput={setProfileUploaderInput}
                      />
                    </>
                  ) : (
                    <>
                      {!(clubName === 'Cultural Club' || clubName === 'CSR Club') && (
                        <>
                          {clubJoined === 'requested' ? (
                            <Button className={s.requestButton}>
                              <img src={clockIcon} className={s.requestIcon} alt="clock Icon" />
                              Requested
                            </Button>
                          ) : clubJoined === 'true' ? (
                            <Button
                              className={s.leaveButton}
                              onClick={() => {
                                setConfirmLeaveClub(true);
                              }}
                              disabled={props.loading || toast.isActive(loadingState)}
                            >
                              <img src={leaveIcon} className={s.clockIcon} alt="clock Icon" style={{display:'inline'}}/>
                              Leave Club
                            </Button>
                          ) : (
                            <Button
                              className={s.joinButton}
                              onClick={() => {
                                clubName === 'Tech Titans'
                                  ? setJoinReqModalOpen(true)
                                  : setConfirmJoinClub(true);
                              }}
                              disabled={
                                props.loading ||
                                toast.isActive(loadingState) ||
                                clubName === 'Cultural Club' ||
                                clubName === 'CSR Club'
                              }
                            >
                              <img src={addIcon} className={s.addIcon} alt="join Button" />
                              Join Club
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            {!coverImgLoader ? (
              <div
                className={s.coverImage}
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.95) 11.62%, rgba(0, 0, 0, 0) 60%), url("${coverImg}")`
                }}
              ></div>
            ) : (
              <Skeleton variant="rect" className={s.coverImageLoading} />
            )}

            <div>
              <p
                className={selectedTab === 'about' ? s.isSelected : ''}
                onClick={() => setSelectedTab('about')}
              >
                About
              </p>

              {/* members of this club are allowed to see discussion forums */}
              {(clubJoined === 'true' || isAdmin) && (
                <p
                  className={selectedTab === 'discuss' ? s.isSelected : ''}
                  onClick={() => setSelectedTab('discuss')}
                >
                  Discussion
                </p>
              )}
              <p
                className={selectedTab === 'events' ? s.isSelected : ''}
                onClick={() => setSelectedTab('events')}
              >
                Events
              </p>
            </div>
          </div>
          <ConfirmationModal
            showModal={confirmJoinClub}
            heading={'Are you sure you want to join ' + clubName + '? '}
            message={"*Please note that you will be added to the Club's Teams Channel as well."}
            onSuccess={() => {
              handleJoinClub();
            }}
            onReject={() => setConfirmJoinClub(false)}
          />
          <ConfirmationModal
            showModal={confirmLeaveClub}
            heading={'Are you sure you want to leave ' + clubName + '?'}
            message={
              "*Please note that you will be removed from the Club's Teams Channel as well, and you would no longer have access to any of the discussions or events related to this club."
            }
            onSuccess={() => {
              handleLeaveClub();
            }}
            onReject={() => setConfirmLeaveClub(false)}
          />

          {/* Confirmation for tech club */}
          <Modal open={joinReqModalOpen} className={s.mod}>
            <div className={s.joinReqModal}>
              <div className={s.iconDiv}>
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  className="cursor-pointer"
                  onClick={() => {
                    setJoinReqModalOpen(false);
                    setFormValues({ q1: '', q2: '' });
                  }}
                />
              </div>
              <h4 className="mt-4 font-weight-bold">Are you sure you want to join {clubName}?</h4>
              {/* --------Form questions--------- */}
              <FormControl error={formError} className={s.formDiv}>
                <FormLabel className={s.label}>
                  Q1. Do you want to join as Host or Participant?
                </FormLabel>
                <RadioGroup
                  value={formValues.q1}
                  onChange={handleRadioChange}
                  className={s.radioGroup}
                >
                  <FormControlLabel
                    value="host"
                    control={<Radio />}
                    label="Host"
                    className={s.radio}
                  />
                  <FormControlLabel
                    value="participant"
                    control={<Radio />}
                    label="Participant"
                    className={s.radio}
                  />
                </RadioGroup>
                {formValues.q1 === 'host' && (
                  <>
                    <FormLabel className={s.label}>
                      Q2. Do you have any event idea on your mind that we can conduct?{' '}
                      <span className={s.span}>{'(*Max Words: 200)'}</span>
                    </FormLabel>
                    <TextareaAutosize
                      minRows={3}
                      maxRows={3}
                      className={s.formTextBox}
                      value={formValues.q2}
                      onChange={(e) => setFormValues({ ...formValues, q2: e.target.value })}
                    />
                  </>
                )}
                {formValues.q1 === 'participant' && (
                  <>
                    <FormLabel className={s.label}>
                      Q2. What do you expect from Tech Titans?{' '}
                      <span className={s.span}>{'(*Max Words: 200)'}</span>
                    </FormLabel>
                    <TextareaAutosize
                      minRows={3}
                      maxRows={3}
                      className={s.formTextBox}
                      value={formValues.q2}
                      onChange={(e) => setFormValues({ ...formValues, q2: e.target.value })}
                    />
                  </>
                )}
              </FormControl>
              <p className={classNames(s.techMessage, 'text-center')}>
                *Please note that your request will be sent to the admins.
              </p>
              <div className={classNames(s.btnRow, 'd-flex flex-row mt-4')}>
                <Button
                  className={classNames('px-4 py-2', s.btn, s.rejectBtn)}
                  onClick={() => {
                    setJoinReqModalOpen(false);
                    setFormValues({ q1: '', q2: '' });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={classNames('px-4 py-2 ml-4', s.btn, s.successBtn)}
                  onClick={() => {
                    handleJoinClub();
                    setJoinReqModalOpen(false);
                    setFormValues({ q1: '', q2: '' });
                  }}
                  disabled={
                    formValues.q1 === '' || formValues.q2 === '' || formValues.q2.length > 250
                  }
                >
                  Send Request
                </Button>
              </div>
            </div>
          </Modal>
        </>
      );
    };

    const Mobile = () => {
      const [formValues, setFormValues] = useState({
        q1: '',
        q2: ''
      });

      const handleRadioChange = (e) => {
        setFormValues((prev) => ({ ...prev, q1: e.target.value }));
      };

      const handleJoinClub = () => {
        toast.info('Sending request to Join Club . . . ');
        setClubJoined('true');
        {
          clubName === 'Tech Titans'
            ? props.joinClub(data?.clubId, '2', clubName, formValues.q1, formValues.q2)
            : props.joinClub(data?.clubId, '2', clubName);
        }
      };
      return (
        <>
          <div
            className={s.outerBoxMobile}
            onClick={(e) => {
              setIsClubOptions(false);
            }}
          >
            {profileImg ? (
              <div className={s.clubPhotoMobile}>
                <img src={profileImg} alt="profile Img" />
              </div>
            ) : (
              <Skeleton variant="circle" className={s.clubPhotoMobile} />
            )}
            {coverImg ? (
              <div className={s.coverImageMobile}>
                <img src={coverImg} alt="cover Img" />
              </div>
            ) : (
              <Skeleton variant="rectangular" className={s.coverImageMobile} />
            )}
            <div className={s.clubInfoMobile}>
              {isClubOptions && (
                <div className={s.clubOptionsMobile}>
                  {!isAdmin && (
                    <>
                      {clubJoined === 'requested' ? (
                        <div
                          onClick={() => {
                            setConfirmLeaveClub(true);
                          }}
                          disabled={props.loading || toast.isActive(loadingState)}
                        >
                          <img src={clockIcon} className={s.clockIcon} alt="clock Icon" />
                          <p>Requested</p>
                        </div>
                      ) : clubJoined === 'true' ? (
                        <div
                          onClick={() => {
                            setConfirmLeaveClub(true);
                          }}
                          disabled={props.loading || toast.isActive(loadingState)}
                        >
                          <img src={leaveIcon} className={s.clockIcon} alt="clock Icon" />
                          <p>Leave Club</p>
                        </div>
                      ) : (
                        <div>
                          <Button
                            onClick={() => {
                              clubName === 'Tech Titans'
                                ? setJoinReqModalOpen(true)
                                : setConfirmJoinClub(true);
                            }}
                            disabled={
                              props.loading ||
                              toast.isActive(loadingState) ||
                              clubName === 'Cultural Club' ||
                              clubName === 'CSR Club'
                            }
                            className={s.buttonMobile}
                          >
                            <img src={addIcon} className={s.addIcon} alt="join Button" />
                            <p>Join Club</p>
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              <div className={s.iconsMobile}>
                <h4 className={s.headingMobile}>
                  {clubName} &nbsp;
                  <img
                    src={arrowForward}
                    className={s.arrowMobile}
                    onClick={() => setViewRightWrapper(true)}
                    alt="exit Icon"
                  />
                </h4>
                {isAdmin ? (
                  <div className={s.adminOptions}>
                    <img src={adminIcon} /> <p>Admin </p>
                    <TopSectionEditing
                      setCoverUploaderInput={setCoverUploaderInput}
                      setProfileUploaderInput={setProfileUploaderInput}
                    />
                  </div>
                ) : (
                  <img
                    src={moreIcon}
                    className={s.moreIconMobile}
                    onClick={(e) => {
                      setIsClubOptions(true);
                      e.stopPropagation();
                    }}
                    alt="more Icon"
                  />
                )}
              </div>

              <div className={s.details}>
                <div
                  onClick={() => {
                    setShowMembers(true);
                  }}
                >
                  <img src={membersIcon} alt="members Icon" className={s.detailsIconMembers} />
                  {membersCount} Members
                </div>
                <div>
                  <img src={timeIcon} alt="time Icon" className={s.detailsIconTime} />
                  Created Mar 2022
                </div>
              </div>

              <div className={s.tabsMobile}>
                <p
                  className={classNames(selectedTab === 'about' ? s.isSelected : '', s.tabs)}
                  onClick={() => setSelectedTab('about')}
                >
                  About
                </p>

                {/* members of this club are allowed to see discussion forums */}
                {(clubJoined === 'true' || isAdmin) && (
                  <p
                    className={classNames(selectedTab === 'discuss' ? s.isSelected : '', s.tabs)}
                    onClick={() => setSelectedTab('discuss')}
                  >
                    Discussion
                  </p>
                )}
              </div>
            </div>

            <Drawer open={showMembers} anchor={'bottom'} onClose={() => setShowMembers(false)}>
              <MembersPage
                users={Object.values(users)}
                setShowMembersDesktop={setShowMembers}
                clubName={clubName}
                clubId={clubId}
                membersData={membersData}
                getClubMembers={getClubMembers}
              />
            </Drawer>
          </div>
          <ConfirmationModal
            showModal={confirmJoinClub}
            heading={'Are you sure you want to join ' + clubName + '? '}
            message={"*Please note that you will be added to the Club's Teams Channel as well."}
            onSuccess={() => {
              handleJoinClub();
            }}
            onReject={() => setConfirmJoinClub(false)}
          />
          <ConfirmationModal
            showModal={confirmLeaveClub}
            heading={'Are you sure you want to leave ' + clubName + '?'}
            message={
              "*Please note that you will be removed from the Club's Teams Channel as well, and you would no longer have access to any of the discussions or events related to this club."
            }
            onSuccess={() => {
              handleLeaveClub();
            }}
            onReject={() => setConfirmLeaveClub(false)}
          />
          {/* Confirmation for tech club */}
          <Modal open={joinReqModalOpen} className={s.mod}>
            <div className={s.joinReqModal}>
              <div className={s.iconDiv}>
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  className="cursor-pointer"
                  onClick={() => {
                    setJoinReqModalOpen(false);
                    setFormValues({ q1: '', q2: '' });
                  }}
                />
              </div>
              <h4 className="mt-4 font-weight-bold">Are you sure you want to join {clubName}?</h4>
              {/* --------Form questions--------- */}
              <FormControl error={formError} className={s.formDiv}>
                <FormLabel className={s.label}>
                  Q1. Do you want to join as Host or Participant?
                </FormLabel>
                <RadioGroup
                  value={formValues.q1}
                  onChange={handleRadioChange}
                  className={s.radioGroup}
                >
                  <FormControlLabel
                    value="host"
                    control={<Radio />}
                    label="Host"
                    className={s.radio}
                  />
                  <FormControlLabel
                    value="participant"
                    control={<Radio />}
                    label="Participant"
                    className={s.radio}
                  />
                </RadioGroup>
                {formValues.q1 === 'host' && (
                  <>
                    <FormLabel className={s.label}>
                      Q2. Do you have any event idea on your mind that we can conduct?{' '}
                      <span className={s.span}>{'(*Max Words: 200)'}</span>
                    </FormLabel>
                    <TextareaAutosize
                      minRows={3}
                      maxRows={3}
                      className={s.formTextBox}
                      value={formValues.q2}
                      onChange={(e) => setFormValues({ ...formValues, q2: e.target.value })}
                    />
                  </>
                )}
                {formValues.q1 === 'participant' && (
                  <>
                    <FormLabel className={s.label}>
                      Q2. What do you expect from Tech Titans?{' '}
                      <span className={s.span}>{'(*Max Words: 200)'}</span>
                    </FormLabel>
                    <TextareaAutosize
                      minRows={3}
                      maxRows={3}
                      className={s.formTextBox}
                      value={formValues.q2}
                      onChange={(e) => setFormValues({ ...formValues, q2: e.target.value })}
                    />
                  </>
                )}
              </FormControl>
              <p className={classNames(s.techMessage, 'text-center')}>
                *Please note that your request will be sent to the admins.
              </p>
              <div className={classNames(s.btnRow, 'd-flex flex-row mt-4')}>
                <Button
                  className={classNames('px-4 py-2', s.btn, s.rejectBtn)}
                  onClick={() => {
                    setJoinReqModalOpen(false);
                    setFormValues({ q1: '', q2: '' });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={classNames('px-4 py-2 ml-4', s.btn, s.successBtn)}
                  onClick={() => {
                    handleJoinClub();
                    setJoinReqModalOpen(false);
                    setFormValues({ q1: '', q2: '' });
                  }}
                  disabled={formValues.q1 === '' || formValues.q2 === ''}
                >
                  Send Request
                </Button>
              </div>
            </div>
          </Modal>
        </>
      );
    };

    return <>{isMobile ? <Mobile /> : <Desktop />}</>;
  }
);

function mapStateToProps(state) {
  return {
    loading: state.clubsReducer.loading,
    error: state.clubsReducer.error,
    membersDetails: state.clubsReducer.membersDetails,
    users: state.userReducer.users,
    newCoverImage: state.clubDetailsReducer.coverImage,
    newProfileImage: state.clubDetailsReducer.profileImage
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      joinClub: joinClub,
      leaveClub: leaveClub,
      getClubMembers: getClubMembers,
      getAllUsers: getAllUsers
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TopSection);
