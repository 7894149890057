/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

//css
import { Box, Modal, SwipeableDrawer, Tooltip } from '@material-ui/core';
import s from './navbar.module.scss';

//icons
import user from '../../../assets/img/user.png';
import gembookIcon from '../../../assets/img/GembookIcon.png';

//helpers
import Axios from 'axios';
import moment from 'moment';
import cns from 'classnames';
import _, { uniqueId } from 'lodash';
import Love from '../../../assets/icons/Love hover.svg';
import Sad from '../../../assets/icons/Sad.svg';
import Surprise from '../../../assets/icons/Surprised.svg';
import Support from '../../../assets/icons/Support.svg';
import Like from '../../../assets/icons/Like.svg';
import Happy from '../../../assets/icons/Happy.svg';
import Celebrate from '../../../assets/icons/celebrate.svg';
import { useMediaQuery } from 'react-responsive';

export default function Notification({
  notificationModal,
  setNotificationModal,
  data,
  update,
  handleReadNotificationsCount,
  users,
  notificationCount,
  getValue
}) {
  const [notifications, setNotifications] = useState(data);
  const [activeTab, setActiveTab] = useState('All');

  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [value, setValue] = useState(parseInt(localStorage.getItem('tabValue')));

  const redirectToPost = (postId) => {
    let url = window.location.origin + `/#/dashboard/${postId}`;
    window.location.href = url;
    window.location.reload();
  };
  const redirectToMarketPost = (postId) => {
    const tabValue = localStorage.getItem('tabValue') || value;
    // Only proceed if value is 1 or 2
    if (value === 1 || value === 2) {
      let url = `${window.location.origin}/#/marketplace/market-place-buy-sell-post/v-${tabValue}/postId/${postId}`;
      window.location.href = url;
      window.location.reload();
    }
  };
  const redirectToProfile = () => {
    let url = window.location.origin + `/#/profile`;
    window.location.href = url;
    window.location.reload();
  };
  const handleUpdate = (id, index, postId, notificationType) => {
    let newNoti = [...notifications];
    if (newNoti[index].read === false) {
      newNoti[index].read = true;
      setNotifications(newNoti);
      handleReadNotificationsCount(notifications);
      update(id).then((res) => {
        if (
          notificationType === 'MARKETPLACE_POST_LIKE' ||
          notificationType === 'MARKETPLACE_POST_COMMENT' ||
          notificationType === 'MARKETPLACE_POST_COMMENT_TAG' ||
          notificationType === 'MARKETPLACE_POST_COMMENT_LIKE'
        ) {
          redirectToMarketPost(postId);
        } else if (notificationType === 'PROFILE_UPDATE_REMINDER') {
          redirectToProfile();
        } else {
          redirectToPost(postId);
        }
      });
    } else {
      if (
        notificationType === 'MARKETPLACE_POST_LIKE' ||
        notificationType === 'MARKETPLACE_POST_COMMENT' ||
        notificationType === 'MARKETPLACE_POST_COMMENT_TAG' ||
        notificationType === 'MARKETPLACE_POST_COMMENT_LIKE'
      ) {
        redirectToMarketPost(postId);
      } else if (notificationType === 'PROFILE_UPDATE_REMINDER') {
        redirectToProfile();
      } else {
        redirectToPost(postId);
      }
    }
  };

  const handleAllRead = () => {
    let newNoti = [...notifications];
    newNoti.forEach((noti, i) => {
      if (noti.read === false) {
        noti.read = true;
        update(noti.notificationId);
      }
    });
    setNotifications([...newNoti]);
    handleReadNotificationsCount(notifications);
  };

  const getProfilePicture = (email) => {
    const temp = users?.filter((user) => {
      if (user.email === email) return true;
    })[0]?.photoUrl;
    const imgPath = temp ? temp : user;
    return imgPath;
  };

  useEffect(() => {
    let newData = data?.sort((a, b) => {
      const momentA = moment(a.creationDateTime, 'YYYY-MM-DD hh:mm:ss A');
      const momentB = moment(b.creationDateTime, 'YYYY-MM-DD hh:mm:ss A');
      return momentB - momentA;
    });
    setNotifications(newData);
    notificationCount > 0 ? setActiveTab('Unread') : setActiveTab('All');
  }, [data.length, notificationCount]);
  useEffect(() => {
    notifications.forEach((noti) => {
      let commentLikeMessage = '';
      if (noti.notificationType === 'COMMENT_LIKE') {
        Axios.get(
          process.env.REACT_APP_GEMBOOK_SVC_URL + `/post-comment/by-id?commentId=${noti.commentId}`
        )
          .then((comment) => {
            let email = comment.data.email;
            const data = users && users?.filter((value) => value.email === email);
            const userData = data && data[0];
            commentLikeMessage =
              email === localStorage.getItem('email')
                ? 'liked your comment.'
                : `liked ${userData?.name}'s comment on your post.`;
            noti.commentLikeMessage = commentLikeMessage;
          })
          .catch((err) => {
            commentLikeMessage = 'liked a comment.';
            noti.commentLikeMessage = commentLikeMessage;
          });
      }
    });
  }, [notifications]);

  const style = {
    position: 'absolute',
    maxWidth: '320px',
    top: '3.5rem',
    right: '7rem',
    maxHeight: '350px',
    minHght: '200px',
    borderRadius: '6px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.14)',
    overflowY: 'none',
    width: '100%',
    outline: 'none',
    bgcolor: 'background.paper'
  };

  const mobileStyle = {
    minHeight: '300px',
    backgroundColor: '#fff'
  };

  const renderNotificationMessage = (type) => {
    switch (type) {
      case 'TAGGED_IN_POST':
        return 'tagged you in a post.';
      case 'TAGGED_IN_COMMENT':
        return 'mentioned you in a comment.';
      case 'POST_REACT_LIKE':
        return 'reacted on your post.';
      case 'POST_REACT_HAPPY':
        return 'reacted on your post.';
      case 'POST_REACT_LOVE':
        return 'reacted on your post.';
      case 'POST_REACT_SUPPORT':
        return 'reacted on your post.';
      case 'POST_REACT_SAD':
        return 'reacted on your post.';
      case 'POST_REACT_SURPRISE':
        return 'reacted on your post.';
      case 'POST_REACT_CELEBRATE':
        return 'reacted on your post.';
      case 'POST_COMMENT':
        return 'commented on your post.';
      case 'MARKETPLACE_POST_LIKE':
        return 'liked your Gembook Marketplace post';
      case 'MARKETPLACE_POST_COMMENT':
        return 'commented on your Gembook Marketplace post';
      case 'MARKETPLACE_POST_COMMENT_TAG':
        return 'tagged you in Gembook Marketplace post';
      case 'MARKETPLACE_POST_COMMENT_LIKE':
        return 'liked your Gembook Marketplace post comment';
      case 'PROFILE_UPDATE_REMINDER':
        return 'Keep your profile up-to-date! \uD83D\uDE80 Updating your profile helps others know you better. Take a moment to refresh your details now!';

      default:
        break;
    }
  };

  const reactionImages = {
    POST_REACT_LIKE: Like,
    POST_REACT_LOVE: Love,
    POST_REACT_SUPPORT: Support,
    POST_REACT_HAPPY: Happy,
    POST_REACT_SAD: Sad,
    POST_REACT_SURPRISE: Surprise,
    POST_REACT_CELEBRATE: Celebrate
  };

  const Desktop = () => {
    return (
      <>
        {notificationModal ? (
          <Modal
            open={notificationModal}
            onClose={() => setNotificationModal(false)}
            BackdropProps={{ invisible: true }}
          >
            <Box sx={style}>
              <div className={s.notiHeadingContainer}>
                <strong className={s.notiHeading}>Notifications</strong>
                {notificationCount > 0 && (
                  <Tooltip title={'Mark All as Read'}>
                    <div className={s.notiAllRead} onClick={handleAllRead}>
                      Mark All as Read
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className={s.tabContainer}>
                {['All', 'Unread'].map((tab) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <button
                      className={cns(s.tab, activeTab === tab ? s.activeTab : '')}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab}
                      {tab === 'All' && activeTab === 'All' && notificationCount > 0 && (
                        <span className={s.allUnreadCount}>{notificationCount}</span>
                      )}
                    </button>
                  );
                })}
              </div>
              <div className={s.notiContainer}>
                {notificationCount === 0 && activeTab === 'Unread' ? (
                  <p className={s.noNoti}>You've read all your notifications.</p>
                ) : notifications.length ? (
                  notifications.map((noti, index) => {
                    noti.uniqueId = uniqueId();

                    let momentDate = moment(noti.creationDateTime, 'YYYY-MM-DD HH:mm:ss A');
                    let date = momentDate.format('ddd LL');
                    let dateFromNow = momentDate.fromNow();

                    let notifyBy = '';
                    if (noti.notifyBy === localStorage.getItem('email')) notifyBy = 'You';
                    else notifyBy = _.startCase(noti.notifyBy.split('@')[0].split('.').join(' '));
                    const reactionImageSrc = reactionImages[noti.notificationType];

                    return (
                      <nav
                        key={noti.uniqueId}
                        aria-label="main mailbox folders"
                        onClick={() =>
                          handleUpdate(
                            noti.notificationId,
                            index,
                            noti.postId,
                            noti.notificationType
                          )
                        }
                        style={{ display: noti.read && activeTab === 'Unread' ? 'none' : '' }}
                        className={noti.read ? s.notiNavContainer : s.notiNavContainerRead}
                      >
                        {!noti.read && (
                          <Tooltip title={'Mark as Read'}>
                            <div className={s.notiRead}></div>
                          </Tooltip>
                        )}
                        <div className={s.profileImg}>
                          {noti.notificationType === 'PROFILE_UPDATE_REMINDER' ? (
                            <img alt="gembook-icon" src={gembookIcon} />
                          ) : (
                            <>
                              <img
                                alt="notification"
                                src={getProfilePicture(noti.notifyBy)}
                                loading="lazy"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = user;
                                }}
                              />
                              {reactionImageSrc && (
                                <img src={reactionImageSrc} className={s.reac} />
                              )}
                            </>
                          )}
                        </div>
                        <div className={s.notiInfo}>
                          <p>
                            <strong className={s.notifyByName}>{notifyBy}</strong>&nbsp;
                            <span style={{ fontWeight: noti.read ? '400' : '600' }}>
                              {noti.notificationType === 'PROFILE_UPDATE_REMINDER' ? (
                                <>
                                  <strong className={s.notifyByName}>Reminder:</strong>
                                  <br />
                                  {renderNotificationMessage(noti.notificationType)}
                                </>
                              ) : (
                                <>
                                  {notifyBy === 'You' ? 'have' : 'has'}&nbsp;
                                  {noti.notificationType === 'COMMENT_LIKE'
                                    ? noti.commentLikeMessage ?? 'had liked a comment'
                                    : renderNotificationMessage(noti.notificationType)}
                                </>
                              )}
                            </span>
                          </p>

                          <p className={s.date}>
                            {date} <span>{dateFromNow}</span>
                          </p>
                        </div>
                      </nav>
                    );
                  })
                ) : (
                  <p className={s.noNoti}>No new notifications.</p>
                )}
              </div>
              <div className={s.footer}></div>
            </Box>
          </Modal>
        ) : null}
      </>
    );
  };

  const Mobile = () => {
    return (
      <>
        <SwipeableDrawer
          anchor="bottom"
          className={s.swipeableNotificationDrawer}
          open={notificationModal}
          onClose={() => setNotificationModal(false)}
          onOpen={() => setNotificationModal(true)}
        >
          <Box sx={mobileStyle}>
            <div className={s.notiHeadingContainer}>
              <strong className={s.notiHeading}>Notifications</strong>
              {notificationCount > 0 && (
                <Tooltip title={'Mark All as Read'}>
                  <div
                    className={s.notiAllRead}
                    onClick={() => {
                      handleAllRead();
                    }}
                  >
                    Mark All as Read
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={s.tabContainer}>
              {['All', 'Unread'].map((tab, index) => {
                return (
                  <button
                    className={cns(s.tab, activeTab === tab ? s.activeTab : '')}
                    onClick={() => setActiveTab(tab)}
                    key={index}
                  >
                    {tab}
                    {tab === 'All' && activeTab === 'All' && notificationCount > 0 && (
                      <span className={s.allUnreadCount}>{notificationCount}</span>
                    )}
                  </button>
                );
              })}
            </div>
            <div className={s.notiMobileContainer}>
              {notificationCount === 0 && activeTab === 'Unread' ? (
                <p className={s.noNoti}>You've read all your notifications.</p>
              ) : notifications.length ? (
                notifications.map((noti, index) => {
                  noti.uniqueId = uniqueId();

                  let momentDate = moment(noti.creationDateTime, 'YYYY-MM-DD HH:mm:ss A');
                  let date = momentDate.format('ddd LL');
                  let dateFromNow = momentDate.fromNow();

                  let notifyBy = '';
                  if (noti.notifyBy === localStorage.getItem('email')) notifyBy = 'You';
                  else notifyBy = _.startCase(noti.notifyBy.split('@')[0].split('.').join(' '));
                  const reactionImageSrc = reactionImages[noti.notificationType];
                  return (
                    <nav
                      key={noti.uniqueId}
                      aria-label="main mailbox folders"
                      onClick={() =>
                        handleUpdate(noti.notificationId, index, noti.postId, noti.notificationType)
                      }
                      style={{ display: noti.read && activeTab === 'Unread' ? 'none' : '' }}
                      className={noti.read ? s.notiNavContainer : s.notiNavContainerRead}
                    >
                      {!noti.read && (
                        <Tooltip title={'Mark as Read'}>
                          <div className={s.notiRead}></div>
                        </Tooltip>
                      )}
                      <div className={s.profileImg}>
                        {noti.notificationType === 'PROFILE_UPDATE_REMINDER' ? (
                          <img alt="gembook-icon" src={gembookIcon} />
                        ) : (
                          <>
                            <img
                              alt="notification"
                              src={getProfilePicture(noti.notifyBy)}
                              loading="lazy"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = user;
                              }}
                            />
                            {reactionImageSrc && <img src={reactionImageSrc} className={s.reac} />}
                          </>
                        )}
                      </div>
                      <div className={s.notiInfo}>
                        <p>
                          <strong className={s.notifyByName}>{notifyBy}</strong>&nbsp;
                          <span style={{ fontWeight: noti.read ? '400' : '600' }}>
                            {noti.notificationType === 'PROFILE_UPDATE_REMINDER' ? (
                              <>
                                <strong className={s.notifyByName}>Reminder:</strong>
                                <br />
                                {renderNotificationMessage(noti.notificationType)}
                              </>
                            ) : (
                              <>
                                {notifyBy === 'You' ? 'have' : 'has'}&nbsp;
                                {noti.notificationType === 'COMMENT_LIKE'
                                  ? noti.commentLikeMessage
                                  : renderNotificationMessage(noti.notificationType)}
                              </>
                            )}
                          </span>
                        </p>

                        <p className={s.date}>
                          {date} <span>{dateFromNow}</span>
                        </p>
                      </div>
                    </nav>
                  );
                })
              ) : (
                <p className={s.noNoti}>No new notifications.</p>
              )}
            </div>
            <div className={s.footer}></div>
          </Box>
        </SwipeableDrawer>
      </>
    );
  };

  return <>{isMobile ? <Mobile /> : <Desktop />}</>;
}
