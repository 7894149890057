import React, { Component } from 'react';
import { uniqueId } from 'lodash';
import classNames from 'classnames';

//Redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';

//Action Creator
import {
  editProject,
  addProject,
  deleteProject,
  getProjects
} from '../../../../../redux/actionCreators/profile/project.actionCreator';

// icons
import editpost from '../../../../../assets/icons/editpost.svg';
import deleteBin from '../../../../../assets/icons/delete-bin.svg';
import close from '../../../../../assets/icons/close.svg';
import addIcon from '../../../../../assets/icons/addIcon.svg';
import save from '../../../../../assets/icons/saveDetails.png';
import addMore from '../../../../../assets/icons/add_circle_outline.svg';
import projectIcon from '../../../../../assets/icons/ProjectIcon.svg';
import cns from 'classnames';
//SCSS
import s from './components.module.scss';
import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal';
import { popUpMessages, TOOLTIP_MSSG } from '../../../../../helpers';
import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ReactQuill from 'react-quill';
import { formats, modules } from '../../../../helpers/editorToolbar';

class Project extends Component {
  constructor(props) {
    super(props);
    this.reactQuillRef = React.createRef();
    this.state = {
      project: [...this.props?.internalProjects],
      newProject: {
        projectName: '',
        projectType: '',
        projectDescription: '',
        keyTech: '',
        projectStatus: ''
      },
      editFlag: false,
      addFlag: false,
      error: false,
      reload: false,
      modalData: {
        showModal: false
      },
      isDeleted: false,
      showReactQuill: false
    };
    this.inputRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.modules = {
      toolbar: {
        container: '.toolbar-container'
      },
      clipboard: {
        matchVisual: false
      }
    };
  }

  componentDidMount() {
    this.props.getProjects();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isDeleted && this.props.internalProjects.length < prevState.project.length) {
      this.setState({
        project: [...this.props?.internalProjects],
        isDeleted: false
      });
    } else if (
      !prevState.editFlag &&
      this.props.internalProjects.length > prevState.project.length
    ) {
      this.setState({ project: [...this.props?.internalProjects] });
    }
    if (!prevState.showReactQuill && this.state.showReactQuill) {
      this.focusReactQuill();
    }
  }

  toggleModal = () => {
    this.setState({ modalData: { showModal: !this.state.modalData.showModal } });
  };

  handleChange = (e, idx, identifier) => {
    if (identifier === 'projectName') {
      this.setState({ projectCss: false });
    }
    let projects = [...this.state.project];
    let project = { ...projects[idx] };
    project[identifier] = e.target.value;
    projects[idx] = project;
    this.setState({
      ...this.state,
      project: projects
    });
  };
  handleChangeReactQuill = (value, idx, identifier) => {
    if (identifier === 'projectName') {
      this.setState({ projectCss: false });
    }
    let projects = [...this.state.project];
    let project = { ...projects[idx] };
    project[identifier] = value;
    projects[idx] = project;
    this.setState({
      ...this.state,
      project: projects
    });
  };

  handleDelete = (idx) => {
    if (this.state.project[idx].projectId === null) {
      this.setState({
        ...this.state,
        project: [
          ...this.state.project.filter((pro, index) => {
            if (index !== idx) {
              return pro;
            }
            return null;
          })
        ]
      });
    } else {
      this.props.deleteProject(this.state.project[idx].projectId);
      if (this.state.project.length === 1) {
        this.setState({ editFlag: false });
      }
      this.setState({
        ...this.state,
        editFlag: false,
        isDeleted: true,
        project: [
          ...this.state.project.filter((pro, index) => {
            if (index !== idx) {
              return pro;
            }
            return null;
          })
        ]
      });
    }
  };

  handleAddNew = () => {
    this.setState({ addFlag: !this.state.addFlag });
  };

  handleClose = () => {
    for (let i = this.state.project.length - 1; i >= 0; i--) {
      if (this.state.project[i].projectId === null) {
        this.state.project.pop();
      }
    }
    this.setState({
      error: false,
      editFlag: false,
      showReactQuill: false,
      project: [...this.props?.internalProjects]
    });
  };

  refreshPage = () => {
    this.setState({ reload: true }, () => this.setState({ reload: false }));
  };

  handleSave = () => {
    if (!this.state.project.length) {
      this.setState({ editFlag: !this.state.editFlag });
      return;
    }
    let projects = [];
    let data = {};
    for (let i = 0; i < this.state.project.length; i++) {
      data = this.state.project[i];
      if (
        !data.projectName.trim() ||
        !data.projectStatus.trim() ||
        !data.projectDescription.trim() ||
        !data.keyTech.trim() ||
        !data.projectType.trim()
      ) {
        toast.warn('Please fill all the required fields');
        this.setState({ error: true });
        return;
      }
      if (data.projectDescription.length > 1000) {
        toast.warn('Description should not be more than 1000 ');
        this.setState({ error: true });
        return;
      }

      if (this.state.project[i].projectId) {
        this.props.editProject(data);
      }
    }

    if (this.props.getError === '') {
      toast.success('Saved Successfully.');
      this.setState({ editFlag: false, showReactQuill: false });
    }
  };

  handleSaveNewProject = () => {
    let project = this.state.newProject;

    if (
      !project.projectName.trim() ||
      !project.projectStatus.trim() ||
      !project.projectDescription.trim() ||
      !project.keyTech.trim() ||
      !project.projectType.trim()
    ) {
      toast.warn('Please fill all the required fields');
      this.setState({ error: true });
      return;
    }
    if (project.projectDescription.length > 1000) {
      toast.warn('Description should not be more than 1000.');
      this.setState({ error: true });
      return;
    }

    this.props.addProject(project);

    this.setState({
      showReactQuill: false,
      error: false,
      newProject: {
        projectName: '',
        projectType: '',
        projectDescription: '',
        keyTech: '',
        projectStatus: ''
      }
    });
  };

  handleCloseNewProject = () => {
    this.setState({
      addFlag: false,
      showReactQuill: false,
      error: false,
      newProject: {
        projectName: '',
        projectType: '',
        projectDescription: '',
        keyTech: '',
        projectStatus: ''
      }
    });
  };

  focusReactQuill = () => {
    if (this.reactQuillRef) {
      const quillEditor = this.reactQuillRef.getEditor();
      if (quillEditor) {
        quillEditor.focus();
      }
    }
  };

  render() {
    return (
      <div className={s.projectWrapper} ref={this.wrapperRef}>
        {this.state.editFlag && this.state.project.length ? (
          <>
            <div className={s.heading}>
              <h5>Projects</h5>
              <div className={s.buttonContainer}>
                <button onClick={this.handleSave} className={s.saveButton}>
                  <Tooltip title={TOOLTIP_MSSG.SAVE}>
                    <img alt="save Button" src={save} />
                  </Tooltip>
                </button>

                <button onClick={this.handleClose} className={s.saveButton}>
                  <Tooltip title="Click to Close">
                    <img alt="close Button" src={close} />
                  </Tooltip>
                </button>
              </div>
            </div>
            {this.state.project.map((pro, idx) => {
              pro.uniqueKey = uniqueId();
              let shouldDeleteProject = pro.projectId ? true : false;
              let toolbarId = `toolbar-container-${idx}`;
              let modules1 = {
                toolbar: {
                  container: `#toolbar-container-${idx}`
                },
                clipboard: {
                  matchVisual: false
                }
              };
              return (
                <div className={s.inputWrapper} key={idx}>
                  <div className={s.innerInputWrapper}>
                    <input
                      placeholder="Project Name"
                      className={
                        this.state.error && !pro.projectName.trim()
                          ? s.projectNameError
                          : s.projectName
                      }
                      value={pro.projectName}
                      name="project"
                      onChange={(e) => this.handleChange(e, idx, 'projectName')}
                      type="text"
                      maxLength={60}
                    />
                    <select
                      value={pro.projectType}
                      name="projectType"
                      className={
                        this.state.error && pro.projectType === ''
                          ? s.projectNameError
                          : s.projectName
                      }
                      onChange={(e) => this.handleChange(e, idx, 'projectType')}
                    >
                      <option value="">Select Project Type</option>
                      <option>INTERNAL</option>
                      <option>CLIENT</option>
                    </select>
                    <select
                      value={pro.projectStatus}
                      name="projectStatus"
                      className={
                        this.state.error && pro.projectStatus === ''
                          ? s.projectNameError
                          : s.projectName
                      }
                      onChange={(e) => this.handleChange(e, idx, 'projectStatus')}
                    >
                      <option value="">Select Project Status</option>
                      <option value="OnGoing">OnGoing</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                  <div className={s.projectDescriptionWrapper}>
                    <input
                      placeholder="Technology"
                      className={
                        this.state.error && !pro.keyTech.trim()
                          ? s.descriptionError
                          : s.projectDescription
                      }
                      value={pro.keyTech}
                      name="keyTech"
                      onChange={(e) => this.handleChange(e, idx, 'keyTech')}
                      type="text"
                    />
                    {shouldDeleteProject && (
                      <div className={s.saveDeleteBtn}>
                        <button
                          className={s.delBtn}
                          onClick={() => {
                            this.setState({
                              modalData: {
                                method: 'delete',
                                message: popUpMessages.CONFIRM_DELETE_PROJECT,
                                heading: popUpMessages.DELETE_PROJECT,
                                showModal: true,
                                index: idx,
                                value: pro.projectName
                              },
                              showReactQuill: false
                            });
                          }}
                        >
                          <img alt="delete Icon" src={deleteBin} height={24} width={24}></img>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mt-2" style={{ position: 'relative' }}>
                    <div
                      id={`toolbar-container-${idx}`}
                      className={cns(s.toolbarContainer, 'toolbar-container')}
                      style={{ top: '5px' }}
                    >
                      <Tooltip title="Bold">
                        <button className="ql-bold" />
                      </Tooltip>
                      <Tooltip title="Italic">
                        <button className="ql-italic" />
                      </Tooltip>
                      <Tooltip title="Underline">
                        <button className="ql-underline" />
                      </Tooltip>
                      <Tooltip title="Strike">
                        <button className="ql-strike" />
                      </Tooltip>
                      <Tooltip title="Blockquote">
                        <button className="ql-blockquote" />
                      </Tooltip>
                      <Tooltip title="Ordered List">
                        <button className="ql-list" value="ordered" />
                      </Tooltip>
                      <Tooltip title="Bullet List">
                        <button className="ql-list" value="bullet" />
                      </Tooltip>
                      <Tooltip title="Indent">
                        <button className="ql-indent" value="-1" />
                      </Tooltip>
                      <Tooltip title="Outdent">
                        <button className="ql-indent" value="+1" />
                      </Tooltip>
                      <Tooltip title="Clean">
                        <button className="ql-clean" />
                      </Tooltip>
                    </div>

                    <div className={s.projectDescriptionWrapper}>
                      {
                        <ReactQuill
                          style={{ width: '100%', minHeight: '110px', paddingTop: '50px' }} // Adjust the width as needed
                          theme="snow"
                          placeholder="Enter here"
                          className={
                            this.state.error &&
                            (!pro.projectDescription.trim() || pro.projectDescription.length > 1000)
                              ? s.descriptionError
                              : s.projectDescription
                          }
                          value={pro.projectDescription}
                          name="projectDescription"
                          modules={modules1}
                          onChange={(value) =>
                            this.handleChangeReactQuill(value, idx, 'projectDescription')
                          }
                        />
                      }
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className={s.heading}>
              <h5>Projects</h5>
              {this.state.project.length ? (
                !this.state.addFlag ? (
                  <div className={s.buttons}>
                    <div className={s.addButton}>
                      <button onClick={() => this.handleAddNew()}>
                        <Tooltip title={TOOLTIP_MSSG.ADD}>
                          <img className={s.addButton} src={addIcon} alt="add Button" />
                        </Tooltip>
                      </button>
                    </div>

                    <div className={s.editIcon}>
                      <button
                        className={s.editBtn}
                        onClick={() => this.setState({ editFlag: !this.state.editFlag })}
                      >
                        <Tooltip title={TOOLTIP_MSSG.EDIT}>
                          <img src={editpost} alt="Edit button" />
                        </Tooltip>
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )
              ) : (
                <div className={s.editIcon}>
                  <button className={s.addButton} onClick={() => this.handleAddNew()}>
                    <Tooltip title={TOOLTIP_MSSG.ADD}>
                      <img src={addIcon} alt="add Icon" />
                    </Tooltip>
                  </button>
                </div>
              )}
            </div>

            <div className={s.description}>
              {this.props.getLoading ? (
                <div className={s.containerLoader}>
                  <div>
                    <Skeleton variant="circle" className={s.circleLoader} />
                  </div>
                  <div className={s.detailsLoadingContainer}>
                    <Skeleton variant="text" className={s.textLoad} />
                    <Skeleton variant="text" className={s.textLoad} />
                    <Skeleton variant="text" className={s.textLoad} />
                  </div>
                  <div className={s.detailsLoadingContainer}>
                    <Skeleton variant="text" className={s.statusLoader} />
                  </div>
                </div>
              ) : (
                <>
                  {this.state.project.length ? (
                    <>
                      <ul className={s.projectDetailsContainer}>
                        {this.state.project.map((pro, idx) => {
                          pro.uniqueKey = uniqueId();

                          return (
                            <li key={pro.uniqueKey} className={s.projectDetails}>
                              <div className={s.projectName}>
                                <div className="d-flex ">
                                  {pro.projectName ? (
                                    <div className={s.providerIcon}>
                                      <img src={projectIcon} alt="project-icon" />
                                    </div>
                                  ) : (
                                    <Skeleton variant="circle" className={s.providerIcon} />
                                  )}
                                  <div>
                                    <p className={s.proName}>
                                      {pro.projectName}
                                      &nbsp;&nbsp;|&nbsp;&nbsp;
                                      <span className={s.projectType}>{pro.projectType}</span>
                                    </p>

                                    <p className={classNames(s.projectType, 'text-capitalize')}>
                                      Technology : {pro.keyTech || 'NA'}
                                    </p>
                                    <p
                                      className={s.projectDesc}
                                      dangerouslySetInnerHTML={{ __html: pro.projectDescription }}
                                    ></p>
                                  </div>
                                </div>
                                <button
                                  className={
                                    pro.projectStatus === 'Completed'
                                      ? s.completebutton
                                      : s.ongoingBtn
                                  }
                                >
                                  {pro.projectStatus}
                                </button>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : !this.state.addFlag ? (
                    <i className="text-secondary">No Projects added.</i>
                  ) : (
                    ''
                  )}
                  {this.state.addFlag ? (
                    <>
                      {this.state.project.length ? <hr /> : ''}
                      <div className={s.newProjectCard}>
                        <div className={s.addSkillHeading}>
                          ADD NEW PROJECT
                          <div className={s.buttonContainer}>
                            <Tooltip title={TOOLTIP_MSSG.SAVE}>
                              <button onClick={this.handleSaveNewProject}>
                                <img height={23} src={save} alt="save Icon" />
                              </button>
                            </Tooltip>
                            <Tooltip title={TOOLTIP_MSSG.CLOSE}>
                              <button onClick={this.handleCloseNewProject}>
                                <img height={23} alt="close Icon" src={close} />
                              </button>
                            </Tooltip>
                          </div>
                        </div>

                        <div className={classNames(s.inputWrapper)}>
                          <div className={s.innerInputWrapper}>
                            <input
                              autoFocus
                              placeholder="Project Name"
                              className={
                                this.state.error && !this.state.newProject.projectName.trim()
                                  ? s.projectNameError
                                  : s.projectName
                              }
                              value={this.state.newProject.projectName}
                              name="project"
                              onChange={(e) =>
                                this.setState({
                                  newProject: {
                                    ...this.state.newProject,
                                    projectName: e.target.value
                                  }
                                })
                              }
                              type="text"
                              maxLength={60}
                            />
                            <select
                              value={this.state.newProject.projectType}
                              name="projectType"
                              className={
                                this.state.error && this.state.newProject.projectType === ''
                                  ? s.projectNameError
                                  : s.projectName
                              }
                              onChange={(e) =>
                                this.setState({
                                  newProject: {
                                    ...this.state.newProject,
                                    projectType: e.target.value
                                  }
                                })
                              }
                            >
                              <option value="">Select Project Type</option>
                              <option>INTERNAL</option>
                              <option>CLIENT</option>
                            </select>
                            <select
                              value={this.state.newProject.projectStatus}
                              name="projectStatus"
                              className={
                                this.state.error && this.state.newProject.projectStatus === ''
                                  ? s.projectNameError
                                  : s.projectName
                              }
                              onChange={(e) =>
                                this.setState({
                                  newProject: {
                                    ...this.state.newProject,
                                    projectStatus: e.target.value
                                  }
                                })
                              }
                            >
                              <option value="">Select Project Status</option>
                              <option value="OnGoing">OnGoing</option>
                              <option value="Completed">Completed</option>
                            </select>
                          </div>
                          <div className={s.projectDescriptionWrapper}>
                            <input
                              placeholder="Technology"
                              className={
                                this.state.error && !this.state.newProject.keyTech.trim()
                                  ? s.descriptionError
                                  : s.projectDescription
                              }
                              value={this.state.newProject.keyTech}
                              name="keyTech"
                              onChange={(e) =>
                                this.setState({
                                  newProject: {
                                    ...this.state.newProject,
                                    keyTech: e.target.value
                                  }
                                })
                              }
                              type="text"
                            />
                          </div>
                          <div className={s.projectDescriptionWrapper}>
                            {!this.state.showReactQuill ? (
                              <input
                                placeholder="Project Description"
                                className={
                                  this.state.error &&
                                  (!this.state.newProject.projectDescription.trim() ||
                                    this.state.newProject.projectDescription.length > 1000)
                                    ? s.descriptionError
                                    : s.projectDescription
                                }
                                value={this.state.newProject.projectDescription}
                                name="projectDescription"
                                onClick={() => {
                                  this.setState({ ...this.state, showReactQuill: true });
                                }}
                                onChange={(e) =>
                                  this.setState({
                                    newProject: {
                                      ...this.state.newProject,
                                      projectDescription: e.target.value
                                    }
                                  })
                                }
                                type="text"
                              />
                            ) : (
                              <div className="w-100" style={{ position: 'relative' }}>
                                <div className={cns(s.toolbarContainer, 'toolbar-container')}>
                                  <Tooltip title="Bold">
                                    <button className="ql-bold" />
                                  </Tooltip>
                                  <Tooltip title="Italic">
                                    <button className="ql-italic" />
                                  </Tooltip>
                                  <Tooltip title="Underline">
                                    <button className="ql-underline" />
                                  </Tooltip>
                                  <Tooltip title="Strike">
                                    <button className="ql-strike" />
                                  </Tooltip>
                                  <Tooltip title="Blockquote">
                                    <button className="ql-blockquote" />
                                  </Tooltip>
                                  <Tooltip title="Ordered List">
                                    <button className="ql-list" value="ordered" />
                                  </Tooltip>
                                  <Tooltip title="Bullet List">
                                    <button className="ql-list" value="bullet" />
                                  </Tooltip>
                                  <Tooltip title="Indent">
                                    <button className="ql-indent" value="-1" />
                                  </Tooltip>
                                  <Tooltip title="Outdent">
                                    <button className="ql-indent" value="+1" />
                                  </Tooltip>
                                  <Tooltip title="Clean">
                                    <button className="ql-clean" />
                                  </Tooltip>
                                </div>
                                <ReactQuill
                                  style={{ width: '100%', paddingTop: '40px', minHeight: '110px' }} // Adjust the width as needed
                                  theme="snow"
                                  ref={(el) => (this.reactQuillRef = el)}
                                  placeholder="Enter here"
                                  className={
                                    this.state.error &&
                                    (!this.state.newProject.projectDescription.trim() ||
                                      this.state.newProject.projectDescription.length > 1000)
                                      ? s.descriptionError
                                      : s.projectDescription
                                  }
                                  value={this.state.newProject.projectDescription}
                                  name="projectDescription"
                                  modules={this.modules}
                                  onChange={(value) =>
                                    this.setState({
                                      newProject: {
                                        ...this.state.newProject,
                                        projectDescription: value
                                      },
                                      charCount: value.length
                                    })
                                  }
                                />

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '5px'
                                  }}
                                >
                                  {this.state.charCount > 1000 && (
                                    <span
                                      style={{
                                        color: 'red',
                                        marginRight: 'auto',
                                        fontSize: '11px'
                                      }}
                                    >
                                      Word limit cannot be more than 1000
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
          </>
        )}
        <ConfirmationModal
          showModal={this.state.modalData.showModal}
          toggle={this.toggleModal}
          message={`Are you sure you want to permanently remove project "${this.state.modalData.value}" from Gembook?`}
          heading={this.state.modalData.heading}
          onSuccess={() => {
            if (this.state.modalData.method === 'save') {
              this.handleSave(this.state.modalData.index);
            } else {
              this.handleDelete(this.state.modalData.index);
            }
            this.toggleModal();
          }}
          icon={this.state.modalData.icon}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projectReducer.projects,
    internalProjects: state.projectReducer.internalProjects,
    editLoading: state.projectReducer.editLoading,
    getLoading: state.projectReducer.getLoading,
    getError: state.projectReducer.getError,
    editError: state.projectReducer.editError
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProjects: getProjects,
      editProject: editProject,
      addProject: addProject,
      deleteProject: deleteProject
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Project));
