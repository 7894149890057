import { GET_POLLUX_FAILURE, GET_POLLUX_LOADING, GET_POLLUX_SUCCESS } from '../actions/actions';

const initialState = {
  loading: false,
  polluxData: [],
  error: '',
  success: false
};

const publicationsReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_POLLUX_LOADING:
      newState.loading = true;
      newState.success = false;

      break;
    case GET_POLLUX_SUCCESS: {
      newState.polluxData = action.payload.details;
      newState.success = true;
      newState.loading = false;
      break;
    }
    case GET_POLLUX_FAILURE:
      newState.loading = false;
      newState.success = false;
      newState.error = action.payload.error;
      break;

    default:
      return state;
  }
  return newState;
};

export default publicationsReducer;
