/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import post from '../../../../../../assets/icons/post.png';
import ReactQuill, { Quill } from 'react-quill';
import { EditorToolbar, modules, formats } from '../../../../../helpers/editorToolbar';
import 'react-quill/dist/quill.snow.css';

import cns from 'classnames';

// ActionCreators
import { getPosts } from '../../../../../../redux/actionCreators/post.actionCreator';

// Components
import MediaUploadPreview from './mediaUploadPreview';
import Tags from './tags';
import SearchedPeople from '../../searched-people';

// Images
import userImg from '../../../../../../assets/img/user.png';
import InfoIcon from '@material-ui/icons/Info';

// Scss
import s from './writePost.module.scss';

// helpers
import _, { get } from 'lodash';
import axios from 'axios';
import { mediaSize, validateMediaSize } from '../../../../../../helpers';
import { Backdrop, CircularProgress, Tooltip } from '@material-ui/core';
import { getAllHashtags } from '../../../../../../redux/actionCreators/hashtag.actionCreator';
import HashtagDropdown from './HashtagDropdown';
import SuggestionModal from '../../../../../../components/Modal/SuggestionModal';
import CustomTags from './customTags';
import { getCustomTagData } from '../../../../../../redux/actionCreators/customTags.actionCreator';
import PostSpinnerBackdrop from './postSpinnerBackdrop';

// ************************************
// CONSTANTS
// ************************************

const WritePost = (props) => {
  // ************************************
  // INIT
  // ************************************
  const [state, setState] = useState((prevState) => ({
    lastCursorPostion: '',
    lastCursorPostionHashtag: '',
    isPersonTaggedInMiddle: '',
    postContent: '',
    postContentWithTagging: [],
    uploaderInput: [],
    isUploading: false,
    postTypeId: props.postTypes.length
      ? props.postTypes.filter((post) => {
          if (post.postType === 'Generic') return true;
        })[0].postTypeId
      : -1,
    src: sessionStorage.getItem('imageURL'),
    toggleDialog: false,
    isToggled: true,
    isBlank: true,
    searchInput: { searchValue: '', isPost: false },
    editor: true,
    selectedPostType: '',
    mediaValidated: true,
    searchHashtag: props.hashtags,
    toggleHashtagDropdown: false,
    usedHashtags: [],
    tagId: null,
    mentionsList: [],
    prevTagId: null
  }));

  const editorRef = useRef(null);
  const editorConfig = {
    id: 'writePost',
    modules: modules({ id: 'writePost' })
  };
  const toastId = useRef(null);

  const getCurrentCursorPosition = () => {
    const editor = editorRef.current.getEditor();
    const selection = editor.getSelection();
    if (selection) {
      const { index, length } = selection;
      return index;
    }
  };
  useEffect(() => {
    if (props.postTypes.length && state.postTypeId === -1) {
      setState((prevState) => ({
        ...prevState,
        postTypeId: props.postTypes.filter((post) => {
          if (post.postType === 'Generic') return true;
        })[0].postTypeId
      }));
    }
    if (!state.editor) {
      editorRef.current.focus();
    }
  }, [props.postTypes.length, state.editor]);

  const addContentToEditor = (content, pos) => {
    const editor = editorRef.current.getEditor();
    // let ind = editor.getSelection()?.index || 0;
    let ind = pos;
    if (!ind) {
      ind = editor.getLength() - 1;
    }
    editor.insertText(ind, content);
    editor.setSelection(ind + 1, 0);
  };

  const onEmojiClick = (event, emojiData) => {
    const editor = editorRef.current.getEditor();
    const range = editor.getSelection();

    if (state.doubleClicked) {
      const cursorPosition = editor.getLength();
      editor.insertText(cursorPosition, emojiData.emoji);
    } else {
      if (range && range.index !== null) {
        editor.deleteText(range.index, range.length);
        editor.insertText(range.index, emojiData.emoji);

        editor.setSelection(range.index + emojiData.emoji.length);
      } else {
        const cursorPosition = editor.getSelection(true).index;
        editor.insertText(cursorPosition, emojiData.emoji);

        editor.setSelection(cursorPosition + emojiData.emoji.length);
      }
    }

    setState((prevState) => ({ ...prevState, doubleClicked: false }));

    editor.focus();
  };

  const isContentBlank = (content) => {
    const trimmedContent = content.replace(/<\/?[^>]+>/gi, '').trim();
    const emptyTags = ['<p><br></p>', '<ol><li><br></li></ol>', '<ul><li><br></li></ul>'];
    return !trimmedContent || emptyTags.includes(content.trim());
  };

  useEffect(() => {
    const isBlank = isContentBlank(state.postContent);
    const mediaValidated = state.uploaderInput.every((file) =>
      validateMediaSize(file.type.split('/')[0], file)
    );

    setState((prevState) => ({
      ...prevState,
      isBlank,
      mediaValidated
    }));
  }, [state.postContent, state.uploaderInput]);

  const handleChange = (e) => {
    setState((prevState) => ({ ...prevState, postContent: e }));
    const x = e;
    if (['<p><br></p>', '<ol><li><br></li></ol>', '<ul><li><br></li></ul>'].includes(x.trim())) {
      setState((prevState) => ({ ...prevState, isBlank: true }));
    } else if (x.trim()) {
      setState((prevState) => ({ ...prevState, isBlank: false }));
    }
  };

  const handleUpload = (e) => {
    let files = Array.from(e.target.files);
    e.target.value = '';

    if (
      files[0].type.split('/')[0] === 'image' ||
      (files[0].type.split('/')[0] === 'video' && files[0].type.split('/')[1] === 'mp4')
    ) {
      let newFiles = [...files, ...state.uploaderInput];
      let isUnderSize = true;

      newFiles.forEach((file, index) => {
        let timestamp = Date.now();
        let fileName = `${timestamp}_${file.name}`;
        newFiles[index] = new File([file], fileName, { type: file.type });
        isUnderSize = validateMediaSize(file.type.split('/')[0], file) && isUnderSize;
      });

      setState((prevState) => ({
        ...prevState,
        uploaderInput: newFiles,
        mediaValidated: isUnderSize
      }));
    } else {
      toast.error('Unsupported media file');

      return;
    }
  };

  const validateForm = () => {
    var value = state.postContent;
    setState((prevState) => ({ ...prevState, postContent: value }));
    if (!state.postContent) {
      toast.error('Error. Please write something in post.');
      return false;
    } else if (!state.postTypeId) {
      toast.error('Error. Please select a tag for your post.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const { postContent, postTypeId, uploaderInput } = state;

    let newPostContent = postContent;
    newPostContent = newPostContent.replace(
      /<span style="color: green;">(.*?)<\/span>/g,
      function (match, g1) {
        return g1.split(' ').join('.').toLowerCase() + '@geminisolutions.com';
      }
    );

    newPostContent = newPostContent.replace(
      /<span style="color: #2c6db3;">(.*?)<\/span>/g,
      function (match, g1) {
        return g1;
      }
    );

    setState((prevState) => ({
      ...prevState,
      isUploading: true,
      isBlank: true,
      editor: true,
      selectedPostType: '',
      toggleHashtagDropdown: false
    }));
    props.setEditOnePost(false);
    props.setCurrentEditedPost(0);

    let data = {
      postDetails: {
        postContent: newPostContent,
        postTypeId: parseInt(postTypeId),
        userId: localStorage.getItem('email')
      },
      files: uploaderInput
    };

    const formData = new FormData();
    formData.append('postDetails', JSON.stringify(data.postDetails));
    Array.from(data.files).forEach((file) => {
      formData.append('files', file);
    });
    if (props?.clubId) formData.append('clubId', props.clubId);
    if (state?.tagId) formData.append('tagId', state.tagId);
    handleAddPost(formData);
  };

  const handleAddPost = (formData) => {
    axios
      .post(process.env.REACT_APP_GEMBOOK_SVC_URL + '/posts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: '*/*'
        },
        onUploadProgress: (p) => {
          const progress = p.loaded / p.total;

          // check if we already displayed a toast
          if (toastId.current === null && progress < 1) {
            toastId.current = toast('Uploading...', {
              progress: progress
            });
          } else {
            toast.update(toastId.current, { progress });
          }
        }
      })
      .then((res) => {
        toast.update(toastId.current, { autoClose: 1000 });
        toast.success('Post Created!');

        setState((prevState) => ({
          ...prevState,
          uploaderInput: [],
          postContent: '',
          isUploading: false,
          postTypeId: props.postTypes.length
            ? props.postTypes.filter((post) => {
                if (post.postType === 'Generic') return true;
              })[0].postTypeId
            : -1
        }));

        let data = { pageNum: 0 };
        if (props?.clubId) data.clubId = props.clubId;
        if (state.tagId) data.tagId = state.tagId;
        props.getPosts(data);
      })
      .catch((err) => {
        toast.error('Failed. Please Try Again.');
      });
    toastId.current = null;
  };

  const handleDeleteUpload = (name) => {
    let newFiles = [...state.uploaderInput];
    let newUploaderInput = [];
    let isUnderSizeLimit = true;

    newFiles.forEach((e) => {
      if (e.name !== name) {
        newUploaderInput.push(e);
        isUnderSizeLimit = validateMediaSize(e.type.split('/')[0], e) && isUnderSizeLimit;
      }
    });

    setState((prevState) => ({
      ...prevState,
      uploaderInput: [...newUploaderInput],
      mediaValidated: isUnderSizeLimit
    }));
  };

  const handleReset = () => {
    setState((prevState) => ({
      ...prevState,
      uploaderInput: [],
      postContent: '',
      postType: -1,
      isBlank: true,
      editor: !state.editor,
      searchInput: { searchValue: '', isPost: false },
      prevTagId: null
    }));
    props.setEditOnePost(false);
    props.setCurrentEditedPost(0);
  };

  const handleEditor = () => {
    setState((prevState) => ({ ...prevState, tagId: state.tagId }));

    if (!props.editOnePost) {
      setState((prevState) => ({ ...prevState, editor: !state.editor }));
      props.setEditOnePost(true);
      props.setCurrentEditedPost(-1);
    }

    if (props.currentEditedPost !== 0) {
      setState((prevState) => ({ ...prevState, toggleDialog: true }));
    }
  };

  const handleEditorChange = (content, delta, source, editor) => {
    if (
      !editor.getText().includes('@') &&
      !editor.getText().includes('#') &&
      !state.isPersonTaggedInMiddle
    ) {
      // editorRef.current.setEditorSelection(editorRef.editor, {
      //   index: editor.getText().length + 100,
      //   length: 0
      // });
      editorRef.current.getEditor().setSelection({
        index: editor.getText().length + 100,
        length: 0
      });
    }
    if (source === 'user') {
      if (delta.ops.length === 1 && delta.ops[0].delete === 1) {
        editorRef.current.getEditor().setSelection(0);
      }
    }
    let newEmailsArray = state.postContentWithTagging.filter((email) => {
      let name = email.split('@')[0].split('.').join(' ');
      return content.includes(name);
    });

    // setState({
    //   ...state,

    // });

    // checking if input is blank
    if (
      ['<p><br></p>', '<ol><li><br></li></ol>', '<ul><li><br></li></ul>'].includes(content.trim())
    ) {
      setState((prevState) => ({
        ...prevState,
        isBlank: true,
        postContent: content,
        postContentWithTagging: newEmailsArray
      }));
    } else if (content.trim()) {
      setState((prevState) => ({
        ...prevState,
        isBlank: false,
        postContent: content,
        postContentWithTagging: newEmailsArray
      }));
    }

    // while tagging, getting the characters after '@'
    const text = editor.getText(content);

    if (text.includes('@')) {
      let getName = text.match(/@[^\s@]+(\s+[^\s@]+)*/g);
      if (getName && getName[0].split(' ')[0].length >= 3) {
        setState((prevState) => ({ ...prevState, lastCursorPostion: getCurrentCursorPosition() }));
        handleSearch(text.substring(text.indexOf(getName[0]), getCurrentCursorPosition()));
      }
    } else if (text.includes('#') && state.toggleHashtagDropdown) {
      let getHashtag = text.match(/#\w+/g);
      let newHashtag = _.difference(getHashtag, state.usedHashtags);
      let latestChange = delta.ops[1];
      let latestCharacter = '';
      if (latestChange && latestChange.insert) {
        latestCharacter = latestChange.insert;
        // You can perform further actions based on the latest character here
      }
      if (
        (latestCharacter == ' ' || latestCharacter == '\n') &&
        newHashtag &&
        newHashtag.length > 0
      ) {
        handleHashtagContent(newHashtag[0]);
      } else {
        if (newHashtag) {
          setState((prevState) => ({
            ...prevState,
            searchHashtag: newHashtag,
            // toggleHashtagDropdown: true,
            lastCursorPostionHashtag: getCurrentCursorPosition()
          }));
        }
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        searchInput: { searchValue: '', isPost: false },
        searchHashtag: [],
        toggleHashtagDropdown: true
      }));
    }
  };

  const handleSearch = (name) => {
    setState((prevState) => ({
      ...prevState,
      searchInput: { searchValue: name, isPost: false }
    }));
  };

  const handlePostContent = async (user) => {
    let getName = state.postContent.match('@(.*?)</p>') ?? state.postContent.match('@(.*?)</li>');
    const content = editorRef.current.getEditor().getText();
    let textWithTag = content.match(/@[^\s@]+(\s+[^\s@]+)*/g)[0];
    let tag = content.substring(content.indexOf(textWithTag), state.lastCursorPostion);
    let htmlTextAfterTag = state.postContent.substring(
      state.postContent.lastIndexOf(tag) + tag.length
    );
    // to remove the tags that are standalone
    const span1 = document.createElement('span');
    const span2 = document.createElement('span');
    const mentionSpan = document.createElement('span');
    const textNode = document.createTextNode(user.email.split('@')[0].split('.').join(' '));
    mentionSpan.appendChild(textNode);
    span1.appendChild(mentionSpan);
    span2.appendChild(document.createTextNode(' '));
    mentionSpan.style.cssText = 'color:green;';

    var htmlTagPattern = /^<\/[a-z]+>$/i;
    let name = state.postContent
      .split(`@${getName[1]}`)
      .join(
        (!state.postContent.includes('span') ? '&nbsp;' + span1.outerHTML : span1.outerHTML) +
          (!htmlTextAfterTag || htmlTagPattern.test(htmlTextAfterTag) ? '&nbsp;' : htmlTextAfterTag)
      );
    let newEmailArray = [...state.postContentWithTagging];
    newEmailArray.push(user.email);
    setState((prevState) => ({
      ...prevState,
      isPersonTaggedInMiddle: true,
      postContent: name,
      searchInput: { searchValue: '', isPost: false },
      postContentWithTagging: newEmailArray
    }));
    let taggedPerson = user.email.split('@')[0].split('.').join(' ');
    const editorEditorref = await editorRef.current.getEditor();
    const textContent = editorEditorref.getText(name);
    editorRef.current
      .getEditor()
      .setSelection(
        textContent.indexOf(taggedPerson, state.lastCursorPostion - taggedPerson.length - 2) +
          taggedPerson.length +
          1
      );
    setState((prevState) => ({
      ...prevState,
      isPersonTaggedInMiddle: false
    }));
  };

  const handleSelectedPostType = (postType) => {
    setState((prevState) => ({ ...prevState, selectedPostType: postType }));
  };

  const handleTagAdd = (tagId, tagName) => {
    setState((prevState) => ({ ...prevState, tagId: tagId }));
    setState((prevState) => ({ ...prevState, prevTagId: tagName }));
  };

  const handleNameOnPostTextarea = () => {
    let firstName = localStorage.getItem('email')?.split('@')[0]?.split('.')[0];
    return firstName?.charAt(0).toUpperCase() + firstName?.slice(1);
  };

  const handleHashtagContent = async (tag) => {
    let allHashtags = state.postContent.match(/#\w+/g);

    let newHashtag = _.difference(allHashtags, state.usedHashtags);

    let newIndices = [];
    state.usedHashtags.forEach((tag) => {
      let index = state.postContent.indexOf(tag);
      newIndices.push(index);
    });

    let span1 = document.createElement('span');
    let span2 = document.createElement('span');

    const textNode = document.createTextNode(tag);
    span1.appendChild(textNode);
    span2.appendChild(document.createTextNode(' '));
    span1.style.cssText = 'color:#2c6db3;';

    let lastIndex = state.postContent.lastIndexOf(newHashtag[0]);
    let newHashtagIndex = state.postContent.indexOf(newHashtag[0]);
    let checkIndex = newIndices.includes(lastIndex) ? newHashtagIndex : lastIndex;

    let fullHashtag =
      state.postContent.slice(0, checkIndex) +
      state.postContent.slice(checkIndex).replace(newHashtag[0], span1.outerHTML + span2.outerHTML);

    let newUsedHashtags = [...state.usedHashtags];
    newUsedHashtags.push(tag);
    setState((prevState) => ({
      ...prevState,
      postContent: fullHashtag,
      toggleHashtagDropdown: false,
      searchHashtag: [],
      usedHashtags: newUsedHashtags
    }));
    const editor = await editorRef.current.getEditor();
    const text = editor.getText();
    if (state.lastCursorPostionHashtag && tag && tag.length > 0) {
      const currentPosition = state.lastCursorPostionHashtag - tag?.length - 2;
      editorRef.current
        .getEditor()
        .setSelection(
          text.indexOf(tag, currentPosition > 0 ? currentPosition : 0) + tag.length + 1
        );
    }
  };
  return (
    <div className={s.card}>
      <PostSpinnerBackdrop open={state.isUploading} />
      {state.editor ? (
        <div className={s.content}>
          <div className={cns(s.top, 'd-flex w-100')}>
            <img
              referrerPolicy="no-referrer"
              src={props.userDetails.imageURL}
              onError={() => setState((prevState) => ({ ...prevState, src: userImg }))}
              alt="user"
              className={s.picture}
            />
            <input
              className={s.inputBox}
              placeholder={`What's on your mind ${handleNameOnPostTextarea()}?`}
              onClick={handleEditor}
              onChange={handleChange}
              value={state.searchInput.searchValue}
            ></input>
          </div>
          <hr className={s.line}></hr>
          <div className={s.tagBlock}>
            <Tags
              postTypes={props.postTypes}
              handleChange={(p) => {
                setState((prevState) => ({ ...prevState, postTypeId: p }));
              }}
              selectedPostType={state.selectedPostType}
              handleSelectedPostType={handleSelectedPostType}
            />
            {props.clubId && <CustomTags clubId={props.clubId} handleTagAdd={handleTagAdd} />}
          </div>
        </div>
      ) : (
        <>
          {props.loading ? 'Loading' : null}
          <div className={s.content}>
            <div className={cns(s.top, 'd-flex w-100')}>
              <img
                referrerPolicy="no-referrer"
                src={props.userDetails.imageURL}
                onError={() => setState((prevState) => ({ ...prevState, src: userImg }))}
                alt="user"
                className={s.picture}
              />
              <div className={s.editor} onDrop={(e) => e.preventDefault()}>
                <ReactQuill
                  placeholder={`What's on your mind ${handleNameOnPostTextarea()}?`}
                  value={state.postContent}
                  className={s.editorStyle}
                  ref={editorRef}
                  onChange={handleEditorChange}
                  formats={formats}
                  modules={editorConfig.modules}
                  selection={{ start: 0, end: 0 }}
                />
              </div>
              <Tooltip
                arrow
                title={`The maximum size for uploading images - ${mediaSize.image}MB, formats are png, jpeg`}
              >
                <p className={s.mediaValidate}>
                  <InfoIcon />
                </p>
              </Tooltip>
            </div>
            {state.toggleHashtagDropdown && (
              <HashtagDropdown
                searchHashtag={state.searchHashtag}
                toggleHashtagDropdown={state.toggleHashtagDropdown}
                hashtags={props.hashtags}
                handleHashtagContent={handleHashtagContent}
              />
            )}
            {state.searchInput.searchValue && (
              <SearchedPeople
                handlePostContent={handlePostContent}
                searchValue={state.searchInput.searchValue}
                postContent={state.postContent}
                emails={state.postContentWithTagging}
                tag={true}
              />
            )}
            {state.uploaderInput?.length > 0 ? (
              <MediaUploadPreview media={state.uploaderInput} deleteMedia={handleDeleteUpload} />
            ) : null}
            <div className={s.boldbar}>
              <EditorToolbar
                handleUpload={handleUpload}
                className={s.toolbar}
                id={editorConfig.id}
                onEmojiClick={onEmojiClick}
              />
            </div>
            <div className={s.footer}>
              <div className={s.leftAligned}>
                <Tags
                  postTypes={props.postTypes}
                  handleChange={(p) => {
                    setState((prevState) => ({ ...prevState, postTypeId: p }));
                  }}
                  selectedPostType={state.selectedPostType}
                  handleSelectedPostType={handleSelectedPostType}
                />
                {props.clubId && (
                  <CustomTags
                    clubId={props.clubId}
                    handleTagAdd={handleTagAdd}
                    prevTagId={state?.prevTagId}
                  />
                )}
              </div>
              <div className={s.rightAligned}>
                <button
                  className={s.discardBtn}
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Discard
                </button>
                <button
                  className={state.isBlank || !state.mediaValidated ? s.disabled : s.createPostBtn}
                  disabled={state.isBlank || state.isUploading || !state.mediaValidated}
                  onClick={handleSubmit}
                >
                  Post
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className={s.mobileViewMain}>
        <div className={s.mobileViewWrapper}>
          <img
            src={props.userDetails.imageURL}
            onError={() => setState((prevState) => ({ ...prevState, src: userImg }))}
            alt="user"
            className={s.mobilePic}
          />
          <textarea
            name="postContent"
            rows={1}
            placeholder="Write a Post..."
            onChange={handleChange}
            value={state.postContent}
            className="w-100"
          />
          <label className={s.mobileBtnCustom}>
            <input
              multiple
              type="file"
              name="uploaderInput"
              className={s.mobileViewUploader}
              onChange={handleUpload}
            />
            <span className="p-0 d-flex align-items-center">
              <img
                alt="asset icon"
                src={process.env.PUBLIC_URL + '/assets/icons/photo.svg'}
                className="m-0"
                height={17}
                width={17}
              />
            </span>
            <span>
              <button className={s.mobileCreatePostBtn} onClick={handleSubmit}>
                <img alt="post" src={post} className="m-0" height={17} width={17} />
              </button>
            </span>
          </label>
        </div>
      </div>
      {state.toggleDialog && (
        <SuggestionModal
          toggleDialog={state.toggleDialog}
          setToggleDialog={(val) => setState((prevState) => ({ ...prevState, toggleDialog: val }))}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    postTypes: state.postTypesReducer.postTypes,
    loading: state.postReducer.addLoading,
    userDetails: state.storageReducer.user,
    hashtags: state.hashtagReducer.hashtags
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getPosts: getPosts, getAllHashtags: getAllHashtags, getCustomTagData: getCustomTagData },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WritePost);
