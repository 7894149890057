import React, { useState } from 'react';

//scss
import s from '../marketplace.module.scss';

//assests
import userIcon from '../../../assets/img/user.png';

//other imports
import { getProfileByEmail } from '../../../helpers/index';
import { reactions } from '../../../../src/helpers/iconsHelper';
import { uniqueId } from 'lodash';

const LikeNames = ({
  names,
  loveNames,
  likeNames,
  happyNames,
  supportNames,
  surpriseNames,
  sadNames,
  users,
  userReaction
}) => {
  const [activeTab, setActiveTab] = useState('All');
  const reactionTypes = [
    { label: 'All', data: names, icon: reactions.LIKE },
    { label: 'Love', data: loveNames, icon: reactions.LOVE },
    { label: 'Like', data: likeNames, icon: reactions.LIKE },
    { label: 'Happy', data: happyNames, icon: reactions.HAPPY },
    { label: 'Support', data: supportNames, icon: reactions.SUPPORT },
    { label: 'Surprise', data: surpriseNames, icon: reactions.SURPRISE },
    { label: 'Sad', data: sadNames, icon: reactions.SAD }
  ];

  const handleTabChange = (tabLabel) => {
    setActiveTab(tabLabel);
  };

  const getProfile = (userId) => {
    getProfileByEmail(userId);
  };

  return (
    <div className={s.likeName}>
      <div className={s.tabContainer}>
        {reactionTypes?.map(
          (reaction, ind) =>
            reaction?.data?.length > 0 && (
              <div
                key={ind}
                className={`${s.tab} ${activeTab === reaction?.label ? s.activeTab : ''}`}
                onClick={() => handleTabChange(reaction?.label)}
              >
                {reaction?.label !== 'All' && (
                  <img src={reaction?.icon} alt={`${reaction?.label} Icon`} className={s.icon} />
                )}
                {reaction?.label === 'All' ? reaction?.label : reaction?.data?.length}
              </div>
            )
        )}
      </div>
      <div className={s.likeBox}>
        {reactionTypes?.map(
          (reaction, ind) =>
            activeTab === reaction?.label && (
              <div key={ind}>
                {reaction?.data.map((user, ind) => {
                  const data =
                    users && Object.values(users).filter((value) => value?.email === user?.userId);

                  const userData = data && data[0];
                  const userReactionType = userReaction?.find(
                    (reaction) => reaction?.postReactIdentity?.userId === user?.userId
                  );

                  user.unique_id = uniqueId() + '' + ind;
                  return (
                    <div
                      key={user?.unique_id}
                      className={s.content}
                      onClick={() => userData && getProfile(user?.userId)}
                    >
                      <img
                        referrerPolicy="no-referrer"
                        className={s.image}
                        src={userData?.photoUrl ?? userIcon}
                        width={30}
                        height={30}
                        alt="profile"
                      />
                      {userReactionType && (
                        <img
                          src={reactions[userReactionType?.reactionType]}
                          alt={`${userReactionType?.reactionType} Icon`}
                          className={s.reactionIcon}
                        />
                      )}
                      <span className={s.userName}>{userData?.name ?? '[Former Employee]'}</span>
                    </div>
                  );
                })}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default LikeNames;
