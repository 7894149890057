import {
  GET_NEWMEMBERS_FAILURE,
  GET_NEWMEMBERS_LOADING,
  GET_NEWMEMBERS_SUCCESS
} from '../actions/actions';

const initialState = {
  loading: false,
  newMember: {},
  error: ''
};

const newMemberReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_NEWMEMBERS_LOADING:
      newState.loading = true;
      break;
    case GET_NEWMEMBERS_SUCCESS: {
      newState.newMember = { ...state.details, ...action.payload.details };
      newState.loading = false;
      break;
    }
    case GET_NEWMEMBERS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default newMemberReducer;
