import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import s from '../../dashboard.module.scss';
import Sidebar from '../../../../layouts/layout1/sidebar/index';
import Navbar from '../../../../layouts/layout1/navbar/index';
import Footer from '../../../../layouts/layout1/footer/index';
import Profiles from './profiles';
import _ from 'lodash';
import SearchedPeople from '../home/searched-people';
import Posts from '../home/postContainer/posts';
import postClass from './../home/postContainer/postContainer.module.scss';
import peopleClass from './../home/home.module.scss';
import RightSidebar from '../home/sidebar';
import Viewport from '../../../helpers/viewport';
import { useEffect } from 'react';
import useCheckJwtExpiration from '../../../../hooks/useCheckJwtExpiration';
import { useMediaQuery } from 'react-responsive';

function Profile(props) {
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const edit = true;
  const [inputChangeData, setInputChangeData] = useState({
    searchValue: '',
    isPost: false
  });
  const [inputVal, setInputVal] = useState('');
  const [toggle, setToggle] = useState(isMobile ? false : true);
  const [editOnePost, setEditOnePost] = useState(false);
  const [currentEditedPost, setCurrentEditedPost] = useState(0);

  const onChange = (value, isPost) => {
    setInputChangeData({ searchValue: value, isPost: isPost });
  };
  const useJwtExpiration = useCheckJwtExpiration();
  const debouncedOnChange = useCallback(_.debounce(onChange, 500), [onChange]);

  useEffect(() => {
    isMobile ? setToggle(false) : setToggle(true);
  }, [isMobile]);

  return (
    <div className={s.dashboard}>
      <section className={s.profileSection}>
        <Navbar
          setToggle={setToggle}
          toggle={toggle}
          profileDetails={props.profileDetails}
          onChange={debouncedOnChange}
          inputVal={inputVal}
          setInputVal={setInputVal}
        />
        <div className={s.profileWrapper}>
          <Sidebar
            toggle={toggle}
            setToggle={setToggle}
            onChange={debouncedOnChange}
            inputVal={inputChangeData.searchValue}
            setInputVal={setInputVal}
          />
          {inputChangeData.searchValue?.length ? (
            <div className={peopleClass.home}>
              {!inputChangeData.isPost ? (
                <div className={peopleClass.containerWrapper}>
                  <SearchedPeople searchValue={inputChangeData.searchValue} />
                </div>
              ) : (
                <div className={peopleClass.containerWrapper}>
                  <div className={postClass.postContainer}>
                    <Posts
                      searchValue={inputChangeData.searchValue}
                      editOnePost={editOnePost}
                      setEditOnePost={(val) => setEditOnePost(val)}
                      currentEditedPost={currentEditedPost}
                      setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Profiles
              setInput={setInputVal}
              setInputVal={setInputChangeData}
              toggle={toggle}
              edit={edit}
            />
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profileDetails: state.profileReducer.details
  };
};

export default connect(mapStateToProps, null)(Profile);
