export const msalConfig = {
  auth: {
    clientId: 'b7287f38-f9b9-4657-8117-4d31e43ab13d',
    authority: 'https://login.microsoftonline.com/b9806c7d-9280-4e44-afea-6dc0ff495c2f', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: 'https://gembookuibeta.geminisolutions.com'
    // redirectUri: 'http://localhost:3000'
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['b7287f38-f9b9-4657-8117-4d31e43ab13d/.default']
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com'
};
