import React, { useEffect, useState } from 'react';

// Components
import LeftBottomShare from './leftBottomShare';
import LeftBottom from './leftBottom';
// StyleSheet
import s from './leftWrapper.module.scss';

//Helper

//Images
import user from '../../../../../assets/img/user.png';
import RightWrapper from './rightWrapper';
import { TOOLTIP_MSSG } from '../../../../../helpers';
import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import download from '../../../../../assets/icons/download.svg';
import { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
const LeftWrapper = React.memo(
  ({
    details,
    editSkill,
    editAchievement,
    editTraining,
    editCertification,
    editProject,
    edit,
    managerDetails,
    awardsByEmail,
    educationDetails,
    handleDownloadPDF,
    handleDownloadPPT
  }) => {
    // const { isMobile } = Viewport();
    const isMobile = useMediaQuery({ maxWidth: 992 });
    const params = {
      ...details,
      editSkill: editSkill,
      editAchievement: editAchievement,
      editTraining: editTraining,
      editProject: editProject,
      editCertification: editCertification,
      educationDetails
    };
    const rightWrapperParams = {
      details,
      managerDetails,
      awardsByEmail
    };
    const [toggle, setToggle] = useState(false);

    let wrapper = useRef();

    useEffect(() => {
      let handler = (event) => {
        if (!wrapper.current.contains(event.target)) {
          setToggle(false);
        }
      };
      document.addEventListener('mousedown', handler);
      return () => {
        document.removeEventListener('mousedown', handler);
      };
    }, []);

    const Mobile = React.memo(() => {
      return (
        <div className={s.mobileLeftWrapper}>
          <div ref={wrapper} className={s.userWrapper}>
            <div className={s.userWrapperInner}>
              <div className={s.employeeImg}>
                {!details.photoUrl ? (
                  <Skeleton variant="circle" className={s.circleLoader} />
                ) : (
                  <img
                    referrerPolicy="no-referrer"
                    width={20}
                    src={details.photoUrl}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = user;
                    }}
                    alt="user"
                    className={s.userImage}
                  />
                )}
              </div>

              <div className={s.employeeName}>
                {details.name ? (
                  <h4>{details.name}</h4>
                ) : (
                  <Skeleton variant="text" className={s.textLoader} />
                )}
                {details.designation ? (
                  <span>{details.designation}</span>
                ) : (
                  <Skeleton variant="text" className={s.textLoader} />
                )}
                {!details.email && !details.contactNo ? (
                  <Skeleton variant="text" className={s.textLoader} />
                ) : (
                  <>
                    <div className={s.email}>
                      <Tooltip title={TOOLTIP_MSSG.CLICK_MAIL}>
                        <a className={s.contact} href={`mailto:${details.email}`}>
                          {details.email}
                        </a>
                      </Tooltip>
                      &nbsp; &nbsp;|&nbsp; &nbsp;
                      <Tooltip title={TOOLTIP_MSSG.CLICK_MOBILE}>
                        <a className={s.contact} href={`tel:${details.contactNo}`}>
                          {details.contactNo}
                        </a>
                      </Tooltip>
                    </div>
                    <div className={s.downloadContainer}>
                      <div className={s.download} onClick={() => setToggle(!toggle)}>
                        <p>Download</p>
                      </div>
                    </div>
                    {toggle && (
                      <div className={s.dropdownContainer}>
                        <div className={s.dropdown}>
                          <p onClick={handleDownloadPDF}>
                            <img src={download} className={s.icon} alt="download icon" />
                            Save Profile to PDF
                          </p>
                          <p onClick={handleDownloadPPT}>
                            <img src={download} className={s.icon} alt="download icon" />
                            Save Profile to PPT
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <RightWrapper {...rightWrapperParams} />
          {edit ? <LeftBottom /> : <LeftBottomShare {...params} />}
        </div>
      );
    });

    const Desktop = React.memo(() => {
      return (
        <div className={s.leftWrapper}>
          <div className={s.leftInner}>
            <div ref={wrapper} className={s.desktop}>
              <div className={s.leftTop}>
                <div className={s.employeeImg}>
                  {!details.photoUrl ? (
                    <Skeleton variant="circle" className={s.image} />
                  ) : (
                    <img
                      referrerPolicy="no-referrer"
                      src={details?.photoUrl ? details.photoUrl : user}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = user;
                      }}
                      alt="user"
                      className={s.image}
                    />
                  )}
                </div>

                <div className={s.employeeName}>
                  {details.name ? (
                    <h4>{details.name}</h4>
                  ) : (
                    <Skeleton variant="text" className={s.textLoader} />
                  )}
                  {details.designation ? (
                    <div className={s.designation}>{details.designation}</div>
                  ) : (
                    <Skeleton variant="text" className={s.designation} />
                  )}

                  {!details.email && !details.contactNo ? (
                    <Skeleton variant="text" className={s.textLoader} />
                  ) : (
                    <>
                      <div className={s.email}>
                        <Tooltip title={TOOLTIP_MSSG.CLICK_MAIL}>
                          <a className={s.contact} href={`mailto:${details.email}`}>
                            {details.email}
                          </a>
                        </Tooltip>
                        &nbsp; &nbsp;|&nbsp; &nbsp;
                        <Tooltip title={TOOLTIP_MSSG.CLICK_MOBILE}>
                          <a className={s.contact} href={`tel:${details.contactNo}`}>
                            {details.contactNo}
                          </a>
                        </Tooltip>
                      </div>

                      <div className={s.download} onClick={() => setToggle(!toggle)}>
                        <p>Download</p>
                      </div>
                    </>
                  )}
                  {toggle && (
                    <div className={s.dropdown}>
                      <p onClick={handleDownloadPDF}>
                        <img src={download} className={s.icon} alt="download icon" />
                        Save Profile to PDF
                      </p>
                      <p onClick={handleDownloadPPT}>
                        <img src={download} className={s.icon} alt="download icon" />
                        Save Profile to PPT
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {details.id ? edit ? <LeftBottom /> : <LeftBottomShare {...params} /> : null}
          </div>
        </div>
      );
    });

    return <>{isMobile ? <Mobile /> : <Desktop />}</>;
  }
);

export default LeftWrapper;
