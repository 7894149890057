import { Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import s from './details.module.scss';
import React, { useState } from 'react';

import editIcon from '../../../../../../assets/icons/edit.svg';
import deleteIcon from '../../../../../../assets/icons/delete.svg';
import downloadIcon from '../../../../../../assets/icons/downloadPoll.svg';
import copylink from '../../../../../../assets/icons/copylink.svg';
import moreIcon from '../../../../../../assets/icons/3Dots.svg';

const EventBtns = ({
  title,
  handleEdit,
  handleDownload,
  handleDelete,
  editCondition,
  poll,
  copyHandler,
  hrArr,
  event
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const eventId = window.location.href.includes('/event/') && window.location.href.split('/').pop();

  return (
    <>
      <div className={s.editdeletebtns}>
        {hrArr?.some(
          (hrMember) =>
            hrMember?.email?.toLowerCase() === localStorage.getItem('email') &&
            hrMember?.club === event?.organizerName
        ) && (
          <>
            {editCondition && (
              <Tooltip title={title.edit}>
                <img className={s.editBtn} onClick={handleEdit} src={editIcon} alt="Edit icon" />
              </Tooltip>
            )}
            <Tooltip title={title.delete}>
              <img
                className={s.deleteBtn}
                onClick={handleDelete}
                src={deleteIcon}
                alt="Delete Icon"
              />
            </Tooltip>
          </>
        )}

        {!eventId && (
          <>
            <button onClick={handleClick} className={s.moreBtn}>
              <img src={moreIcon} alt="more" width={12} />
            </button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={copyHandler} style={{ fontSize: '12px' }}>
                <img src={copylink} alt="copyIcon" /> &nbsp; Copy link to Post
              </MenuItem>
            </Menu>
          </>
        )}

        {poll?.pollId && (
          <Tooltip title="Download Poll Results">
            <img
              className={s.editBtn}
              onClick={handleDownload}
              src={downloadIcon}
              alt="Download icon"
            />
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default EventBtns;
