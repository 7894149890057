import React from 'react';
import classes from './Button.module.scss';
import cls from 'classnames';

const Button = ({
  id,
  name,
  placeholder,
  style,
  className,
  type = 'button',
  onClick,
  children,
  disabled
}) => {
  return (
    <button
      id={id}
      name={name}
      placeholder={placeholder}
      style={style}
      className={cls(classes.btn, className)}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
