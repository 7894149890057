import {
  GET_ALLCLUBS_SUCCESS,
  GET_ALLCLUBS_LOADING,
  GET_ALLCLUBS_FAILURE,
  GET_CLUB_DETAILS_SUCCESS,
  GET_CLUB_DETAILS_LOADING,
  GET_CLUB_DETAILS_FAILURE,
  GET_CLUB_MEMBERS_SUCCESS,
  GET_CLUB_MEMBERS_LOADING,
  GET_CLUB_MEMBERS_FAILURE,
  JOIN_CLUB_SUCCESS,
  JOIN_CLUB_LOADING,
  JOIN_CLUB_FAILURE,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_LOADING,
  GET_REQUEST_FAILURE,
  JOIN_STATUS_SUCCESS,
  JOIN_STATUS_LOADING,
  JOIN_STATUS_FAILURE,
  LEAVE_CLUB_SUCCESS,
  LEAVE_CLUB_LOADING,
  LEAVE_CLUB_FAILURE,
  LEAVE_STATUS_SUCCESS,
  LEAVE_STATUS_LOADING,
  LEAVE_STATUS_FAILURE,
  UPDATE_JOINREQUEST_LOADING,
  UPDATE_JOINREQUEST_SUCCESS,
  UPDATE_JOINREQUEST_FAILURE,
  GET_CLUB_DETAILS_WITH_CLUB_ID_LOADING,
  GET_CLUB_DETAILS_WITH_CLUB_ID_FAILURE,
  GET_CLUB_DETAILS_WITH_CLUB_ID_SUCCESS
} from '../actions/actions';

const initialState = {
  loading: false,
  clubs: [],
  clubDetails: [],
  membersDetails: [],
  requests: [],
  clubsIdByClubName: [],
  error: ''
};

const clubsReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_ALLCLUBS_LOADING:
      newState.loading = true;
      break;
    case GET_ALLCLUBS_SUCCESS: {
      newState.clubs = [...action.payload.details];
      newState.loading = false;
      break;
    }
    case GET_ALLCLUBS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case GET_CLUB_DETAILS_LOADING:
      newState.loading = true;
      break;
    case GET_CLUB_DETAILS_SUCCESS: {
      newState.clubDetails = { ...state.clubDetails, ...action.payload.clubDetails };
      newState.loading = false;
      break;
    }
    case GET_CLUB_DETAILS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case GET_CLUB_MEMBERS_LOADING:
      newState.loading = true;
      break;
    case GET_CLUB_MEMBERS_SUCCESS: {
      newState.membersDetails = [...action.payload.details];
      newState.loading = false;
      break;
    }
    case GET_CLUB_MEMBERS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case JOIN_CLUB_LOADING:
      newState.loading = true;
      break;
    case JOIN_CLUB_SUCCESS:
      break;
    case JOIN_CLUB_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case GET_REQUEST_LOADING:
      newState.loading = true;
      break;
    case GET_REQUEST_SUCCESS: {
      newState.requests = [...action.payload.details];
      newState.loading = false;
      break;
    }
    case GET_REQUEST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case UPDATE_JOINREQUEST_LOADING:
      newState.loading = true;
      break;
    case UPDATE_JOINREQUEST_SUCCESS:
      newState.loading = false;
      break;
    case UPDATE_JOINREQUEST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case JOIN_STATUS_LOADING:
      newState.loading = true;
      break;
    case JOIN_STATUS_SUCCESS:
      break;
    case JOIN_STATUS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case LEAVE_CLUB_LOADING:
      newState.loading = true;
      break;
    case LEAVE_CLUB_SUCCESS:
      break;
    case LEAVE_CLUB_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case LEAVE_STATUS_LOADING:
      newState.loading = true;
      break;
    case LEAVE_STATUS_SUCCESS:
      break;
    case LEAVE_STATUS_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case GET_CLUB_DETAILS_WITH_CLUB_ID_LOADING:
      newState.loading = true;
      break;
    case GET_CLUB_DETAILS_WITH_CLUB_ID_SUCCESS:
      newState.clubsIdByClubName = action.payload.clubsID;
      newState.getError = '';
      newState.getLoading = false;
      break;
    case GET_CLUB_DETAILS_WITH_CLUB_ID_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;

    default:
      return state;
  }
  return newState;
};

export default clubsReducer;
