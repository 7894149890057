import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import s from './rightWrapper.module.scss';
import { Skeleton } from '@material-ui/lab';
import { editHobbies } from '../../../../../redux/actionCreators/profile/hobbies.actionCreator';
import { useDispatch } from 'react-redux';

//icons
import editIcon from '../../../../../assets/icons/editpost.svg';
import saveIcon from '../../../../../assets/icons/saveIcon.svg';
import closeIcon from '../../../../../assets/icons/close.svg';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';

const Hobbies = ({ details, setInput, setInputVal, ...props }) => {
  const dispatch = useDispatch();
  const myHobbies = useSelector(
    (state) => state?.hobbiesReducer?.hobbies ?? state?.profileReducer?.details?.hobbies
  );
  const hobbiesData = localStorage.getItem('email') === details.email ? myHobbies : details.hobbies;

  const [editDiv, setEditDiv] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hobbiesInput, setHobbiesInput] = useState(hobbiesData);
  const [viewMore, setViewMore] = useState(false);

  const handleChange = (e) => {
    if ((e.target.value.match(/,/g) || []).length < 4) {
      setHobbiesInput(e.target.value);
    } else {
      toast.error('Please enter only 4 hobbies');
    }
  };

  const handleSave = () => {
    const data = {
      hobbies: hobbiesInput
    };
    editHobbies(data)(dispatch);
    setIsEditing(!isEditing);
    setEditDiv(!editDiv);
  };

  const handleClose = () => {
    setHobbiesInput(hobbiesData);
    setIsEditing(!isEditing);
    setEditDiv(!editDiv);
  };

  return (
    <>
      <div className={s.detailHeading}>
        Hobbies
        {localStorage.getItem('email') === details.email &&
          (isEditing ? (
            <img src={saveIcon} alt="save-Img" className={s.saveImg} onClick={handleSave} />
          ) : hobbiesData?.length ? (
            <img
              src={editIcon}
              alt="edit-Img"
              className={s.editImg}
              onClick={() => {
                setIsEditing(true);
                setEditDiv(true);
              }}
            />
          ) : (
            ''
          ))}
      </div>

      <div className={classNames(s.detailDescription, s.hobbyDescription)}>
        {!editDiv ? (
          props.loading ? (
            <Skeleton variant="text" className={s.detailDescription} />
          ) : hobbiesData?.length ? (
            hobbiesData.split(',').map((hobby, idx) => {
              let isEmpty = /^ *$/.test(hobby);
              return (
                !isEmpty && (
                  <Tooltip title={`Search people who like ${hobby}`} key={idx}>
                    <div
                      className={s.displayDescription}
                      onClick={() => {
                        setInputVal({ searchValue: hobby, isPost: false });
                        setInput(hobby);
                      }}
                    >
                      {hobby}
                    </div>
                  </Tooltip>
                )
              );
            })
          ) : localStorage.getItem('email') === details.email ? (
            <p
              onClick={() => {
                setIsEditing(true);
                setEditDiv(true);
              }}
              className={s.addButton}
            >
              + ADD
            </p>
          ) : (
            '-'
          )
        ) : (
          <div className={s.inputDiv}>
            <Tooltip title="Insert comma to add multiple hobbies">
              <input
                autoFocus
                autoComplete="off"
                className={s.addText}
                type="text"
                value={hobbiesInput}
                onChange={handleChange}
                name="hobbies"
                id="hobbies"
              />
            </Tooltip>
            <img src={closeIcon} alt="close-icon" className={s.closeImg} onClick={handleClose} />
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.profileReducer.loading
  };
}

export default connect(mapStateToProps)(Hobbies);
