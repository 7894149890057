import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllUsers } from '../../../../../../redux/actionCreators/user.actionCreator';
import SearchEmployee from './searchEmployee';

// ActionCreators
import { addAward, addWinner } from '../../../../../../redux/actionCreators/award.actionCreator';

//SCSS
import s from '../sidebar.module.scss';

const AddAward = (props) => {
  const [year, setYear] = useState('');
  const [rank, setRank] = useState(null);
  const [title, setTitle] = useState('');
  const [winners, setWinners] = useState('');
  const [winnerEmail, setWinnerEmail] = useState('');
  const [managerComment, setManagerComment] = useState('');
  const [selectedOption, setSelectedOption] = useState('rank');

  const [isEvent, setIsEvent] = useState(false);
  const [award, setAward] = useState('');
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();

  // ************************************
  // HANDLERS
  // ************************************
  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const inputName = e.target.name;
    if (inputName === 'year') {
      setYear(value);
      props.getAwards(value);
    } else if (inputName === 'award') {
      setAward(value);
      if (value.startsWith('E')) {
        setIsEvent(true);
      } else if (value.startsWith('R')) {
        setIsEvent(false);
      }
    }
  };
  const setWinnerName = (name, email) => {
    setWinners(name);
    setWinnerEmail(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      award === '' ||
      year === '' ||
      winners === '' ||
      (isEvent &&
        (selectedOption === '' ||
          (selectedOption === 'rank' && (rank === null || rank > 5 || rank < 1)) ||
          (selectedOption === 'title' && (title === '' || title.length > 40))))
    ) {
      setIsError(true);
      return;
    }

    if (isEvent) {
      let data = {
        winnerName: winners,
        eventId: award,
        eventWinnerEmail: winnerEmail,
        rank: rank,
        eventWinnerTitle: title,
        organizerComment: managerComment
      };
      dispatch(addWinner(data, selectedOption));
    } else {
      let data = {
        recognitionId: award,
        winnerName: winners,
        recWinnerEmail: winnerEmail,
        managerComment: managerComment
      };
      dispatch(addAward(data));
    }

    setAward('');
    setYear('');
    setRank(0);
    setTitle('');
    setWinners('');
    setManagerComment('');
    setIsEvent(false);
    props.setAddAwardToggle(false);
  };
  return (
    <div className="p-4">
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <SearchEmployee users={props.users} setWinnerName={setWinnerName} />
          {isError && winners === '' && (
            <p className={s.errorMsgAwards}>WINNER NAME cannot be empty</p>
          )}
        </div>

        <div className="mb-2 d-flex">
          <div className="w-40">
            <select
              className={s.yearTab}
              name="year"
              placeholder="Year"
              onChange={handleChange}
              value={year}
            >
              {[
                { name: 'Select Year', value: '' },
                ...Array(parseInt(new Date().getFullYear()) - 2012 + 1)
                  .fill()
                  .map((val, idx) => ({ name: 2012 + idx, value: 2012 + idx }))
                  .reverse()
              ].map((option, idx) => {
                return (
                  <option key={idx} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>
            {isError && year === '' && <p className={s.errorMsgAwards}>YEAR cannot be empty</p>}
          </div>

          <div>
            <select className={s.awardTab} value={award} name="award" onChange={handleChange}>
              <option value={''}>Select Award</option>
              {[...props.awards].map((option, idx) => (
                <option value={option.eventRecognitionId} key={idx}>
                  {option.name}
                </option>
              ))}
            </select>
            {isError && award === '' && (
              <p className={s.errorMsgAwards}>AWARD NAME cannot be empty</p>
            )}
          </div>
        </div>

        {isEvent ? (
          <>
            <div className="d-flex mb-2">
              <div className="w-auto">
                <select
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className={s.yearTab}
                >
                  <option value="rank">Rank</option>
                  <option value="title">Title</option>
                </select>
              </div>
              <div className="w-100">
                {selectedOption === 'rank' ? (
                  <>
                    <input
                      className={s.rankTitle}
                      placeholder="Enter Winner's rank (1 to 5)"
                      name="rank"
                      onChange={(e) => setRank(e.target.value.replace(/[^0-9]/g, ''))}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    {isError && (rank === null || rank < 1 || rank > 5) && (
                      <p className={s.errorMsgAwards}>RANK should be between 1 and 5</p>
                    )}
                  </>
                ) : selectedOption === 'title' ? (
                  <>
                    <input
                      className={s.rankTitle}
                      placeholder="Enter Winner's Title"
                      name="title"
                      onChange={(e) => setTitle(e.target.value)}
                      maxLength={41}
                    />
                    {isError && title === '' && (
                      <p className={s.errorMsgAwards}>TITLE cannot be empty</p>
                    )}
                    {isError && title.length > 40 && (
                      <p className={s.errorMsgAwards}>TITLE length exceeded</p>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
        {award.length ? (
          <div className="mb-2">
            <textarea
              placeholder="Add Comment"
              value={managerComment}
              onChange={(e) => setManagerComment(e.target.value)}
              className={s.searchQuery}
              style={{ resize: 'none' }}
            ></textarea>
          </div>
        ) : (
          ''
        )}

        <div className="text-center">
          <button type="submit" className={s.createBtn} onClick={handleSubmit}>
            Add
          </button>
        </div>
      </form>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    users: state.userReducer.users
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllUsers
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAward);
