import Axios from 'axios';

export const getEmployeeIdByEmail = (email) => {
  return Axios.get(`${process.env.REACT_APP_GEMBOOK_SVC_URL}/profile/emp-id-by-email`, {
    headers: {
      accept: '*/*'
    },
    params: {
      email: email
    }
  });
};

export const getProfileDetailsById = (id) => {
  return Axios.get(
    `${process.env.REACT_APP_GEMBOOK_SVC_URL}/profile/employee-details-by-id?employeeId=${id}`,
    {
      headers: {
        accept: '*/*'
      }
    }
  );
};

export const getImageURL = (email) => {
  return Axios.get(`${process.env.REACT_APP_GEMBOOK_SVC_URL}/profile/employee-details`, {
    headers: {
      accept: '*/*'
    },
    params: {
      email: email
    }
  });
};
