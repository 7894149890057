import {
  SAVE_LIKE_FOR_POST_FAILURE,
  SAVE_LIKE_FOR_POST_SUCCESS,
  SAVE_LIKE_FOR_POST_LOADING,
  DELETE_LIKE_FOR_POST_FAILURE,
  DELETE_LIKE_FOR_POST_SUCCESS,
  DELETE_LIKE_FOR_POST_LOADING,
  SAVE_LIKE_FOR_MARKET_POST_SUCCESS
} from '../actions/actions';

const initialState = {
  saveLoading: false,
  saveError: '',
  updatedReaction: null,
  marketPlaceUpdatedReaction: null,
  getLoading: false,
  getError: '',
  deleteLoading: false,
  deleteError: ''
};

const postLikeReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case SAVE_LIKE_FOR_POST_LOADING:
      newState.saveLoading = true;
      break;
    case SAVE_LIKE_FOR_POST_SUCCESS:
      newState.saveLoading = false;
      newState.updatedReaction = action.payload.details;
      newState.saveError = '';
      break;
    case SAVE_LIKE_FOR_POST_FAILURE:
      newState.saveLoading = false;
      newState.saveError = action.payload.error;
      break;
    case DELETE_LIKE_FOR_POST_LOADING:
      newState.deleteLoading = true;
      break;
    case DELETE_LIKE_FOR_POST_SUCCESS:
      newState.deleteLoading = false;
      newState.deleteError = '';
      break;
    case DELETE_LIKE_FOR_POST_FAILURE:
      newState.deleteLoading = false;
      newState.deleteError = action.payload.error;
      break;

    case SAVE_LIKE_FOR_MARKET_POST_SUCCESS:
      newState.saveLoading = false;
      newState.marketPlaceUpdatedReaction = action.payload.details;
      newState.saveError = '';
      break;

    default:
      return state;
  }
  return newState;
};

export default postLikeReducer;
