import axios from 'axios';
import {
  GET_EDUCATIONDETAILS_LOADING,
  GET_EDUCATIONDETAILS_SUCCESS,
  GET_EDUCATIONDETAILS_FAILURE,
  ADD_EDUCATIONDETAILS_LOADING,
  UPDATE_EDUCATIONDETAILS_LOADING,
  DELETE_EDUCATIONDETAILS_LOADING,
  DELETE_EDUCATIONDETAILS_SUCCESS,
  DELETE_EDUCATIONDETAILS_FAILURE
} from '../../actions/actions';
import { toast } from 'react-toastify';

export const getEducationDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_EDUCATIONDETAILS_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/education-details', {
        headers: {
          accept: '*/*'
        },
        params: {
          employeeId: data
        }
      })
      .then((res) => {
        dispatch({
          type: GET_EDUCATIONDETAILS_SUCCESS,
          payload: {
            educationDetails: res.data
          }
        });
      })
      .catch(() => {
        dispatch({
          type: GET_EDUCATIONDETAILS_FAILURE,
          payload: {
            err: 'Error'
          }
        });
      });
  };
};

export const addEducationDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_EDUCATIONDETAILS_LOADING
    });
    return axios.post(process.env.REACT_APP_GEMBOOK_SVC_URL + '/education-details', data, {
      headers: {
        accept: '*/*'
      },
      params: {
        employeeId: localStorage.getItem('id')
      }
    });
  };
};

export const updateEducationDetails = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_EDUCATIONDETAILS_LOADING
    });
    return axios.put(process.env.REACT_APP_GEMBOOK_SVC_URL + '/education-details', data, {
      headers: {
        accept: '*/*'
      },
      params: {
        educationId: id
      }
    });
  };
};

export const deleteEducationDetails = (educationId) => {
  const empId = localStorage.getItem('id');
  return (dispatch) => {
    dispatch({
      type: DELETE_EDUCATIONDETAILS_LOADING
    });
    axios
      .delete(process.env.REACT_APP_GEMBOOK_SVC_URL + '/education-details', {
        headers: {
          accept: '*/*'
        },
        params: {
          educationId: educationId
        }
      })
      .then(() => {
        dispatch({
          type: DELETE_EDUCATIONDETAILS_SUCCESS
        });
        toast.success('Data Deleted Successfully!');
        dispatch(getEducationDetails(empId));
      })
      .catch(() => {
        dispatch({
          type: DELETE_EDUCATIONDETAILS_FAILURE,
          payload: {
            err: 'Error'
          }
        });
        toast.error('Failed to delete Data. Please Retry Later!');
      });
  };
};
