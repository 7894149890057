import React, { useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

//icons
import edit from '../../../../../assets/icons/editpost.svg';
import save from '../../../../../assets/icons/saveDetails.png';
import close from '../../../../../assets/icons/close.svg';
import addIcon from '../../../../../assets/icons/addIcon.svg';

// CSS
import s from './components.module.scss';
import cns from 'classnames';

//Helper
import { useDispatch, useSelector } from 'react-redux';
import { editAchievement } from '../../../../../redux/actionCreators/profile/achievement.actionCreator';
import { TOOLTIP_MSSG } from '../../../../../helpers';
import { Tooltip } from '@material-ui/core';
import { uniqueId } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';

const Achievement = () => {
  const dispatch = useDispatch();
  const achievements = useSelector((state) => state?.profileReducer?.details?.achievements);
  const loading = useSelector((state) => state?.profileReducer?.loading);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [editFlag, setEditFlag] = useState(false);
  const [achievementInput, setAchievementInput] = useState(achievements);
  let wrapper = useRef(null);

  const handleChange = (e) => {
    setAchievementInput(e.target.value);
  };

  const handleSave = () => {
    if (!achievementInput.trim()) {
      toast.warn('Achievement field cannot be empty!', { // a toast warning
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const data = {
      achievements: achievementInput,
    };
    editAchievement(data)(dispatch);
    setEditFlag(!editFlag);
  };
  const handleClose = () => {
    setAchievementInput(achievements);
    setEditFlag(!editFlag);
  };

  return (
    <div
      className={cns(s.achievementWrapper, isMobile ? s.achievementResponsive : '')}
      ref={wrapper}
    >
      {editFlag ? (
        <div className={s.labelWrapper}>
          <div className={s.heading}>
            <h5>Achievement</h5>
            <div className={s.buttonContainer}>
              <Tooltip title={TOOLTIP_MSSG.SAVE}>
                <button onClick={handleSave}>
                  <img src={save} alt="save Icon" />
                </button>
              </Tooltip>
              <Tooltip title={TOOLTIP_MSSG.CLOSE}>
                <button onClick={handleClose}>
                  <img alt="close Icon" src={close} />
                </button>
              </Tooltip>
            </div>
          </div>
          <div className={s.description}>
            <Tooltip title="Insert comma to add multiple Achievements">
              <input
                className={cns(s.formControl, s.editInput)}
                name="achievement"
                onChange={handleChange}
                value={achievementInput}
                type="text"
                id="achievement"
              />
            </Tooltip>
          </div>
        </div>
      ) : (
        <>
          <div className={cns(s.achievementWrapper, isMobile ? s.achievementResponsive : '')}>
            <div className={s.heading}>
              <h5>Achievement</h5>
              {achievementInput?.length ? (
                <Tooltip title={TOOLTIP_MSSG.EDIT}>
                  <div className={s.editIcon}>
                    <button onClick={() => setEditFlag(!editFlag)}>
                      <img src={edit} alt="edit Icon" width={18} height={18}></img>
                    </button>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={TOOLTIP_MSSG.ADD}>
                  <div className={s.editIcon}>
                    <button className={s.addButton} onClick={() => setEditFlag(!editFlag)}>
                      <img src={addIcon} alt="add Icon"></img>
                    </button>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={s.description}>
              {loading ? (
                <>
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                </>
              ) : achievementInput?.length ? (
                <>
                  {achievementInput.split(',').map((achievement, idx) => {
                    let isEmpty = /^ *$/.test(achievement);
                    let uniqueKey = uniqueId();
                    return (
                      <React.Fragment key={uniqueKey}>
                        {!isEmpty && (
                          <div key={idx} className={s.displayDescription}>
                            {achievement}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <i className="text-secondary">No Achievement added.</i>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Achievement;
