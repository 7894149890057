import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_HRDASHBOARD_LOADING,
  GET_HRDASHBOARD_SUCCESS,
  GET_HRDASHBOARD_FAILURE,
  GET_REPORT_LOADING,
  GET_REPORT_FAILURE,
  GET_REPORT_SUCCESS
} from '../actions/actions';

// Fetch council list based on council type
export const getCouncilList = (councilType) => {
  return (dispatch) => {
    dispatch({ type: GET_HRDASHBOARD_LOADING });

    axios
      .get(
        `https://devapi.geminisolutions.com/gembook/reports/getCouncilMap?councilType=${councilType}`,
        {
          headers: { accept: '*/*' }
        }
      )
      .then((response) => {
        // Convert the response object to an array of objects
        //   const councilArray = Object.entries(response.data).map(([id, name]) => ({
        //     id: Number(id), // Convert the ID to a number
        //     name: name
        //   }));

        dispatch({
          type: GET_HRDASHBOARD_SUCCESS,
          payload: { details: response.data }
        });
      })
      .catch((error) => {
        toast.error('Could not fetch data');
        dispatch({
          type: GET_HRDASHBOARD_FAILURE,
          payload: { error: error.message }
        });
      });
  };
};

export const getDownloadReport = (councilType, councils, startDate, endDate, reportType) => {
  return (dispatch) => {
    dispatch({ type: GET_REPORT_LOADING });

    //  parameters for the API request
    const councilParams = councils ? councils.map((id) => `councils=${id}`).join('&') : '';
    const url = `https://devapi.geminisolutions.com/gembook/reports?councilType=${councilType}&${councilParams}&startDate=${startDate}&endDate=${endDate}&reportType=${reportType}`;

    axios
      .get(url, {
        headers: { accept: 'application/json' },
        responseType: 'blob', // for downloading the report as a file
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `report_${reportType}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);

        dispatch({
          type: GET_REPORT_SUCCESS,
          payload: { details: response.data }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_REPORT_FAILURE,
          payload: { error: error.message }
        });
      });
  };
};

export const getDownloadReportForAwards = (councilType, councils, startDate, endDate, reportType) => {
  return (dispatch) => {
    dispatch({ type: GET_REPORT_LOADING });

    const councilParams = councils ? councils.map((id) => `councils=${id}`).join('&') : '';
    const url = `https://devapi.geminisolutions.com/awardsevents/recognition-winner/report?councilType=${councilType}&${councilParams}&startDate=${startDate}&endDate=${endDate}&reportType=${reportType}`;

    axios
      .get(url, {
        headers: { accept: 'application/json' },
        responseType: 'blob', 
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `report_${reportType}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);

        dispatch({
          type: GET_REPORT_SUCCESS,
          payload: { details: response.data }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_REPORT_FAILURE,
          payload: { error: error.message }
        });
      });
  };
};