import React, { createContext, useState } from 'react';

export const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [marketplaceFilterBody, setMarketplaceFilterBody] = useState({
    isLookingOrLending: ['LOOKING', 'LENDING'],
    propertyTypes: ['APARTMENT', 'PG', 'INDEPENDENT_FLOOR', 'FULL_RESIDENCE'],
    numberOfBedrooms: [],
    furnishingTypes: ['FURNISHED', 'SEMI_FURNISHED', 'UNFURNISHED'],
    minPrice: 0,
    maxPrice: 100000,
    postAvailability: 'AVAILABLE',
    suitableFor: ['FAMILY', 'BACHELORS', 'ANY'],
    locations: [],
    landmarks: []
  });

  const [marketplaceCarPoolFilterBody, setMarketplaceCarPoolFilterBody] = useState({
    carPoolType: ['DRIVER_POST', 'PASSENGER_POST'],
    departLocations: [],
    destLocations: [],
    status: 'AVAILABLE',
    minCost: 0,
    maxCost: 10000,
    // departDateTime: [],
    preferredDepartDate: '',
    startTime: '',
    endTime: '',
    totalSeats: []
  });

  return (
    <MyContext.Provider
      value={{
        marketplaceFilterBody,
        setMarketplaceFilterBody,
        marketplaceCarPoolFilterBody,
        setMarketplaceCarPoolFilterBody
      }}
    >
      {children}
    </MyContext.Provider>
  );
};
