import axios from 'axios';
import { GET_TOOLS_LOADING, GET_TOOLS_SUCCESS, GET_TOOLS_FAILURE } from '../actions/actions';

export const getToolLinks = () => {
  return (dispatch) => {
    dispatch({
      type: GET_TOOLS_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/tool-links', {
        headers: {
          accept: '*/*'
        }
      })
      .then((response) => {
        const links = response.data;
        dispatch({
          type: GET_TOOLS_SUCCESS,
          payload: {
            links: links
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOOLS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
