import React, { useState } from 'react';

import tagIcon from '../../../../../../../assets/icons/tags.svg';

import s from '../../../home.module.scss';
import p from '../../../postContainer/postContainer.module.scss';
import { useParams } from 'react-router-dom';
import Posts from '../../../postContainer/posts';

const SearchedTagContainer = ({ tag }) => {
  const params = useParams();
  const [tagPosts, setTagPosts] = useState([]);

  return (
    <div className={s.searchedTagContainer}>
      <div className={s.leftTagContainer}>
        <div className={s.tagHeading}>
          <img src={tagIcon} alt="tag icon" />
          <div className={s.tagHeader}>
            <h4>#{tag}</h4>
            <p className={s.postsCount}>{tagPosts?.length ?? 0} posts</p>
          </div>
        </div>

        <div className={s.containerWrapper}>
          <div className={p.postContainer}>
            <Posts searchValue={'#' + params?.tag} setTagPosts={setTagPosts} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchedTagContainer;
