import React, { useState, useRef, useEffect } from 'react';

import s from './tags.module.scss';

import cns from 'classnames';

// icons
import { Skeleton } from '@material-ui/lab';
import { post } from 'jquery';

const Tags = ({ postTypes, handleChange, selectedPostType, handleSelectedPostType }) => {
  const [editTag, setEditTag] = useState(false);
  const [toggleOptions, setToggleOptions] = useState(false);
  const [postType, setPostType] = useState(selectedPostType);
  const [postTypeId, setPostTypeId] = useState('');
  let wrapper = useRef();
  const [question, setQuestion] = useState('');
  const handler = (event) => {
    if (!wrapper.current.contains(event.target)) {
      setEditTag(false);
    }
  };

  useEffect(() => {
    let handler = (event) => {
      if (!wrapper.current.contains(event.target)) {
        setToggleOptions(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  useEffect(() => {
    if (postTypes.length && !selectedPostType) {
      const val = postTypes.filter((row) => {
        return row.postType === 'Generic';
      });
      setPostType(val[0]);
      setPostTypeId(val[0].postTypeId);
      const ques = postTypes.filter((row) => {
        return row.postType === 'Questions';
      });
      setQuestion(ques[0]);
    }
  }, [postTypes]);
  useEffect(() => {
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [editTag]);

  const handleChangePostType = (value) => {
    setPostType(value);
    setPostTypeId(value.postTypeId);
    handleChange(value.postTypeId);
    setToggleOptions(!toggleOptions);
    handleSelectedPostType && handleSelectedPostType(value);
  };

  return (
    <div className={s.tagsContainer}>
      <div className={s.postTypeContainer} ref={wrapper}>
        <div ref={wrapper} className={cns(s.dropDown, toggleOptions ? s.active : '')}>
          <button
            className={cns(s.filterButton, s.buttonCSS)}
            onClick={() => setToggleOptions(!toggleOptions)}
          >
            {postType.iconFile ? (
              <img
                referrerPolicy="no-referrer"
                src={postType.iconFile}
                alt="post type"
                height={20}
                width={20}
                style={{marginRight:"0.3rem"}}
              ></img>
            ) : (
              <Skeleton variant="circle" height={20} width={20} />
            )}

            {postType.postType}
          </button>
          <ul>
            {postTypes.map((postType, idx) => {
              return (
                <li onClick={() => handleChangePostType(postType)} key={idx}>
                  <img src={postType?.iconFile} alt="post type" height={20} width={20} style={{display:"inline"}}></img>
                  {postType.postType}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Tags;
