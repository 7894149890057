import axios from 'axios';
import { toast } from 'react-toastify';
import { EDIT_TRAINING_LOADING, EDIT_TRAINING_SUCCESS } from '../../actions/actions';
import { getProfileData } from '../profile.actionCreator';
// import { getProfileData } from "../profile.actionCreator";

export const editTraining = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_TRAINING_LOADING
    });
    axios
      .put(
        process.env.REACT_APP_GEMBOOK_SVC_URL + '/profile',
        {
          trainingsDone: data.trainingsDone
        },
        {
          headers: {
            accept: '*/*'
          },
          params: {
            employeeId: localStorage.getItem('id')
          }
        }
      )
      .then((res) => {
        const details = res.data.object;
        toast.success('Saved Succesfully');
        dispatch({
          type: EDIT_TRAINING_SUCCESS,
          payload: {
            details: {
              ...details,
              imageURL: localStorage.getItem('imageURL')
            }
          }
        });
        dispatch(getProfileData());
      });
  };
};
