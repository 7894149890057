import React from 'react';

// Icons

import LoaderStatic from '../../../../../../assets/icons/loader-ellipse-static.svg';
import RollingLoader from '../../../../../../assets/icons/loader-ellipse-animated.svg';
import { useParams } from 'react-router-dom';
import s from '../postContainer.module.scss';

const PostLoader = ({ isLoading, error, getPosts }) => {
  const params = useParams();
  const redirectHomepage = () => {
    const url = window.location.origin + '/#/dashboard';
    window.open(url, '_self');
  };
  const loaderStatic = () => {
    return (
      <div onClick={getPosts} className="cursor-pointer">
        {!params?.id && (
          <>
            <img height={60} width={60} src={LoaderStatic} alt="loader" />
            <br />
          </>
        )}
        {error ? (
          error
        ) : params?.id ? (
          <>
            <div className={s.postError}>
              The Post might be removed or updated please contact the author for more information.
            </div>
            <div className="w-100 d-flex justify-content-center">
              <button className={s.goBackBtn} onClick={redirectHomepage}>
                Go to home
              </button>
            </div>
          </>
        ) : (
          'Click Here oree Scroll down to load more posts'
        )}
      </div>
    );
  };

  const loaderAnimated = () => {
    return (
      <>
        <img height={60} width={60} src={RollingLoader} alt="rolling loader" />
        <br />
        Fetching Posts. Please Wait...
      </>
    );
  };

  return (
    <div className="container text-center py-5 text-secondary">
      {isLoading ? loaderAnimated() : loaderStatic()}
    </div>
  );
};

export default PostLoader;
