import React, { useEffect, useState } from 'react';
import { Modal, Table, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { uniqueId } from 'lodash';
import classNames from 'classnames';

//scss
import s from './membersPage.module.scss';

//icons
import acceptIcon from '../../../../../../assets/icons/acceptIcon.svg';
import rejectIcon from '../../../../../../assets/icons/rejectIcon.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getProfileByEmail } from '../../../../../../helpers';
import SearchEmployee from './searchEmployee';
import closeIcon from '../../../../../../assets/icons/close.svg';

const RequestListTable = ({ data, joinRequestData, handleRequestAccept, handleRequestReject }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [rows, setRows] = useState([]);
  const [newData, setNewData] = useState(data);
  const [responseModal, setResponseModal] = useState(false);
  const [modalData, setModalData] = useState();

  const getProfile = (email) => {
    getProfileByEmail(email);
  };

  useEffect(() => {
    setNewData(data);
    searchQuery ? setNewData(rows) : setNewData(data);
  }, [searchQuery, data]);

  const handleViewModal = (allInfo) => {
    const data = joinRequestData?.filter((item) => item.userId === allInfo.email);
    setModalData({
      ...data[0],
      name: allInfo?.name,
      image: allInfo?.photoUrl ?? allInfo?.misPhotoUrl
    });
    setResponseModal(true);
  };

  return (
    <>
      {data?.length ? (
        <>
          <div className={s.wrapper}>
            <SearchEmployee
              list={data}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setRows={setRows}
            />
            <Table className={s.table}>
              <TableBody>
                {newData?.map((item) => {
                  item.unique_id = uniqueId();
                  return (
                    <TableRow key={item.unique_id}>
                      <TableCell className={s.tableCell}>
                        <div className={s.employeeName} onClick={() => getProfile(item?.email)}>
                          <img src={item?.photoUrl ?? item?.misPhotoUrl} />
                          <div>
                            {item?.name}
                            <br />
                            <p className={s.subInformation}>{item?.designation}</p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={s.tableCell}>
                        <Tooltip title="View Response">
                          <VisibilityIcon
                            className={s.icons}
                            color="action"
                            onClick={() => {
                              handleViewModal(item);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Accept Request">
                          <img
                            src={acceptIcon}
                            onClick={() => {
                              handleRequestAccept(item?.email);
                            }}
                            className={s.icons}
                            alt="accept Icon"
                          />
                        </Tooltip>
                        <Tooltip title="Reject Request">
                          <img
                            src={rejectIcon}
                            onClick={() => {
                              handleRequestReject(item?.email);
                            }}
                            className={s.icons}
                            alt="reject Icon"
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <Modal open={responseModal} className={s.mod}>
            <div className={s.joinReqModal}>
              <div className={s.iconDiv}>
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  className="cursor-pointer"
                  onClick={() => {
                    setResponseModal(false);
                  }}
                />
              </div>
              <div className="d-flex flex-column align-items-center mb-4">
                <h2 className="mt-4  font-weight-bold">Response submitted by</h2>
                <img
                  referrerPolicy="no-referrer"
                  src={modalData?.image}
                  alt="profileimg"
                  className={s.userImage}
                />
                <p className={s.userName}>{modalData?.name}</p>
              </div>

              {/* --------Form questions--------- */}
              <div className={s.formDiv}>
                <p className={s.label}>Q1. Do you want to join as Host or Participant?</p>
                <p className={classNames(s.formTextBox, 'mb-4')}>{modalData?.memberType}</p>
                {modalData?.memberType === 'HOST' && (
                  <>
                    <p className={s.label}>
                      Q2. Do you have any event idea on your mind that we can conduct?
                    </p>
                    <p className={s.formTextBox}>{modalData?.response}</p>
                  </>
                )}
                {modalData?.memberType === 'PARTICIPANT' && (
                  <>
                    <p className={s.label}>Q2. What do you expect from Tech Titans?</p>
                    <p className={s.formTextBox}>{modalData?.response} </p>
                  </>
                )}
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <div className={s.noRequest}>No requests</div>
      )}
    </>
  );
};

export default RequestListTable;
