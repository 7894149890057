import React from 'react';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import clsx from 'classnames';
import _ from 'lodash';
import SearchedPeople from '../home/searched-people';
import Posts from '../home/postContainer/posts';
import { getClubs } from '../../../../redux/actionCreators/clubs.actionCreator';
import { bindActionCreators } from 'redux';
import { useEffect, useState } from 'react';

//scss
import s from '../../dashboard.module.scss';
import postClass from './../home/postContainer/postContainer.module.scss';
import peopleClass from './../home/home.module.scss';

//layouts
import Sidebar from '../../../../layouts/layout1/sidebar';
import Navbar from '../../../../layouts/layout1/navbar';
import Footer from '../../../../layouts/layout1/footer';
import RightSidebar from '../home/sidebar';
import Viewport from '../../../helpers/viewport';
import AllClubsComponent from './allClubs';
import { store } from '../../../../redux/createStore';
import useCheckJwtExpiration from '../../../../hooks/useCheckJwtExpiration';
import { useMediaQuery } from 'react-responsive';

function Clubs(props) {
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [inputChangeData, setInputChangeData] = useState({
    searchValue: '',
    isPost: false
  });
  const [inputVal, setInputVal] = useState('');
  const [toggle, setToggle] = useState(isMobile ? false : true);
  const [editOnePost, setEditOnePost] = useState(false);
  const [currentEditedPost, setCurrentEditedPost] = useState(0);

  const onChange = (value, isPost) => {
    setInputChangeData({ searchValue: value, isPost: isPost });
  };

  const debouncedOnChange = useCallback(_.debounce(onChange, 500), [onChange]);

  useEffect(() => {
    if (!store.getState().clubsReducer?.clubs?.length) props.getClubs();
  }, []);

  useEffect(() => {
    isMobile ? setToggle(false) : setToggle(true);
  }, [isMobile]);
  const useJwtExpiration = useCheckJwtExpiration();
  return (
    <div className={s.dashboard}>
      <section className={s.profileSection}>
        <Navbar
          setToggle={setToggle}
          toggle={toggle}
          profileDetails={props.profileDetails}
          onChange={debouncedOnChange}
          inputVal={inputVal}
          setInputVal={setInputVal}
        />
        <div className={s.clubsWrapper}>
          <Sidebar
            toggle={toggle}
            setToggle={setToggle}
            onChange={debouncedOnChange}
            inputVal={inputVal}
            setInputVal={setInputVal}
          />
          {inputChangeData.searchValue.length ? (
            <div className={peopleClass.home}>
              {!inputChangeData.isPost ? (
                <div className={peopleClass.containerWrapper}>
                  <SearchedPeople searchValue={inputChangeData.searchValue} />
                </div>
              ) : (
                <div className={peopleClass.containerWrapper}>
                  <div className={postClass.postContainer}>
                    <Posts
                      searchValue={inputChangeData.searchValue}
                      editOnePost={editOnePost}
                      setEditOnePost={(val) => setEditOnePost(val)}
                      currentEditedPost={currentEditedPost}
                      setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
                    />
                  </div>
                </div>
              )}
              <div className={peopleClass.sidebarWrapper}>
                <RightSidebar />
              </div>
            </div>
          ) : (
            <AllClubsComponent data={props.clubs} />
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profileDetails: state.profileReducer.details,
    loading: state.clubsReducer.loading,
    error: state.clubsReducer.error,
    clubs: state.clubsReducer.clubs
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClubs: getClubs
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Clubs);
