import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

// ActionCreators
import {
  addEvent,
  editEvent,
  getClubSubChannels,
  getEvents,
  getOrganisers
} from '../../../../../../redux/actionCreators/event.actionCreator';

import cns from 'classnames';
import s from '../sidebar.module.scss';
import p from './details.module.scss';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Polls from './polls';
import { DATE_FORMAT, TIME_FORMAT, getDateFromArray } from '../../../../../../helpers';
import moment from 'moment';

const AddEvent = (props) => {
  const today = new Date().toISOString().split('T')[0];

  const regex = /\s+[a-zA-Z]+\s+\d{4}$/;

  const [state, setState] = useState({
    name: props?.editEventData?.eventName.replace(regex, '') ?? '',
    description: props?.editEventData?.eventDescription ?? '',
    startDate: getDateFromArray(props?.editEventData?.startDate, DATE_FORMAT.YYYY_MM_DD) ?? '',
    endDate: getDateFromArray(props?.editEventData?.endDate, DATE_FORMAT.YYYY_MM_DD) ?? '',
    startTime: getDateFromArray(props?.editEventData?.startDate, TIME_FORMAT.HH_MM) ?? '',
    endTime: getDateFromArray(props?.editEventData?.endDate, TIME_FORMAT.HH_MM) ?? '',
    reward: props?.editEventData?.rewards ?? '',
    winners: props?.editEventData?.eventName ?? '',
    location: props?.editEventData?.location ?? '',
    startDateType: 'text',
    endDateType: 'text',
    startTimeType: 'text',
    endTimeType: 'text',
    organizerName: props?.editEventData?.organizerName ?? props?.clubName ?? '',
    tagEvent: props?.editEventData?.tagEvent ?? '',
    isEventAdded: false,
    isError: false,
    poll: props?.editEventData?.poll?.pollId ? 'yes' : 'no',
    pollData: {
      question: props?.editEventData?.poll?.pollTitle ?? '',
      selectedDate:
        getDateFromArray(props?.editEventData?.poll?.endDate, DATE_FORMAT.YYYY_MM_DD) ?? '',
      selectedTime: getDateFromArray(props?.editEventData?.poll?.endDate, TIME_FORMAT.HH_MM) ?? ''
    }
  });

  const [isPollTimeValid, setIsPollTimeValid] = useState(true);

  useEffect(() => {
    if (props.organisers?.length === 0) {
      props.getOrganisers();
    }
    if (props?.clubName) {
      const organiser = props.organisers.find(
        (organiserD) => organiserD.clubName === props?.clubName
      );
      if (organiser) {
        props.getClubSubChannels(organiser?.clubId);
      }
    }
  }, []);

  const setPollData = (value) => {
    setState((prevState) => ({ ...prevState, pollData: value }));
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === 'organizerName') {
      const organiser = props.organisers.find((organiserD) => organiserD.clubName === value);
      if (organiser) {
        props.getClubSubChannels(organiser?.clubId);
      }
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !state?.name?.trim() ||
      !state?.organizerName?.trim() ||
      !state?.tagEvent?.trim() ||
      !state?.location?.trim() ||
      !state?.startDate?.trim() ||
      !state?.startTime?.trim() ||
      !state?.endDate?.trim() ||
      !state?.endTime?.trim() ||
      !state?.description?.trim() ||
      (state?.poll === 'yes' &&
        (!state?.pollData.question?.trim() ||
          !state?.pollData.selectedDate?.trim() ||
          !state?.pollData.selectedTime?.trim()))
    ) {
      toast.warn('Please fill all the required fields');
      setState((prevState) => ({ ...prevState, isError: true }));
      return;
    }

    const startDateTime = new Date(`${state?.startDate}T${state?.startTime}`);
    const endDateTime = new Date(`${state?.endDate}T${state?.endTime}`);

    if (startDateTime > endDateTime) {
      toast.error('Start date and time cannot be later than the end date and time.');
      return;
    }

    if (startDateTime.getTime() === endDateTime.getTime()) {
      toast.error('Start date and time cannot be the same as end date and time.');
      return;
    }

    if (new Date(state?.startDate) > new Date(state?.endDate)) {
      toast.error('Start date cannot be later than the end date.');
      return;
    }

    if (state?.poll === 'yes' && !isPollTimeValid) {
      setState((prevState) => ({ ...prevState, isError: true }));
      return;
    }

    const loadingToast = toast('Saving...', { autoClose: false, type: toast.TYPE.INFO });

    let timeFormat = state?.pollData?.selectedTime.includes('am')
      ? state?.pollData.selectedTime.split('am')[0]
      : state?.pollData.selectedTime.split('pm')[0];

    let data = {
      eventName: state?.name.replace(/\s+/g, ' ').trim(),
      eventDescription: state?.description,
      organizerName: state?.organizerName,
      startDate: `${state?.startDate}T${state?.startTime}`,
      endDate: `${state?.endDate}T${state?.endTime}`,
      organizerEmail: localStorage.getItem('email'),
      rewards: '',
      tagEvent: state?.tagEvent ?? 'general',
      location: state?.location,
      poll:
        state?.poll === 'yes'
          ? {
              pollTitle: state?.pollData.question,
              startDate: `${state?.startDate}T${state?.startTime}`,
              endDate: `${state?.pollData.selectedDate}T${timeFormat}Z`
            }
          : null
    };

    {
      props?.editEventData?.eventId
        ? props
            .editEvent(
              props?.editEventData?.eventId,
              data,
              props.organisers?.filter(
                (organiserD) => organiserD.clubName === state?.organizerName
              )[0]?.clubId
            )
            .then((response) => {
              if (response.status === 200) {
                toast.success('Event edited successfully!');
                props.getEvents();
                props.setAddEventToggle('');
              }
            })
            .catch((error) => {
              if (
                error.response &&
                error.response.status === 400 &&
                error.response.data === 'Event with this name already exists.'
              ) {
                toast.error('Event with this name already exists!');
              } else {
                toast.error('Some error has occurred. Please try again later!');
              }
            })
            .finally(() => {
              toast.dismiss(loadingToast);
            })
        : props
            .addEvent(
              data,
              props.organisers?.filter(
                (organiserD) => organiserD.clubName === state?.organizerName
              )[0]?.clubId
            )
            .then((res) => {
              if (res.status === 200) {
                toast.success('New Event Created Successfully!');
                props.setAddEventToggle(false);
                props.getEvents();
              }
            })
            .catch((error) => {
              if (
                error.response &&
                error.response.status === 400 &&
                error.response.data === 'Event with this name already exists.'
              ) {
                toast.error('Event with this name already exists!');
              } else {
                toast.error('Some error has occurred. Please try again later!');
              }
            })
            .finally(() => {
              toast.dismiss(loadingToast);
            });
    }
  };

  const handleIsPollChange = (e) => {
    setState({ ...state, poll: e.target.value });
  };

  return (
    <div className={s.addEventContainer}>
      <form onSubmit={handleSubmit}>
        <div className={s.eventDataDiv}>
          <input
            autoFocus
            className={
              state?.isError && (state?.name?.length > 50 || !state?.name?.trim())
                ? s.eventInputError
                : s.eventInput
            }
            type="text"
            placeholder="Enter Event Name *"
            name="name"
            onChange={handleChange}
            value={state?.name}
            maxLength={50}
          />
          <Tooltip arrow title={`Max 50 characters.`}>
            <p className={s.validate}>
              <InfoIcon />
            </p>
          </Tooltip>
        </div>

        <div className={s.eventDataDiv}>
          <select
            className={
              state?.isError && !state?.organizerName?.trim() ? s.eventInputError : s.eventInput
            }
            value={state?.organizerName}
            name="organizerName"
            onChange={handleChange}
          >
            <option value={''} disabled>
              Select Organiser *
            </option>
            {props?.organisers?.length &&
              [...props?.organisers]?.map((option, index) => (
                <option
                  key={index} // Use index as a fallback if no unique identifier is available
                  hidden={props.clubName && props?.clubName !== option.clubName}
                  value={option.clubName}
                >
                  {option.clubName}
                </option>
              ))}
          </select>
        </div>

        <div className={s.eventDataDiv}>
          <select
            className={
              state?.isError && !state?.tagEvent?.trim() ? s.eventInputError : s.eventInput
            }
            value={state?.tagEvent}
            name="tagEvent"
            onChange={handleChange}
            disabled={!state?.organizerName?.trim()}
          >
            <option value={''} disabled>
              Select Sub Channel *
            </option>
            {props.subChannels?.length &&
              [...props?.subChannels]?.map((option, index) => (
                <option
                  key={index} // Use index as a fallback if no unique identifier is available
                  value={option}
                >
                  {option}
                </option>
              ))}
          </select>
        </div>

        <div className={s.eventDataDiv}>
          <input
            className={
              state?.isError && (state?.location?.length > 80 || !state?.location?.trim())
                ? s.eventInputError
                : s.eventInput
            }
            type="text"
            placeholder="Enter Event Location *"
            name="location"
            onChange={handleChange}
            value={state?.location}
            maxLength={80}
          />
          <Tooltip arrow title={`Max 80 characters.`}>
            <p className={s.validate}>
              <InfoIcon />
            </p>
          </Tooltip>
        </div>

        <div className={s.eventDataDiv}>
          <input
            className={
              state?.isError && !state?.startDate
                ? `w-50 ${s.eventInputError}`
                : `w-50 ${s.eventInput}`
            }
            type={state?.startDateType}
            name="startDate"
            value={state?.startDate}
            onFocus={() => setState((prevState) => ({ ...prevState, startDateType: 'date' }))}
            onBlur={() => setState((prevState) => ({ ...prevState, startDateType: 'text' }))}
            placeholder="Select Start Date *"
            onChange={handleChange}
            min={today}
          />

          <input
            className={
              state?.isError && !state?.startTime
                ? `w-50 ${s.eventInputError}`
                : `w-50 ${s.eventInput}`
            }
            type={state?.startTimeType}
            name="startTime"
            value={state?.startTime}
            onFocus={() => setState((prevState) => ({ ...prevState, startTimeType: 'time' }))}
            placeholder="Select Start Time *"
            onChange={handleChange}
            min="14:00"
            max="16:00"
          />
        </div>
        <div className={s.eventDataDiv}>
          <input
            className={
              state?.isError && !state?.endDate
                ? `w-50 ${s.eventInputError}`
                : `w-50 ${s.eventInput}`
            }
            type={state?.endDateType}
            name="endDate"
            value={state?.endDate}
            onFocus={() => setState((prevState) => ({ ...prevState, endDateType: 'date' }))}
            onBlur={() => setState((prevState) => ({ ...prevState, endDateType: 'text' }))}
            placeholder="Select End Date *"
            onChange={handleChange}
            min={state?.startDate}
          />
          <input
            className={
              state?.isError && !state?.endTime
                ? `w-50 ${s.eventInputError}`
                : `w-50 ${s.eventInput}`
            }
            type={state?.endTimeType}
            name="endTime"
            value={state?.endTime}
            onFocus={() => setState((prevState) => ({ ...prevState, endTimeType: 'time' }))}
            placeholder="Select End Time *"
            onChange={handleChange}
            min={state?.startTime}
          />
        </div>

        <div className={s.eventDataDiv}>
          <textarea
            className={
              state?.isError && (state?.description?.length > 600 || !state?.description?.trim())
                ? s.eventDescError
                : s.eventDescription
            }
            type="text"
            placeholder="Enter Description *"
            name="description"
            value={state?.description}
            onChange={handleChange}
            maxLength={600}
          />
          <Tooltip arrow title={`Max 600 characters.`}>
            <p className={s.validate}>
              <InfoIcon />
            </p>
          </Tooltip>
        </div>

        {props?.editEventData?.poll?.pollId ? (
          <div className={s.pollQuestDiv}>
            <p className={s.eventFont}>Edit POLL</p>
          </div>
        ) : (
          <div className={s.pollQuestDiv}>
            <p className={s.eventFont}>Want to create POLL?</p>
            <span className="d-flex">
              <label htmlFor="yes" className={s.pollLabel}>
                <input
                  type="radio"
                  name="poll"
                  value="yes"
                  onChange={handleIsPollChange}
                  checked={state?.poll === 'yes'}
                />
                Yes
              </label>
              <label htmlFor="no" className={s.pollLabel}>
                <input
                  type="radio"
                  name="poll"
                  value="no"
                  onChange={handleIsPollChange}
                  checked={state?.poll === 'no'}
                />
                No
              </label>
            </span>
          </div>
        )}

        {state?.poll === 'yes' ? (
          <Polls
            event={state}
            setPollData={setPollData}
            isError={state?.isError}
            setIsPollTimeValid={setIsPollTimeValid}
          />
        ) : null}

        <div className={p.buttons}>
          <button className={p.btn} onClick={handleSubmit}>
            {props?.editEventData?.eventId ? 'Save' : 'Create Event'}
          </button>
          <button
            className={cns(p.btn, p.discardBtn)}
            onClick={() => {
              props.setAddEventToggle('');
            }}
          >
            {props?.editEventData?.eventId ? 'Cancel' : 'Discard'}
          </button>
        </div>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    organisers: state.eventReducer.organisers,
    subChannels: state.eventReducer.subChannels
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addEvent: addEvent,
      editEvent: editEvent,
      getEvents: getEvents,
      getOrganisers: getOrganisers,
      getClubSubChannels: getClubSubChannels
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
