import React, { useEffect } from 'react';

// SCSS
import styles from './profile.module.scss';
import mobileStyles from './profileMobile.module.scss';

//ActionCreator
import { getProfileData } from '../../../../redux/actionCreators/profile.actionCreator';
import { editSkill } from '../../../../redux/actionCreators/profile/skill.actionCreator';
import { editAchievement } from '../../../../redux/actionCreators/profile/achievement.actionCreator';
import { editTraining } from '../../../../redux/actionCreators/profile/training.actionCreator';
import { editProject } from '../../../../redux/actionCreators/profile/project.actionCreator';
import { editCertification } from '../../../../redux/actionCreators/profile/certification.actionCreator';
import { getManagerDataById } from '../../../../redux/actionCreators/profile.actionCreator';

// Helpers
import Viewport from '../../../helpers/viewport';

// Component
import RightWrapper from './components/rightWrapper';
import LeftWrapper from './components/leftWrapper';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//Loader
import ProfileLoader from './helpers/profileLoader';
import { getAwardsByEmail } from '../../../../redux/actionCreators/award.actionCreator';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getEducationDetails } from '../../../../redux/actionCreators/profile/education.actionCreator';
import { useMediaQuery } from 'react-responsive';

const Profiles = React.memo((props) => {
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  useEffect(() => {
    const data = {
      empId: localStorage.getItem('id'),
      email: localStorage.getItem('email')
    };
    props.getProfileData();
    props.getManagerDataById(data);
    props.getAwardsByEmail(data.email);
    props.getEducationDetails(data.empId);
  }, []);

  let s = styles;
  if (isMobile) s = mobileStyles;
  const handleDownload = (empId, format) => {
    const info = toast.info('Downloading Profile...');

    let apiUrl;

    if (format === 'pdf') {
      apiUrl = `${process.env.REACT_APP_GEMBOOK_SVC_URL}/profile/pdf?employeeId=${empId}`;
    } else if (format === 'pptx') {
      apiUrl = `${process.env.REACT_APP_GEMBOOK_SVC_URL}/profile/ppt?employeeId=${empId}`;
    } else {
      toast.error(`Unsupported format: ${format.toUpperCase()}`);
      return;
    }

    axios({
      url: apiUrl,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const fileName = `${props.details.name}.${format}`;
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        toast.dismiss(info);
        toast.success(`Profile downloaded successfully.`);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };
  const handleDownloadPDF = () => {
    handleDownload(props.details.id, 'pdf');
  };

  const handleDownloadPPT = () => {
    handleDownload(props.details.id, 'pptx');
  };

  const rightWrapperParams = {
    details: props.details,
    managerDetails: props.managerDetails,
    awardsByEmail: props.awardsByEmail,
    loading: props.loading,
    getLoading: props.getLoading,
    setInput: props.setInput,
    setInputVal: props.setInputVal
  };

  const leftWrapperParams = {
    details: props.details,
    editSkill: props.editSkill,
    editAchievement: props.editAchievement,
    editTraining: props.editTraining,
    editCertification: props.editCertification,
    editProject: props.editProject,
    edit: props.edit,
    managerDetails: props.managerDetails,
    awardsByEmail: props.awardsByEmail
  };

  return (
    <div className={s.mainWrapper}>
      <div className={s.mainWrapperInner}>
        <LeftWrapper
          {...leftWrapperParams}
          handleDownloadPDF={handleDownloadPDF}
          handleDownloadPPT={handleDownloadPPT}
        />
        {isMobile ? null : <RightWrapper {...rightWrapperParams} />}
      </div>
    </div>
  );
});

function mapStateToProps(state) {
  return {
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    details: state.profileReducer.details,
    managerDetails: state.managerDataReducer.managerDetails,
    awardsByEmail: state.awardReducer.awardsByEmail,
    getLoading: state.awardReducer.getLoading,
    educationDetails: state.educationReducer.educationDetails
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProfileData: getProfileData,
      editSkill: editSkill,
      editAchievement: editAchievement,
      editTraining: editTraining,
      editProject: editProject,
      editCertification: editCertification,
      getManagerDataById: getManagerDataById,
      getAwardsByEmail: getAwardsByEmail,
      getEducationDetails: getEducationDetails
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Profiles);
