import {
  EDIT_CLUB_DESCRIPTION_LOADING,
  EDIT_CLUB_DESCRIPTION_SUCCESS,
  EDIT_CLUB_DESCRIPTION_FAILURE,
  EDIT_COVERIMAGE_LOADING,
  EDIT_COVERIMAGE_SUCCESS,
  EDIT_COVERIMAGE_FAILURE,
  EDIT_PROFILEIMAGE_LOADING,
  EDIT_PROFILEIMAGE_SUCCESS,
  EDIT_PROFILEIMAGE_FAILURE
} from '../actions/actions';

const initialState = {
  editLoading: false,
  editError: '',
  description: null,
  coverImage: null,
  profileImage: null
};

const clubDetailsReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case EDIT_CLUB_DESCRIPTION_LOADING:
      newState.editLoading = true;
      break;
    case EDIT_CLUB_DESCRIPTION_SUCCESS:
      newState.editLoading = false;
      newState.description = action.payload.details;
      break;
    case EDIT_CLUB_DESCRIPTION_FAILURE:
      newState.editLoading = false;
      newState.editError = action.payload.error;
      break;

    case EDIT_COVERIMAGE_LOADING:
      newState.editLoading = true;
      break;
    case EDIT_COVERIMAGE_SUCCESS:
      newState.editLoading = false;
      newState.coverImage = action.payload.details;
      break;
    case EDIT_COVERIMAGE_FAILURE:
      newState.editLoading = false;
      newState.editError = action.payload.error;
      break;

    case EDIT_PROFILEIMAGE_LOADING:
      newState.editLoading = true;
      break;
    case EDIT_PROFILEIMAGE_SUCCESS:
      newState.editLoading = false;
      newState.profileImage = action.payload.details;
      break;
    case EDIT_PROFILEIMAGE_FAILURE:
      newState.editLoading = false;
      newState.editError = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default clubDetailsReducer;
