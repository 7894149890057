import {
  GET_SEARCHED_POST_LOADING,
  GET_SEARCHED_POST_SUCCESS,
  GET_SEARCHED_POST_FAILURE,
  REFRESH_POST_BY_ID_SUCCESS,
  DELETE_POST_SUCCESS_HASHTAG
} from '../actions/actions';

const initialState = {
  loading: false,
  error: '',
  addLoading: false,
  addError: '',
  posts: []
};

const searchedPostReducer = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_SEARCHED_POST_LOADING:
      newState.loading = true;
      newState.error = '';
      newState.posts = [];
      break;
    case GET_SEARCHED_POST_SUCCESS:
      newState.posts = Array.isArray(action.payload.posts) ? action.payload.posts : [];
      newState.error = Array.isArray(action.payload.posts) ? '' : 'No Records found';
      newState.loading = false;
      break;
    case GET_SEARCHED_POST_FAILURE:
      newState.loading = false;
      newState.error = action.payload.error;
      break;
    case REFRESH_POST_BY_ID_SUCCESS:
      const post = action.payload.post;
      newState.posts = newState.posts.map((p) => {
        if (parseInt(p.postId) === parseInt(post.postId)) return post;
        return p;
      });
      break;
    case DELETE_POST_SUCCESS_HASHTAG:
      const index = action.payload.postId;
      newState.error = '';
      newState.loading = false;
      newState.posts = newState.posts.filter((p) => {
        return p.postId !== index;
      });
      break;
    default:
      return state;
  }
  return newState;
};

export default searchedPostReducer;
