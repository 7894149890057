import {
  GET_POST_TYPE_LOADING,
  GET_POST_TYPE_SUCCESS,
  GET_POST_TYPE_FAILURE
} from '../actions/actions';

const initialState = {
  loading: false,
  postTypes: [],
  error: ''
};

const postTypesReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_POST_TYPE_LOADING:
      newState = {
        loading: true,
        postTypes: [],
        error: ''
      };
      break;
    case GET_POST_TYPE_SUCCESS:
      newState = {
        loading: false,
        postTypes: [...action.payload.postTypes],
        error: ''
      };
      break;
    case GET_POST_TYPE_FAILURE:
      newState = {
        loading: false,
        postTypes: newState.postTypes,
        error: action.payload.error
      };
      break;
    default:
      return state;
  }
  state = newState;
  return state;
};

export default postTypesReducer;
