import React, { useState, useEffect, useRef } from 'react';

//icons

import moreIcon from '../../../../../../assets/icons/3DotsBlue.svg';

//scss
import s from './topSection.module.scss';
import {
  editCoverImage,
  editProfileImage
} from '../../../../../../redux/actionCreators/clubDetails.actionCreator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const TopSectionEditing = ({ setCoverUploaderInput, setProfileUploaderInput, ...props }) => {
  const [dropdown, setDropdown] = useState(false);
  const inputRefProfile = useRef(null);
  const inputRefCover = useRef(null);

  const handleProfileClick = () => {
    inputRefProfile.current.click();
  };
  const handleCoverClick = () => {
    inputRefCover.current.click();
  };

  const handleCoverImgUpload = (e) => {
    let file = Array.from(e.target.files);
    setCoverUploaderInput(file);
    let data = {
      clubId: props.clubDetails.clubId,
      coverImage: file
    };
    props.editCoverImage(data);
  };
  const moreButtonRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !moreButtonRef.current.contains(event.target) &&
        dropdown &&
        !inputRefProfile.current.contains(event.target) &&
        !inputRefCover.current.contains(event.target) &&
        !event.target.closest(`.${s.dropdownDiv}`)
      ) {
        setDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdown]);

  const handleProfileImgUpload = (e) => {
    let file = Array.from(e.target.files);
    setProfileUploaderInput(file);
    let data = {
      clubId: props.clubDetails.clubId,
      profileImage: file
    };
    props.editProfileImage(data);
  };

  return (
    <div className={s.topSectionEditing}>
      <button ref={moreButtonRef} onClick={() => setDropdown(!dropdown)} className={s.moreButton}>
        <img src={moreIcon} />
      </button>
      {dropdown && (
        <>
          <div className={s.dropdownDiv}>
            <label htmlFor="profileImgUpload">
              <button onClick={() => handleProfileClick()}>Update Profile Image</button>
            </label>
            <input
              ref={inputRefProfile}
              type="file"
              name="uploaderInput"
              accept="image/png, image/jpeg"
              className={s.uploaderInput}
              onChange={(e) => handleProfileImgUpload(e)}
            />

            <label htmlFor="coverImgUpload">
              <button onClick={() => handleCoverClick()}>Update Cover Image</button>
            </label>
            <input
              ref={inputRefCover}
              type="file"
              name="uploaderInput"
              accept="image/png, image/jpeg"
              className={s.uploaderInput}
              onChange={(e2) => handleCoverImgUpload(e2)}
            />
          </div>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    clubDetails: state.clubsReducer.clubDetails
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editCoverImage: editCoverImage,
      editProfileImage: editProfileImage
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TopSectionEditing);
