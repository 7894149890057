import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import hashTagIcon from '../../../../../../assets/icons/hashtag.svg';
import { Skeleton } from '@material-ui/lab';

import {
  getAllHashtags,
  getTrendingHashtags
} from '../../../../../../redux/actionCreators/hashtag.actionCreator';
import { getSearchedPosts } from '../../../../../../redux/actionCreators/post.actionCreator';

import Axios from 'axios';
import { store } from '../../../../../../redux/createStore';

const TrendingHash = (props) => {
  const history = useHistory();
  const [trendingHashTags, setTrendingHashTags] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    props.getTrendingHashtags();
    props.getAllHashtags();
  }, []);

  useEffect(() => {
    function getPostByHashtags(hashtags) {
      hashtags[0].forEach((hash) => {
        hash !== '#' &&
          Axios.get(process.env.REACT_APP_GEMBOOK_SVC_URL + `/search/post/`, {
            headers: {
              accept: '*/*'
            },
            params: {
              post: hash
            }
          }).then((response) => {
            if (typeof response.data !== 'string') {
              let postsWithTags = filterPostsContainingTags(response.data, hash);
              let hashObj = { tagName: hash, posts: postsWithTags };
              newTrendingHashTags.push(hashObj);

              setTrendingHashTags([...newTrendingHashTags]);
            }
            setLoading(false);
          });
      });
    }

    let hashtags = props.hashtags;
    let newTrendingHashTags = [];
    if (hashtags.length) {
      setLoading(true);
      getPostByHashtags(hashtags);
    }
  }, [props.hashtags.length]);

  const filterPostsContainingTags = (data, tag) => {
    let postsWithTags = [];
    postsWithTags = data?.filter((post) => post.postContent.includes(tag));
    return postsWithTags;
  };

  const handleHashTagRedirect = (tag) => {
    props.setShowRightWrapperMobile(false);
    history.push({
      pathname: `/dashboard/tags/${tag.tagName.split('#')[1]}`,
      state: { posts: tag.posts }
    });
  };

  return (
    <div className="hashtag">
      <div className="header">
        <img src={hashTagIcon} height="25" width="25" alt="hashtag icon" className="hashtag-icon" />
        <span className="hashtag-heading">Trending</span>
      </div>
      <div className="hashtag-container">
        {loading ? (
          <div>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <hr />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <hr />
          </div>
        ) : !trendingHashTags?.length ? (
          <p className="noHashtags">No Trending hashtags found</p>
        ) : (
          trendingHashTags?.map((hashtag) => {
            hashtag.id = crypto.randomUUID();
            return (
              <React.Fragment key={hashtag.id}>
                {hashtag?.posts.length ? (
                  <div className="w-100">
                    <p className="hashtag-name" onClick={() => handleHashTagRedirect(hashtag)}>
                      {hashtag.tagName}
                    </p>
                    <p className="hashtag-posts">
                      {hashtag?.posts.length} post{hashtag?.posts.length > 1 ? 's' : ''}
                    </p>
                    <hr />
                  </div>
                ) : null}
              </React.Fragment>
            );
          })
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    hashtags: state.hashtagReducer.trendingHashtags,
    loading: state.hashtagReducer.getLoading,
    error: state.hashtagReducer.getError,
    posts: state.searchedPostReducer.posts
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllHashtags: getAllHashtags,
      getTrendingHashtags: getTrendingHashtags,
      getSearchedPosts: getSearchedPosts
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendingHash);
