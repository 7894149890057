import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import cns from 'classnames';
import classNames from 'classnames';
import { Carousel } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageModal from '../../../../../../components/Modal/imageModal';
import ConfirmationModal from '../../../../../../components/Modal/ConfirmationModal';
import { Tooltip } from '@material-ui/core';
import MediaUploadPreview from '../writePost/mediaUploadPreview';

// Helpers
import TimeAgo from '../../../../../helpers/timer';
import _, { set } from 'lodash';
import { EditorToolbar, modules, formats } from '../../../../../helpers/editorToolbar';
import {
  getProfileByEmail,
  isUserActive,
  mediaSize,
  validateMediaSize
} from '../../../../../../helpers';
import { popUpMessages } from '../../../../../../helpers';

// Scss
import s from '../postContainer.module.scss';

//Icons
import copylink from '../../../../../../assets/icons/copylink.svg';
import editpost from '../../../../../../assets/icons/editpost.svg';
import deleteIcon from '../../../../../../assets/icons/deletepost.svg';
import userIcon from '../../../../../../assets/img/user.png';

import SearchedPeople from '../../searched-people';
import Tags from '../writePost/tags';
import Skeleton from '@material-ui/lab/Skeleton';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import InfoIcon from '@material-ui/icons/Info';
import userImg from '../../../../../../assets/img/user.png';
import HashtagDropdown from '../writePost/HashtagDropdown';
import SuggestionModal from '../../../../../../components/Modal/SuggestionModal';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCustomTagData } from '../../../../../../redux/actionCreators/customTags.actionCreator';
import { bindActionCreators } from 'redux';
import CustomTags from '../writePost/customTags';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';

const PostCardBody = React.memo(
  ({
    setImageUrl,
    files,
    content,
    postType,
    user,
    time,
    isEdited,
    postId,
    deletePost,
    updatePostById,
    postTypes,
    hashtags,
    currentEditedPost,
    setCurrentEditedPost,
    setEditOnePost,
    clubId,
    clubDetails,
    postTagId,
    users,
    userData,
    ...props
  }) => {
    const [editFlag, setEditFlag] = useState(false);
    const [lastCursorPostionHashtag, setLastCursorPostionHashtag] = useState('');
    const [togglePostOptions, setTogglePostOptions] = useState(false);
    const [inputText, setInputText] = useState(content);
    const [postContentWithTagging, setPostContentWithTagging] = useState([]);
    const [isBlank, setIsBlank] = useState(false);
    const [searchInput, setSearchInput] = useState({ searchValue: '', isPost: false });
    const [showModal, setShowModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [uploaderInput, setUploaderInput] = useState([]);
    const [postTypeId, setPostTypeId] = useState(postType.postTypeId);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isMediaLoaded, setIsMediaLoaded] = useState(false);
    const [isMediaValidated, setIsMediaValidated] = useState(true);
    const [searchHashtag, setSearchHashtag] = useState(hashtags);
    const [toggleHashtagDropdown, setToggleHashtagDropdown] = useState(false);
    const [usedHashtags, setUsedHashtags] = useState([]);
    const [toggleDialog, setToggleDialog] = useState(false);
    const [postClubInfo, setPostClubInfo] = useState(clubDetails);
    const [postImg, setPostImg] = useState([{ src: null, fileType: '' }]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [tagId, setTagId] = useState(postTagId ?? null);
    const [newTagData, setNewTagData] = useState(props?.tagData);

    let wrapper = useRef();
    let editorRef = useRef();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const getCurrentCursorPosition = (editor) => {
      const selection = editor.getSelection();
      if (selection) {
        const { index, length } = selection;
        return index;
      }
    };
    const addContentToEditor = (content) => {
      const editor = editorRef?.getEditor();
      if (editor) {
        let ind = editor.getSelection()?.index || 0;
        if (!ind) {
          ind = editor.getLength() - 1;
        }
        editor.insertText(ind, content);
        editor.setSelection(ind + 1, 0);
      }
    };

    const fetchData = async (imgUrl, fileType) => {
      try {
        const uniqueKey = Date.now() + Math.floor(Math.random());
        const match = imgUrl.match(/\/images\/([^/]+)_\d+\.(.+)/);
        const fileName = match[1] + '_' + uniqueKey + '.' + match[2];

        const response = await axios.get(imgUrl, {
          responseType: 'arraybuffer',
          transformRequest: [
            (data, headers) => {
              delete headers['Authorization'];
              return data;
            }
          ]
        });

        // Create a File object from the array buffer data
        const file = new File([response.data], fileName, { type: fileType });
        return file;
      } catch (error) {
        console.error('Error fetching image:', error);
        throw error; // Rethrow the error for handling in the calling function
      }
    };

    useEffect(() => {
      let handler = (event) => {
        if (!wrapper.current.contains(event.target)) {
          setTogglePostOptions(false);
        }
      };

      document.addEventListener('mousedown', handler);
      return () => {
        document.removeEventListener('mousedown', handler);
      };
    }, []);

    useEffect(() => {
      if (!editFlag) {
        let div = document.createElement('div');
        const textNode = document.createTextNode(inputText);
        div.appendChild(textNode);

        let email = inputText.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
        let hashtags = inputText.match(/#\w+/g);

        let newInput = inputText;
        if (hashtags !== null) {
          hashtags.forEach((tag) => {
            let anchor = document.createElement('a');
            let anchorHref = `/#/dashboard/tags/${tag.split('#')[1]}`;
            anchor.href = anchorHref;
            const textNode = document.createTextNode(tag);
            anchor.appendChild(textNode);
            anchor.style.cssText = 'color:#00376b;';
            newInput = newInput.replace(tag, anchor.outerHTML);
          });
        }

        if (email !== null) {
          email.forEach((id) => {
            let anchor = document.createElement('a');
            let anchorHref =
              localStorage.getItem('email') === id ? '/#/profile/' : `/#/profile/${id}`;
            anchor.href = anchorHref;
            const textNode = document.createTextNode(id.split('@')[0].split('.').join(' '));
            anchor.appendChild(textNode);
            anchor.style.cssText = 'color:green; text-transform:capitalize; font-weight:bold';
            newInput = newInput.replace(id, anchor.outerHTML);
          });
        }
        setInputText(newInput);
      } else {
        editorRef.current.focus();
      }
    }, [editFlag]);

    const toggleModal = () => {
      setShowModal(!showModal);
      setTogglePostOptions(false);
    };
    const toggleImageModal = (index) => {
      setSelectedImageIndex(index);
      setShowImageModal(!showImageModal);
    };
    const deleteHandler = (e) => {
      e.preventDefault();
      deletePost(postId);
    };

    const validateForm = () => {
      if (!inputText) {
        toast.warn('Please write something in post.');
        return false;
      } else if (!postType.postTypeId) {
        toast.error('Error. Please select a tag for your post.');
        return false;
      }
      return true;
    };

    const editHandler = async (e) => {
      let fileArray = [];

      const promises = files.map((file) => fetchData(file.fileName, file.fileType));

      Promise.all(promises)
        .then((files) => {
          if (files.length > 0) setIsMediaLoaded(true);
          setUploaderInput(files);
        })
        .catch((error) => {
          console.error('Error fetching images:', error);
        });
      setUsedHashtags(inputText.match(/#\w+/g) ?? []);

      const val = !editFlag;
      localStorage.setItem('flag', editFlag);
      e.preventDefault();
    };

    const handleUpload = (e) => {
      let media = Array.from(e.target.files);
      e.target.value = ''; // allows to upload same file multiple times

      if (
        media[0].type.split('/')[0] === 'image' ||
        (media[0].type.split('/')[0] === 'video' && media[0].type.split('/')[1] === 'mp4')
      ) {
        let newFiles = [...uploaderInput, ...media];
        let isUnderSizeLimit = true;

        newFiles.forEach((file, index) => {
          const timestamp = Date.now();
          const extension = file.name.split('.').pop();
          const newName = `${file.name.split('.')[0]}_${timestamp}.${extension}`;

          const updatedFile = new File([file], newName, { type: file.type });

          newFiles[index] = updatedFile;

          isUnderSizeLimit =
            validateMediaSize(updatedFile.type.split('/')[0], updatedFile) && isUnderSizeLimit;
        });

        setUploaderInput(newFiles);
        setIsMediaValidated(isUnderSizeLimit);
      } else {
        toast.error('Unsupported media file');
        return;
      }
    };
    const handleDeleteUpload = (name) => {
      let newFiles = [];
      let isUnderSizeLimit = true;

      uploaderInput.forEach((e) => {
        if (e.name !== name) {
          newFiles.push(e);
          isUnderSizeLimit = validateMediaSize(e.type.split('/')[0], e) && isUnderSizeLimit;
        }
      });

      setUploaderInput(newFiles);
      setIsMediaValidated(isUnderSizeLimit);
    };

    const handleEditPostContent = (postText, type) => {
      let matches = postText.match(/<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g);

      if (matches) {
        matches.forEach((item) => {
          let x = item.match('<strong>(.*?)</strong>');

          if (x) x = x[1];
          else return postText;

          if (type === 'save')
            postText = postText.replace(
              item,
              x.split(' ').join('.').toLowerCase() + '@geminisolutions.com'
            );
          else {
            postText = postText.replace(item, `<span style="color: green";>${x}</span>`);
          }
        });
      }
      return postText;
    };

    const save = async (e) => {
      e.preventDefault();
      if (!validateForm()) return;

      let content = handleEditPostContent(inputText, 'save');

      content = content.replace(/<span style="color: green;">(.*?)<\/span>/g, function (match, g1) {
        return g1.split(' ').join('.').toLowerCase() + '@geminisolutions.com';
      });
      let data = {
        postData: {
          postContent: content,
          postId: postId,
          postTypeId: postTypeId
        },
        files: uploaderInput
      };

      let ids = { paramsPostId: params?.id };
      if (clubId) ids.clubId = clubId;
      if (tagId !== null) data.tagId = tagId;

      updatePostById(data, ids, location.pathname);
      setEditFlag(!editFlag);
      setIsMediaLoaded(false);
      setTogglePostOptions(false);
      setCurrentEditedPost(0);
      setEditOnePost(false);
    };

    const copyHandler = (e) => {
      const url = window.location.origin;
      const val = url + '/#/dashboard/' + postId;
      navigator.clipboard.writeText(val);
      setTogglePostOptions(false);
      toast.success('Copied!');
    };
    const [lastCursorPostion, setLastCursorPosition] = useState('');
    const updateInput = (content, delta, source, editor) => {
      setInputText(content);

      let newEmailsArray = [];
      if (postContentWithTagging.length > 0) {
        newEmailsArray = postContentWithTagging?.filter((email) => {
          let name = email.split('@')[0].split('.').join(' ');
          return content.includes(_.startCase(name));
        });
        setPostContentWithTagging(newEmailsArray);
      }

      // checking if input is blank
      if (
        ['<p><br></p>', '<ol><li><br></li></ol>', '<ul><li><br></li></ul>'].includes(content.trim())
      ) {
        setIsBlank(true);
      } else if (content.trim()) {
        setIsBlank(false);
      }

      // while tagging, getting the name after '@'
      const text = editor.getText(content);
      if (text.includes('@')) {
        let getName = text.match(/@[^\s@]+(\s+[^\s@]+)*/g);
        if (getName && getName[0].split(' ')[0].length >= 3) {
          setLastCursorPosition(getCurrentCursorPosition(editorRef.current.getEditor()));
          handleSearch(text.substring(text.indexOf(getName[0]), getCurrentCursorPosition(editor)));
        }
      } else if (text.includes('#') && toggleHashtagDropdown) {
        let getHashtag = text.match(/#\w+/g);
        let latestChange = delta.ops[1];
        let newHashtag = _.difference(getHashtag, usedHashtags);
        let latestCharacter = '';
        if (latestChange && latestChange.insert) {
          latestCharacter = latestChange.insert;
        }
        if (
          (latestCharacter == ' ' || latestCharacter == '\n') &&
          newHashtag &&
          newHashtag.length > 0
        ) {
          handleHashtagContent(newHashtag[0]);
        } else {
          if (newHashtag) {
            setSearchHashtag(newHashtag);
            // setToggleHashtagDropdown(true);
            setLastCursorPostionHashtag(getCurrentCursorPosition(editorRef.current.getEditor()));
          }
        }
        if (
          (latestCharacter == ' ' || latestCharacter == '\n') &&
          newHashtag &&
          newHashtag.length > 0
        ) {
          handleHashtagContent(newHashtag[0]);
        } else {
          if (newHashtag) {
            setSearchHashtag(newHashtag);
            setLastCursorPostionHashtag(getCurrentCursorPosition(editorRef.current.getEditor()));
          }
        }
      } else {
        setSearchInput({ searchValue: '', isPost: false });
        setSearchHashtag([]);
        setToggleHashtagDropdown(true);
      }
    };

    const handleSearch = (name) => {
      setSearchInput({ searchValue: name, isPost: false });
    };

    const handlePostContent = async (user) => {
      const contentText = editorRef.current.getEditor().getText();
      let textWithTag = contentText.match(/@[^\s@]+(\s+[^\s@]+)*/g)[0];
      let tagName = contentText.substring(contentText.indexOf(textWithTag), lastCursorPostion);
      let htmlTextAfterTag = inputText.substring(inputText.lastIndexOf(tagName) + tagName.length);
      const span1 = document.createElement('span');
      const span2 = document.createElement('span');
      const textNode = document.createTextNode(
        _.startCase(user.email.split('@')[0].split('.').join(' '))
      );

      span1.appendChild(textNode);
      span2.appendChild(document.createTextNode(' '));
      span1.style.cssText = 'color:green;';
      let content = handleEditPostContent(inputText, 'edit');
      let regex = content.match(/@(.*?)<\/li>/g) ? /@(.*?)<\/li>/g : /@(.*?)<\/p>/g;
      let tag = content.match(/@(.*?)<\/li>/g) ? '</li>' : '</p>';
      var htmlTagPattern = /^<\/[a-z]+>$/i;
      let name = content.replace(regex, function (match, g1) {
        return (
          span1.outerHTML +
          span2.outerHTML +
          (!htmlTextAfterTag || htmlTagPattern.test(htmlTextAfterTag) ? tag : htmlTextAfterTag)
        );
      });
      let newEmailArray = [...postContentWithTagging];
      newEmailArray.push(user.email);

      setPostContentWithTagging([...newEmailArray]);
      setInputText(name);
      setSearchInput({ searchValue: '', isPost: false });
      let taggedPerson = user.email.split('@')[0].split('.').join(' ');
      const editorEditorref = await editorRef.current.getEditor();
      const textContent = editorEditorref.getText(name);
      let taggedPersonLengthByPass = lastCursorPostion - taggedPerson.length - 2;
      editorRef.current
        .getEditor()
        .setSelection(
          textContent
            .toLowerCase()
            .indexOf(taggedPerson, taggedPersonLengthByPass > 0 ? taggedPersonLengthByPass : 0) +
            taggedPerson.length +
            1
        );
    };

    const onEmojiClick = (event, emojiData) => {
      const editor = editorRef.current.getEditor();
      const range = editor.getSelection();
      if (range && range.index !== null) {
        editor.deleteText(range.index, range.length);
        editor.insertText(range.index, emojiData.emoji);

        editor.setSelection(range.index + emojiData.emoji.length);
      } else {
        const cursorPosition = editor.getSelection(true).index;
        editor.insertText(cursorPosition, emojiData.emoji);

        editor.setSelection(cursorPosition + emojiData.emoji.length);
      }
      setInputText(editor.getText());
    };

    const getProfile = async () => {
      getProfileByEmail(user.email);
    };

    const closeEditPost = () => {
      setEditFlag(!editFlag);
      setInputText(content);
      setTogglePostOptions(false);
      setIsMediaLoaded(false);
      setCurrentEditedPost(0);
      setEditOnePost(false);
    };

    const editorConfig = useMemo(() => {
      const editorId = `_${postId}`;
      return {
        id: editorId,
        modules: modules({ id: editorId })
      };
    }, [postId]);

    const handleSkeletonLoading = (state, type) => {
      const style = {
        filter: state ? 'invert(1)' : 'invert(0)',
        display: state ? 'none' : 'block'
      };

      switch (type) {
        case 'edit':
          return (
            <div className="d-flex w-100">
              {files.map(() => (
                <Skeleton variant="rect" className={s.mediaLoader} style={style} />
              ))}
            </div>
          );

        case 'post':
          return <Skeleton variant="rect" className={s.videoSkeleton} style={style} />;
        default:
          break;
      }
    };

    const handleHashtagContent = async (tag) => {
      let allHashtags = inputText.match(/#\w+/g);

      let newHashtag = _.difference(allHashtags, usedHashtags);

      let newIndices = [];
      usedHashtags.forEach((tag) => {
        let index = inputText.indexOf(tag);
        newIndices.push(index);
      });

      let span1 = document.createElement('span');
      let span2 = document.createElement('span');

      const textNode = document.createTextNode(tag);
      span1.appendChild(textNode);
      span2.appendChild(document.createTextNode(' '));
      span1.style.cssText = 'color:#2c6db3;';

      let lastIndex = inputText.lastIndexOf(newHashtag[0]);

      let newHashtagIndex = inputText.indexOf(newHashtag[0]);
      let checkIndex = newIndices.includes(lastIndex) ? newHashtagIndex : lastIndex;

      let fullHashtag =
        inputText.slice(0, checkIndex) +
        inputText.slice(checkIndex).replace(newHashtag[0], span1.outerHTML + span2.outerHTML);

      let newUsedHashtags = [...usedHashtags];
      newUsedHashtags.push(tag);
      setUsedHashtags(newUsedHashtags);

      setInputText(fullHashtag);
      setToggleHashtagDropdown(false);
      setSearchHashtag([]);
      const editor = await editorRef.current.getEditor();
      const text = editor.getText();
      if (lastCursorPostionHashtag && tag && tag.length > 0) {
        const currentPosition = lastCursorPostionHashtag - tag?.length - 2;
        editorRef.current
          .getEditor()
          .setSelection(
            text.indexOf(tag, currentPosition > 0 ? currentPosition : 0) + tag.length + 1
          );
      }
    };

    useEffect(() => {
      if (!postClubInfo && clubId !== 0) {
        props.clubs.forEach(async (club) => {
          if (club.club.clubId === clubId) {
            setPostClubInfo({ name: club.club.name, id: clubId, imgs: [club.club.clubProfileUrl] });
          }
        });
      }
    }, [props?.clubs]);

    const handleTagAdd = (tagId) => {
      setTagId(tagId);
    };

    useEffect(() => {
      if (postTagId && postTagId !== 0)
        props.getCustomTagData(postTagId).then((res) => {
          setNewTagData(res?.data);
        });
    }, [postTagId !== 0]);

    return (
      <div ref={wrapper}>
        {editFlag ? (
          <>
            <div className={s.postHeader}>
              <div
                onClick={() => (clubId !== 0 ? history.replace(`/clubs/${clubId}`) : getProfile())}
                className={s.profilePicture}
              >
                <img
                  referrerPolicy="no-referrer"
                  onError={(e) => {
                    e.target.src = userImg;
                    e.target.onerror = null;
                  }}
                  src={clubId !== 0 ? postClubInfo?.imgs[0] : userData?.photoUrl ?? deleteIcon}
                  alt="user"
                  height={30}
                  width={30}
                />
              </div>
              <div className={s.headerText}>
                <div className={s.profileName}>
                  {clubId !== 0 ? postClubInfo?.name : userData?.name}
                </div>

                <div className={s.details}>
                  <span className="time">{TimeAgo(time)}</span>{' '}
                  {isEdited && <i>&#8226; {isEdited}</i>}
                </div>
              </div>
            </div>
            <div className={s.postContent}>
              <div>
                <div className={s.postContentText}>
                  <EditorToolbar
                    className={s.toolbar}
                    id={editorConfig.id}
                    onEmojiClick={onEmojiClick}
                    handleUpload={handleUpload}
                  />
                  <div>
                    <ReactQuill
                      value={inputText}
                      ref={editorRef}
                      formats={formats}
                      modules={editorConfig.modules}
                      preserveWhitespace={true}
                      onChange={updateInput}
                      className={s.editorStyle}
                    />
                    <HashtagDropdown
                      searchHashtag={searchHashtag}
                      toggleHashtagDropdown={toggleHashtagDropdown}
                      hashtags={hashtags}
                      handleHashtagContent={handleHashtagContent}
                    />
                    {searchInput.searchValue && (
                      <SearchedPeople
                        handlePostContent={handlePostContent}
                        searchValue={searchInput.searchValue}
                        tag={true}
                        postContent={inputText}
                        emails={postContentWithTagging}
                      />
                    )}
                  </div>
                  <div style={{ height: '150px' }}>
                    {uploaderInput.length ? (
                      <MediaUploadPreview media={uploaderInput} deleteMedia={handleDeleteUpload} />
                    ) : (
                      handleSkeletonLoading(isMediaLoaded, 'edit')
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex w-100 justify-content-end">
                <Tooltip
                  arrow
                  title={`The maximum size for uploading images - ${mediaSize.image}MB, formats are png, jpeg`}
                >
                  <p className={s.mediaValidate}>
                    <InfoIcon />
                  </p>
                </Tooltip>
              </div>
              <div className={s.btnWrapper}>
                <div className={s.tagButton}>
                  <Tags
                    postTypes={postTypes}
                    handleChange={(p) => {
                      setPostTypeId(p);
                    }}
                    selectedPostType={postType}
                  />
                  {clubId ? (
                    <CustomTags
                      clubId={clubId}
                      handleTagAdd={handleTagAdd}
                      prevTagId={newTagData?.tagName}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <button className={classNames(s.btn, s.cancelBtn)} onClick={closeEditPost}>
                    Discard
                  </button>
                  <button
                    disabled={!isMediaValidated}
                    className={!isMediaValidated ? s.disabled : classNames(s.btn, s.saveBtn)}
                    onClick={save}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={s.postHeader}>
              {(clubId !== 0 && !postClubInfo?.imgs[0]) || props.userDataLoading ? (
                <div style={{ width: '100%' }} className={s.headerLoading}>
                  <div>
                    <Skeleton animation="wave" variant="circle" width={40} height={40} />
                  </div>
                  <div style={{ width: '100%', marginLeft: '6px' }}>
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="80%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton animation="wave" height={10} width="40%" />
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={s.profilePicture}
                    onClick={() =>
                      clubId !== 0
                        ? history.replace(`/clubs/${clubId}`)
                        : userData && isUserActive(users, userData?.email) && getProfile()
                    }
                  >
                    {(clubId !== 0 && !postClubInfo?.imgs[0]) || props.userDataLoading ? (
                      <Skeleton className={s.profilePicture} variant="circle" />
                    ) : (
                      <img
                        style={{ border: clubId !== 0 ? '2px solid #38A3A5' : 'none' }}
                        referrerPolicy="no-referrer"
                        onError={(e) => {
                          e.target.src = userImg;
                          e.target.onerror = null;
                        }}
                        src={
                          clubId !== 0
                            ? postClubInfo?.imgs[0]
                            : isUserActive(users, userData?.email)
                            ? userData?.photoUrl
                            : userImg
                        }
                        alt="user"
                      />
                    )}
                  </div>
                  <div className={s.headerText}>
                    <div className={s.profileName}>
                      <button
                        className={isUserActive(users, userData?.email) ? s.click : s.notClick}
                        onClick={() =>
                          clubId !== 0
                            ? history.replace(`/clubs/${clubId}`)
                            : userData && isUserActive(users, userData?.email) && getProfile()
                        }
                      >
                        {clubId !== 0 ? (
                          postClubInfo?.name
                        ) : isUserActive(users, userData?.email) ? (
                          userData?.name
                        ) : (
                          <i>Unknown User</i>
                        )}
                        {!clubId && <p className={s.nameDesig}>{userData?.designation}</p>}
                      </button>
                      {localStorage.getItem('email') === user?.email ? (
                        <div className={cns(s.dropDown, togglePostOptions ? s.active : '')}>
                          <button onClick={() => setTogglePostOptions(!togglePostOptions)}>
                            ...
                          </button>
                          <ul className={s.deleteBtn}>
                            <Tooltip
                              title={
                                currentEditedPost !== undefined && currentEditedPost !== 0
                                  ? 'Already in Editing Mode'
                                  : 'Edit post'
                              }
                            >
                              <li
                                onClick={(e) => {
                                  editHandler(e);

                                  if (currentEditedPost !== undefined && currentEditedPost !== 0) {
                                    setToggleDialog(true);
                                  } else {
                                    setEditOnePost(true);
                                    setEditFlag(!editFlag);
                                    setCurrentEditedPost(postId);
                                  }
                                }}
                              >
                                <img
                                  width="28"
                                  height="28"
                                  style={{ display: 'inline' }}
                                  src={editpost}
                                  alt="editIcon"
                                />{' '}
                                <span>Edit post</span>
                              </li>
                            </Tooltip>
                            <li onClick={toggleModal}>
                              <img
                                width="28"
                                height="28"
                                style={{ display: 'inline' }}
                                src={deleteIcon}
                                alt="deleteIcon"
                              />
                              <span> Delete Post</span>
                            </li>
                            <li onClick={copyHandler}>
                              <img
                                width="28"
                                height="28"
                                style={{ display: 'inline' }}
                                src={copylink}
                                alt="copyIcon"
                              />
                              <span> Copy link to Post</span>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className={cns(s.dropDown, togglePostOptions ? s.active : '')}>
                          <button onClick={() => setTogglePostOptions(!togglePostOptions)}>
                            ...
                          </button>
                          <ul className={s.deleteBtn}>
                            <li onClick={copyHandler}>
                              <img src={copylink} alt="copyIcon" style={{ display: 'inline' }} />{' '}
                              Copy link to Post
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className={s.details}>
                      {clubId !== 0 && (
                        <strong className="pr-2 text-secondary">{userData?.name}</strong>
                      )}
                      <span className="time">{TimeAgo(time)}</span>{' '}
                      {isEdited && <i>&#8226; {isEdited} &#8226;</i>}
                      <Tooltip title={postType.postType}>
                        <img
                          referrerPolicy="no-referrer"
                          src={postType.iconFile}
                          alt="postType"
                          width={18}
                          height={18}
                        />
                      </Tooltip>
                    </div>

                    {postTagId ? <div className={s.tagDetails}>{newTagData?.tagName}</div> : ''}
                  </div>
                </>
              )}
            </div>
            <div className={s.postContent}>
              <div className={s.postContentText}>
                <div className="ql-editor" dangerouslySetInnerHTML={{ __html: inputText }}></div>
              </div>
              {files?.length ? (
                <div className={s.imageContainer}>
                  <Carousel
                    interval={null}
                    controls={files.length <= 1 ? false : true}
                    indicators={false}
                    variant="dark"
                    wrap={false}
                    keyboard={false}
                  >
                    {files && !props.userDataLoading ? (
                      files?.map((photo, index) => {
                        return (
                          <Carousel.Item key={photo.fileId}>
                            {photo.fileType.split('/')[0] === 'image' && (
                              <>
                                {handleSkeletonLoading(isImageLoaded, 'post')}
                                <img
                                  onClick={() => toggleImageModal(index)}
                                  referrerPolicy="no-referrer"
                                  src={photo?.fileName}
                                  alt="Not available"
                                  loading="eager"
                                  style={{ opacity: isImageLoaded ? 1 : 0, margin: 'auto' }}
                                  onLoad={() => setIsImageLoaded(true)}
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </>
                            )}
                            {photo.fileType.split('/')[0] === 'video' && (
                              <>
                                <div
                                  className={s.loaderContainer}
                                  style={{
                                    filter: isVideoLoaded ? 'invert(1)' : 'invert(0)',
                                    display: isVideoLoaded ? 'none' : 'block'
                                  }}
                                >
                                  <p className={s.videoName}>{photo.fileName}</p>
                                  <button
                                    onClick={() => setIsVideoLoaded(true)}
                                    className={s.playBtn}
                                  >
                                    <PlayCircleFilledIcon className={s.playIcon} />
                                  </button>
                                </div>
                                {isVideoLoaded && (
                                  <video
                                    width="100%"
                                    height="100%"
                                    controls
                                    style={{ opacity: isVideoLoaded ? 1 : 0 }}
                                    preload="auto"
                                  >
                                    <source
                                      src={`${process.env.REACT_APP_GEMBOOK_SVC_URL}/files/video/${photo.fileName}`}
                                    ></source>
                                  </video>
                                )}
                              </>
                            )}
                          </Carousel.Item>
                        );
                      })
                    ) : (
                      <div>
                        <Skeleton
                          variant="rect"
                          style={{ filter: 'invert(0)', minHeight: '250px' }}
                          width="100%"
                          className={`${s.mediaLoader} m-0`}
                        />
                      </div>
                    )}
                  </Carousel>
                </div>
              ) : null}
            </div>
          </>
        )}
        <ConfirmationModal
          showModal={showModal}
          toggle={toggleModal}
          heading={popUpMessages.DELETE_POST}
          message={popUpMessages.CONFIRM_DELETE_POST}
          onSuccess={(e) => {
            deleteHandler(e);
            toggleModal();
          }}
        />
        <ImageModal
          showModal={showImageModal}
          toggle={toggleImageModal}
          setShowImageModal={setShowImageModal}
          onSuccess={(e) => {
            toggleImageModal();
          }}
          postImg={postImg}
          files={files}
          selectedImageIndex={selectedImageIndex}
        />
        {toggleDialog && (
          <SuggestionModal toggleDialog={toggleDialog} setToggleDialog={setToggleDialog} />
        )}
      </div>
    );
  }
);

function mapStateToProps(state) {
  return {
    clubs: state.clubsReducer.clubs,
    tagData: state.customTagsReducer.customTagData,
    userDataLoading: state.userReducer.loading
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCustomTagData: getCustomTagData
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PostCardBody);
