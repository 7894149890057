import { EDIT_SKILL_LOADING, EDIT_SKILL_SUCCESS, EDIT_SKILL_FAILURE } from '../../actions/actions';

const initialState = {
  editLoading: false,
  editError: '',
  newSkills: null
};

const skillReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case EDIT_SKILL_LOADING:
      newState.editLoading = true;
      break;
    case EDIT_SKILL_SUCCESS:
      newState.editLoading = false;
      newState.newSkills = action.payload.details;
      break;
    case EDIT_SKILL_FAILURE:
      newState.editLoading = false;
      newState.editError = action.payload.error;
      break;
    default:
      return state;
  }
  return newState;
};

export default skillReducer;
