import axios from 'axios';
import { toast } from 'react-toastify';
import { EDIT_HOBBIES_LOADING, EDIT_HOBBIES_SUCCESS } from '../../actions/actions';

export const editHobbies = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_HOBBIES_LOADING
    });
    axios
      .put(
        process.env.REACT_APP_GEMBOOK_SVC_URL + '/profile',
        {
          hobbies: data.hobbies
        },
        {
          headers: {
            accept: '*/*'
          },
          params: {
            employeeId: localStorage.getItem('id')
          }
        }
      )
      .then((res) => {
        const details = res.data.hobbies;
        toast.success('Saved Succesfully');
        dispatch({
          type: EDIT_HOBBIES_SUCCESS,
          payload: {
            details: details
          }
        });
      });
  };
};
