import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './ReportDialogue.scss';

const ReportDialogue = ({ prevOpen, setPrevOpen }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorDescription, setErrorDescription] = useState('');

  const handleCancel = () => {
    setTitle('');
    setDescription('');
    setImages([]);
    setErrorTitle('');
    setErrorDescription('');
    setPrevOpen(false);
  };

  const validation = () => {
    let titleError = false;
    let descError = false;

    if (!title.trim()) {
      setErrorTitle('Subject is required');
      titleError = true;
    } else if (title.length > 50) {
      setErrorTitle('Subject should be less than 50 characters');
      titleError = true;
    } else {
      setErrorTitle('');
      titleError = false;
    }

    if (!description.trim()) {
      setErrorDescription('Description is required');
      descError = true;
    } else if (description.length > 400) {
      setErrorDescription('Description should be less than 400 characters');
      descError = true;
    } else {
      setErrorDescription('');
      descError = false;
    }

    return titleError || descError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorTitle('');
    setErrorDescription('');
    let isError = validation();

    if (isError) {
      return;
    }

    const apiUrl = `${process.env.REACT_APP_GEMBOOK_SVC_URL}/incident`;
    const formData = new FormData();
    const incidentRecord = {
      incidentName: title,
      incidentDescription: description,
      userEmail: localStorage.getItem('email')
    };

    formData.append('incidentRecord', JSON.stringify(incidentRecord));
    images.forEach((image) => {
      formData.append('files', image);
    });

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json'
        }
      });

      if (response.status === 200) {
        toast.success('Request has been successfully sent to Gembook DL');
        handleCancel();
      } else {
        toast.error('Failed to send request to Gembook DL. Please try again');
      }
    } catch (error) {
      if (error.response) {
        toast.error('Request Failed');
      } else if (error.request) {
        toast.error('Failed to connect to the server. Please try again later');
      } else {
        toast.error('An unexpected error occurred. Please try again later');
      }
    }
  };

  const isSupportedFileType = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return [
      'jpeg',
      'jpg',
      'png',
      'webp',
      'raw',
      'bmp',
      'gif',
      'avif',
      'jfif',
      'svg',
      'pdf',
      'pptx'
    ].includes(fileExtension);
  };

  const handleChangeFile = (e) => {
    const files = e.target.files;
    let updatedImages = [...images];
    let isFileUploaded = false;
    let uploadedFilesCount = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!isSupportedFileType(file.name)) {
        toast.error('Unsupported file type. Please upload image/pdf/pptx files');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        toast.error('File size should be less than 5MB');
        return;
      }
      if (images.length + files.length > 5) {
        toast.error('You can upload a maximum of 5 files');
        return;
      }
      if (updatedImages.findIndex((img) => img.name === file.name) !== -1) {
        isFileUploaded = true;
        uploadedFilesCount += 1;
        continue;
      }
      updatedImages.push(file);
    }

    if (isFileUploaded) {
      if (uploadedFilesCount == 1) {
        toast.error(uploadedFilesCount + ' file already uploaded');
      } else {
        toast.error(uploadedFilesCount + ' files already uploaded');
      }
    }
    setImages(updatedImages);
  };

  const handleChangeTitle = (e) => {
    const value = e.target.value;
    setTitle(value);
    if (value.trim().length > 50) {
      setErrorTitle('Subject should be less than 50 characters');
    } else if (errorTitle && value.trim().length <= 50) {
      setErrorTitle('');
    }
  };

  const handleChangeDescription = (e) => {
    const value = e.target.value;
    setDescription(value);
    if (value.trim().length > 400) {
      setErrorDescription('Description should be less than 400 characters');
    } else if (errorDescription && value.trim().length <= 400) {
      setErrorDescription('');
    }
  };

  return (
    <>
      <Dialog
        open={prevOpen}
        onClose={() => setPrevOpen(false)}
        onBackdropClick={handleCancel}
        PaperProps={{
          className: 'dialogPaper'
        }}
      >
        <DialogTitle className="dialogTitle">
          {' '}
          <b> Facing Issue? </b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="dialogContentText">
            <b> Please fill the form to report the issue you're facing </b>
          </DialogContentText>
          <TextField
            margin="dense"
            id="issueTitle"
            label="Enter Subject"
            type="text"
            multiline
            fullWidth
            required
            InputLabelProps={{
              className: 'inputLabelItalic'
            }}
            value={title}
            onChange={handleChangeTitle}
          />
          {errorTitle && (
            <b>
              {' '}
              <p className="errorText"> {errorTitle} </p>{' '}
            </b>
          )}
          <TextField
            margin="dense"
            id="issueDescription"
            label="Enter Description"
            type="text"
            multiline
            fullWidth
            required
            InputLabelProps={{
              className: 'inputLabelItalic'
            }}
            value={description}
            onChange={handleChangeDescription}
          />
          {errorDescription && (
            <b>
              {' '}
              <p className="errorText"> {errorDescription} </p>{' '}
            </b>
          )}

          <div className="filesContainer">
            <label htmlFor="file" className="chooseFileButton">
              Choose File
            </label>
            <input
              type="file"
              id="file"
              multiple
              className="fileInput"
              onChange={handleChangeFile}
            />
            <table className="fileTable">
              <thead></thead>
              <tbody>
                {images.map((file, index) => (
                  <tr key={index}>
                    <td className="fileName">{file.name}</td>
                    <td className="fileRemove">
                      <button
                        className="removeButton"
                        onClick={() => {
                          const updatedImages = [...images];
                          updatedImages.splice(index, 1);
                          setImages(updatedImages);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 0 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DialogContent>
        <div className="submitButton">
          <DialogActions>
            <Button onClick={handleCancel} color="secondary" className="dialogButton">
              Cancel
            </Button>
            <Button onClick={handleSubmit} className="dialogButton">
              <div className="submitButtonColor"> Submit </div>
            </Button>
          </DialogActions>
        </div>
        <ToastContainer />
      </Dialog>
    </>
  );
};

export default ReportDialogue;
