/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';

import s from './details.module.scss';
import { LinearProgress, makeStyles } from '@material-ui/core';

import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT, getDateFromArray } from '../../../../../../helpers';

import EventBtns from './eventBtns';

const rootStyles = {
  height: '25px',
  borderRadius: '7px',
  '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
    backgroundColor: '#fff'
  }
};

const optionStyles = makeStyles(() => ({
  root: {
    ...rootStyles,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#38A3A5'
    }
  }
}));

const DisplayPoll = React.memo(
  ({
    poll,
    editVote,
    addVote,
    getPollResponseByUser,
    setEventToggle,
    deletePoll,
    hrArr,
    handleDownload,
    event
  }) => {
    const optionClasses = optionStyles();
    const [userVoteInfo, setUserVoteInfo] = useState({
      isvoted: false,
      ans: ''
    });
    const [toggle, setToggle] = useState(false);
    const [votes, setVotes] = useState('');
    const [intervalId, setIntervalId] = useState(null);

    let today = moment().format('YYYY-MM-DD');
    let endDate = getDateFromArray(poll?.endDate, DATE_FORMAT.YYYY_MM_DD);

    const optionsArray = [
      { option: 'Yes', id: 1, score: poll?.scores?.YES ?? 0 },
      { option: 'No', id: 2, score: poll?.scores?.NO ?? 0 }
    ];

    const handleAddVote = (e, pollId, option, name) => {
      if (today > endDate || calculateTimeOrDaysLeft()?.timePeriod <= 0) {
        return;
      }
      setVotes(name);
      addVote(pollId, option.toUpperCase());
    };

    const handleEditVote = (e, pollId, option, name) => {
      if (today > endDate || calculateTimeOrDaysLeft()?.timePeriod <= 0) {
        return;
      }
      if (userVoteInfo?.ans.toLowerCase() === option.toLowerCase()) {
        return;
      }
      setVotes(name);
      editVote(pollId, option.toUpperCase());
    };

    useEffect(() => {
      const fetchPollResponse = async () => {
        if (poll?.pollId) {
          try {
            const res = await getPollResponseByUser(poll.pollId, localStorage.getItem('email'));
            if (res.data) {
              setUserVoteInfo({
                isvoted: ['NO', 'YES'].includes(res.data),
                ans: _.capitalize(res.data)
              });
            }
          } catch (err) {
            setUserVoteInfo({ isvoted: false, ans: '' });
          }
        }
      };

      fetchPollResponse();

      // Set interval to fetch poll response every 5 seconds
      const id = setInterval(fetchPollResponse, 5000);
      setIntervalId(id);

      return () => clearInterval(id); // Cleanup on unmount
    }, [poll?.scores, votes]);

    const getDiffFromNow = (type, diff) => ({
      timePeriod: diff,
      type: type
    });

    const calculateTimeOrDaysLeft = () => {
      let daysLeft = moment(endDate).diff(moment(today), 'days');
      if (daysLeft === 0) {
        let pollEndDateTime = moment(
          getDateFromArray(poll?.endDate, TIME_FORMAT.HH_MM_SS_A),
          'HH:mm:ss'
        );
        let nowMoment = moment(moment(), 'HH:mm:ss a');
        let diff = pollEndDateTime.diff(nowMoment, 'hours');

        if (diff === 0) {
          diff = pollEndDateTime.diff(nowMoment, 'minutes');
          return getDiffFromNow('minutes ', diff);
        }
        return getDiffFromNow('hours ', diff);
      }

      return getDiffFromNow('days ', daysLeft);
    };
    return (
      <>
        {poll?.pollId ? (
          <div
            className={s.pollWrapper}
            onMouseEnter={() => setToggle(true)}
            onMouseLeave={() => setToggle(false)}
          >
            {toggle &&
              today <= endDate &&
              hrArr.some(
                (hrMember) =>
                  hrMember?.email?.toLowerCase() === localStorage.getItem('email') &&
                  hrMember?.club === event?.organizerName
              ) && (
                <EventBtns
                  title={{ edit: 'Edit Poll', delete: 'Delete Poll' }}
                  handleEdit={() => setEventToggle('poll')}
                  handleDelete={() => deletePoll(poll.pollId)}
                  handleDownload={() => handleDownload(poll.pollId)}
                  editCondition={true}
                  poll={poll}
                />
              )}
            <div className="d-flex align-items-start justify-content-between position-relative">
              <p className={s.pollTitle}>{poll?.pollTitle}</p>
            </div>
            {optionsArray.map((item) => {
              let scoreInPercent =
                item.score > 0
                  ? (item.score / (optionsArray[0].score + optionsArray[1].score)) * 100
                  : 0;

              let checkUserVoted = votes === `option-${item.id}` || userVoteInfo.isvoted;
              return (
                <div key={item.id} className="d-flex align-items-start mt-2">
                  <div
                    onClick={(e) =>
                      !checkUserVoted &&
                      today <= endDate &&
                      calculateTimeOrDaysLeft()?.timePeriod > 0
                        ? handleAddVote(e, poll?.pollId, item.option, `option-${item.id}`)
                        : handleEditVote(e, poll?.pollId, item.option, `option-${item.id}`)
                    }
                    className="w-100 position-relative"
                  >
                    <p
                      className={s.pollOption}
                      style={{
                        cursor:
                          today <= endDate && calculateTimeOrDaysLeft()?.timePeriod > 0
                            ? 'pointer'
                            : 'default'
                      }}
                    >
                      <span style={{ color: scoreInPercent > 12 ? '#fff' : 'initial' }}>
                        {item.option}
                      </span>
                      <span
                        style={{ color: scoreInPercent > 92 ? '#fff' : 'initial' }}
                        className={s.pollScore}
                      >
                        {scoreInPercent ? `${scoreInPercent.toFixed(1)}%` : ''}
                      </span>
                    </p>
                    <LinearProgress
                      classes={{
                        root: optionClasses.root
                      }}
                      variant="determinate"
                      value={scoreInPercent}
                    />
                  </div>
                </div>
              );
            })}
            <p className={s.votesInfo}>
              {today > endDate || calculateTimeOrDaysLeft()?.timePeriod <= 0
                ? 'Poll closed'
                : `${calculateTimeOrDaysLeft()?.timePeriod} ${
                    calculateTimeOrDaysLeft()?.timePeriod === 1
                      ? calculateTimeOrDaysLeft()
                          ?.type.trim()
                          ?.slice(0, calculateTimeOrDaysLeft()?.type.length - 2)
                          .toLowerCase()
                      : calculateTimeOrDaysLeft()?.type.toLowerCase()
                  } left`}
            </p>
          </div>
        ) : null}
      </>
    );
  }
);

export default DisplayPoll;
