import React, { useState } from 'react';

import style from './Discussion.module.scss';
import s from '../../../home/postContainer/postContainer.module.scss';
import cns from 'classnames';

import WritePost from '../../../home/postContainer/writePost';
import Posts from '../../../home/postContainer/posts';
import { useEffect } from 'react';
import PostTypeList from '../../../home/postContainer/posts/postTypeList';
import { useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getPosts,
  getRefreshPost
} from '../../../../../../redux/actionCreators/post.actionCreator';
import TagTypeList from '../../../home/postContainer/posts/tagTypeList';

const Discussion = ({ clubId, membersDetails, clubData, ...props }) => {
  const [postType, setPostType] = useState(0);
  const [tagType, setTagType] = useState(0);
  const [editOnePost, setEditOnePost] = useState(false);
  const [currentEditedPost, setCurrentEditedPost] = useState(0);
  const [admin, setAdmin] = useState({});
  const [toggleOptions, setToggleOptions] = useState(false);
  const [tagToggleOptions, setTagToggleOptions] = useState(false);

  const wrapper = useRef();

  useEffect(() => {
    let adminDetails = membersDetails.filter((member) => member.roles.clubRoleId === 1);
    let isUserAnAdmin = adminDetails.filter(
      (member) => member.userId === localStorage.getItem('email')
    );
    setAdmin(isUserAnAdmin[0]);
  }, []);

  return (
    <div className={cns(style.wrapper, s.postContainer)} id="postContainer">
      {admin?.userId === localStorage.getItem('email') && (
        <WritePost
          clubId={clubData?.id}
          editOnePost={editOnePost}
          setEditOnePost={setEditOnePost}
          currentEditedPost={currentEditedPost}
          setCurrentEditedPost={setCurrentEditedPost}
        />
      )}
      <div className="d-flex justify-content-end">
        {(props.posts?.length > 0 || tagType !== null) && (
          <>
            <TagTypeList
              tagType={tagType}
              setTagType={setTagType}
              wrapper={wrapper}
              setToggleOptions={setTagToggleOptions}
              toggleOptions={tagToggleOptions}
              getPosts={props.getPosts}
              clubId={clubData?.id}
            />

            <PostTypeList
              wrapper={wrapper}
              setToggleOptions={setToggleOptions}
              toggleOptions={toggleOptions}
              postTypes={props.postTypes}
              postTypeId={props.postTypeId}
              setPostType={setPostType}
              postType={postType}
              getPosts={props.getPosts}
              clubId={clubData?.id}
            />
          </>
        )}
      </div>
      <Posts
        clubData={clubData}
        name={postType}
        tagType={tagType}
        editOnePost={editOnePost}
        setEditOnePost={(val) => setEditOnePost(val)}
        currentEditedPost={currentEditedPost}
        setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    postTypes: state.postTypesReducer.postTypes,
    postTypeId: state.postReducer.postTypeId,
    posts: state.postReducer.posts
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPosts: getPosts,
      getRefreshPost: getRefreshPost
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Discussion);
